import { Component, OnInit, Input, Output ,EventEmitter, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'data-Show',
  templateUrl: './data-show.component.html',
  styleUrls: ['./data-show.component.css']
})
export class DataShowComponent implements OnInit {
  @Input() show:boolean = false;//是否展示
  @Input() type: string = "noData";//展示内容类型 具体看 html文件定义
  @Input() hint = "暂无数据" ;
  @Output() outData = new EventEmitter<any>(); //输出并传递数据给调用者
  // @ViewChild('outInput') outInput :ElementRef; //在父类中的input 加上 #outInput 就能获取到input对象及其属性值 this.outInput.value
  constructor() { }
  ngOnInit() {
  }
  //点击事件并把数据传递出去
  request(info: string) {  
    this.outData.emit(info); 
   } 
}
