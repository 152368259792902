//商品订单
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OrderConfirmComponent } from "../order-confirm/order-confirm.component"
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'orderConfirm', component: OrderConfirmComponent }]),
        LazyLoadImageModule,
        FormsModule,//input [(ngModel)]需要导入这个module
    ],
    exports: [],
    declarations: [
        OrderConfirmComponent
    ],
    providers: [],
})
export class OrderConfirmModule { }