import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {FixedNavModule} from '../component/fixed-nav/fixed-nav.module';
import { shopCarRoutes } from './shop-car.routes';
import { ShopCarComponent } from './shop-car.component';
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
@NgModule({
    imports: [
        RouterModule.forRoot(shopCarRoutes),
        FixedNavModule,
        CommonModule,
        DataShowModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        ShopCarComponent
    ],
    providers: [],
})
export class ShopCarModule{}