import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-increase-capital',
  templateUrl: './increase-capital.component.html',
  styleUrls: ['./increase-capital.component.css']
})
export class IncreaseCapitalComponent implements OnInit {
  public mAmount = '';
  public balance: any;
  public isRank = false;
  public isGoSetPayPwd = false; //是否有支付密码
  // { 'id': 0, 'name': 10000, 'select': true }
  public mAmountList = [];
  // { 'id': 0, 'name': '初级', 'amount': '10000-19999', 'ratio': '10%', 'isRank': false }
  public mShareHolderList = [];
  public shareholderpay = 2;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.getDetails();
  }
  public getDetails() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.shareholder.index.addStockMoney',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.utils.getLocalStorageItem("shopid"),
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        // { 'id': 0, 'name': 10000, 'select': true }
        this.balance = result.data.balance;
        this.shareholderpay = result.data.shareholderpay;
        let moneyArr = [];
        if (result.data.list) {
          for (let i = 0; i < result.data.list.length; i++) {
            // let select = false;
            // if (i == 0) {
            //   select = true;
            // }
            // let item = {
            //   'name': parseInt(result.data.list[i].price),
            //   'select': select
            // }
            // this.mAmountList.push(item);
            moneyArr.push(result.data.list[i]);
          }
          for (let i = 0; i < result.data.money.length; i++) {
            let select = false;
            if (i == 0) {
              select = true;
            }
            let item = {
              'name': parseInt(result.data.money[i]),
              'select': select
            }
            this.mAmountList.push(item);
          }
          moneyArr.unshift({
            "level": "未分红",
            "proportion": "0.00",
            "money": "0.00"
          });
          let index = -1;
          for (let x = 0; x < moneyArr.length; x++) {
            let balance = parseFloat(this.balance);
            let money = parseFloat(moneyArr[x].money);
            if (!this.isRank) {
              if (balance < money) {
                index = x;
                this.isRank = true;
              }
            }
            moneyArr[x].isRank = false;
          }
          if (index != -1) {
            moneyArr[index - 1].isRank = true;
          } else {
            moneyArr[moneyArr.length - 1].isRank = true;
          }
          for (let j = 0; j < moneyArr.length; j++) {
            moneyArr[j].proportion = parseInt(moneyArr[j].proportion);
            if (j == moneyArr.length - 1) {
              moneyArr[j].money = parseInt(moneyArr[j].money) + "及以上"
            } else {
              let money1 = parseInt(moneyArr[j].money) + "";
              let money2 = parseInt(result.data.list[j].money + "");
              moneyArr[j].money = money1 + "-" + money2 + "以下";
            }
          }

          this.mShareHolderList = moneyArr;
        }

        // this.list = result.data;
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    })
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

  public selectAmount(i) {
    for (let j = 0; j < this.mAmountList.length; j++) {
      this.mAmountList[j].select = false;
    }
    this.mAmountList[i].select = true;
  }
  // 立即增持
  public increase() {
    // console.log("金额：" + this.mAmount);
    if (this.mAmount == undefined || this.mAmount.length <= 0) {
      let balance = 0;
      for (let i = 0; i < this.mAmountList.length; i++) {
        if (this.mAmountList[i].select) {
          balance = this.mAmountList[i].name;
        }
      }

      this.increaseCapital(balance);
    } else {
      let amountStr = (this.mAmount + '');
      var regA = amountStr.split('.').join('');
      if (!this.checknumber(regA)) {
        this.nativeService.showWarningAlert('请输入正确金额');
        return;
      }
      if (this.mAmount.length == 1) {
        if (amountStr.indexOf('.') != -1) {
          this.nativeService.showWarningAlert('请输入正确金额');
          return;
        }
      } else {
        let amountSubStart = amountStr.substring(0, 1);
        if (amountSubStart.indexOf('.') != -1) {
          this.nativeService.showWarningAlert('请输入正确金额');
          return;
        }
        let amountSub = amountStr.substring(amountStr.length - 1, amountStr.length);
        // console.log(amountSub);
        if (amountSub.indexOf('.') != -1) {
          this.nativeService.showWarningAlert('请输入正确金额');
          return;
        }
      }
      if (!(this.normalize(this.mAmount))) {
        this.nativeService.showWarningAlert('请输入正确金额');
        return;
      }
      this.increaseCapital(parseFloat(this.mAmount).toFixed(2));
    }
  }

  public increaseCapital(price) {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.order.Orderbuilder.recharge',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.utils.getLocalStorageItem("shopid"),
      'price': price
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        var orderid = result.data.orderid;
        this.router.navigate(['orderPay'], {
          queryParams: {
            'orderid': orderid,
            'orderType': 2
          }
        });
      } else {
        if(result.msg=='您还没有设置支付密码，请先设置'){
          this.isGoSetPayPwd = true ;
        }else{
          this.nativeService.showWarningAlert(result.msg);
        }
      }
    })
  }
  public normalize(event) {
    let amountF = parseFloat(event + '');
    if (isNaN(amountF)) {
      return false;
    }
    return true;
  }
  public checknumber(String) {
    // var reg = /^[0-9]+.?[0-9]*$/
    let reg = /^[0-9]+$/;
    if (reg.test(String)) {
      return true
    }
    return false
  }

  public dismiss() {
    this.isGoSetPayPwd = false;
  }
  public goToSetPayPwd() {
    this.router.navigate(['payment-password']);
  }
}
