//商品购物车
import { Component, OnInit } from '@angular/core';
import { NativeService } from "../providers/NativeService";
import { HttpService } from "../providers/HttpService"
import { Utils } from "../providers/Utils"
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-goods-cart',
  templateUrl: './goods-cart.component.html',
  styleUrls: ['./goods-cart.component.css']
})
export class GoodsCartComponent implements OnInit {
  public stockid = 0;
  public editeNow = false;//是否在编辑
  public allSelcted = false; //是否为全选
  public showLoading = false;
  public productlist = [];
  public numtotal = 0;
  public amountpay: any = 0;
  public isLogin = false;
  constructor(public routeInfo: ActivatedRoute, public nativeService: NativeService, public httpService: HttpService, public router: Router, public utils: Utils) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.stockid = queryParams.stockid == undefined ? 0 : queryParams.stockid;
    });
    this.isLogin = this.utils.getLocalStorageItem("isLogin");
    if (this.isLogin) {
      //已登陆
      this.showLoading = true;
      this.nativeService.showLoading();
      this.getListData();
    }
  }
  // 获取列表
  getListData() {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.cart.mallcart.getCart',
      'productids': 0,
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        var amountpay = 0;
        if (this.stockid == 0) {
          for (var i = 0; i < success.data.productlist.length; i++) {
            for (var j = 0; j < this.productlist.length; j++) {
              if (this.productlist[j].id == success.data.productlist[i].id) {
                success.data.productlist[i].selected = this.productlist[j].selected;

              }
            }
            if (success.data.productlist[i].selected == true) {
              amountpay = amountpay + (success.data.productlist[i].mallprice * success.data.productlist[i].num);
            }
          }
          this.productlist = success.data.productlist;
          this.numtotal = success.data.numtotal;
          this.amountpay = amountpay.toFixed(2);
        } else {

          for (var i = 0; i < success.data.productlist.length; i++) {
            for (var j = 0; j < this.productlist.length; j++) {
              if (this.productlist[j].id == success.data.productlist[i].id) {
                success.data.productlist[i].selected = this.productlist[j].selected;

              }
            }
            if (this.stockid == success.data.productlist[i].stockid) {
              success.data.productlist[i].selected = true;
            }
            if (success.data.productlist[i].selected == true) {
              amountpay = amountpay + (success.data.productlist[i].mallprice * success.data.productlist[i].num);
            }
          }
          this.productlist = success.data.productlist;
          this.numtotal = success.data.numtotal;
          this.amountpay = amountpay.toFixed(2);
        }
      } else {
        // this.nativeService.showErrorAlert(success.msg);
        if (success.msg == "登录已过期,请重新登录" || success.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.productlist = [];
          this.numtotal = 0;
          this.amountpay = 0;
        }
      }
      this.showLoading = false;
      this.nativeService.hideLoading();
    });
  }
  goodsDeal(item, add) {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': add == true ? "wap.cart.mallcart.add" : "wap.cart.mallcart.reduce",
      'pid': item.stockid,
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'num': 1
    };
    // this.showLoading = true;
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        this.getListData();
      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
      this.showLoading = false;
      this.nativeService.hideLoading();
    });
  }
  //去登陆
  gotoLogin() {
    this.utils.setLocalStorageItem('isWechatAuth', false);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
    this.router.navigate(['login']);
  }
  //选择单个商品
  singleClick(item) {
    var productlist = this.productlist;
    var amountpay = 0;
    for (var i = 0; i < productlist.length; i++) {
      if (productlist[i].id == item.id) {
        productlist[i].selected = !productlist[i].selected;
      }
      if (productlist[i].selected == true) {
        amountpay = amountpay + (productlist[i].mallprice * productlist[i].num);
      }
    }
    this.productlist = productlist;
    this.allSelcted = false;
    this.amountpay = amountpay.toFixed(2);
  }
  //商品详情
  itemClick(item) {
    this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.stockid } });
  }
  //减少数量
  removeToCar(item, idx) {
    this.goodsDeal(item, false);
  }
  //添加数量
  addToCar(item, idx) {
    this.goodsDeal(item, true);
  }
  //全选
  allClick() {

    // 
    var productlist = this.productlist;
    var amountpay = 0;
    for (var i = 0; i < productlist.length; i++) {
      productlist[i].selected = this.allSelcted ? false : true;
      if (productlist[i].selected == true) {
        amountpay = amountpay + (productlist[i].mallprice * productlist[i].num);
      }
    }
    this.productlist = productlist;
    this.allSelcted = !this.allSelcted;
    this.amountpay = amountpay.toFixed(2);
  }
  //编辑/完成
  editClick() {
    this.editeNow = !this.editeNow;
  }
  //结算/删除
  commitClick() {
    if (this.editeNow) {
      //删除
      this.delectClick();
    } else {
      //结算
      this.payClick();
    }
  }
  //删除
  delectClick() {
    var productlist = this.productlist;
    var pidList = [];
    for (var i = 0; i < productlist.length; i++) {
      if (productlist[i].selected == true) {
        pidList.push(productlist[i].stockid);
      }
    }
    var pidStr = pidList.join(",");
    if (pidStr.length > 0) {
      var param = {
        'plat': 'plat_wap',
        'openno': '1',
        'openalias': "wap.cart.mallcart.del",
        'pid': pidStr,
        'userid': this.utils.getLocalStorageItem("user_info").userid,
        'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      };
      this.showLoading = true;
      this.nativeService.showLoading();
      this.httpService.request({
        method: 'GET',
        url: 'api/index',
        data: param,
      }).then(success => {
        if (success.code == 100 && success.data) {
          this.getListData();
          this.nativeService.showSuccessAlert("删除成功");
        } else {
          this.nativeService.showErrorAlert(success.msg);
        }
        this.showLoading = false;
        this.nativeService.hideLoading();
      });
    } else {
      this.nativeService.showWarningAlert("至少选择一个商品");
    }
  }
  //结算
  payClick() {
    var productlist = this.productlist;
    var pidList = [];
    for (var i = 0; i < productlist.length; i++) {
      if (productlist[i].selected == true) {
        pidList.push(productlist[i].stockid);
      }
    }
    var pidStr = pidList.join(",");
    if (pidStr.length > 0) {
      this.clearStorageData();
      this.router.navigate(['orderConfirm'], { queryParams: { pidStr: pidStr } })
    } else {
      this.nativeService.showWarningAlert("至少选择一个商品");
    }
  }
  clearStorageData() {
    this.utils.sessionStorageRemoveItem("chooceAddress");
    this.utils.sessionStorageRemoveItem("invoiceInfo");
    this.utils.sessionStorageRemoveItem("remarkInfo");
  }

}
