import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { Utils } from '../providers/Utils';
import { HttpService } from '../providers/HttpService'

@Component({
  selector: 'app-goods-services',
  templateUrl: './goods-services.component.html',
  styleUrls: ['./goods-services.component.css']
})
export class GoodsServicesComponent implements OnInit {

  public isTabSelected = 1;
  public isNoData = false;
  public isLogin;
  public iswechat;
  public logid;
  public shopid;
  public tabList = [
    {
      'name': "未使用",
      'id': "1",
      'tabId': 1
    },
    {
      'name': "已使用",
      'id': "2",
      'tabId': 2
    },
    // {
    //   'name': "已过期",
    //   'id': "3",
    //   'tabId': 3
    // }
  ];
  public pageIndex = 1; //商品和通用券
  public productIndex = 1; //商品切换
  public generalIndex = 1; //通用券切换
  public generalUseArr = [];//未使用
  public generalOutListArr = [];//已过期
  constructor(
    public router: Router, 
    public routeInfo: ActivatedRoute, 
    public nativeService: NativeService, 
    public utils: Utils, 
    public http: HttpService) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.iswechat = queryParams.iswechat == undefined ? 0 : parseInt(queryParams.iswechat);
      this.logid = queryParams.logid;
      this.shopid = queryParams.shopid;
      var shopid = queryParams.shopid == undefined ? 0 : queryParams.shopid;
      if (shopid != 0) this.utils.setLocalStorageItem('shopid', shopid);
    });
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = isLogin;
    }
    if (!this.isLogin) {
      this.goToLogin();
    } else {
      if (this.iswechat == 1) {
        this.wechatLog();
        this.utils.setLocalStorageItem('shopid', this.shopid)
      } else {
        this.shopid = this.utils.getLocalStorageItem('shopid');
      }

      this.getGoodsServiceList();
      this.getGeneralList();
    }
  }
  // 微信消息
  public wechatLog() {
    let params = {
      'openalias': 'wap.ticket.Ticketmessage.upWxMsgStatus',
      'id': this.logid,
      'plat': 'plat_wap',
      'openno': '1',
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        // this.nativeService.showSuccessAlert("微信消息");
      } else {
        // this.wechatLog()
        // this.nativeService.showSuccessAlert("微信消息err");
      }
    })
  }
  public goToLogin() {
    this.utils.setLocalStorageItem('isWechatAuth', false);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
    this.router.navigate(['login']);
  }
  public pickOnTab(id) {
    this.isTabSelected = id;
    if (id == 1) {
      console.log('未使用：' + this.unuseArr.length + JSON.stringify(this.unuseArr));
      this.isNoData = this.isNotUnuse;
    }
    if (id == 2) {
      console.log('已使用：' + this.useArr.length + JSON.stringify(this.useArr));
      this.isNoData = this.isNotUse;
    }
    if (id == 3) {
      console.log('过期：' + this.outDateArr.length + JSON.stringify(this.outDateArr));
      this.isNoData = this.isNotOutDate;
    }
  }


  public getGoodsServiceList() {
    this.nativeService.showLoading();
    let params = {
      'openalias': 'wap.prepurchase.prepurchase.inventoryList',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
      'shopid': this.utils.getLocalStorageItem('shopid'),
    }
    // console.log(JSON.stringify(params)) ;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data)); 
        this.filterData(result.data);
      } else {
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录" || result.code == 1) {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.goToLogin();
        }
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  public useArr = new Array();
  public unuseArr = new Array();
  public outDateArr = new Array();
  public isNotUse = true;
  public isNotUnuse = true;
  public isNotOutDate = true;
  public filterData(data) {
    for (var i = 0; i < data.length; i++) {
      this.useArr[i] = {};
      this.unuseArr[i] = {};
      this.outDateArr[i] = {};
      // 店铺名字 地址
      this.useArr[i].shopname = data[i].shopname;
      this.useArr[i].address = data[i].province + data[i].cityname + data[i].areaname + data[i].streetname + data[i].addressdetail;
      this.unuseArr[i].shopname = data[i].shopname;
      this.unuseArr[i].address = data[i].province + data[i].cityname + data[i].areaname + data[i].streetname + data[i].addressdetail;
      this.outDateArr[i].shopname = data[i].shopname;
      this.outDateArr[i].address = data[i].province + data[i].cityname + data[i].areaname + data[i].streetname + data[i].addressdetail;
      // 商品  服务 套餐 ；
      var not_use_product = data[i].not_use_product;
      var use_product = data[i].use_product;
      var not_use_server = data[i].not_use_server;
      var use_server = data[i].use_server;
      var setmeal = data[i].setmeal;
      this.useArr[i].type = false;
      this.unuseArr[i].type = false;
      this.outDateArr[i].type = false;
      // 未使用 商品 服务
      if (not_use_product != undefined || not_use_server != undefined) {
        this.unuseArr[i].not_use_product = not_use_product == undefined ? [] : not_use_product;
        this.unuseArr[i].not_use_server = not_use_server == undefined ? [] : not_use_server;
        this.unuseArr[i].type = true;
        this.isNotUnuse = false;
      } else {
        this.unuseArr[i].not_use_product = [];
        this.unuseArr[i].not_use_server = [];
      }
      // 已使用商品  服务
      if (use_product != undefined || use_server != undefined) {
        this.useArr[i].use_product = use_product == undefined ? [] : use_product;
        this.useArr[i].use_server = use_server == undefined ? [] : use_server;
        this.useArr[i].type = true;
        this.isNotUse = false;
      } else {
        this.useArr[i].use_product = [];
        this.useArr[i].use_server = [];
      }
      this.useArr[i].setmeal = setmeal == undefined ? [] : setmeal;
      this.unuseArr[i].setmeal = setmeal == undefined ? [] : setmeal;
      this.outDateArr[i].setmeal = setmeal == undefined ? [] : setmeal;
      // 使用 套餐
      for (var j = 0; j < this.useArr[i].setmeal.length; j++) {
        if (this.useArr[i].setmeal[j].use != undefined) {
          this.useArr[i].type = true;
          this.useArr[i].ttype = true;
          this.isNotUse = false;
        } else {
          this.useArr[i].ttype = false;
          this.useArr[i].setmeal[j].use = [];
        }
      }
      // 未使用 套餐
      for (var j = 0; j < this.unuseArr[i].setmeal.length; j++) {
        if (this.unuseArr[i].setmeal[j].not_user != undefined) {
          this.unuseArr[i].ttype = true;
          this.unuseArr[i].type = true;
          this.isNotUnuse = false;
        } else {
          this.unuseArr[i].setmeal[j].not_user = [];
          this.unuseArr[i].ttype = false;
        }
      }
      //  过期
      for (var j = 0; j < this.outDateArr[i].setmeal.length; j++) {
        if (this.outDateArr[i].setmeal[j].out_date != undefined) {
          this.outDateArr[i].ttype = true;
          this.outDateArr[i].type = true;
          this.isNotOutDate = false;
        } else {
          this.outDateArr[i].setmeal[j].out_date = [];
          this.outDateArr[i].ttype = false;
        }
      }

      if (this.outDateArr) {

      }
    }

    this.isNotUse = true;
    this.isNotUnuse = true;
    this.isNotOutDate = true;
    for (let i = 0; i < this.unuseArr.length; i++) {
      if (this.unuseArr[i].not_use_product.length > 0) {
        this.isNotUnuse = false;
      }
      if (this.unuseArr[i].not_use_server.length > 0) {
        this.isNotUnuse = false;
      }
      for(let j =0 ; j<this.unuseArr[i].setmeal.length ; j++){
        if (this.unuseArr[i].setmeal[j].not_user != undefined && this.unuseArr[i].setmeal[j].not_user.length > 0) {
          this.isNotUnuse = false;
        }
      }
    }
    for (let i = 0; i < this.useArr.length; i++) {
      if (this.useArr[i].use_product.length > 0) {
        this.isNotUse = false;
      }
      if (this.useArr[i].use_server.length > 0) {
        this.isNotUse = false;
      }
      for(let j=0 ; j<this.useArr[i].setmeal.length ; j++){
        if (this.useArr[i].setmeal[j].use != undefined && this.useArr[i].setmeal[j].use.length > 0) {
          this.isNotUse = false;
        }
      } 
    }
    for (let i = 0; i < this.outDateArr.length; i++) {
      for(let j = 0 ; j<this.outDateArr[i].setmeal.length ; j++){
        if (this.outDateArr[i].setmeal[j].out_date != undefined && this.outDateArr[i].setmeal[j].out_date.length > 0) {
          this.isNotOutDate = false;
        }
      }
    }
    // console.log(JSON.stringify(this.unuseArr));
    this.isNoData = this.isNotUnuse;
  }

  public changePageIndex (i){
    // console.log(i);
    this.pageIndex = i;
  }

  public changeProductIndex(i){
    // console.log(i);
    this.productIndex = i;
  }

  public chagneGeneralIndex(i){
    // console.log(i);
    this.generalIndex = i;
  }

  public getGeneralList(){
    let params = {
      'openalias': 'wap.prepurchase.prepurchase.getSaasTicket',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
      'shopid': this.utils.getLocalStorageItem('shopid'),
      'status': ''
    }
    // console.log(JSON.stringify(params)) ;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(res => {
      if (res.code == 100 && res.data) {
        let useArr = res.data.noUse;
        let outListArr = res.data.outList;
        for(let i =0; i < useArr.length;i++){
          useArr[i].hideBox = 0;
          // useArr[i].price = useArr[i].price.substring(0,useArr[i].price.length-3);
        }
        for(let j =0; j < outListArr.length;j++){
          outListArr[j].hideBox = 0;
          // outListArr[j].price = outListArr[j].price.substring(0,outListArr[j].price.length-3);
        }
        this.generalUseArr = useArr;
        this.generalOutListArr = outListArr;
      } else {
        if (res.msg == "登录已过期,请重新登录" || res.msg == "请登录" || res.code == 1) {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.goToLogin();
        }
        this.nativeService.showErrorAlert(res.msg);
      }
    })
  }

  public chengeHideBox(item,type){
    if(type == 1){// 未使用
      let arr = this.generalUseArr;
      for(let i = 0; i < arr.length;i++){
        if(item.id == arr[i].id){
          if(item.hideBox == 0){
            arr[i].hideBox = 1;
          }else{
            arr[i].hideBox = 0;
          }
        }
      }

    }else if(type == 2){// 已过期
      let arr = this.generalOutListArr;
      for(let i = 0; i < arr.length;i++){
        if(item.id == arr[i].id){
          if(item.hideBox == 0){
            arr[i].hideBox = 1;
          }else{
            arr[i].hideBox = 0;
          }
        }
      }
    }
  }
}

