import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { HttpService } from "../providers/HttpService"
import { Utils } from "../providers/Utils";
import { _require } from '../providers/require_lib';
declare let Swiper: any;
@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.css', '../../assets/css/swiper.min.css']
})
export class ServiceListComponent implements OnInit {
  public showHeader = false;
  public showFooter = false;
  public noMore = false;
  public total: number = 1;
  public page: number = 1;
  public itemList = [];
  public shopid = 0;
  public categoryid = 0;
  public service_Swiper: any;
  public menuList = [];
  public menuIndex = 0;
  constructor(public routeInfo: ActivatedRoute, public router: Router, public httpService: HttpService, public nativeService: NativeService, public utils: Utils) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid == undefined ? this.utils.getLocalStorageItem('shopid') : queryParams.shopid;
      this.categoryid = queryParams.categoryid == undefined ? 0 : queryParams.categoryid;
    });
    this.getMenuData();
  }
  dataInit() {
    this.page = 1;
    this.getDataList(false);
    window.scroll(0, 0);
  }
  getMenuData() {
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': 'wap.server.serverprogram.categoryList',
      'shopid': this.shopid,
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.menuList = result.data.list;
        if (this.menuList.length > 0) {
          for (let index = 0; index < this.menuList.length; index++) {
            const element = this.menuList[index];
            if (element.id == this.categoryid) {
              this.menuIndex = index;
            }
          }
          this.swiperInit();
          this.dataInit();
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }
  getDataList(state) {
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': 'wap.server.serverprogram.serverList',
      'shopid':this.shopid,
      'page': this.page,
      'limit': 20,
      'categoryid': this.categoryid
    };
    if (state == false)   this.nativeService.showLoading();
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.total = result.data.total;
        if (this.total > 0) {
          if (state) {
            //加载更多
            this.itemList = this.itemList.concat(result.data.list);
          } else {
            //下拉刷新
            this.itemList = result.data.list;
          }
          this.noMore = this.itemList.length >= this.total ? true : false;
        } else {
          //没有数据
          this.total = 0;
          this.itemList = [];
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
      this.nativeService.hideLoading();
      this.showFooter = false;
      this.showHeader = false;
    });
  }
  onScrollDown() {
    console.log("onScrollDown");
    if (this.itemList.length < this.total) {
      this.page = 1 + this.page;
      this.showFooter = true;
      this.getDataList(true);
    }
  }

  onScrollUp() {

  }
  gotoDetails(item) {
    this.router.navigate(['serviceDetail'], { queryParams: { serviceid: item.id, shopid: this.utils.getLocalStorageItem('shopid') } });
  }
  swiperInit() {
    /* 引入：swiper.js */
    var that = this;
    _require('wap/assets/js/swiper.min.js', function () {
      /* swiper初始化 */
      that.service_Swiper = new Swiper('#header', {
        freeMode: true,
        freeModeSticky: true,
        slidesPerView: 'auto',
      });
      that.service_Swiper.slideTo(that.menuIndex);
    });
  }
  menuClick(item,clickedIndex){
    this.menuIndex = clickedIndex;
    this.service_Swiper.slideTo(clickedIndex);
    this.categoryid = item.id;
    this.dataInit();
  }

}

