import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TabsModule } from '../component/tabs/tabs.modules';
import { _require } from '../providers/require_lib';
import { GoodsDetailComponent } from "../goods-detail/goods-detail.component"
import { ClipboardModule } from 'ngx-clipboard';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'goodsDetail', component: GoodsDetailComponent}]),
        TabsModule,
        ClipboardModule,
        LazyLoadImageModule,
        DataShowModule
    ],
    exports: [],
    declarations: [
        GoodsDetailComponent
    ],
    providers: [],
})
export class GoodsDetailModule { }