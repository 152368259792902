import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";


@Component({
  selector: 'app-ticketList',
  templateUrl: './ticketList.component.html',
  styleUrls: ['./ticketList.component.css']
})
export class TicketListComponent implements OnInit {
  public ticketList: Array<any> = [];
  public ticketPage: number = 1;
  public ticketStatus: boolean = true;
  // 
  public isLogin;
  // 
  public ticketType;
  public shopId;
  constructor(
    public nativeService: NativeService,
    public utils: Utils,
    public http: HttpService,
    public routeInfo: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.ticketType = queryParams.type;
      this.shopId = queryParams.sid?queryParams.sid:'';
    });
    
    // 获取列表
    this.getList();
  }
  // 返回
  public backToPage() {
    this.nativeService.backToPage();
  }
  // 获取列表
  public getList() {
    let params = {
      'openalias': 'wap.saas.Ticket.getList',
      'plat': 'plat_wap',
      'openno': '2',
      'page': this.ticketPage,
      'limit': '10',
    }
    var that = this;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        let newArr = result.data.list;
        let oldArr = that.ticketList;
        if (newArr && newArr.length > 0) {
          if (that.ticketPage == 1) {
            that.ticketStatus = true;
            that.ticketList = newArr;
          } else {
            that.ticketStatus = true;
            that.ticketList = oldArr.concat(newArr);
          }
        } else {
          that.ticketStatus = false;
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 列表上啦加载更多
  public listMore() {
    if (this.ticketStatus) {
      this.ticketPage += 1;
      this.getList();
    }
  }
  // 通用券详情
  public goTicketDetail(item) {
    if(this.ticketType=='pt'){
      this.router.navigate(['ticketDetail'], { queryParams: { id: item.id,shopid: 0} });
    }else{
      this.router.navigate(['ticketDetail'], { queryParams: { id: item.id,shopid: this.shopId} });
    }
  }
  // 通用券立即购买
  public goToOrder(tid, sid) {
    if(!this.isLogin){
      this.router.navigate(['login']);
      return;
    }
    if(this.ticketType=='pt'){
      this.router.navigate(['ticketOrder'], { queryParams: {id: tid,sid: sid}})
    }else{
      this.router.navigate(['ticketOrder'], { queryParams: {id: tid,sid: this.shopId}})
    }
  }


}