import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TabsComponent} from '../tabs/tabs.component';
@NgModule({
    imports: [CommonModule,RouterModule],
    exports: [TabsComponent],
    declarations: [TabsComponent],
    providers: [],
})
export class TabsModule {
}