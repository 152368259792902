import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-withdraw-deposit',
  templateUrl: './withdraw-deposit.component.html',
  styleUrls: ['./withdraw-deposit.component.css']
})
export class WithdrawDepositComponent implements OnInit {

  public bankName = '' ;
  public account = '' ;
  public accountName = '' ;
  public amount = '' ;
  public isSelect = false ;
  public mWithdrawalWay = '请选择提现方式';
  public type = 0 ;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
  }

  public backToPage() {
    this.nativeService.backToPage(); 
  }

  public withdrawalWay(){
    this.isSelect = true ;
  }
  public dismiss(){
    this.isSelect = false ;
  }
  public select(type,text){
    this.isSelect = false ;
    this.mWithdrawalWay = text ;
    this.type = type ;
  }
  public confirm(){
    console.log('银行名称：'+this.bankName+'--账号：'+this.account+'--账户名称：'+this.accountName+'--金额：'+this.amount);
    if(this.mWithdrawalWay=='请选择提现方式'){
      this.nativeService.showWarningAlert('请选择提现方式');
    }else if(this.mWithdrawalWay=='支付宝'){
      this.nativeService.showWarningAlert('支付宝');
    }else if(this.mWithdrawalWay=='微信'){
      this.nativeService.showWarningAlert('微信');
    }else if(this.mWithdrawalWay=='现金'){
      this.nativeService.showWarningAlert('现金');
    }else if(this.mWithdrawalWay=='银行卡'){
      this.nativeService.showWarningAlert('银行卡');
    }
  }
}
