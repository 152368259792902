import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  public isNoData = false;// 没有数据
  public showFooter = false;// 显示加载更多
  public isNoMore = false;// 没有更多
  public page = 1;
  public total;
  public list;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.nativeService.showLoading();
    this.getData();
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  getData() {
    let params = {
      'openalias': 'wap.news.News.aboutjls',
      'plat': 'plat_wap',
      'openno': '1',
      'page': this.page,
      'limit': 20
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      this.showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        // console.log(JSON.stringify(result.data));
        this.total = result.data.total;
        if (this.page == 1) {
          if (result.data.list == undefined || result.data.list.length == 0) {
            this.isNoData = true;
          } else {
            this.list = result.data.list;
          }
        } else {
          if (result.data.list == undefined || result.data.list.length == 0) {
            this.isNoMore = true;
          } else {
            this.list = this.list.concat(result.data.list);
          }
        }

        this.page++;
        if (this.list.length > 0 && this.list.length == this.total) {
          this.isNoMore = true;
        }
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }

  // 去到详情页
  public goToDetails(alias,id){
    this.router.navigate(['news-details'],{queryParams:{'alias':alias,'id':id}});
  }

  public onScrollDown() {
    console.log('加载。。。1');
    if(this.isNoMore || this.showFooter || this.page==1)return ;
    this.showFooter = true ;
    this.getData();
  }
}
