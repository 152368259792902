import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-secoo-fixed-nav',
    templateUrl: './fixed-nav.component.html',
    styleUrls: ['./fixed-nav.component.css']
})
export class AppSecooFixedNavComponent implements OnInit {

    public nav: any[] = [
        {url: "/index", text: "首页"},
        {url: "/shopList", text: "门店"},
        {url: "/shopCar", text: "购物车"},
        {url: "/mine", text: "我的"}
    ];
    @Input() activeId: number = 0;
    constructor() {
    }

    ngOnInit() {

    }
}