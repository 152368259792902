import { NativeService } from './../../providers/NativeService';
import { Utils } from './../../providers/Utils';
import { HttpService } from './../../providers/HttpService';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
declare let DialogByZ: any;
@Component({
  selector: 'app-month-gift',
  templateUrl: './month-gift.component.html',
  styleUrls: ['./month-gift.component.css', '../../../assets/css/animate.min.css']
})
export class MonthGiftComponent implements OnInit {
  public shopid = 0;
  public isShow = false;
  public showAnimatin = false;
  public dataDict: any = {};
  public sanitizeHtml;
  public hadData = false;
  constructor(public sanitizer: DomSanitizer, public routeInfo: ActivatedRoute, public router: Router, public nativeService: NativeService, private http: HttpService, public utils: Utils) { }
  public backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid;
    });
    this.nativeService.showLoading();
    this.getData();
  }
  getData() {
    let param = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.shareholder.giftcycle.getInfo',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.shopid
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        if (result.data.id != undefined) {
          this.hadData = true;
          for (let i = 0; i < result.data.detailList.length; i++) {
            var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data.detailList[i].price));
            result.data.detailList[i].price = priceArr[0];
            result.data.detailList[i].price1 = priceArr[1];
          }
          // 本月剩余礼金
          result.data.leftTotle = this.formatNumber((result.data.amounttotal - result.data.amountget).toFixed(2));
          this.dataDict = result.data;
          this.sanitizeHtml = this.sanitizer.bypassSecurityTrustHtml(result.data.detial);
        }
      } else {
        if (result.code == 66 || result.code == 67) {
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.nativeService.showErrorAlert(result.msg);

      }
    })
  };
  /**
* @param price 价格限制处理
*/
  public formatNumber(num) {
    if (isNaN(num)) {
      return 0;
    } else {
      var index = (num + "").indexOf('.');
      if (index >= 0) {
        var arr = (num + "").split('.');
        if (arr.length > 1) {
          if (arr[1].length >= 2) {
            if (arr[1].charAt(0) == '0' && arr[1].charAt(1) == '0')
              return arr[0];
            else if (arr[1].charAt(0) == '0' && arr[1].charAt(1) != '0')
              return arr[0] + "." + arr[1].charAt(0) + arr[1].charAt(1);
            else if (arr[1].charAt(0) != '0' && arr[1].charAt(1) == '0')
              return arr[0] + "." + arr[1].charAt(0);
            else
              return arr[0] + "." + arr[1].charAt(0) + arr[1].charAt(1);
          } else {
            if (arr[1].charAt(0) == '0')
              return arr[0];
            else
              return arr[0] + "." + arr[1].charAt(0);
          }
        } else {
          return arr[0];
        }
      } else {
        return num;
      }

    }
  }
  //权益详情
  public inteDetail() {
    this.isShow = true;
    this.showAnimatin = true;
  }
  commit() {
    const that = this;
    that.showAnimatin = false;
    setTimeout(function () {
      that.isShow = false;
    }, 800);
  }
  // 领取优惠券
  public getCoupon(item) {
    this.nativeService.showLoading();
    let param = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.shareholder.giftcycle.getTicket',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.shopid,
      "cycleid": item.cycleid,
      "detailid": item.id
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      this.nativeService.hideLoading();
      var that = this;
      if (result.code == 100 && result.data) {
        DialogByZ.Confirm({
          Title: "领取成功", Content: "", BtnL: "确定", BtnR: "去使用", FunL: function () {
            //点击左按钮
            that.getData();
            DialogByZ.Close();
          }, FunR: function () {
            //点击右按钮
            DialogByZ.Close();
            that.utils.activityJump({
              'wapurl': item.url
            }, that.router);
          }
        })
      } else {
        if (result.code == 66 || result.code == 67) {
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        } else {
          DialogByZ.Alert({
            Title: "提示", Content: result.msg, BtnL: "确定", FunL: function () {
              DialogByZ.Close();
            }
          });
        }
      }
    })
  }

}

