import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SafePipe } from "../providers/SafePipe";
import { IframeShowComponent } from "../iframe-show/iframe-show.component";
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'iframeShow', component: IframeShowComponent }]),
        FormsModule,//input [(ngModel)]需要导入这个module
    ],
    exports: [],
    declarations: [
        IframeShowComponent,
        SafePipe
    ],
    providers: [SafePipe],
})
export class IframeShowModule{}