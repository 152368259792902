import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NativeService } from '../providers/NativeService';
import { HttpService } from '../providers/HttpService';
import { Utils } from '../providers/Utils';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  public account = '';
  public password = '';
  public isWechatLogin = false;
  public isWechatAuth;
  public isWechatAuthGetKey;
  public isWeixin;
  public isLogin;
  public canPush = false;
  public push = true;
  public backTo = true;
  public loginType = 2; //1:微信登录 2:账号登录  3:快捷登录
  public code = '';
  public accountHint = '请输入账号';
  public isBindOauth = false;
  public oauthkey = '';
  public title = '登录';
  public agreementStr = "<<基利斯用户协议>>"
  constructor(public zone: NgZone, public nativeService: NativeService, public router: Router, public http: HttpService, public utils: Utils) { }
  ngOnDestroy() {
    this.push = false;
    this.backTo = false;
  }
  ngOnInit() {
    // this.getRoute(this.rName)
    // this.getParamsArr(this.rName);
    // this.getParams(this.getParamsArr(this.rName));
    //判断
    this.utils.getLocalStorageItem("isLogin")
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = isLogin;
    }
    if (this.isLogin) {
      this.nativeService.showLoading();
      this.isWechatLogin = true;
      if (this.backTo) {
        this.backRet()
      }
      setTimeout(() => {
        this.backToPage();
        this.back()
      }, 500);
    }

    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    this.isWeixin = isWeixin;
    if (isWeixin) {
      this.isWechatLogin = true;
    } else {
      // console.log('isWechatWeb:false');
    }
    this.oauthkey = this.utils.getLocalStorageItem('WeChAtKeY-P');
    var key = this.utils.getLocalStorageItem('WeChAtKeY-P');
    if (key != undefined && key.length > 0) {
      this.isWechatWeb();
    }
  }
  public backRet() {
    let that = this;
    function check1() {
      if (that.backTo) {
        if (that.backTo) {
          that.nativeService.backToPage();
        }
      } else {
        setTimeout(check1, 1000);
      }
    }
    check1();
  }
  public back() {
    setTimeout(() => {
      if (this.backTo) {
        this.backToPage();
      }
    }, 500);
  }
  public isWechatWeb() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    this.isWeixin = isWeixin;
    this.isWechatAuth = this.utils.getLocalStorageItem('isWechatAuth');
    this.isWechatAuthGetKey = this.utils.getLocalStorageItem('isWechatAuthGetKey');
    if (isWeixin) {
      this.isWechatLogin = true;
      // if (this.isWechatAuth != true) this.getWechatLoginUrl();
      // this.getWechatLoginUrl();
      if (this.isWechatAuthGetKey == true) {
        this.getWechatLoginMessage();
      }
      return true;
    } else {
      // console.log('isWechatWeb:false');
      return false;
    }
  }
  public wechatAuth() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    this.isWeixin = isWeixin;
    this.isWechatAuth = this.utils.getLocalStorageItem('isWechatAuth');
    this.isWechatAuthGetKey = this.utils.getLocalStorageItem('isWechatAuthGetKey');
    if (isWeixin) {
      this.isWechatLogin = true;
      // if (this.isWechatAuth != true) this.getWechatLoginUrl();
      this.getWechatLoginUrl();
      if (this.isWechatAuthGetKey == true) {
        this.getWechatLoginMessage();
      }
      return true;
    } else {
      // console.log('isWechatWeb:false');
      return false;
    }
  }
  public data;
  public getWechatLoginMessage() {
    var that = this;
    this.nativeService.showLoading();
    that.checkOrder();
    var key = this.utils.getLocalStorageItem('WeChAtKeY-P');
    let params = {
      'openalias': "wap.member.oauth.keyinfo",
      'key': key,
      'plat': 'plat_wap',
      'openno': '1',
    }
    this.http.request({
      method: 'POST',
      url: 'api/index',
      data: params,
    }).then(result => {
      that.data = JSON.stringify(result);
      console.log("dddd:" + JSON.stringify(that.data));
      if (result.code == 100 && result.data) {
        this.utils.setLocalStorageItem('WeChAtKeY-P', '');
        this.nativeService.hideLoading();
        var status = result.data.status;
        if (status == 1) {
          that.utils.setLocalStorageItem("isLogin", true);
          that.utils.setLocalStorageItem("user_token", result.data.usertoken);
          that.utils.setLocalStorageItem("user_id", result.data.userid);
          var user_info = {
            'userid': result.data.userid,
            'usertoken': result.data.usertoken
          }
          that.utils.setLocalStorageItem("user_info", user_info);
          that.nativeService.showSuccessAlert('登录成功');
          this.utils.setLocalStorageItem('WeChAtKeY-P', '');
          that.canPush = true;
          setTimeout(() => {
            if (that.push) {
              that.backToPage();
            }
          }, 1000);
        } else if (status == 2) {
          that.nativeService.showWarningAlert('您还未绑定基利斯账号,请先绑定。');
          that.loginType = 3;
          that.isBindOauth = true;
          that.title = '基利斯账号绑定';
          that.accountHint = '请输入手机号';
          that.isWechatLogin = false;
        } else if (status == 3) {
          that.nativeService.showWarningAlert('用户取消授权，或者授权异常。');
          this.utils.setLocalStorageItem('WeChAtKeY-P', '');
          that.nativeService.backToPage();
        }
      } else {
        that.utils.setLocalStorageItem('WeChAtKeY-P', '');
        that.nativeService.hideLoading();
        that.nativeService.showErrorAlert(result.msg);
        that.nativeService.backToPage();
      }
    });
  }
  public checkOrder() {
    let that = this;
    function check() {
      if (that.canPush) {
        if (that.push) {
          that.canPush = true;
          that.nativeService.backToPage();
        }
      } else {
        setTimeout(check, 1000);
      }
    }
    check();
  }
  public getWechatLoginUrl() {
    this.nativeService.showLoading();
    let params = {
      'openalias': "wap.member.oauth.login",
      'oauthtype': 'wechat',
      'plat': 'plat_wap',
      'openno': '1',
    }
    this.http.request({
      method: 'POST',
      url: 'api/index',
      data: params,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(JSON.stringify(result.data));
        window.location.href = result.data.jumpurl;
        this.utils.setLocalStorageItem('isWechatAuth', true);
      } else {
        this.nativeService.showErrorAlert(result.msg);
        this.nativeService.backToPage();
      }
    });
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

  public goToRegister() {
    this.router.navigate(['register']);
  }

  public goToForgetPassword() {
    this.router.navigate(['forget-password'])
  }

  public login() {

    if (this.loginType == 2) {
      if (this.account.length <= 0) {
        this.nativeService.showErrorAlert("请输入账号");
        return;
      }
      if (this.password.length < 6 || this.password.length > 12) {
        this.nativeService.showErrorAlert("密码必须是6-12位");
        return;
      }
      this.submitLogin();
    }
    if (this.loginType == 3) {
      if (this.account == undefined || this.account.length == 0) {
        this.nativeService.showWarningAlert('请输入手机号!');
        return;
      }
      if (this.account.length < 11 || this.account.length > 11 || !this.checknumber(this.account) || !this.checkPhone(this.account)) {
        this.nativeService.showWarningAlert('手机号格式不对!');
        return;
      }
      if (this.code.length <= 0) {
        this.nativeService.showErrorAlert("请输入验证码");
        return;
      }
      this.submitQuickLogin();
    }
  }
  public submitQuickLogin() {
    this.nativeService.showLoading();
    var that = this;
    let params;
    if (this.oauthkey != undefined && this.oauthkey.length > 0) {
      params = {
        'openalias': "wap.member.quickLogin.login",
        'mobile': this.account,
        'code': this.code,
        'shopid': this.utils.getLocalStorageItem('shopid'),
        'oauthkey': this.oauthkey,
        'plat': 'plat_wap',
        'openno': '1',
      }
    } else {
      params = {
        'openalias': "wap.member.quickLogin.login",
        'mobile': this.account,
        'code': this.code,
        'shopid': this.utils.getLocalStorageItem('shopid'),
        'plat': 'plat_wap',
        'openno': '1',
      }
    }
    this.http.request({
      method: 'POST',
      url: 'api/index',
      data: params,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert(result.data.msg);
        this.utils.setLocalStorageItem("isLogin", true);
        this.utils.setLocalStorageItem("user_token", result.data.usertoken);
        this.utils.setLocalStorageItem("user_id", result.data.userid);
        this.utils.setLocalStorageItem("user_info", result.data);
        if (this.oauthkey != undefined && this.oauthkey.length > 0) {
          this.canPush = true;
          setTimeout(() => {
            if (that.push) {
              this.nativeService.backToPage();
            }
          }, 1000);
        } else {
          this.nativeService.backToPage();
        }
        this.utils.setLocalStorageItem('WeChAtKeY-P', '');
      } else {
        this.nativeService.hideLoading();
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }
  public submitLogin() {
    this.nativeService.showLoading();
    var that = this;
    if (this.isWeixin) {
      this.checkOrder();
    }
    let params;
    let oauthkey = this.utils.getLocalStorageItem('WeChAtKeY-P');
    if (oauthkey != undefined && oauthkey.length > 0) {
      params = {
        'openalias': "wap.member.login.login",
        'account': this.account,
        'passwd': this.password,
        'plat': 'plat_wap',
        'openno': '1',
        'oauthkey': this.utils.getLocalStorageItem('WeChAtKeY-P'),
      }
    } else {
      params = {
        'openalias': "wap.member.login.login",
        'account': this.account,
        'passwd': this.password,
        'plat': 'plat_wap',
        'openno': '1',
      }
    }
    this.http.request({
      method: 'POST',
      url: 'api/index',
      data: params,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert(result.data.msg);
        this.utils.setLocalStorageItem("isLogin", true);
        this.utils.setLocalStorageItem("user_token", result.data.usertoken);
        this.utils.setLocalStorageItem("user_id", result.data.userid);
        this.utils.setLocalStorageItem("user_info", result.data);
        if (oauthkey != undefined && oauthkey.length > 0) {
          this.canPush = true;
          setTimeout(() => {
            if (that.push) {
              this.nativeService.backToPage();
            }
          }, 1500);
        } else {
          this.nativeService.backToPage();
        }
        this.utils.setLocalStorageItem('WeChAtKeY-P', '');
      } else {
        this.nativeService.hideLoading();
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }

  // 确认微信登录
  public confirmWechatLogin() {
    this.wechatAuth();
  }
  // 微信登录
  public wechatLogin() {
    this.loginType = 1;
  }
  // 账号登录
  public toLogin() {
    this.loginType = 2;
    this.accountHint = '请输入账号';
  }
  // 快捷登录
  public quickLogin() {
    this.loginType = 3;
    this.accountHint = '请输入手机号';
  }
  public checknumber(String) {
    // var reg = /^[0-9]+.?[0-9]*$/
    let reg = /^[0-9]+$/;
    if (reg.test(String)) {
      return true
    }
    return false
  }
  public checkPhone(phone) {
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
      return false;
    }
    return true;
    // if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))) {
    //   return false;
    // }
  }
  public urlll = 'http://m.btobvip.com/#/goodsDetail?stockid=51&shopid=1';
  public isShow = false;
  public getChildData(data) {
    this.isShow = false;
    if (data != 0) this.getCodeNow(data);
    $('html, body').animate({         
      scrollTop: 0
    }, 1000);
  }
  public getCode() {
    if (this.isCode) {
      if (this.account == undefined || this.account.length == 0) {
        this.nativeService.showWarningAlert('请输入手机号!');
        return;
      }
      if (this.account.length < 11 || this.account.length > 11 || !this.checknumber(this.account) || !this.checkPhone(this.account)) {
        this.nativeService.showWarningAlert('手机号格式不对!');
        return;
      }
      this.isShow = true;
    }
  }
  public getCodeNow(data) {
    this.nativeService.showLoading();
    let params;
    params = {
      'openalias': "wap.member.quickLogin.sendCode",
      'mobile': this.account,
      'plat': 'plat_wap',
      'openno': '1',
      'verifytoken': data
    }
    this.http.request({
      method: 'POST',
      url: 'api/index',
      data: params,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        if (result.data.status == 1) {
          this.nativeService.showSuccessAlert('发送成功');
          this.resetTime();
        } else {
          this.nativeService.showWarningAlert(result.data.msg);
        }
      } else {
        this.nativeService.hideLoading();
        this.nativeService.showErrorAlert(result.msg);
      }
    });

  }
  public time = 60;
  public msg = "获取验证码"
  public isCode = true;
  public resetTime() {
    this.isCode = false;
    let that = this;
    that.time = 60;
    function countdown() {

      if (that.time > 0) {
        --that.time;
        that.msg = that.time + "s" + "后重试"
        setTimeout(countdown, 1000);
      } else {
        that.isCode = true;
        that.msg = "获取验证码"
      }
    }
    countdown();
  }

  // http://m.btobvip.com/#/shareholder?shopid=1
  public rName = 'http://m.btobvip.com/#/shareholder?shopid=6&pkey=2269_1'
  public getRoute(url) {
    console.log('url:' + url);
    let routerName = ''
    let urlArr = (url + '').split('#');
    let routerNameArr = [];
    if (urlArr.length >= 1) {
      let routerStr = urlArr[1] + '';
      routerNameArr = routerStr.split('?');
      if (routerNameArr.length >= 1) {
        let routerstring = routerNameArr[0] + '';
        routerName = (routerstring).substring(1, routerstring.length);
      } else {
        routerName = ''
      }
    } else {
      routerName = '';
    }
    console.log('routerName:' + routerName);
    return routerName;
  }

  public getParamsArr(url) {
    let paramsArr = [];
    let urlArr = (url + '').split('#');
    let routerNameArr = [];
    if (urlArr.length >= 1) {
      let routerStr = urlArr[1] + '';
      routerNameArr = routerStr.split('?');
      if (routerNameArr.length > 1) {
        let paramsstring = routerNameArr[1] + '';
        paramsArr = paramsstring.split('&');
      } else {
        paramsArr = [];
      }
    } else {
      paramsArr = [];
    }
    console.log('paramsArr:' + paramsArr);
    return paramsArr;
  }

  public getParams(paramsArr) {
    let params = {};
    if (paramsArr != undefined && paramsArr.length > 0) {
      for (let i = 0; i < paramsArr.length; i++) {
        let arr = (paramsArr[i] + '').split('=');
        let name = arr[0] + '';
        params[name] = arr[1];
      }
    } else {
      params = {};
    }
    console.log(params);
    // console.log(JSON.parse(params)); 
    // console.log(JSON.stringify(params)); 
    return params;
  }
  public clickAgreement() {
    this.router.navigate(['news-details'], { queryParams: { 'alias': "", 'id': 1 } });
  }
}
