import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DiscountCouponSelectComponent } from './discount-coupon-select.component';
import { DataShowModule } from "../component/data-show/data-show.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'discountCouponSelect', component:  DiscountCouponSelectComponent}]),
        FormsModule,
        ReactiveFormsModule,
        DataShowModule
    ],
    exports: [],
    declarations: [
        DiscountCouponSelectComponent
    ],
    providers: [],
})
// 选择优惠券
export class DiscountCouponSelectModule{

}