import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../providers/HttpService';
import { NativeService } from '../providers/NativeService';
import { Utils } from '../providers/Utils';

@Component({
  selector: 'app-shop-order-details',
  templateUrl: './shop-order-details.component.html',
  styleUrls: ['./shop-order-details.component.css']
})
export class ShopOrderDetailsComponent implements OnInit {
  public isShowPage = false;
  public orderid;
  public isDialog = false;
  public dialogMessage = '';
  public goodsPrice = '' ;//商品价格
  public discountAmount = '' ;//商品金额
  public mDetails = {
    'detail': [],
    'shopinfo': {}
  };
  constructor(public router: Router, public nativeService: NativeService, public routerInfo: ActivatedRoute, public utils: Utils, public http: HttpService) { }

  ngOnInit() {
    this.routerInfo.queryParams.subscribe(queryParams => {
      this.orderid = queryParams.orderid
    })
    // console.log(this.orderid);
    this.getDetailsMessage();
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

  // public mDetails = {
  //   'detail': [],
  //   'shopinfo': {}
  // };

  public status = '';
  public status_ = 100;
  public getDetailsMessage() {
    let params = {
      'orderid': this.orderid,
      'openalias': 'wap.order.ordermall.orderDetail',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.isShowPage = true;
        // console.log(JSON.stringify(result.data));
        this.mDetails = result.data;
        var Price = '0.0' ;
        for(let i = 0;i<result.data.detail.length ;i++){
          Price = ( parseFloat(Price)+(parseFloat(result.data.detail[i].pricetotal)) ).toFixed(2)
        }
        // this.goodsPrice = (parseFloat(result.data.productdiscount) + parseFloat(result.data.amountticket)).toFixed(2);
        this.goodsPrice = Price ; 
        // -parseFloat(result.data.productdiscount)
        this.discountAmount = (parseFloat(result.data.amountdiscount) + parseFloat(result.data.amountticket)-parseFloat(result.data.productdiscount)).toFixed(2);
        this.detectionStatus(result.data.status);
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  public detectionStatus(status) {
    this.status_ = status;
    switch (status) {
      case 0:
        this.status = "待付款"
        break;
      case 1:
        this.status = "已完成"
        break;
      case 2:
        this.status = "已取消订单"
        break;
      case 3:
        this.status = "锁单中"
        break;
      case 4:
        this.status = "申请退款中"
        break;
      case 5:
        this.status = "用户已退款"
        break;
      case 6:
        this.status = "系统已退款"
        break;
      case 7:
        this.status = "已支付"
        break;
      case 8:
        this.status = "待发货"
        break;
      case 9:
        this.status = "待收货"
        break;
      default:
        break;
    }
  }

  // 立即付款
  public payment() {
    console.log("立即付款");
    this.router.navigate(['orderPay'], {
      queryParams: {
        'orderid': this.orderid,
        'orderType': 1,
        'page': -1
      }
    });
  }

  public cancelOrder() {
    this.dialogMessage = "点击确定，订单被取消！";
    this.isDialog = true;
  }

  public cancel() {
    this.isDialog = false;
  }
  public confirm() {
    this.isDialog = false;
    if(this.dialogMessage == "点击确定，订单被取消！"){
      this.cancelOrder_();
    }else{
      this.refundOrder_();
    }
  }
  public cancelOrder_() {
    this.nativeService.showLoading();
    let params = {
      'orderid': this.orderid,
      'openalias': 'wap.order.ordermall.cancelOrder',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(JSON.stringify(result.data));
        this.nativeService.showSuccessAlert("取消订单成功");
        this.nativeService.backToPage();
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  public toRefund(){
    this.dialogMessage = "点击确定，订单申请退款！";
    this.isDialog = true;
  }

  public refundOrder_() {
    this.nativeService.showLoading();
    let params = {
      'orderid': this.orderid,
      'openalias': 'wap.order.Order.apply',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(JSON.stringify(result.data));
        this.nativeService.showSuccessAlert("申请退款成功");
        this.nativeService.backToPage();
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
}
