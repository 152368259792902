import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AddressSelectionComponent} from './address-selection.component';
@NgModule({
    imports: [CommonModule,RouterModule],
    exports: [AddressSelectionComponent],
    declarations: [AddressSelectionComponent],
    providers: [],
})
export class AddressSelectionModule {
}