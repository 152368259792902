import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-detailed-account',
  templateUrl: './detailed-account.component.html',
  styleUrls: ['./detailed-account.component.css']
})
export class DetailedAccountComponent implements OnInit {
  public mPageList = [] ;
  public isShowLoading = false;
  public isNoData = false ;
  public showFooter = false;
  public isNoMore = false;
  public page = 1;
  public total = 0;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.isShowLoading = true;
    this.getMemberLogs();
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  public getMemberLogs(){
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.Balance.memberLogs',
      'plat': 'plat_wap',
      'openno': '1',
      'page':this.page,
      'limit':10
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.showFooter = false ;
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        // console.log(JSON.stringify(result.data));
        this.total = result.data.total ;
        if (this.page == 1) {
          if (result.data.list == undefined || result.data.list.length == 0) {
            this.isNoData = true;
          } else {
            this.mPageList = result.data.list;
          }
        } else {
          if (result.data.list == undefined || result.data.list.length == 0) {
            this.isNoMore = true ;
          } else {
            this.mPageList = this.mPageList.concat(result.data.list);
          }
        }

        this.page ++ ;
        if(this.mPageList.length>0&&this.mPageList.length==this.total){
          this.isNoMore = true ;
        }
      } else {
        if(result.code == 66 || result.code == 67){
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }

  public onScrollDown() {
    // console.log('加载。。。1');
    if(this.isNoMore || this.showFooter )return ;
    this.showFooter = true ;
    this.getMemberLogs();
  }
}
