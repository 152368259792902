import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {FixedNavModule} from '../component/fixed-nav/fixed-nav.module';
import { mineRoutes } from './mine.routes';
import { MineComponent } from './mine.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
@NgModule({
    imports: [
        RouterModule.forRoot(mineRoutes),
        FixedNavModule,
        CommonModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        MineComponent
    ],
    providers: [],
})
export class MineModule {}