import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WelfareCentreComponent } from './welfare-centre.component';
import { DataShowModule } from "../../component/data-show/data-show.module";//数据状态展示
@NgModule({
  imports: [
    RouterModule.forRoot([{ path: 'welfareCentre', component: WelfareCentreComponent }]),
    CommonModule,
    DataShowModule,
  ],
  declarations: [WelfareCentreComponent]
})
export class WelfareCentreModule { }
