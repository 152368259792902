import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { SelectShopComponent } from "../select-shop/select-shop.component"
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forRoot([{ path: 'selectShop', component: SelectShopComponent }]),
        InfiniteScrollModule,
        FooterRefreshModule,
        DataShowModule,
        LazyLoadImageModule
    ],
    exports: [SelectShopComponent],
    declarations: [
        SelectShopComponent
    ],
    providers: [],
}) export class SelectShopModule { }