import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-buy-shareholder',
  templateUrl: './buy-shareholder.component.html',
  styleUrls: ['./buy-shareholder.component.css']
})
export class BuyShareholderComponent implements OnInit {
  public mTab =
    [{ 'name': '10000','isSelect':true },
    { 'name': '20000','isSelect':false },
    { 'name': '30000','isSelect':false },
    { 'name': '40000','isSelect':false },
    { 'name': '50000','isSelect':false }];
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  public select(index){
    for(let i=0;i<this.mTab.length ; i++){
      this.mTab[i].isSelect = false ;
    }
    this.mTab[index].isSelect = true ;
  }
}
