import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
import { SHARE_URL } from "../../providers/Constants";
import { DomSanitizer } from '@angular/platform-browser';
declare var wx: any;
declare let DialogByZ: any;
@Component({
  selector: 'app-generalize-detail',
  templateUrl: './generalize-detail.component.html',
  styleUrls: ['./generalize-detail.component.css', '../../../assets/css/animate.min.css']
})
export class GeneralizeDetailComponent implements OnInit {
  public isShare = false;
  public isWxShare = false;
  public sharepic = 'wap/assets/images/counpShare/xiche_1.jpg';
  public showShareLinkParams = "我是分享内容";
  public showAnimation = false;
  public mShareLinkParams = '';
  public showWXShareTip = false;
  public ticketList = [];
  public details: any;
  public title = '';
  public drawBtnText = '';
  public mType = 'generalize';
  public shareholderid = '';//分享人id
  public getticketid = '';//积客券id

  // 
  public showQRcode = false;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router, public sanitize: DomSanitizer) {
  }

  ngOnInit() {
    // generalize
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.mType = queryParams.type;
      this.shareholderid = queryParams.shareholderid;
      this.getticketid = queryParams.id;
    })
    // this.mType = 'wap';
    this.shareholderid = this.shareholderid == undefined ? '83' : this.shareholderid;
    this.getticketid = this.getticketid == undefined ? '29' : this.getticketid;

    if (this.mType == 'generalize') {
      this.title = '积客券礼包详情';
      this.drawBtnText = '一键分享礼包';
      this.getData();
    } else if (this.mType == 'wap') {
      this.title = '积客券礼包';
      this.drawBtnText = '立即领取礼包';
      this.getTicketList();
    }
  }
  public getTicketList() {
    this.nativeService.showLoading();
    let params = {
      'openalias': 'wap.ticket.ticketgetmember.getTicket',
      'plat': 'plat_wap',
      'openno': '1',
      'getticketid': this.getticketid,
      'shareholderid': this.shareholderid
    }
    this.http.request({
      'method': 'get',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.getticketid = result.data.id;
        this.mDetails = result.data;
        for (let i = 0; i < result.data.detail.length; i++) {
          result.data.detail[i].isIntroduce = false;
          // result.data.detail[i].limitprice = parseInt(result.data.detail[i].limitprice);
          result.data.detail[i].detail = this.sanitize.bypassSecurityTrustHtml(result.data.detail[i].detail);
          try {
            this.shareConfig();
            var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data.detail[i].price));
            result.data.detail[i].price = priceArr[0];
            result.data.detail[i].price1 = priceArr[1];

          } catch (err) {

          }
        }
        this.ticketList = result.data.detail;
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    })
  }
  public getData() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.shareholder.index.ticketInfo',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.utils.getLocalStorageItem("shopid"),
    }
    this.http.request({
      'method': 'get',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.getticketid = result.data.id;
        this.mDetails = result.data;
        for (let i = 0; i < result.data.detail.length; i++) {
          result.data.detail[i].isIntroduce = false;
          // result.data.detail[i].limitprice = parseInt(result.data.detail[i].limitprice);
          result.data.detail[i].detail = this.sanitize.bypassSecurityTrustHtml(result.data.detail[i].detail);
          try {
            this.shareConfig();
            var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data.detail[i].price));
            result.data.detail[i].price = priceArr[0];
            result.data.detail[i].price1 = priceArr[1];

          } catch (err) {

          }
        }
        this.ticketList = result.data.detail;
      } else {
        if(result.code == 66 || result.code == 67){
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }
  // 
  public showIntroduce(index) {
    this.ticketList[index].isIntroduce = !this.ticketList[index].isIntroduce;
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  // 分享显示
  public share() {
    if (this.isWeixin()) {
      this.showWXShareTip = true;
      this.getShareDataAndReady(this.data);
      setTimeout(() => {
        this.showWXShareTip = false;
        this.isShare = false;
        // this.isMenu = false;
        this.showAnimation = false;
      }, 6000);
    } else {
      this.sharepic = this.mDetails.sharepic;
      var tip = "点击链接或者复制链接到浏览器打开!!!";
      let userid = this.utils.getLocalStorageItem("user_id") == undefined ? this.shareholderid : this.utils.getLocalStorageItem("user_id");
      if (this.mType == 'wap') {
        userid = this.shareholderid;
      } else {
        userid = this.utils.getLocalStorageItem("user_id");
      }
      this.showShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail;
      this.mShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail + SHARE_URL + '#/generalize?id=' + this.getticketid + '&shopid=' + this.mDetails.shopid + '&type=wap' + '&shareholderid=' + userid + tip;
      this.isShare = true;
      this.showAnimation = true;
    }
    // this.showWXShareTip = true ;
  }

  public toShowQRcode(){
    this.showQRcode = true;
  }


  public data;
  public shareConfig() {
    if (this.isWeixin()) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.http.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        if (result.code == 100 && result.data) {
          this.data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.getShareDataAndReady(this.data);
        }
      });
    }
  }

  public mDetails: any;
  public shareLink = 'http://www.osos.com';
  public type = 'wap';
  // 对分享信息进行处理，并注册分享函数
  public getShareDataAndReady(data) {
    wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp + '',
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareQZone',
        'onMenuShareWeibo']
    });
    let shareDesc = this.mDetails.sharedetail;
    let shareImg = this.mDetails.sharepic;
    let userid = this.utils.getLocalStorageItem("user_id") == undefined ? this.shareholderid : this.utils.getLocalStorageItem("user_id");
    if (this.mType == 'wap') {
      userid = this.shareholderid;
    } else {
      userid = this.utils.getLocalStorageItem("user_id");
    }
    if (this.type == 'wap') {
      this.shareLink = SHARE_URL + '#/generalize?id=' + this.getticketid + '&shopid=' + this.mDetails.shopid + '&type=wap' + '&shareholderid=' + userid;
    } else {
      this.shareLink = SHARE_URL + '#/generalize?id=' + this.getticketid + '&shopid=' + this.mDetails.shopid + '&shareholderid=' + userid;
    }
    let link = this.shareLink;
    let timelineTitle = this.mDetails.sharetitle;
    let frendTitle = this.mDetails.sharetitle;
    var that = this;
    wx.ready(function () {
      wx.onMenuShareTimeline({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareAppMessage({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQQ({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQZone({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareWeibo({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
    });
  }
  public isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }
  public shareSuccess(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  public shareCancel(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  // 拷贝链接
  public copyLink() { }
  //微信浏览器分享
  public wxShare() { }
  public successFun() {
    console.log('复制成功!');
  }
  public cancelShare() {
    this.showAnimation = false;
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  };
  public shareCopylink() {
    // var this_ = this ;
    // var save = function (e) {
    //   e.clipboardData.setData('text/plain', APP_SERVE_URL+this_.mShareLinkParams);//下面会说到clipboardData对象"#/couponDetail?id=123&shopid=222&shareholderid=111&type=web"
    //   e.preventDefault();//阻止默认行为
    // }
    // document.addEventListener('copy', save);
    // document.execCommand("copy");//使文档处于可编辑状态，否则无效
    // document.execCommand("优惠券赠送规则...");
    this.showAnimation = false;
    this.nativeService.showSuccessAlert("复制成功,快去粘贴分享吧~");
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  }
  public showWXShareTipClick() {
    this.showWXShareTip = false;
    this.isShare = false;
  }
  // 进入店铺
  public goToSop() {
    this.router.navigate(['home'], { queryParams: {shopid:this.utils.getLocalStorageItem("shopid")} });
  }
  // 立即领取
  public draw() {
    if (this.utils.getLocalStorageItem('isLogin') == true) {
      this.nativeService.showLoading();
      let params = {
        'userid': this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.ticket.Ticketuser.getMemberTicket',
        'plat': 'plat_wap',
        'openno': '1',
        'getticketid': this.getticketid,
        'shareholderid': this.shareholderid
      }
      this.http.request({
        'method': 'get',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        var that = this;
        if (result.code == 100 && result.data) {
          this.nativeService.showSuccessAlert('领取成功');
          DialogByZ.Confirm({
            Title: "领取成功", Content: "", BtnL: "确定", BtnR: "去使用", FunL: function () {
              //点击左按钮
              DialogByZ.Close();
            }, FunR: function () {
              //点击右按钮
              DialogByZ.Close();
              that.router.navigate(['discountCoupon'], {
                queryParams: {
                  'shopid': that.utils.getLocalStorageItem("shopid"),
                  'isUnUser': true
                }
              });
            }
          });
        } else {
          if(result.code == 66 || result.code == 67){
            this.utils.setLocalStorageItem("isLogin", false);
            this.router.navigate(['login'], { queryParams: {} });
          }else{
            DialogByZ.Confirm({
              Title: "提示", Content:result.msg, BtnL: "确定", BtnR: "邀请好友领券", FunL: function () {
                //点击左按钮
                DialogByZ.Close();
              }, FunR: function () {
                //点击右按钮
                DialogByZ.Close();
                that.share();
              }
            });
          }
        }
      })
    } else {
      this.router.navigate(['login'], { queryParams: {} });
    }
  }
}
