import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";


@Component({
  selector: 'app-dis-couoon-detail',
  templateUrl: './dis-couoon-detail.component.html',
  styleUrls: ['./dis-couoon-detail.component.css']
})
export class DisCouoonDetailComponent implements OnInit {
  public ticketId = '';
  public ticketType = '';
  public ticketObj;
  public ticketList = [];

  constructor(
    public nativeService: NativeService,
    public utils: Utils,
    public http: HttpService,
    public routeInfo: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    // 处理路由参数
    this.routeInfo.queryParams.subscribe(query => {
      this.ticketId = query.tid ? query.tid : '';
      this.ticketType = query.type ? query.type : '';
      // 获取详情
      this.getDetail(query.tid);
    })
  }
  public backToPage() {
    this.nativeService.backToPage();
  }
  public getDetail(tid) {
    let param = {
      'plat': 'plat_wap',
      'openno': '1',
      'pchannel': 3,
      'openalias': 'wap.ticket.ticketuser.useTicketInfo',
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      // 
      ticketid: tid,//卷id
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.ticketObj = result.data.info;
        this.ticketList = result.data.list;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }
  //请求领取券
  public requestGetCoupon() {
    let param = {
      'plat': 'plat_wap',
      'openno': '1',
      'pchannel': 3,
      'openalias': 'wap.ticket.Ticketuser.addTicket',
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      // 
      shareId: this.ticketId, //领取券列表id
      addtype: this.ticketObj.tagtype,
      shareuserid: '',
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert('领取成功~');
        // 获取详情
        this.getDetail(this.ticketId);
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }
  // 
  toUse(index) {
    //1无限制2商品3服务4套餐6平台商品7平台通用券
    if (this.ticketObj.uselimit == '2') {
      this.router.navigate(['goodsDetail'], { queryParams: { stockid: index, shopid: this.ticketObj.shopid } });
    } else if (this.ticketObj.uselimit == '3') {
      this.router.navigate(['serveSetMealDetail'], { queryParams: { type: 'server', id: index } });
    } else if (this.ticketObj.uselimit == '4') {
      this.router.navigate(['serveSetMealDetail'], { queryParams: { type: 'package', id: index } });
    } else if (this.ticketObj.uselimit == '6') {
      this.router.navigate(['goodsDetail'], { queryParams: { stockid: index } });
    } else if (this.ticketObj.uselimit == '7') {
      this.router.navigate(['ticketDetail'], { queryParams: { id: index, shopid: this.ticketObj.shopid } });
    }
  }

}
