import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
import { DataServerService } from "./../providers/DataServer";
import { ReturnStatement } from '@angular/compiler';
@Component({
  selector: 'app-my-car',
  templateUrl: './my-car.component.html',
  styleUrls: ['./my-car.component.css']
})
export class MyCarComponent implements OnInit {

  public list = [];
  public isNoData = false;
  public isSelected = false;
  public carItems;
  constructor(
    public nativeService: NativeService,
    public utils: Utils,
    public http: HttpService,
    public routeInfo: ActivatedRoute,
    public router: Router,
    public dataServer: DataServerService
  ) { }

  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.isSelected = queryParams.isSelected == undefined ? false : queryParams.isSelected;
    });
    this.dataRequest();
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  public goToAddCar() {
    this.router.navigate(['add-car'], { queryParams: { 'type': 1 } });
  }
  public goToEditCar(item) {
    console.log(item);
    this.router.navigate(['add-car'], { queryParams: { 'type': 2, vid: item.vid } });
  }
  public dataRequest() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.Vehicle.getList',
      'plat': 'plat_wap',
      'openno': '1'
    }
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: params,
    }).then(result => {
      if (result.code == 100 && result.data) {
        let datas = result.data.list;
        this.isNoData = result.data.total > 0 ? false : true;
        for (let i = 0; i < datas.length; i++) {
          datas[i].select = false;
        }
        this.list = datas;
      } else {
        this.nativeService.showWarningAlert(result.data.msg);
      }
      this.nativeService.hideLoading();
    });
  }
  // 选择绑定车辆
  public selectBind(item) {
    this.carItems = item;
    let ids = item.vid;
    let newArr = this.list;
    for (let i = 0; i < newArr.length; i++) {
      newArr[i].select = false;
      if (newArr[i].vid == ids) {
        newArr[i].select = true;
      }
    }
    this.list = newArr;
  }

  // 确定绑定的车辆并返回数据
  public sureBindCar() {
    console.log(this.carItems);
    if(!this.carItems){
      this.nativeService.showWarningAlert('未选中车辆绑定');
      return;
    }
    let flag = true;
    if(this.dataServer.data.length>0){
      for (let i = 0; i < this.dataServer.data.length; i++) {
        if (this.dataServer.data[i].vid == this.carItems.vid) {
          flag = false;
          this.nativeService.showWarningAlert('该车辆已经被绑定');
          return;
        }
      }
      if(flag){
        this.dataServer.data.push(this.carItems);
        this.nativeService.backToPage();
      }

    }else{
      this.dataServer.data.push(this.carItems);
      this.nativeService.backToPage();
    }
    
  }
}
