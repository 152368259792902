import { Component, OnInit } from '@angular/core';
import { NativeService } from '../providers/NativeService';
import { HttpService } from '../providers/HttpService'
import { Utils } from '../providers/Utils';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})

export class ForgetPasswordComponent implements OnInit {

  public title = "找回密码";
  public isForgetPassword = true;
  public isChangePassword = false;
  public code = '';
  public account = '';
  public password = '';
  public isWeixin = false ;
  constructor(public nativeService: NativeService, public http: HttpService, public utils: Utils) { }
  ngOnInit() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    
    if (isWeixin) {
      this.isWeixin = true;
      return true;
    } else {
      this.isWeixin = false;
      return false;
    }
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  public isShow = false;
  public getChildData(data) {
    this.isShow = false;
    if (data != 0)this.getCodeNow(data);
    $('html, body').animate({         
      scrollTop: 0
    }, 1000);
  }
  public getCode() {
    // console.log('获取验证码');
    if (this.isCode) {
      if (this.account.length != 11) {
        this.nativeService.showWarningAlert("手机号码长度不对");
        return;
      }
      this.isShow = true;
    } else {
      console.log(this.time + "s" + "后获取验证码");
    }

  }
  public getCodeNow(data){
    this.nativeService.showLoading();
      let params = {
        'mobile': this.account,
        'openalias': 'wap.member.retrievePwd.sendCode',
        'plat': 'plat_wap',
        'openno': '1',
        'verifytoken':data
      }
      this.http.request({
        'method': 'POST',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          console.log(result.data);
          var status = result.data.status;
          if (status == 1) {
            this.nativeService.showSuccessAlert(result.data.msg);
            this.resetTime();
          } else if (status == 2) {
            this.nativeService.showErrorAlert(result.data.msg);
          }
          // console.log(result.data);
        } else {
          this.nativeService.showErrorAlert(result.msg);
        }
      })
  }
  public time = 60;
  public msg = "获取验证码"
  public isCode = true;
  public resetTime() {
    this.isCode = false;
    let _this = this;
    _this.time = 60;
    function countdown() {

      if (_this.time > 0) {
        --_this.time;
        _this.msg = _this.time + "s" + "后重试"
        setTimeout(countdown, 1000);
      } else {
        _this.isCode = true;
        _this.msg = "获取验证码"
      }
    }
    countdown();
  }

  public forgetPassword() {
    if (this.account.length != 11) {
      this.nativeService.showWarningAlert("手机号码长度不对");
      return;
    }
    if (this.code == '') {
      this.nativeService.showWarningAlert("验证码不能为空");
      return;
    }
    this.nativeService.showLoading();
    let params = {
      'mobile': this.account,
      'verifycode': this.code,
      'openalias': 'wap.member.retrievePwd.verify',
      'plat': 'plat_wap',
      'openno': '1'
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(result.data);
        this.nativeService.showSuccessAlert(result.data.msg);
        this.isForgetPassword = false;
        this.isChangePassword = true;
        this.title = "设置新密码"
        // console.log(result.data);
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  public confirm() {
    // this.isForgetPassword = true;
    // this.isChangePassword = false;
    if (this.password.length < 6 || this.password.length > 12) {
      this.nativeService.showWarningAlert("密码长度为6-12位数字或字母");
      return;
    }
    this.nativeService.showLoading();
    let params = {
      'mobile': this.account,
      'pwd': this.password,
      'openalias': 'wap.member.retrievePwd.setLoginPwd',
      'plat': 'plat_wap',
      'openno': '1'
    }
    // 'oauthkey':this.utils.getLocalStorageItem('WeChAtKeY-P'),
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(JSON.stringify(result));
        // result.data.msg
        
        this.utils.setLocalStorageItem("isLogin", true);
        this.utils.setLocalStorageItem("user_token", result.data.usertoken);
        this.utils.setLocalStorageItem("user_id", result.data.userid);
        var user_info = {
          'userid': result.data.userid,
          'usertoken': result.data.usertoken
        }
        this.utils.setLocalStorageItem("user_info", user_info);
        // this.isForgetPassword = false;
        // this.isChangePassword = true;
        // this.title = "设置新密码"
        // console.log(result.data);
        // this.nativeService.backToPage();
        this.nativeService.showSuccessAlert('修改成功,已为您自动登录。');
        window.history.go(-2);
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    })
  }
}
