import {  NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DataShowModule } from '../component/data-show/data-show.module';
import { GoodsServicesComponent } from '../goods-services/goods-services.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([{ path: 'goodsServices', component: GoodsServicesComponent }]),
    DataShowModule,

  ],
  exports: [],
  declarations: [
    GoodsServicesComponent
  ],
  providers: [],
})
export class GoodsServicesModule{}