//门店购物车
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TicketOrderComponent } from "../ticket-order/ticket-order.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'ticketOrder', component: TicketOrderComponent }]),
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [],
    declarations: [
        TicketOrderComponent
    ],
    providers: [],
})
export class TicketOrderModule { }