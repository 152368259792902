import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { NativeService } from '../providers/NativeService';
import { Utils } from '../providers/Utils';
import { HttpService } from '../providers/HttpService';

// // 领券中心
@Component({
  selector: 'app-coupon-redemption-centre',
  templateUrl: './coupon-redemption-centre.component.html',
  styleUrls: ['./coupon-redemption-centre.component.css']
})
export class CouponRedemptionCentreComponent implements OnInit {
  public isNoData = false;//是否显示无数据
  public showFooter = false;//尾部  显示加载更多
  public isNoMore = false;//是否显示没有更多数据了
  public mCouponArray = [];// 券列表
  public page = 1;
  public total = 0;
  public shopid ;
  public isLogin ;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService, public router: Router,public routerInfo:ActivatedRoute) { }

  ngOnInit() {
    this.nativeService.showLoading();
    this.routerInfo.queryParams.subscribe(queryParams=>{
      this.shopid = queryParams.shopid==undefined ? this.utils.getLocalStorageItem('shopid') : queryParams.shopid;
    });
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = isLogin;
    }
    
    this.getCouponList();
  }
  public goToLogin() {
    this.utils.setLocalStorageItem('isWechatAuth', false);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
    this.router.navigate(['login']);
  }
  //获取券列表
  public getCouponList() {
    // 'userid': this.utils.getLocalStorageItem("user_id"),
    // 'user_token': this.utils.getLocalStorageItem("user_token"),
    let params = {
      'openalias': 'wap.product.ticket.getlist',
      'plat': 'plat_wap',
      'openno': '1',
      'limit': 20,
      'page': this.page,
      'shopid':this.shopid ,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.showFooter = false;
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.total = result.data.total;
        for (let i = 0; i < result.data.list.length; i++) {
          for (let j = 0; j < result.data.list[i].list.length; j++) {
            result.data.list[i].list[j].price = parseInt(result.data.list[i].list[j].price);
          }
        }
        if (this.page == 1) {
          if (result.data.total <= 0) this.isNoData = true;
          this.mCouponArray = result.data.list;
          // console.log(JSON.stringify(this.mCouponArray));
        } else {
          this.mCouponArray = this.mCouponArray.concat(result.data.list);
        }
        if (this.mCouponArray.length >= result.data.total&&result.data.total!=0) { this.isNoMore = true }
        this.page++;
        // console.log(JSON.stringify(result.data));
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  // 领取优惠券
  public getCoupon(event, id, tagtype) {
    this.nativeService.showLoading();
    event.stopPropagation();
    if (!this.isLogin) {
      this.goToLogin();
      return ;
    }
    // console.log("id:"+id +"---" +"tagtype:"+tagtype);
    // 'userid': this.utils.getLocalStorageItem("user_id"),
    // 'user_token': this.utils.getLocalStorageItem("user_token"),
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.ticket.Ticketuser.addTicket',
      'plat': 'plat_wap',
      'openno': '1',
      'shareId':id ,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.showFooter = false;
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        this.nativeService.showSuccessAlert("领取成功！");
      } else {
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.goToLogin();
        }
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 进入店铺
  public goToShop(shopid) {
    // console.log("进入店铺");
    this.router.navigate(['shopDetail'],{queryParams:{shopid}})
  }
  // 进入券详情...
  public goToCouponDetail(ticketId) {
    // this.router.navigate(['couponDetail'], {
    //   queryParams: {
    //     'id': '21',
    //     'shopid': '1',
    //     'shareid': '28',
    //     'type': 'wap'
    //   }
    // });
  }
  // 加载更多...
  public onLoadMore() {
    // console.log("加载更多...");
    if (this.mCouponArray.length >= this.total || this.showFooter) { return }
    this.showFooter = true;
    this.getCouponList();
    // console.log("加载更多...+++");
  }
}
