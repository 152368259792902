import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NativeService } from '../providers/NativeService';
import { HttpService } from "../providers/HttpService"
import { SHARE_URL } from "../providers/Constants";
import { WeChatShareService } from "../providers/weChatShareService";
import { Utils } from '../providers/Utils';
declare var wx: any;
@Component({
  selector: 'app-coupon-friends-share',
  templateUrl: './coupon-friends-share.component.html',
  styleUrls: ['./coupon-friends-share.component.css', '../../assets/css/animate.min.css']
})
export class CouponFriendsShareComponent implements OnInit {

  public shopid = '';
  public isNoData = false;
  public showFooter = false;
  public isNoMore = false;
  // document.documentElement.clientHeight
  // document.body.clientHeight
  public clientH: any;
  public bodyH: any;
  @ViewChild('footerHeight') elementView: ElementRef;
  viewHeight: number;
  public footerHeight: number;
  public isTransparent = false;
  constructor(public nativeService: NativeService, public routerInfo: ActivatedRoute
    , public http: HttpService, public shareService: WeChatShareService
    , public router: Router, public utils: Utils) { }

  ngOnInit() {
    this.routerInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid;
    })
    this.getCouponList();
  }
  public getBodyH() {
    try {
      setTimeout(() => {
        this.clientH = document.documentElement.clientHeight;
        this.bodyH = document.body.clientHeight;
        this.footerHeight = this.elementView.nativeElement.offsetHeight;
        console.log('-----' + this.clientH + '-----' + this.bodyH + '-----' + this.footerHeight);
        if ((this.bodyH - this.clientH - this.footerHeight) > 0) {
          this.isTransparent = true;
        } else {
          this.isTransparent = false;
        }
      }, 300);
    } catch (error) {
    }
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

  public page = 1;
  public mCouponArray = [];
  //获取券列表
  public getCouponList() {
    // 'userid': this.utils.getLocalStorageItem("user_id"),
    // 'user_token': this.utils.getLocalStorageItem("user_token"),
    // wap.product.ticket.shareList  wap.product.ticket.getlist 
    if (this.page <= 1) {
      this.nativeService.showLoading();
    }
    let params = {
      'openalias': 'wap.product.ticket.shareList',
      'plat': 'plat_wap',
      'openno': '1',
      'limit': 20,
      'page': this.page,
      'shopid': this.shopid,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.showFooter = false;
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // this.total = result.data.total;
        // console.log(JSON.stringify(result.data));
        for (let i = 0; i < result.data.list.length; i++) {
          // for (let j = 0; j < result.data.list[i].list.length; j++) {
          var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data.list[i].price)); 
          result.data.list[i].price = priceArr[0];
          result.data.list[i].price1 = priceArr[1];
          // result.data.list[i].price = parseInt(result.data.list[i].price);

          // }
        }
        if (this.page == 1) {
          if (result.data.total <= 0) {
            this.isNoData = true;
          }
          this.mCouponArray = result.data.list;
          // console.log(JSON.stringify(this.mCouponArray));
        } else {
          this.mCouponArray = this.mCouponArray.concat(result.data.list);
        }
        console.log(this.isNoMore + result.data.total + this.mCouponArray.length);
        if (this.mCouponArray.length == result.data.total && result.data.total != 0) {
          this.isNoMore = true;
          console.log(this.isNoMore);
        }

        this.page++;
        this.shareConfig();
        this.getBodyH();
        // console.log(JSON.stringify(result.data));
      } else {
        this.nativeService.showErrorAlert(result.msg);
        this.backToPage();
      }
    })
  }

  public goToCouponList() {
    this.router.navigate(['discountCoupon'], {
      queryParams: {
        'shopid': this.shopid
      }
    });
  }
  public showWXShareTip = false;
  public showAnimation = false;
  public mShareLinkParams = '';
  public showShareLinkParams = '';
  public sharepic;
  public isShare = false;
  public shareLink = "";
  public isMenu = false;
  public mDetails: any;
  public type = 'wap';
  public shareid = '';
  public share(event, item) {
    this.mDetails = item;
    event.stopPropagation();
    // console.log(JSON.stringify(item));
    if (this.isWeixin()) {
      this.showWXShareTip = true;
      this.getShareDataAndReady(this.data);
      setTimeout(() => {
        this.showWXShareTip = false;
        this.isShare = false;
        this.isMenu = false;
        this.showAnimation = false;
      }, 6000);
    } else {
      // console.log(JSON.stringify(this.mDetails));
      this.sharepic = this.mDetails.sharepic;
      var tip = "点击链接或者复制链接到浏览器打开!!!";
      if (this.type == 'wap') {
        this.mShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail + SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.mDetails.shopid + '&type=wap' + '&shareid=' + this.utils.getLocalStorageItem("user_id") + tip;
      } else {
        this.mShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail + SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.mDetails.shopid + '&shareid=' + this.utils.getLocalStorageItem("user_id") + tip;
      }
      this.showShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail;
      // console.log(this.mShareLinkParams);
      this.isShare = true;
      this.showAnimation = true;
    }
  }
  public isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }
  // 加载更多。。。
  public onLoadMore() {
    // console.log("加载更多。。。");
    if (this.isNoData || this.isNoMore || this.showFooter) {
      return;
    }
    this.showFooter = true;
    this.getCouponList();
  }

  public shareCopylink() {
    // var this_ = this ;
    // var save = function (e) {
    //   e.clipboardData.setData('text/plain', APP_SERVE_URL+this_.mShareLinkParams);//下面会说到clipboardData对象"#/couponDetail?id=123&shopid=222&shareid=111&type=web"
    //   e.preventDefault();//阻止默认行为
    // }
    // document.addEventListener('copy', save);
    // document.execCommand("copy");//使文档处于可编辑状态，否则无效
    // document.execCommand("优惠券赠送规则...");
    this.showAnimation = false;
    this.nativeService.showSuccessAlert("复制成功,快去粘贴分享吧~");
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  }
  public cancelShare() {
    this.showAnimation = false;
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  };

  public successFun() {
    // this.nativeService.showSuccessAlert("复制成功,快去粘贴吧~");
  }
  public showWXShareTipClick() {
    this.showWXShareTip = false;
    this.isShare = false;
  }

  public data;
  public shareConfig() {
    if (this.isWeixin()) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.http.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        if (result.code == 100 && result.data) {
          this.data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.getShareDataAndReady(this.data);
        }
      });
    }
  }
  public shareSuccess(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  public shareCancel(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  // 对分享信息进行处理，并注册分享函数
  public getShareDataAndReady(data) {
    wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp + '',
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareQZone',
        'onMenuShareWeibo']
    });
    let shareDesc = this.mDetails.sharedetail;
    let shareImg = this.mDetails.sharepic;
    if (this.type == 'wap') {
      this.shareLink = SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.mDetails.shopid + '&type=wap' + '&shareid=' + this.utils.getLocalStorageItem("user_id");
    } else {
      this.shareLink = SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.mDetails.shopid + '&shareid=' + this.utils.getLocalStorageItem("user_id");
    }
    let link = this.shareLink;
    let timelineTitle = this.mDetails.sharecircledetail;
    let frendTitle = this.mDetails.sharetitle;
    var that = this;
    wx.ready(function () {
      wx.onMenuShareTimeline({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareAppMessage({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQQ({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQZone({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareWeibo({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
    });
  }

  /****************/
  @HostListener('window:scroll', ['$event']) public onScroll = ($event) => {
    // console.log('滚动了...'); 
    //客户端高度
    var clientH = document.documentElement.clientHeight;
    //body高度
    var bodyH = document.body.clientHeight;
    //滚动的高度
    var scrollTop = document.documentElement.scrollTop;
    // console.log("滚动的高度:" + scrollTop);
    // console.log("滚动的高度:" + scrollTop+'----'+'body高度:'+bodyH+'-----------'+'客户端高度:'+clientH);
    try {
      // this.shopAddressHeight = this.elementView.nativeElement.offsetHeight;
    } catch (error) {
    }
    // console.log('店铺div高度：' + this.shopAddressHeight);
    if (scrollTop > 0) {

    } else {

    }
    // 滚动到底部60以内
    if (bodyH - clientH - scrollTop < 3) {
      // if (!this.flag) {
      //     console.log('翻页');
      //     //翻页
      //     this.changePage('+');
      // }
      // this.flag = true;
      this.isTransparent = false;
    } else {
      // this.flag = false;
      this.isTransparent = true;
    }
  }
}
