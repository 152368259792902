import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CouponDetailComponent } from './coupon-detail.component';
import { ClipboardModule } from 'ngx-clipboard';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'couponDetail', component:  CouponDetailComponent}]),
        FormsModule,
        ReactiveFormsModule,
        ClipboardModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        CouponDetailComponent
    ],
    providers: [],
})
export class CouponDetailModule{

}