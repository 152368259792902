import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-login-password',
  templateUrl: './login-password.component.html',
  styleUrls: ['./login-password.component.css']
})
export class LoginPasswordComponent implements OnInit {

  public new_password = '';
  public password = '';
  public mobile = '';
  public mCode = '';
  public type = 1;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) {
    let _this = this;
    _this.routeInfo.queryParams.subscribe(queryParams => {
      // _this.mobile = queryParams.mobile;
      let mob = queryParams.mobile;
      if (mob != undefined) {
        _this.mobile = _this.replacepos(mob, 3, 7, '****');
      }
    });
  }

  public replacepos(text, start, stop, replacetext) {
    let mystr = text.substring(0, start) + replacetext + text.substring(stop, text.length);
    return mystr;
  }

  ngOnInit() {
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.router.navigate(['mine']);
    } else {
    }
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  public confirm() {
    if (this.new_password == undefined || this.new_password.length <= 0) {
      this.nativeService.showWarningAlert('请输入新密码！');
    } else if (this.new_password.length < 6 || this.new_password.length > 12) {
      this.nativeService.showWarningAlert('请输入6-12位新密码！');
    } else {
      this.nativeService.showLoading();
      let params = {
        'password': this.new_password,
        // 'new_password': this.new_password,
        'userid': this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.member.Member.modifyPassword',
        'plat': 'plat_wap',
        'openno': '1'
      }
      this.http.request({
        'method': 'POST',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          // console.log(result.data);
          // console.log(JSON.stringify(result.data));
          if (result.data.ret) {
            this.nativeService.showSuccessAlert('修改密码成功');
            this.logout();
          } else {
            this.nativeService.showWarningAlert(result.data.msg);
          }
        } else {
          this.nativeService.showWarningAlert(result.msg);
        }
      })
    }
  }
  public isShow = false;
  public getChildData(data) {
    this.isShow = false;
    if (data != 0) this.sendCodeNow(data);
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
  }
  public sendCode() {
    this.isShow = true;
  }
  public sendCodeNow(data) {
    if (!this.isSend) {
      this.isSend = true;
      this.nativeService.showLoading();
      let params = {
        'userid': this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.member.Member.sendCode',
        'plat': 'plat_wap',
        'openno': '1',
        'verifytoken': data
      }
      this.http.request({
        'method': 'POST',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          // console.log(result.data);
          // console.log(JSON.stringify(result.data));
          if (result.data.status == 1) {
            this.nativeService.showSuccessAlert("发送成功");
            this.resetTime();
          } else {
            this.isSend = false;
            if (result.data.msg) {
              this.nativeService.showWarningAlert(result.data.msg);
            } else {
              this.nativeService.showWarningAlert("发送失败");
            }
          }

        } else {
          this.isSend = false;
          this.nativeService.showWarningAlert(result.msg);
        }
      })
    }
  }
  public logout() {
    this.utils.setLocalStorageItem("isLogin", false);
    this.utils.setLocalStorageItem("user_token", "");
    this.utils.setLocalStorageItem("user_id", "");
    this.utils.setLocalStorageItem("user_info", "");

    this.router.navigate(['mine']);
  }

  public isSend = false;
  public time = 0;
  public mSendCodeText = '获取验证码';
  public resetTime() {
    let _this = this;
    _this.isSend = true;
    _this.time = 60;

    function countdown() {
      if (_this.time > 0) {
        _this.mSendCodeText = _this.time + 's' + "后重试";
        --_this.time;
        setTimeout(countdown, 1000);
      } else {
        _this.mSendCodeText = '重新获取';
        _this.isSend = false;
      }
    }

    countdown();
  }

  public next() {
    if (this.mCode == undefined || this.mCode.length == 0) {
      this.nativeService.showWarningAlert('请输入验证码');
      return;
    }
    if (this.mCode.length < 4) {
      this.nativeService.showWarningAlert('验证码长度至少为四位数');
      return;
    }
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.Member.verify',
      'plat': 'plat_wap',
      'openno': '1',
      'code': this.mCode
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        if (result.data.status == 1) {
          this.type = 2;
          this.nativeService.showSuccessAlert("验证成功");
        } else {
          if (result.data.msg) {
            this.nativeService.showWarningAlert(result.data.msg);
          } else {
            this.nativeService.showWarningAlert("验证失败");
          }
        }

      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }
}
