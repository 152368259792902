import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';//不加上这个module可以ng的一些指令不能识别
import { RouterModule } from '@angular/router';
import { FixedNavModule } from '../component/fixed-nav/fixed-nav.module';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { WalletComponent } from './wallet.component';
import { CashAccountModule } from './cash-account/cash-account.module';//提现账号
import { WithdrawApplyModule } from './withdraw-apply/withdraw-apply.module';//提现申请
import { DetailedAccountModule } from './detailed-account/detailed-account.module';//收支明细
import { WithdrawalRecordModule } from './withdrawal-record/withdrawal-record.module';//提现记录
import { ShowDetailsModule } from './show-details/show-details.module';//提现详情
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'wallet', component: WalletComponent}]),
        FixedNavModule,
        InfiniteScrollModule,
        FooterRefreshModule,
        DataShowModule,
        LazyLoadImageModule,
        CashAccountModule,
        WithdrawApplyModule,
        DetailedAccountModule,
        WithdrawalRecordModule,
        ShowDetailsModule
    ],
    exports: [],
    declarations: [
        WalletComponent
    ],
    providers: [],
})
export class WalletModule {}