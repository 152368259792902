import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-withdrawal-record',
  templateUrl: './withdrawal-record.component.html',
  styleUrls: ['./withdrawal-record.component.css']
})
export class WithdrawalRecordComponent implements OnInit {
  public mList = [
    {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
  ]
  public isDialog = false ;
  public mTab = [
    { 'name': "审核中", 'type': 0, 'isSelect': true },
    { 'name': "提现成功", 'type': 1, 'isSelect': false },
    { 'name': "提现失败", 'type': 2, 'isSelect': false }
  ];
  public type = this.mTab[0].type ;

  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  // 选中的tab
  public select(i){
    for(let j =0 ; j<this.mTab.length ; j++){
      this.mTab[j].isSelect = false ;
    }

    this.mTab[i].isSelect = true ;
    this.type = this.mTab[i].type ;
  }

  // 显示审核失败原因
  public showDialog(){
    this.isDialog = true ;
  }
  // 关闭 dialog 
  public dismiss(){
    this.isDialog = false ;
  }
}
