import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GraphicCheckModule } from '../component/graphic-check/graphic-check.module';
@NgModule({
    imports: [
        RouterModule.forRoot([{ path: 'login', component: LoginComponent }]),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GraphicCheckModule
    ],
    declarations: [LoginComponent],
    exports: [],
    providers: [],
})
export class LoginModule { }