import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { Utils } from "../providers/Utils"
@Component({
  selector: 'app-shop-activity',
  templateUrl: './shop-activity.component.html',
  styleUrls: ['./shop-activity.component.css']
})
export class ShopActivityComponent implements OnInit {
  public showView = false;
  public shopid = 0;
  public isLogin = false;
  public titleIndex = 0;//标题index 默认选第0个
  public acticityList = [];
  public acticityInfo: any = {};
  public showHeader = false;
  public showFooter = false;
  public noMore = false;
  public total: number = 1;
  public page: number = 1;
  public itemList = [];

  public productlist = [];//购物车列表
  public cartTotalNum = 0;
  public amounttotal = 0;
  public amountpay = 0;
  public amountdiscount = 0;
  public aid = 0;
  constructor(public utils: Utils, public router: Router, public routeInfo: ActivatedRoute, public nativeService: NativeService, public httpService: HttpService) { }
  backToPage() {
    this.titleIndex = 0;
    this.navigateInitData();
    this.nativeService.backToPage();
  }
  ngOnInit() {
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.aid = queryParams.aid == undefined ? 0 : queryParams.aid;
      this.shopid = queryParams.shopid == undefined ? this.utils.getLocalStorageItem('shopid') : queryParams.shopid;
    });
    this.isLogin = this.utils.getLocalStorageItem("isLogin");
    this.nativeService.showLoading();
    var json = this.utils.sessionStorageGetItem('enterActivityInitData');
    if (json != null && json != undefined) {
      this.titleIndex = JSON.parse(json).titleIndex;
    }
    this.getActivityTitle();
  }
  navigateInitData() {
    var json = JSON.stringify({
      "titleIndex": this.titleIndex
    });
    this.utils.sessionStorageSetItem("enterActivityInitData", json);
  }
  //获取促销标题列表
  getActivityTitle() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.promotion.ActivityPromotion.promotionList',
      'shopid': that.shopid,
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        that.showView = true;
        that.acticityList = result.data;
        if (that.aid != 0) {
          for (let index = 0; index < result.data.length; index++) {
            const element = result.data[index];
            if (element.id == that.aid) {
              that.titleIndex = index;
            }
          }
        }
        that.acticityInfo = result.data[that.titleIndex];
        that.getGoodsList(false);
      } else {
        this.nativeService.hideLoading();
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }
  //获取购物车列表
  getCarListData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': 'wap.cart.shopcart.getCart',
      'productids': 0,
      'shopid': that.shopid,
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        var goodsListData = that.itemList;
        if (success.data.suborder != undefined && success.data.suborder.length > 0) {
          that.setBuyMun(success.data.suborder[0].productlist, goodsListData);
          // console.log(success.data.suborder.length)
          if (success.data.suborder.length > 1) {
            // console.log(success.data.suborder.length)
            that.setBuyMun(success.data.suborder[1].productlist, goodsListData);
          }
        }
        that.productlist = success.data.productlist;
        that.amounttotal = success.data.amounttotal;
        that.amountpay = success.data.amountpay;
        that.amountdiscount = parseFloat(success.data.orderdiscount) + parseFloat(success.data.amountticket);;
        that.cartTotalNum = success.data.numtotal;
      } else {
        if (success.msg == "登录已过期,请重新登录" || success.msg == "请登录") {
          that.utils.setLocalStorageItem("isLogin", false);
          that.cartTotalNum = 0;
        }
      }
    });
  }
  public setBuyMun(productlist, goodsListData) {
    // console.log('productlist:'+JSON.stringify(productlist)) ;
    // console.log('goodsListData:'+JSON.stringify(goodsListData)) ;
    if (productlist.length > 0) {
      for (var i = 0; i < goodsListData.length; i++) {
        try{
          goodsListData[i].key = goodsListData[i].stockid + "_1" ;
        }catch(err){

        }
        for (var j = 0; j < productlist.length; j++) {
          var item = productlist[j];
          // productid
          // item.key == (goodsListData[i].stockid + "_1")
          if (item.productid == (goodsListData[i].productid)) {
            goodsListData[i].buyNum = item.num; 
            goodsListData[i].key = item.key; 
          }
        }
      }
    } else {
      for (var i = 0; i < goodsListData.length; i++) {
        goodsListData[i].buyNum = 0;
      }
    }
    this.itemList = goodsListData;
  }
  // 标题点击
  titleClcik(index) {
    this.titleIndex = index;
    this.acticityInfo = this.acticityList[index];
    this.page = 1;
    this.getGoodsList(false);
  }
  getGoodsList(state) {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.promotion.ActivityPromotion.orderActivityInfo',
      'shopid': this.utils.getLocalStorageItem('shopid'),
      'page': this.page,
      'limit': 20,
      "id": this.acticityInfo.id
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        that.total = result.data.total;
        if (that.total > 0) {
          for (var i = 0; i < result.data.list.length; i++) {
            try{
              result.data.list[i].key = result.data.list[i].stockid+"_1";
            }catch(err){}
            for (var j = 0; j < that.itemList.length; j++) {
              var item = that.itemList[j];
              if (item.stockid == (result.data.list[i].stockid)) {
                result.data.list[i].buyNum = item.num;
              }
            }
          }
          if (state) {
            //加载更多
            that.itemList = that.itemList.concat(result.data.list);
          } else {
            //下拉刷新
            that.itemList = result.data.list;
          }
          // console.log(JSON.stringify(that.itemList));
          that.noMore = that.itemList.length >= that.total ? true : false;
          if (that.isLogin) {
            that.getCarListData();
          }
        } else {
          //没有数据
          that.total = 0;
          that.itemList = [];
        }
      } else {
        that.nativeService.showErrorAlert(result.msg);
      }
      this.nativeService.hideLoading();
      that.showFooter = false;
      that.showHeader = false;
    });
  }
  onScrollDown() {
    console.log("onScrollDown");
    if (this.itemList.length < this.total) {
      this.page = 1 + this.page;
      this.showFooter = true;
      this.getGoodsList(true);
    }
  }

  onScrollUp() {

  }
  //商品点击
  itemClick(item) {
    this.navigateInitData();
    this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.stockid, shopid: this.shopid } });
  }
  whetherLogin() {
    if (!this.isLogin) {
      this.utils.setLocalStorageItem('isWechatAuth', false);
      this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
  // 加入购物车
  addToCar(item, index) {
    if (this.whetherLogin()) {
      this.addGoodsToCar(item);
    }
  }
  // 减少商品
  removeToCar(item, index) {
    if (this.whetherLogin()) {
      this.removeGoodsFromCar(item);
    }
  }
  // 加入购物车
  addGoodsToCar(item) {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': "wap.cart.shopcart.add",
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'pid': item.stockid,
      'shopid': this.shopid,
      'num': 1,
      'productType': 1
    }
    // this.showLoading = true;
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        that.getCarListData();
      } else {
        that.nativeService.showErrorAlert(success.msg);
      }
      that.nativeService.hideLoading();
    });
  }
  // 减少商品
  removeGoodsFromCar(item) {
    var that = this;
    var pid = item.key;
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': "wap.cart.shopcart.reduce",
      'userid': that.utils.getLocalStorageItem("user_info").userid,
      'user_token': that.utils.getLocalStorageItem("user_info").usertoken,
      'pid': pid,
      'shopid': that.shopid,
      'num': 1,
    }
    // that.showLoading = true;
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        var goodsListData = that.itemList;
        for (var i = 0; i < goodsListData.length; i++) {
          if (item.productid == goodsListData[i].productid) {
            goodsListData[i].buyNum = goodsListData[i].buyNum - 1;
          }
        }
        that.itemList = goodsListData;
        that.getCarListData();
      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
      this.nativeService.hideLoading();
    });
  }
  //去购物车 去结算
  gotoShopCart(index) {
    if (this.whetherLogin()) {
      this.navigateInitData();
      this.router.navigate(['shoppingCart'], { queryParams: { shopid: this.shopid } });
    }
  }

}
