import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { HttpService } from '../providers/HttpService';
import { Utils } from '../providers/Utils'

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.css']
})
export class AddAddressComponent implements OnInit {
  public address = "请选择";
  public mName = '';
  public mPhoneNumber = '';
  public mAddressDetails = '';
  public title = "";
  public id;
  public type;
  public cityCode;
  public provinceCode;
  public districtCode
  public data: any;
  public addressid;
  constructor(public router: Router, public routeInfo: ActivatedRoute, public nativeService: NativeService, public http: HttpService, public utils: Utils) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      var address = queryParams.address == undefined ? "" : JSON.parse(queryParams.address);
      var type = queryParams.type == undefined ? "" : queryParams.type;
      var title = queryParams.title == undefined ? "" : queryParams.title;
      this.data =  address;
      this.type = type;
      this.title = title;
    })
    if (this.type == 1) {
      this.address = '请选择';
    }
    // {
    //   "id": 13,
    //   "userid": 28,
    //   "consignee": "99",
    //   "mobile": "189000999111",
    //   "provinceid": 370000,
    //   "cityid": 371700,
    //   "areaid": 371725,
    //   "streetid": null,
    //   "address": "山东省菏泽市郓城县",
    //   "isdefault": 2,
    //   "datastatus": 1,
    //   "province": "山东省",
    //   "cityname": "菏泽市",
    //   "areaname": "郓城县"
    // }
    if (this.type == 2) {
      this.address = this.data.province + this.data.cityname + this.data.areaname;
      this.provinceCode = this.data.provinceid;
      this.cityCode = this.data.cityid;
      this.districtCode = this.data.areaid;
      this.mAddressDetails = this.data.address;
      this.mName = this.data.consignee;
      this.mPhoneNumber = this.data.mobile;
      this.status = this.data.isdefault == 2 ? false : true;
      this.addressid = this.data.id;
    }
  }

  public selectAddress() {
    this.isShow = true;
  }
  public addressData: any;
  public isShow = false;
  public getChildData(ev) {
    this.addressData = ev;
    this.isShow = this.addressData.isShow;
    if (this.addressData.type == 1) {
      this.address = this.addressData.address.address;
      this.provinceCode = this.addressData.address.provinceCode;
      this.cityCode = this.addressData.address.cityCode;
      this.districtCode = this.addressData.address.districtCode;
      // console.log(this.address + "-----" + "省id：" + this.provinceCode + "-----" + "市id：" + this.cityCode + "-----" + "区id：" + this.districtCode)
    }
    // console.log(this.addressData);
  }

  public saveAddress() {
    if (this.mName.length <= 0) {
      this.nativeService.showWarningAlert("请填写收件人");
      return;
    }
    if (this.mPhoneNumber.length < 11) {
      this.nativeService.showWarningAlert("请检查手机号码长度");
      return;
    }
    if (this.address == '请选择') {
      this.nativeService.showWarningAlert("请选择地址");
      return;
    }
    if (this.mAddressDetails.length <= 0) {
      this.nativeService.showWarningAlert("请填写详细地址");
      return;
    }

    this.nativeService.showLoading();
    if (this.type == 1) {
      this.submitAddress();
      console.log("添加");
    } else if (this.type == 2) {
      this.submitEditAddress();
      console.log("修改");
    }

  }
  // 编辑地址
  public submitEditAddress() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'consignee': this.mName,
      'mobile': this.mPhoneNumber,
      'address': this.mAddressDetails,
      'isdefault': this.status == true ? 1 : 2,
      'openalias': "wap.member.address.edit",
      'provinceid': this.provinceCode,
      'cityid': this.cityCode,
      'areaid': this.districtCode,
      'plat': "plat_wap",
      'openno': 1,
      'addressid': this.addressid
      // 'streetid':4,
    }

    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        this.nativeService.showSuccessAlert("修改成功");
        this.nativeService.backToPage();
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 添加地址
  public submitAddress() {
    //添加 member.address.add
    // 修改 member.address.edit
    // 'addressid':"",

    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'consignee': this.mName,
      'mobile': this.mPhoneNumber,
      'address': this.mAddressDetails,
      'isdefault': this.status == true ? 1 : 2,
      'openalias': "wap.member.address.add",
      'provinceid': this.provinceCode,
      'cityid': this.cityCode,
      'areaid': this.districtCode,
      'plat': "plat_wap",
      'openno': 1
      // 'streetid':4,
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        this.nativeService.showSuccessAlert("添加成功");
        this.nativeService.backToPage();
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  public status = false;
  public triggerFn() {
    this.status = !this.status;
  }
}

