import { Component, OnInit } from '@angular/core';
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { Router } from "@angular/router";
import { Utils } from '../providers/Utils'
import { NativeService } from '../providers/NativeService';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载

declare let rolldate: any;
@Component({
  selector: 'app-mine',
  templateUrl: './mine.component.html',
  styleUrls: ['./mine.component.css']
})
export class MineComponent implements OnInit {
  public isShowPage = false;
  public isLogin = false;
  public userInfo;
  public vipInfo;
  public mShopName = '';
  public shopid;
  public shopMenuData = [];
  public shopMenuLength = 0;
  public isSelectShop; //是否选择店铺
  public vipConfig = {};
  public isShowShareholder = true;
  constructor(public http: HttpService, public router: Router, public utils: Utils, public nativeService: NativeService) {

  }
  ngOnInit() {
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = isLogin;
    }
    // 判断是否又选择店铺
    let isSelect = this.utils.getLocalStorageItem('shopid');
    if (isSelect == undefined || isSelect == null || isSelect == '') {
      this.isSelectShop = false;
    } else {
      this.mShopName = this.utils.getLocalStorageItem('shopInfo').shopname;
      this.shopid = this.utils.getLocalStorageItem('shopid');
      this.isSelectShop = true
    }
    if (this.isLogin) {
      this.getUserInfo();
      this.getShareInfo();
      this.getShareInfoNew();
      this.getVipConfigInfo();
    }
  }

  public mShareText = '';
  public isGetShare = false;
  public getShareInfo() {
    this.nativeService.showLoading();
    this.uId = this.utils.getLocalStorageItem("user_id");
    this.uToken = this.utils.getLocalStorageItem("user_token");
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.sys.menu.member',
      'plat': 'plat_wap',
      // 'openno': '1'
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.isGetShare = true;
        this.mShareText = result.data[0].menu;
        // console.log(JSON.stringify(result.data)); 
        // console.log(JSON.stringify(result.data));
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
        }
      }

      this.data = JSON.stringify(result);
    })
  }
  public getShareInfoNew() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'shopid': this.utils.getLocalStorageItem('shopid'),
      'openalias': 'wap.sys.menu.membernew',
      'plat': 'plat_wap',
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        var menuDta = result.data;
        menuDta.forEach(element => {
          if (element.shop == this.shopid) {
            this.shopMenuData.push(element);
          }
        });
        this.shopMenuLength = this.shopMenuData.length;
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
        }
      }
    })
  }
  // 查看二维码
  public goQrCode() {
    // alert("查看二维码");
    // console.log('查看二维码');
    this.router.navigate(['mimeQrcode'], {
      queryParams: {
        'name': this.userInfo.truename,
        'portrait': this.userInfo.headthumb,
        'mobile': this.userInfo.mobile
      }
    });
  }
  // 选择门店
  public selectStores() {
    this.router.navigate(['selectShop'], { queryParams: { homePush: true } });
  }
  //积分
  public goIntegral() {
    this.router.navigate(["mineIntegral"], { queryParams: { 'shopid': this.shopid } });
  }
  // 优惠券
  public goDiscountCoupon() {
    this.router.navigate(['discountCoupon'], {
      queryParams: {
        'shopid': this.shopid
      }
    });
  }
  // 好友分享
  public goToCouponFriendsShare() {
    if (this.isGetShare) {
      this.router.navigate(['couponFriendsShare'], { queryParams: { 'shopid': this.shopid } });
    } else {
      this.getShareInfo();
    }

  }
  // 商城订单
  public goStoreOrder() {
    // alert("商城订单");
    this.router.navigate(["mallOrders"]);
  }
  // 门店订单
  public goShopOrder() {
    // alert("门店订单");
    this.router.navigate(['shopOrders']);
  }
  // 商品服务
  public goGoodsService() {
    // alert('商品服务')
    this.router.navigate(['goodsServices'])
  }
  // 收货地址
  public goAddress() {
    // alert('收货地址')
    this.router.navigate(['mineAddress']);
  }
  // 股东分红页面
  // 非股东
  public isShareholder = true;
  public goShareholder() {
    // buy-shareholder
    if (this.isShareholder) {
      this.router.navigate(['shareholder'], { queryParams: { 'shopid': this.shopid } });
    } else {
      this.router.navigate(['buy-shareholder'], { queryParams: { 'shopid': this.shopid } });
    }
  }
  // 账户中心
  public goToAccountCenter() {
    this.router.navigate(['account-center'], { queryParams: this.mUserInfo });
  }
  // 余额
  public goToWallet() {
    this.router.navigate(['wallet'], { queryParams: {} });
  }
  // 我的车辆
  public goToMyCar() {
    this.router.navigate(['my-car'], { queryParams: {} });
  }
  // 关于基利斯
  public goToAboutUs() {
    this.router.navigate(['about-us'], { queryParams: {} });
  }
  // 中石化加油 goSRpage
  public goSRpage(index) {
    this.router.navigate(['SinopecRefueling'], { queryParams: {type: index} });
  }

  public gotoLogin() {
    this.utils.setLocalStorageItem('isWechatAuth', false);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
    this.router.navigate(['login']);
  }

  public mUserInfo = {
    name: '',
    mobile: '',
    head_thumb: ''
  };
  public data;
  public uId;
  public uToken;
  public getUserInfo() {
    this.nativeService.showLoading();
    this.uId = this.utils.getLocalStorageItem("user_id");
    this.uToken = this.utils.getLocalStorageItem("user_token");
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.vipIndex',
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.shopid,//否,下单的门店Id
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.isShowPage = true;
        // console.log(result.data);
        this.userInfo = result.data.userinfo;
        this.vipInfo = result.data.vipInfo;
        this.isShowShareholder = result.data.isshareholder==1;
        this.utils.setLocalStorageItem('mobile', result.data.userinfo.mobile);
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
        }
      }

      this.data = JSON.stringify(result);
    })
  }
  // 是否退出登录
  public isLogout = false;
  public logOut() {
    this.isLogout = true;
  }
  public cancelLogoutDialog() {
    this.isLogout = false;
  }
  public confirmLogout() {
    this.isLogout = false;
    this.utils.setLocalStorageItem("isLogin", false);
    this.utils.setLocalStorageItem("user_token", "");
    this.utils.setLocalStorageItem("user_id", "");
    this.utils.setLocalStorageItem("user_info", "");
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = isLogin;
    }
  }
  public goToCounpActivityShare(item) {
    var data = JSON.stringify(item);
    this.router.navigate(['couponActivityShare'], { queryParams: { data: data } });
  }

  public goTicketOrder() {
    this.router.navigate(['ticketOrders'])
  }

  public openVip() {
    console.log(1);
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.order.orderbuilder.openvip',
      'plat': 'plat_wap',
      'openno': '1',
      'vipip': this.vipInfo.id,//是,vip配置ID
      'puserid': '',//否,推广用户id【分享购买vip会员必传】
      'remark': '',//否,订单备注
      'shopid': this.shopid,//否,下单的门店Id
      'cityid': '',//否,下单城市ID
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.router.navigate(['orderPay'], {
          queryParams: {
            'orderid': result.data.orderid,
            'page': -1,
            'orderType': 11
          }
        });
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
        }
      }
    })
  }

  public goInviteFriends(){
    this.router.navigate(['inviteFriends'])
  }

  public goToWelgfare(){
    this.router.navigate(['welfareList'])
  }


  /* 2023.11.21 */ 
  // 获取vip配置信息
  public getVipConfigInfo(){
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.Config.getInfo',
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.shopid,//否,下单的门店Id
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        this.vipConfig = result.data;
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
        }
      }
    })
  }

  public toVipMember(){
    this.router.navigate(['vipMember'])
  }

  public aboutJLS(){
    this.router.navigate(['news-details'],{queryParams:{'alias':"",'id':2}});
  }
}
