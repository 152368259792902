import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NativeService } from '../providers/NativeService';
import { HttpService } from "../providers/HttpService"
import { SHARE_URL } from "../providers/Constants";
import { WeChatShareService } from "../providers/weChatShareService";
import { Utils } from '../providers/Utils';
import { Title } from '@angular/platform-browser';
import { NgZone } from '@angular/core';
declare var wx: any;
@Component({
  selector: 'app-coupon-share',
  templateUrl: './coupon-share.component.html',
  styleUrls: ['./coupon-share.component.css', '../../assets/css/animate.min.css']
})

export class CouponShareComponent implements OnInit {
  public picList = [];
  public shopList = [];
  public iswx = false;
  // public iswx = true;
  public hadShare = false;
  public showWXShareTip = false;
  public title = "基利斯养车 双节保养套餐";
  public isLogin = false;
  public isShow = false;
  public showAnimatin = false;
  public shopid = 0;
  public type = 1;//type ： 1是领取和分享的页面，2是分享出去页面
  public showBtn = false;
  constructor(
    public nativeService: NativeService, 
    public routerInfo: ActivatedRoute, 
    public http: HttpService, 
    public shareService: WeChatShareService, 
    public router: Router, 
    public utils: Utils, 
    public titleServers: Title, 
    public zone: NgZone, 
    public routeInfo: ActivatedRoute
    ) {
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.type = queryParams.type == undefined ? 1 : queryParams.type;
    });
  }

  ngOnInit() {
    this.hadShare = this.utils.getLocalStorageItem('hadShare');
    this.isLogin = this.utils.getLocalStorageItem("isLogin");
    this.titleServers.setTitle(this.title);
    if (this.isWeixin()) {
      this.iswx = true;
      this.getPicInfo();
    } else {
      this.iswx = false;
      this.nativeService.showWarningAlert("请用微信打开此页面");
    }
  }
  public isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }
  public backToPage() {
    this.utils.setLocalStorageItem('hadShare',false);
    console.log("backToPage");
    this.router.navigate(['index']);
  }
  public getPicInfo() {
    this.nativeService.showLoading();
    var param = {
      'type': 'xiche',
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.activity.Index.getActivityImg',
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.zone.run(() => {
          this.picList = result.data;
          // this.shareConfig(); 版本3不需要分享
          setTimeout(() => {
            this.showBtn = true;
            this.nativeService.hideLoading();
          }, 1000);
        })
      } else {
        this.nativeService.showErrorAlert(result.msg);
        this.nativeService.hideLoading();
      }
    });
  }
  public share() {
    var that = this;
    that.showWXShareTip = true;
    setTimeout(() => {
        that.showWXShareTip = false;
      }, 6000);
  }
  // 分享
  public shareConfig() {
    var that = this;
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': 'http://m.jilisi.com/#/couponShare',//window.location.href
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.http.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          var data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.shareService.configWXJs(data);
          let timelineTitle = "基利斯养车 迎双节 保养套餐秒到位";
          let shareDesc = "每店50份，先到先得！";
          var link = SHARE_URL + '#/couponShare?type=2';
          let shareImg = this.picList[5];//图片长度
          this.shareService.getShareDataAndReadyLink(timelineTitle,
            shareImg, shareDesc
            , timelineTitle, link).subscribe(
              (success) => {
                
                //分享bug:分享成功和取消都返回1 
                if (success["data"] == 1) {
                  //强制刷新页面
                  this.zone.run(() => {
                    that.hadShare = true;
                    that.showWXShareTip = false;
                    that.utils.setLocalStorageItem('hadShare',true);
                  })
                }
              });
        }
      });
    }
  }
  public showWXShareTipClick() {
    this.showWXShareTip = false;
  }
  //领取券
  public getCoupon() {
    var that = this;
    if (that.whetherLogin()) {
      that.getShopList()
    }
  }
  whetherLogin() {
    if (!this.isLogin) {
      this.utils.setLocalStorageItem('isWechatAuth', false);
      this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
  public closeBox() {
    const that = this;
    that.showAnimatin = false;
    setTimeout(function () {
      that.isShow = false;
    }, 800);
  }
  public getShopList() {
    var that = this;
    this.nativeService.showLoading();
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.activity.Index.getShopList',
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        that.shopList = result.data;
        that.isShow = true;
        that.showAnimatin = true;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
      this.nativeService.hideLoading();
    });
  }
  public selectShop(item) {
    var that = this;
    that.shopid = item.shopid;   
    that.utils.setLocalStorageItem('shopid',that.shopid);
  }
  //立即领取
  public pickCoupon() {
    var that = this;
    that.nativeService.showLoading();
    var param = {
      'type': 'xiche',
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.activity.Ticket.getTicket',
      'userid': that.utils.getLocalStorageItem("user_id"),
      'user_token': that.utils.getLocalStorageItem("user_token"),
      'shopId': that.shopid
    };
    that.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        that.closeBox();
        that.nativeService.showSuccessAlert("领取成功");
        that.router.navigate(['discountCoupon'], {
          queryParams: {
            'shopid':that.shopid,
            'isUnUser':true
          }
        });
      } else {
        that.nativeService.showWarningAlert(result.msg);
        that.nativeService.hideLoading();
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          that.utils.setLocalStorageItem('isWechatAuth', false);
          that.utils.setLocalStorageItem('isWechatAuthGetKey', false);
          that.router.navigate(['login']);
        }
      }
    });
  }
  public enterUrl() {
    window.location.href = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU0MDY5MTM3OA==#wechat_redirect";
  }
}
