import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from "./../../providers/Utils";
import { SHARE_URL } from "../../providers/Constants";
import { WeChatShareService } from "../../providers/weChatShareService";
declare var wx: any;
@Component({
  selector: 'app-shareholder',
  templateUrl: './shareholder.component.html',
  styleUrls: ['./shareholder.component.css']
})
export class ShareholderComponent implements OnInit {
  @ViewChild('bannersHeight') elementView: ElementRef;
  @ViewChild('footerHeight') footerView: ElementRef;
  public bannersHeight: number;
  /**
   * isShareholder 
   * 1：已入股
   * 0：未入股
   */
  public isShareholder = -1;
  public isGoSetPayPwd = false;
  public data: any;
  public mTab = [];
  public shopid;
  public mShopName = '';
  public sharecircledetail = '';
  public sharepic = '';
  public sharetitle = '';
  public shareLink = '';
  public sharedetail = '';
  public sanitizeHtml: any;
  // LQ
  public agreementStatus = false;
  public agreement_name = '';
  public agreement = '';
  public isshareholder:String = '';
  public agreenShow = false;
  constructor(public sanitize: DomSanitizer, public shareService: WeChatShareService, public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }
  public myStyle = {
    "padding-bottom": "30px"
  }
  @HostListener('window:scroll', ['$event']) public onScroll = ($event) => {
    //   if (this.isShareholder && this.mTab.length > 0) {
    //     //客户端高度
    //     var clientH = document.documentElement.clientHeight;
    //     //body高度
    //     var bodyH = document.body.clientHeight;
    //     //滚动的高度
    //     var offHeight = this.footerView.nativeElement.offsetHeight;
    //     var scrollTop = document.documentElement.scrollTop;
    //     try {
    //       this.bannersHeight = (this.elementView.nativeElement.offsetHeight);
    //     } catch (error) {
    //     }
    //     var height = (this.bannersHeight - offHeight * 4 - 50);
    //     if (scrollTop >= height) {
    //       console.log("true")
    //       this.myStyle = {
    //         "margin-bottom": offHeight + "px",
    //       };
    //     }else {
    //       console.log("false");
    //       this.myStyle = {
    //         "margin-bottom": offHeight + "px",
    //       }
    //   } 
    // }
    if (this.isShareholder == 2 && this.mTab.length > 0) {
      var offHeight = this.footerView.nativeElement.offsetHeight;
      this.myStyle = {
        "padding-bottom": offHeight + "px"
      }
    }
  }

  ngOnInit() {
    this.shopid;
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid;
    });
    if (this.utils.getLocalStorageItem('hadChoseShop')) {
      this.shopid = this.utils.getLocalStorageItem('shopid');
      this.utils.setLocalStorageItem('hadChoseShop', false);
    }
    if (this.shopid != undefined) this.utils.setLocalStorageItem('shopid', this.shopid);
    this.shareLink = SHARE_URL + '#/shareholder?' + 'shopid=' + this.shopid;
    this.getShareHolder();
    this.mShopName = this.utils.getLocalStorageItem('shopInfo').shopname;
  }

  public getShareHolder() {
    this.nativeService.showLoading();
    let params;
    let userid = this.utils.getLocalStorageItem("user_id");
    if (userid == undefined || userid == '') {
      params = {
        'openalias': 'wap.shareholder.nologin.index',
        'plat': 'plat_wap',
        'openno': '1',
        'shopid': this.utils.getLocalStorageItem("shopid"),
      }
    } else {
      params = {
        'userid': this.utils.getLocalStorageItem("user_id") == undefined ? '' : this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token") == undefined ? '' : this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.shareholder.index.index',
        'plat': 'plat_wap',
        'openno': '1',
        'shopid': this.utils.getLocalStorageItem("shopid"),
      }
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.data = result.data;
        this.sanitizeHtml = this.sanitize.bypassSecurityTrustHtml(result.data.content);
        this.isShareholder = result.data.status;
        if (result.data.status == 2) {
          this.mShopName = result.data.shopinfo.shopname;
        }
        if (result.data.money) {
          for (let i = 0; i < result.data.money.length; i++) {
            let isSelect = false;
            if (i == 0) {
              isSelect = true;
            }
            let item = { 'name': parseInt(result.data.money[i]), 'isSelect': isSelect }
            this.mTab.push(item);
          }
        }
        this.sharepic = result.data.shareurl;
        this.sharecircledetail = result.data.sharecircledetail;
        this.sharetitle = result.data.sharetitle;
        this.sharedetail = result.data.sharedetail;
        // 
        this.agreement_name = result.data.agreement_name;
        this.agreement = result.data.agreement;
        this.isshareholder = result.data.isshareholder;
        this.agreementStatus = result.data.agreement_name==''?true:false
        this.shareConfig();
      } else {
        if (result.code == 67 || result.msg == '请登录' || result.msg == '登录已过期,请重新登录') {
          this.nativeService.showWarningAlert(result.msg);
          this.gotoLogin();
        } else {
          this.nativeService.showWarningAlert(result.msg);
        }
      }
    })
  }
  public gotoLogin() {
    // console.log("去登录");
    this.utils.setLocalStorageItem('isLogin', '');
    this.utils.setLocalStorageItem('isWechatAuth', false);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
    this.router.navigate(['login']);
  }
  public select(index) {
    for (let i = 0; i < this.mTab.length; i++) {
      this.mTab[i].isSelect = false;
    }
    this.mTab[index].isSelect = true;
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

  // 股金明细
  public goToFinancialDetails() {
    this.router.navigate(['financialDetails'], { queryParams: {} })
  }
  // 我的团队
  public goToMyTeam() {
    this.router.navigate(['myTeam'], { queryParams: {} })
  }
  // 我的分红
  public goToMyBonus() {
    this.router.navigate(['myBonus'], { queryParams: {} })
  }
  // 排行榜
  public goToRankings() {
    this.router.navigate(['rankings'], { queryParams: {} })
  }
  //增持股金
  public goToIncreaseCapital() {
    this.router.navigate(['increase-capital'], { queryParams: {} });
  }
  // 提现
  public goToWithdrawDeposit() {
    this.router.navigate(['withdraw-deposit'], { queryParams: {} });
  }
  // 提现记录
  public goToWithdrawalRecord() {
    this.router.navigate(['withdrawal-record'], { queryParams: {} });
  }
  // 开始推广
  public goToGeneralize() {
    this.router.navigate(['generalize'], { queryParams: { 'type': 'generalize' } });
  }
  //入股其他门店
  public goToOtherStores() {
    this.utils.setLocalStorageItem('hadChoseShop', true);
    this.router.navigate(['selectShop'], { queryParams: { homePush: true } });
  }
  // 入股
  public price = 0;
  public buyAShare() {
    if(!this.agreementStatus){
      this.nativeService.showWarningAlert('请点击协议并确定');
      return;
    }
    if(this.isshareholder=='1'){
      this.nativeService.showWarningAlert('该门店股东已满不能再入股');
      return;
    }
    for (let i = 0; i < this.mTab.length; i++) {
      if (this.mTab[i].isSelect) {
        this.price = this.mTab[i].name;
      }
    }
    let userid = this.utils.getLocalStorageItem("user_id");
    if (userid == undefined || userid == '') {
      this.nativeService.showWarningAlert('请先登录');
      this.gotoLogin();
      return;
    }
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.order.Orderbuilder.recharge',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.utils.getLocalStorageItem("shopid"),
      'price': this.price
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.data = result.data;
        var orderid = result.data.orderid;
        this.router.navigate(['orderPay'], {
          queryParams: {
            'orderid': orderid,
            'orderType': 2
          }
        });
      } else if (result.msg == '您还没有设置支付密码，请先设置') {
        this.isGoSetPayPwd = true;
        this.nativeService.showWarningAlert(result.msg);
      } else {
        if (result.code == 66 || result.code == 67 || result.msg == '请登录' || result.msg == '登录已过期,请重新登录') {
          this.nativeService.showWarningAlert(result.msg);
          this.gotoLogin();
        } else {
          this.nativeService.showWarningAlert(result.msg);
        }
      }
    })
  }
  public dismiss() {
    this.isGoSetPayPwd = false;
  }
  public goToSetPayPwd() {
    this.router.navigate(['payment-password']);
  }

  // 分享
  public shareConfig() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.http.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        if (result.code == 100 && result.data) {
          var data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.shareService.configWXJs(data);
          this.shareService.getShareDataAndReadyLink(this.sharetitle,
            this.sharepic,
            this.sharedetail, this.sharecircledetail, this.shareLink).subscribe(
              (success) => {
                //分享bug:分享成功和取消都返回1
                console.log(success);
              });
        }
      });
    }
  }
  //月月有礼
  public goToMonthGift() {
    this.router.navigate(['monthGift'], { queryParams: { shopid: this.shopid } });
  }
  //福利中心
  public goToWelfareCentre() {
    this.router.navigate(['welfareCentre'], { queryParams: { shopid: this.shopid } });
  }
  /* === LQ ===*/
  public changeAgreen() {
    if(!this.agreementStatus){
      this.agreementStatus = true;
    }else{
      this.agreementStatus = false;
    }
    
  }
  public lookAgreen() {
    this.agreenShow = true;
  }
  public close() {
    this.agreenShow = false;
  }
  public sure() {
    this.agreenShow = false;
    this.agreementStatus = true;
  }
}
