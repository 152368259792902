;
import { LoginPasswordComponent } from './login-password/login-password.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';//不加上这个module可以ng的一些指令不能识别
import { RouterModule } from '@angular/router';
import { FixedNavModule } from '../component/fixed-nav/fixed-nav.module';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { AccountCenterComponent } from './account-center.component';
import { PaymentPasswordModule } from './payment-password/payment-password.module';
import { ChangeMoblieModule } from './change-mobile/change-mobile.module';
import { LoginPasswordModule } from './login-password/login-password.module';

@NgModule({
    imports: [
        CommonModule, 
        RouterModule.forRoot([{ path: 'account-center', component: AccountCenterComponent}]),
        FixedNavModule, 
        InfiniteScrollModule, 
        FooterRefreshModule,
        DataShowModule,
        LazyLoadImageModule,
        PaymentPasswordModule,
        ChangeMoblieModule,
        LoginPasswordModule,
    ],


    exports: [],
    declarations: [
        AccountCenterComponent
    ],
    providers: [],
})
export class AccountCenterModule {}