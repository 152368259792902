import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SRListDetailComponent } from '../SR-list-detail/SR-list-detail.component';
import { DataShowModule } from "../component/data-show/data-show.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'srListDetail', component: SRListDetailComponent }]),
        DataShowModule,
        InfiniteScrollModule,
        FooterRefreshModule,
        LazyLoadImageModule,
    ],
    exports: [],
    declarations: [
        SRListDetailComponent
    ],
    providers: [],
})
export class SRListDetailModule { }