import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataShowModule } from "../component/data-show/data-show.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { CouponRedemptionCentreComponent } from './coupon-redemption-centre.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'couponRedemptionCentre', component:  CouponRedemptionCentreComponent}]),
        FormsModule,
        ReactiveFormsModule,
        DataShowModule,InfiniteScrollModule,FooterRefreshModule
    ],
    exports: [],
    declarations: [
        CouponRedemptionCentreComponent
    ],
    providers: [],
})
// 领券中心
export class CouponRedemptionCentreModule{

}