import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphicCheckComponent } from './graphic-check.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
     RouterModule,
      FormsModule, 
  ],
  exports: [GraphicCheckComponent],
  declarations: [GraphicCheckComponent],
})
export class GraphicCheckModule { }
