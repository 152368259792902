import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AddAddressComponent } from '../add-address/add-address.component';
import { DataShowModule } from "../component/data-show/data-show.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { CanGiveTicketComponent } from './can-give-ticket.component';
import { ClipboardModule } from 'ngx-clipboard';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'canGiveTicket', component:  CanGiveTicketComponent}]),
        FormsModule,
        DataShowModule,
        InfiniteScrollModule,
        FooterRefreshModule,
        ReactiveFormsModule,
        ClipboardModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        CanGiveTicketComponent
    ],
    providers: [],
})
//可赠送券
export class CanGiveTicketModule{

}