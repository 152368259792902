import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  public mWalletInfo: any;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.getWalletInfo();
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  public getWalletInfo() {
    this.nativeService.showLoading();
    let params = {
      'shopid': this.utils.getLocalStorageItem('shopid'),
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.balance.getBalance',
      'plat': 'plat_wap',
      'openno': '1'
    }

    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.mWalletInfo = result.data;
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.backToPage();
        }
      }
    })
  }

  // 提现账号
  public goToCashAccount() {
    // console.log('提现账号...');
    // console.log('提现申请...');
    if (!this.mWalletInfo.has_passwd) {
      this.isGoSetPayPwd = true ;
      return ;
    }
    this.router.navigate(['cash-account'], { queryParams: {} });
  }
  // 提现申请
  public isGoSetPayPwd = false;
  public goToWithdrawApply() {
    // console.log('提现申请...');
    if (!this.mWalletInfo.has_passwd) {
      this.isGoSetPayPwd = true ;
      return ;
    }
    if (this.mWalletInfo.has_bank) {
      this.router.navigate(['withdraw-apply'], { queryParams: {} });
    } else {
      this.nativeService.showWarningAlert("请先绑定提现账号！");
    }
  }
  // 收支明细
  public goToDetailedAccounts() {
    // console.log('收支明细...');
    this.router.navigate(['detailed-account'], { queryParams: {} });
  }

  public goToWithdrawalRecrod() {
    this.router.navigate(['w-record'], { queryParams: {} });
  }
  public dismiss() {
    this.isGoSetPayPwd = false;
  }
  public goToSetPayPwd() {
    this.isGoSetPayPwd = false;
    this.router.navigate(['payment-password']);
  }
}
