import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
@Component({
    selector: 'app-SR-order-detail',
    templateUrl: './SR-order-detail.component.html',
    styleUrls: ['./SR-order-detail.component.css']
})
export class SROrderDetailComponent implements OnInit {

    public orderid;
    public order;
    constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
        public routeInfo: ActivatedRoute, public router: Router) { }

    ngOnInit() {
        this.routeInfo.queryParams.subscribe(queryParams => {
            this.orderid = queryParams.orderid == undefined ? '' : queryParams.orderid;
            this.getOrderDetail(this.orderid);
        });
        // this.nativeService.showLoading();
    }

    public backToPage() {
        this.nativeService.backToPage();
    }
    // 获取订单详情
    public getOrderDetail(id){
        let params = {
            'openalias': 'wap.order.ordermall.orderDetail',
            'plat': 'plat_wap',
            'openno': 1,
            'userid': this.utils.getLocalStorageItem('user_id'),
            'user_token': this.utils.getLocalStorageItem('user_token'),
            'orderid': id
        }
        this.http.request({
            'method': 'POST',
            'url': 'api/index',
            'data': params
        }).then(result => {
            // this.isShowLoading = false;
            if (result.code == 100 && result.data) {
                this.order = result.data;
                // console.log(result.data);
            } else {
                this.nativeService.hideLoading();
                this.nativeService.showErrorAlert(result.msg);
            }
        })
    }
    // 取消订单
    public goCancelOrder(){
        let params = {
            'openalias': 'wap.order.ordermall.cancelOrder',
            'plat': 'plat_wap',
            'openno': 1,
            'userid': this.utils.getLocalStorageItem('user_id'),
            'user_token': this.utils.getLocalStorageItem('user_token'),
            'orderid': this.orderid
        }
        this.http.request({
            'method': 'POST',
            'url': 'api/index',
            'data': params
        }).then(result => {
            // this.isShowLoading = false;
            if (result.code == 100 && result.data) {
                this.nativeService.showSuccessAlert('取消成功');
                this.getOrderDetail(this.orderid);
            } else {
                this.nativeService.hideLoading();
                this.nativeService.showErrorAlert(result.msg);
            }
        })
    }
    // 去支付
    public goPlay(){
        this.router.navigate(['orderPay'],{queryParams: {'orderid': this.orderid,}});
    }
}
