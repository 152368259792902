import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
@Component({
    selector: 'app-SR-list-detail',
    templateUrl: './SR-list-detail.component.html',
    styleUrls: ['./SR-list-detail.component.css']
})
export class SRListDetailComponent implements OnInit {
    public id = '';
    public detailInfo;

    constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
        public routeInfo: ActivatedRoute, public router: Router) { }

    ngOnInit() {
        this.routeInfo.queryParams.subscribe(queryParams => {
            this.id = queryParams.id == undefined ? '' : queryParams.id;
            this.getDetailInfo(this.id);
        });
    }

    public backToPage() {
        this.nativeService.backToPage();
    }
    // 去提交订单
    public goconfirm(id){
        this.router.navigate(['srOrderConfirm'],{queryParams: {'id': id,}})
    }
    // 获取详情
    public getDetailInfo(id){
        let params = {
            'openalias': 'wap.product.Thirds.getInfo',
            'plat': 'plat_wap',
            'openno': 1,
            'userid': this.utils.getLocalStorageItem('user_id'),
            'user_token': this.utils.getLocalStorageItem('user_token'),
            id: id,
        }
        this.http.request({
            'method': 'POST',
            'url': 'api/index',
            'data': params
        }).then(result => {
            // this.isShowLoading = false;
            if (result.code == 100 && result.data) {
                console.log(result);
                this.detailInfo = result.data;
            } else {
                this.nativeService.hideLoading();
                this.nativeService.showErrorAlert(result.msg);
            }
        })
    }

    
}
