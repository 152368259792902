import { Component, OnInit, Output ,EventEmitter} from '@angular/core';
import { HttpService } from "../providers/HttpService"
import { NativeService } from "../providers/NativeService";
import { Utils } from "../providers/Utils";
import { WeChatShareService } from "../providers/weChatShareService";
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
declare var WeixinJSBridge: any;
@Component({
  selector: 'select-shop',
  templateUrl: './select-shop.component.html',
  styleUrls: ['./select-shop.component.css']
})
export class SelectShopComponent implements OnInit {
  public homePush = false;
  public showHeader = false;
  public showFooter = false;
  public noMore = false;
  public total: number = 1;
  public page: number = 1;
  public shopList = [];
  public provinceid: number = 0;
  public cityid: number = 0;
  public areaid: number = 0;
  public showView = false;
  public showLocalStorageSupported = false;//uc浏览器无痕阅读时，localStorage.getItem报错不能判断，只能根据“apitoken丢失”来处理
  constructor(public routeInfo: ActivatedRoute, public shareService: WeChatShareService, public routerInfo: ActivatedRoute, public router: Router, public httpService: HttpService, public nativeService: NativeService, public utils: Utils) { }
  @Output() outData = new EventEmitter<any>(); //输出并传递数据给调用者
  ngOnInit() {
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.homePush = queryParams.homePush == undefined ? false : queryParams.homePush;
    });
    this.nativeService.showLoading();
    this.getShopList(false);
  }
  backToPage() {
    this.nativeService.backToPage();
  }
  getShopList(state) {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.shop.shop.shopList',
      'provinceid': this.provinceid,
      'cityid': this.cityid,
      'areaid': this.areaid,
      'page': this.page,
      'limit': 20,
      'lng': this.utils.getLocalStorageItem('lng'),
      'lat': this.utils.getLocalStorageItem('lat'),
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      this.showView = true;
      if (result.code == 100 && result.data) {
        this.total = result.data.total;
        if (this.total > 0) {
          if (state) {
            //加载更多
            this.shopList = this.shopList.concat(result.data.list);
          } else {
            //下拉刷新
            this.shopList = result.data.list;
          }
          this.noMore = this.shopList.length >= this.total ? true : false;
        } else {
          //没有数据
          this.total = 0;
        }
      } else {
        if (result.msg == "apitoken丢失") {
          this.showLocalStorageSupported = true;
          this.showView = false;
        } else {
          this.showLocalStorageSupported = false;
          this.showView = true;
          this.nativeService.showErrorAlert(result.msg);
        }
      }
      this.nativeService.hideLoading();
      this.showFooter = false;
      this.showHeader = false;
    });
  }
  onScrollDown() {
    console.log("onScrollDown");
    if (this.shopList.length < this.total) {
      this.page = 1 + this.page;
      this.showFooter = true;
      this.getShopList(true);
    }
  }
  // 下拉刷新有点bug不要
  onScrollUp() {
  }
  //门店点击事件
  shopClick(item) {
    //初始化
    var json = JSON.stringify({
      "titleIndex": 0
    });
    this.utils.sessionStorageSetItem("enterActivityInitData", json);
    if(this.homePush){
      this.utils.setLocalStorageItem('shopInfo',item);
      this.utils.setLocalStorageItem('shopid',item.shopid);
      this.nativeService.backToPage();
    }else{
      this.outData.emit(item); 
    }
  }
}
