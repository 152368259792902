//门店确认订单
import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { Utils } from "../providers/Utils"
import { HttpService } from "../providers/HttpService"
import { DataServerService } from "./../providers/DataServer";
@Component({
  selector: 'app-shop-order-confirm',
  templateUrl: './service-package-order-confirm.component.html',
  styleUrls: ['./service-package-order-confirm.component.css']
})
export class ServicePackageOrderConfirmComponent implements OnInit {
  @ViewChild('shopAddressHeight') elementView: ElementRef;
  viewHeight: number;
  public shopAddressHeight: number;
  public shopid = 0;
  public pid = '';
  public productType = 0;
  public shopLongAddress = "";
  public productlist = [];
  public productlistWL = [];
  public amountpay = 0;//订单支付总额
  public amountpayZT = 0;//订单支付总额--自提
  public amountpayWL = 0;//订单支付总额--物流
  public amountdiscount = 0;
  public numtotal = 0;//商品总数
  public numtotalZT = 0;//自提商品总数
  public numtotalWL = 0;//物流商品总数
  public remark = '';//自提留言
  public remarkWL = '';//物流留言
  public pidStr = '';
  public pidStrKey = '';
  public orderid = 0;
  public showView = false;
  public productIdList = '';
  public productIdListWL = '';
  public ticketlist;//优惠券列表
  public ticketlistZT;//优惠券列表--自提
  public ticketlistWL;//优惠券列表--物流
  public amountticket;//券优惠金额
  public amountticketZT;//券优惠金额--自提
  public amountticketWL;//券优惠金额--物流
  public totalDiscount;//总优惠金额
  public ticketList = [];
  public isNoData = false;
  public isSelectAddress = false;//是否已经选择了地址
  public mineAddress;//我的地址数据
  public mineAddressText;//我的地址文本
  public consignee; //收件人
  public mobile; //收件人
  public orderdiscountZT: 0;
  // 是否切换物流配送方式isSHowSwitchDialog
  public isSHowSwitchDialog = false;
  public productlistId = "";
  public modeOfDistributionArr = [
    { 'name': '物流配送发货', 'type': 2, 'isSelect': false },
    { 'name': '到店自提/服务', 'type': 1, 'isSelect': false }
  ];
  public discountsOrderArr = [];//订单优惠
  public isShowOpDialog = false;
  public addressid = '';
  public stockids = '';
  public amountexpressWl = '';//运费
  public setmealDetail = [];
  public validityType = 1;
  public validityTime;
  // lq
  public bindCarNum; //绑定数量
  public bindCarStatus; //绑定限制
  public bindCarList = []; //绑定车辆数组
  // 
  public default_tid;
  constructor(
    public httpService: HttpService,
    public utils: Utils,
    public router: Router,
    public nativeService: NativeService,
    public routeInfo: ActivatedRoute,
    public el: ElementRef,
    public dataServer: DataServerService
  ) { }
  backToPage() {
    this.dataServer.data = [];
    this.nativeService.backToPage();
  }
  ngOnInit() {
    //接收参数
    let sids = '';
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid == undefined ? 0 : queryParams.shopid;
      this.pid = queryParams.pid == undefined ? 0 : queryParams.pid;
      this.productType = queryParams.productType == undefined ? 0 : queryParams.productType;
      this.shopLongAddress = queryParams.shopLongAddress == undefined ? "" : queryParams.shopLongAddress;
      sids = queryParams.pidStr == undefined ? "" : queryParams.pidStr;
      this.default_tid = queryParams.tid == undefined ? 0 : queryParams.tid;
    });
    // console.log(this.shopid + "--" + this.shopLongAddress + "--" + this.pidStr);
    // this.shopLongAddress = this.utils.getLocalStorageItem('shopInfo').addressdetail;
    let pids = this.utils.getLocalStorageItem('pidStr');
    this.pidStr = pids == undefined ? "" : pids;
    // this.stockids = pids == undefined ? "" : pids;
    this.isSelectAddress = this.utils.sessionStorageGetItem('chooceAddress') == null ? false : true;
    this.getShopInfo();
    this.getListData();
    setTimeout(() => {
      // 获取优惠券
      this.getCouponList();
    }, 500)
    console.log(this.dataServer.data);
    if (this.dataServer.data.length > 0) {
      this.bindCarList = this.dataServer.data;
    }
  }
  getShopInfo() {
    let param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': 'wap.shop.index.getIndex',
      'shopid': this.shopid,
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        this.shopLongAddress = success.data.shopInfo.addressdetail;
      }
    });
  }
  requestAddressData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.member.address.getList",
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        if (success.data.list.length > 0) {
          this.isSelectAddress = true;
          this.utils.sessionStorageSetItem('chooceAddress', success.data.list[0])
          this.mineAddress = this.utils.sessionStorageGetItem('chooceAddress');
          this.mineAddressText = this.mineAddress.province + this.mineAddress.cityname + this.mineAddress.areaname + this.mineAddress.address;
          this.mobile = this.mineAddress.mobile;
          this.consignee = this.mineAddress.consignee;
          this.addressid = this.mineAddress.id;
        } else {
          that.mineAddressText = '';
        }
        that.getListData();
      } else {
        that.nativeService.showErrorAlert(success.msg);
        that.nativeService.hideLoading();
      }
    });
  }
  getListData() {
    this.nativeService.showLoading();
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.cart.serversetmealcart.index",
      'shopid': that.shopid,
      'pid': that.pid,
      'productType': this.productType,
      'userTicketId': this.utils.getLocalStorageItem("ServicePakageCoupon").userticketid
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      console.log(1234)
      that.nativeService.hideLoading();
      if (success.code == 100 && success.data) {
        that.isNoData = false;
        if (success.data.setmealDetail != null && success.data.setmealDetail != undefined) that.setmealDetail = success.data.setmealDetail;
        // console.log(JSON.stringify(success.data));
        // that.productlist = success.data.productlist;
        var suborder = success.data.suborder;
        that.productlist = [];
        that.productlistWL = [];
        if (suborder != undefined || suborder != null) {
          let length = suborder.length;
          that.getValidityTime(suborder[0].productlist[0].effectivetime, suborder[0].productlist[0].effectivevalue);
          if (suborder[0].tip == "以下商品需到店自提") {
            that.productlist = suborder[0].productlist;
            that.numtotalZT = suborder[0].numtotal;
            that.amountpayZT = suborder[0].amountpay;
            that.amountticketZT = suborder[0].amountticket;
            that.ticketlistZT = suborder[0].ticketlist;
            that.orderdiscountZT = suborder[0].orderdiscount;

            that.bindCarNum = suborder[0].productlist[0].bindvehiclenum;
            that.bindCarStatus = suborder[0].productlist[0].bindvehiclestatus;
            // numtotal, orderdislist, productlist
            that.discountsOrderArr = that.dataFiltration(suborder[0].numtotal, suborder[0].orderdislist, suborder[0].productlist);
            // that.productlistId = 
            // console.log(JSON.stringify(suborder[0].productlist))
            for (let i = 0; i < suborder[0].productlist.length; i++) {
              that.productlistId += suborder[0].productlist[i].pid + ',';
              that.productlistId += suborder[0].productlist[i].ptype;
              that.pidStrKey += suborder[0].productlist[i].key;
              if (i != (suborder[0].productlist.length - 1)) {
                that.productlistId += '/';
                that.pidStrKey += ',';
              }
            }
          } else {
            that.productlistWL = suborder[0].productlist;
            that.numtotalWL = suborder[0].numtotal;
            that.amountpayWL = suborder[0].amountpay;
            that.amountticketWL = suborder[0].amountticket;
            that.ticketlistWL = suborder[0].ticketlist;
            that.amountexpressWl = suborder[0].amountexpress;
          }
          if (length > 1) {
            if (suborder[1].tip == "以下商品需到店自提") {
              that.productlist = suborder[1].productlist;
              that.numtotalZT = suborder[1].numtotal;
              that.amountpayZT = suborder[1].amountpay;
              that.amountticketZT = suborder[1].amountticket;
              that.ticketlistZT = suborder[1].ticketlist;
              that.orderdiscountZT = suborder[1].orderdiscount;
              that.discountsOrderArr = that.dataFiltration(suborder[1].numtotal, suborder[1].orderdislist, suborder[1].productlist);
              for (let i = 0; i < suborder[1].productlist.length; i++) {
                that.productlistId += suborder[1].productlist[i].pid + ',';
                that.productlistId += suborder[1].productlist[i].ptype;
                that.pidStrKey += suborder[0].productlist[i].key;
                if (i != (suborder[0].productlist.length - 1)) {
                  that.productlistId += '/';
                  that.pidStrKey += ',';
                }
              }
            } else {
              that.productlistWL = suborder[1].productlist;
              that.numtotalWL = suborder[1].numtotal;
              that.amountpayWL = suborder[1].amountpay;
              that.amountticketWL = suborder[1].amountticket;
              that.ticketlistWL = suborder[1].ticketlist;
              that.amountexpressWl = suborder[1].amountexpress;
            }
          }
        }
        that.amountpay = success.data.amountpay;
        that.numtotal = success.data.numtotal;
        that.amountticket = success.data.amountticket;
        var orderdiscount = parseFloat(success.data.orderdiscount);
        var amountticket = parseFloat(success.data.amountticket);
        that.totalDiscount = (orderdiscount + amountticket).toFixed(2);
        that.amountdiscount = parseInt(that.totalDiscount);
        that.showView = true;
        for (let index = 0; index < that.productlist.length; index++) {
          that.productIdList += that.productlist[index].pid + "," + that.productlist[index].ptype;
          if (index < (that.productlist.length - 1)) {
            that.productIdList += "/"
          }
        }
        for (let index = 0; index < that.productlistWL.length; index++) {
          that.productIdListWL += that.productlistWL[index].pid + "," + that.productlistWL[index].ptype;
          if (index < (that.productlistWL.length - 1)) {
            that.productIdListWL += "/"
          }
        }
        // console.log('ticketlistZT1:'+JSON.stringify(that.ticketlistZT));
        // that.ticketlist = success.data.ticketlist;
        // if (that.ticketlist != undefined) {
        //   for (let i = 0; i < that.ticketlist.length; i++) {
        //     var par = {
        //       "userticketid": 0,
        //       "type": 0
        //     }
        //     par.userticketid = that.ticketlist[i].userticketid;
        //     par.type = that.ticketlist[i].tickettype;
        //     that.ticketList.push(par);
        //   }
        //   for (let i = 0; i < that.ticketlist.length; i++) {
        //     if (that.ticketlist[i].ret == false) {
        //       that.nativeService.showWarningAlert('亲，您的优惠券信息有变更(' + that.ticketlist[i].ticketname + ',' + that.ticketlist[i].msg + ')');
        //       return;
        //     }
        //   }
        // }
        if (that.productlistWL.length == 0 && that.productlist.length == 0) {
          that.isNoData = true;
        }
      } else {
        if (success.msg == "产品不存在") {
          that.isNoData = true;
        }
        this.nativeService.showWarningAlert(success.msg);
        this.backToPage();
      }

    });
  }
  //计算套餐的有效期
  public getValidityTime(effectivetime, effectivevalue) {
    // effectivetime   使用有效期：1、有效时间范围 2、天数 3、不限期限',
    // effectivevalue 有效期值
    this.validityType = effectivetime;
    if (effectivetime == 1) {
      var timeList = effectivevalue.split(',');
      this.validityTime = timeList;
    } else if (effectivetime == 2) {
      this.validityTime = effectivevalue + "天";
    } else if (effectivetime == 3) {
      this.validityTime = "不限时间";
    }
  }
  // 筛选数据
  public dataFiltration(numtotal, orderdislist, productlist) {
    var carGoodsList = [];
    var pkeyList = [];
    //满减的商品
    if (numtotal > 0 && orderdislist.length > 0) {
      for (var i = 0; i < orderdislist.length; i++) {
        var orderdisItem = orderdislist[i];
        orderdisItem.showDisstatus = true;
        orderdisItem.list = [];
        for (var j = 0; j < orderdisItem.stock.length; j++) {
          for (var k = 0; k < productlist.length; k++) {
            productlist[k].pkey = productlist[k].key;
            var productlistItem = productlist[k];
            // productlistItem.pkey = productlistItem.key;
            if (productlistItem.pkey == orderdisItem.stock[j]) {
              orderdisItem.list.push(productlistItem);
              pkeyList.push(productlistItem.pkey);//把有满减的商品的kpey放在数组里
            }
          }
        }
        carGoodsList.push(orderdisItem);
      }
    } else {
      carGoodsList = [];
    }
    return carGoodsList;
  }
  commitClick() {
    var that = this;
    var list = [];
    for (let index = 0; index < that.productlist.length; index++) {
      const element = that.productlist[index];
      var stockids = element.key;
      list.push(stockids);
    }
    this.stockids = list.join(',');
    console.log(this.stockids);
    if (that.orderid == 0) {
      var that = this;
      let orderType = 1;
      if (this.productlistWL == undefined || this.productlistWL.length == 0) {
        orderType = 1;
      } else if (this.productlist == undefined || this.productlist.length == 0) {
        orderType = 0;
      } else {
        orderType = 5;
      }
      var bindvehicles = '';
      if (this.bindCarStatus == 2 && this.bindCarList.length == 0) {
        that.nativeService.showWarningAlert("至少选择一辆车绑定");
        return;
      } else {
        for (var index = 0; index < this.bindCarList.length; index++) {
          if (index == 0) {
            bindvehicles += this.bindCarList[index].cartnum;
          } else {
            bindvehicles += ',' + this.bindCarList[index].cartnum;
          }

        }
      }
      var param = {
        'plat': 'plat_wap',
        'openno': '2',
        'userid': this.utils.getLocalStorageItem("user_info").userid,
        'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
        'openalias': "wap.cart.serversetmealcart.createorder",
        'shopid': that.shopid,
        'remarkshop': that.remark,
        'stockids': this.stockids,
        'ptype': this.productType,
        'userticketid': this.utils.getLocalStorageItem("ServicePakageCoupon").userticketid,
        bindvehicles: bindvehicles
      }
      console.log(param);
      this.httpService.request({
        method: 'post',
        url: 'api/index',
        data: param,
      }).then(success => {
        if (success.code == 100 && success.data) {
          var orderid = success.data.orderid;
          this.orderid = orderid;
          this.dataServer.data = [];
          this.router.navigate(['orderPay'], {
            queryParams: {
              'orderid': orderid,
              'orderType': orderType
            }
          });
          this.nativeService.hideLoading();
          this.utils.removeLocalStorageItem("ServicePakageCoupon");
        } else {
          this.nativeService.hideLoading();
          if (success.msg == '收货信息不存在') {
            that.nativeService.showWarningAlert(success.msg + ',请重新选择！');
          } else {
            that.nativeService.showErrorAlert(success.msg);
          }
        }
      });

    } else {
      let orderType = 1;
      if (this.productlistWL == undefined || this.productlistWL.length == 0) {
        orderType = 1;
      } else if (this.productlist == undefined || this.productlist.length == 0) {
        orderType = 0;
      } else {
        orderType = 5;
      }
      this.nativeService.hideLoading();
      this.router.navigate(['orderPay'], {
        queryParams: {
          'orderid': this.orderid,
          'orderType': orderType
        }
      });
    }
  }
  // 选择优惠券
  public selectCoupon() {
    // this.router.navigate([""])
    // var tl = this.ticketList == undefined ? '[]' : JSON.stringify(this.ticketList);
    var tl = this.ticketList == undefined ? '[]' : JSON.stringify(this.ticketlistZT);
    this.router.navigate(['discountCouponSelect'], { queryParams: { 'productlist': this.productlistId, 'shopid': this.shopid, 'ticketlist': tl, 'pidStr': this.pidStrKey, 'isServicePakage': true } });
  }
  // 选择收货地址
  public selectAddress() {
    this.router.navigate(['mineAddress'], { queryParams: { 'selctAddress': true } });
  }

  public isShowAddress = false;
  @HostListener('window:scroll', ['$event']) public onScroll = ($event) => {
    // console.log('滚动了...'); 
    //客户端高度
    var clientH = document.documentElement.clientHeight;
    //body高度
    var bodyH = document.body.clientHeight;

    //滚动的高度
    var scrollTop = document.documentElement.scrollTop;
    // console.log("滚动的高度:" + scrollTop);
    try {
      this.shopAddressHeight = this.elementView.nativeElement.offsetHeight;
    } catch (error) {
    }
    // console.log('店铺div高度：' + this.shopAddressHeight);
    if (scrollTop > this.shopAddressHeight) {
      this.isShowAddress = true;
    } else {
      this.isShowAddress = false;
    }
    // 滚动到底部60以内
    // if (bodyH - clientH - scrollTop < 80) {
    //     if (!this.flag) {
    //         console.log('翻页');
    //         //翻页
    //         this.changePage('+');
    //     }
    //     this.flag = true;
    // } else {
    //     this.flag = false;
    // }
  }

  // 切换配送方式
  public dismissSwitchDialog() {
    this.isSHowSwitchDialog = false;
  }
  public selectType = 0;
  public productData: any;
  public trigger(itemData, type) {
    this.selectType = type;
    this.productData = itemData;
    // console.log(JSON.stringify(itemData));
    let index = 1;
    for (let index = 0; index < this.modeOfDistributionArr.length; index++) {
      if (this.modeOfDistributionArr[index].type == type) {
        this.modeOfDistributionArr[index].isSelect = true;
      } else {
        this.modeOfDistributionArr[index].isSelect = false;
      }
    }
    this.isSHowSwitchDialog = true;
    if (index == 1) {
      return;
    }

  }

  // 选择配送方式
  public selectLogisticsMode(idx) {
    for (let index = 0; index < this.modeOfDistributionArr.length; index++) {
      this.modeOfDistributionArr[index].isSelect = false;
    }
    this.modeOfDistributionArr[idx].isSelect = true;
  }
  // 选择完成
  public done() {
    let isEqual = false;
    for (let index = 0; index < this.modeOfDistributionArr.length; index++) {
      if (this.modeOfDistributionArr[index].isSelect) {
        if (this.modeOfDistributionArr[index].type == this.selectType) {
          isEqual = true;
        }
      }
    }
    this.isSHowSwitchDialog = false;
    if (!isEqual) {
      this.goTrigger();
    }
  }

  public goTrigger() {
    let that = this;
    this.nativeService.showLoading();
    let param = {
      'plat': 'plat_wap',
      'openno': '2',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.cart.shopcart.change",
      'pkey': this.productData.key,
      'shopid': this.utils.getLocalStorageItem("shopid"),
    }
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        let key = success.data.pkey;
        if (success.data.ret) {
          that.nativeService.showSuccessAlert('切换成功');
          that.pidStr = that.getNewKeyList(that.pidStr, that.productData.key, key);
          that.stockids = that.getNewKeyList(that.stockids, that.productData.key, key);
          that.utils.setLocalStorageItem('pidStr', that.pidStr);
          that.pidStrKey = '';
        }
        that.getListData();
      } else {
        that.nativeService.showErrorAlert(success.msg);
      }
      that.nativeService.hideLoading();
    });
  }

  public getNewKeyList(KeyList, key, newKey) {
    let kList = (KeyList + '').split(',');
    for (let i = 0; i < kList.length; i++) {
      if (key == kList[i]) {
        kList.splice(i, 1);
      }
    }
    kList.push(newKey);
    return kList.join(',');
  }
  // 关闭订单优惠dia
  public dismissOpDialog() {
    this.isShowOpDialog = false;
  }
  // 开启订单优惠dia
  public showOpDialog() {
    if (this.discountsOrderArr.length > 0) {
      this.isShowOpDialog = true;
    } else {
      this.nativeService.showWarningAlert("暂无订单优惠!");
    }
  }
  /* lq */
  // 删除绑定车辆
  public delBindCar(index) {
    console.log(index);
    // let index = e.currentTarget.dataset.index
    let newArr = this.bindCarList;
    newArr.splice(index, 1);
    this.bindCarList = newArr;
    this.dataServer.data = newArr;
  }
  // 添加绑定数量
  public addBindCarNums() {
    // /pages/vehicleManagement/vehicleList/vehicleList?isSelected=true
    this.router.navigate(['my-car'], { queryParams: { isSelected: true } })
  }

  // 获取优惠券
  public getCouponList() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.cart.shopcart.getUseList',
      'shopid': this.shopid,
      'productlist': this.productlistId,
      'plat': 'plat_wap',
      'openno': '2',
    }
    this.httpService.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        let arr = result.data;
        console.log(this.default_tid);
        if (this.default_tid) {
          for (let i = 0; i < arr.length; i++) {
            if (this.default_tid == arr[i].ticketid && arr[i].issupport==true) {
              var par = {
                "userticketid": arr[i].id,
                "type": arr[i].type,
                "price": arr[i].price
              }
              this.utils.setLocalStorageItem("ServicePakageCoupon", par);
              this.getListData();
              break;
            }
          }
        }
        // 
        let isHave = this.utils.getLocalStorageItem("ServicePakageCoupon") ? this.utils.getLocalStorageItem("ServicePakageCoupon").userticketid : false;
        if (!isHave) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].issupport==true) {
              var par = {
                "userticketid": arr[i].id,
                "type": arr[i].type,
                "price": arr[i].price
              }
              this.utils.setLocalStorageItem("ServicePakageCoupon", par);
              this.getListData();
              break;
            }
          }
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
}