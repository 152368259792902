import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from "../providers/Utils"
import { WeChatShareService } from "../providers/weChatShareService";
declare let Swiper: any;
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.css', '../../assets/css/swiper.min.css', '../../assets/css/animate.min.css']
})
export class PackageDetailComponent implements OnInit {
  public setmealid = 0;
  public shopid = 0;
  public showView = false;
  public innerWidth = 0;
  public activeIndex = 1;
  public dataDict: any = {};
  public piclistCount = 0;
  public packlistCount = 0;
  public packlist = [];
  public product = [];
  public sanitizeHtml: any;
  public starttime = "1535505364000";
  public endtime = "";

  public isLogin = false;
  public cartTotalNum = 0;
  public buyNow = false;

  public isShow = false;
  public showAnimatin = false;
  public mCouponArray = [];
  public sellPrice:any = '0.00';
  public default_tid;
  constructor(public titleServers:Title,public shareService: WeChatShareService, public utils: Utils, public sanitize: DomSanitizer, public httpService: HttpService, public router: Router, public routeInfo: ActivatedRoute, public nativeService: NativeService) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.setmealid = queryParams.setmealid == undefined ? 0 : queryParams.setmealid;
      this.shopid = queryParams.shopid == undefined ? this.utils.getLocalStorageItem('shopid') : queryParams.shopid;
      this.default_tid = queryParams.tid == undefined ? 0 : queryParams.tid;
    });
    this.nativeService.showLoading();
    this.isLogin = this.utils.getLocalStorageItem("isLogin");
    if (this.isLogin) {
      this.getCartTotalData();
      this.getgetCouponsListData();
    }
    this.getGoodsInfo();
  }
  // 分享
  public shareConfig() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.httpService.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          var data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.shareService.configWXJs(data);
          var title =  this.utils.getLocalStorageItem('shopInfo').shopname!= undefined ?  this.utils.getLocalStorageItem('shopInfo').shopname : "爱车爱生活 爱上基利斯";
          this.shareService.getShareDataAndReadyLink(this.dataDict.setmealname,
            this.dataDict.masterimg,
            title, "", location.href).subscribe(
              (success) => {
                //分享bug:分享成功和取消都返回1
                console.log(success);
              });
        }
      });
    }
  }
  swiperInit() {
    var that = this;
    _require('wap/assets/js/swiper.min.js', function () {
      /* swiper初始化 */
      var mySwiper = new Swiper('.swiper-container', {
        autoplay: 0,
        loop: false,
        pagination: '.swiper-pagination',
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        longSwipesRatio: 0.3,
        touchRatio: 1,
        autoHeight: true,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        onSlideChangeEnd: function (swiper) {
          that.activeIndex = swiper.activeIndex + 1;
        }
      });
    });
  }
  //获取
  getGoodsInfo() {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.setmeal.setmealprogram.getInfo',
      'setmealid': this.setmealid
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        let isLogin = this.utils.getLocalStorageItem("isLogin");
        let isVipUser = this.utils.getLocalStorageItem("user_info").isVipUser; 
        if(success.data.setmeal.isvipprice == 2&&isLogin&&isVipUser==1){
          this.sellPrice = success.data.setmeal.vipprice;
        }else {
          this.sellPrice = success.data.setmeal.sellprice;
        }
        success.data.setmeal.detail = success.data.setmeal.detail.replace(/\<img/gi, '<img style="max-width:100%;display:block;"');
        this.dataDict = success.data.setmeal;
        this.piclistCount = success.data.setmeal.img.length;
        this.packlistCount = success.data.server.length;
        this.packlist = success.data.server;
        this.product = success.data.product;
        this.sanitizeHtml = this.sanitize.bypassSecurityTrustHtml(success.data.setmeal.detail);
        this.showView = true;
        this.swiperInit();
        this.shareConfig();
        var title = this.dataDict.setmealname;
        this.titleServers.setTitle(title);
      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
      this.nativeService.hideLoading();
    });
  }
  //购物车商品数量
  getCartTotalData() {
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': "wap.cart.shopcart.getCartTotal",
      'shopid': this.shopid,
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        this.cartTotalNum = success.data;
      } else {
        // this.nativeService.showErrorAlert(success.msg);
        if (success.msg == "登录已过期,请重新登录" || success.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.cartTotalNum = 0;
        }
      }
    });
  }
  //加入购物车
  addCarAndBuyNow() {
    var that = this;
    if (that.dataDict['stock'] > 0) {
      this.nativeService.showLoading();
      var param = {
        'plat': 'plat_wap',
        'openno': '2',
        'openalias': "wap.cart.shopcart.add",
        'userid': this.utils.getLocalStorageItem("user_info").userid,
        'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
        'pid': this.dataDict['id'],
        'num': 1,
        'shopid': that.shopid,
        'productType': 3
      }
      this.httpService.request({
        method: 'POST',
        url: 'api/index',
        data: param,
      }).then(success => {
        if (success.code == 100 && success.data) {
          that.getCartTotalData();
          if (that.buyNow) {
            this.router.navigate(['shoppingCart'], { queryParams: { shopid: this.shopid, pkey: that.setmealid + "_3" } });
            this.buyNow = false;
          } else {
            this.nativeService.showSuccessAlert("已成功加入购物车");
          }
        } else {
          this.nativeService.showErrorAlert(success.msg);
        }
        this.nativeService.hideLoading();
      });
    } else {
      this.nativeService.showWarningAlert("套餐已售完");
    }
  }
  //首页
  goHome() {
    this.router.navigate(['home']);
  }
  goToCar() {
    if (this.whetherLogin()) {
      this.router.navigate(['shoppingCart'], { queryParams: { shopid: this.shopid } });
    }
  }
  addToCar() {
    if (this.whetherLogin()) {
      this.addCarAndBuyNow();
    }
  }
  buyNowAction(id) {
    if (this.whetherLogin()) {
      this.utils.setLocalStorageItem("ServicePakageCoupon", "");
      this.router.navigate(['ServicePackageOrderConfirm'], { queryParams: { shopid: this.shopid, pid: id, productType: 3, tid: this.default_tid  } });
    }
    // if (this.whetherLogin()) {
    //   this.buyNow = true;
    //   this.addCarAndBuyNow();
    // }
  }
  whetherLogin() {
    if (!this.isLogin) {
      this.utils.setLocalStorageItem('isWechatAuth', false);
      this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
  getgetCouponsListData() {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': "wap.ticket.GetUseTicket.getList",
      'shopid': this.shopid,
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'producttype': 4,//2商品 3服务 4套餐 
      'productid': this.setmealid
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        this.mCouponArray = success.data;
      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
    });
  }
  //获取券列表
  getCouponsList() {
    this.isShow = true;
    this.showAnimatin = true;
  }
  //立即领取
  getCoupon(item) {
    if (this.whetherLogin()) {
      this.nativeService.hideLoading();
      let params = {
        'userid': this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.ticket.Ticketuser.addTicket',
        'plat': 'plat_wap',
        'openno': '1',
        'shareId': item.id,
      }
      this.httpService.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(success => {
        this.nativeService.hideLoading();
        if (success.code == 100 && success.data) {
          this.nativeService.showSuccessAlert(success.msg);
          this.getgetCouponsListData();
        } else {
          this.nativeService.showWarningAlert(success.msg);
        }
      });
    }
  }
  //关闭券列表
  closeGetCouponsBox() {
    const that = this;
    that.showAnimatin = false;
    setTimeout(function () {
      that.isShow = false;
    }, 800);
  }
}
