import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { VipMemberComponent } from '../vip-member/vip-member.component';
import { GraphicCheckModule } from '../component/graphic-check/graphic-check.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([{ path: 'vipMember', component: VipMemberComponent }]),
    InfiniteScrollModule,
    FooterRefreshModule,
    LazyLoadImageModule,
    FormsModule,
    ReactiveFormsModule,
    GraphicCheckModule
  ],
  declarations: [
    VipMemberComponent
  ]
})
export class VipMemberModule { }