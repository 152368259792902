
import { Injectable, Component } from "@angular/core";
import { Router } from '../../../node_modules/@angular/router';
declare let popup: any;
declare let loading: any;
@Injectable()
export class NativeService {
  public showLoad: boolean;
  constructor(public router: Router) {
  }

  //开始加载数据提示
  showLoading() {
    this.showLoad = true;
    loading.showLoading({
      type: 1,
      tip: "数据加载中..."
    });
  }
  showLoadingWithText(title) {
    this.showLoad = true;
    loading.showLoading({
      type: 1,
      tip: title
    });
  }
  //结束加载数据提示
  hideLoading() {
    if (this.showLoad) {
      loading.hideLoading();
    }
    this.showLoad = false;
  }
  //错误提示
  showErrorAlert(title) {
    popup({ type: 'error', msg: title, delay: 2000, bg: true, clickDomCancel: true });
  }
  //成功提示
  showSuccessAlert(title) {
    popup({
      type: 'success', msg: title, delay: 2000, callBack: function () {
        console.log('callBack~~~');
      }
    });
  }
  //警告提示
  showWarningAlert(title) {
    popup({ type: 'tip', msg: title, delay: 2000 });
  }
  //后退按钮点击事件
  backToPage() {
    var u = navigator.userAgent;
    if ((u.indexOf('MSIE') >= 0) && (u.indexOf('Opera') < 0)) { // IE
      if (history.length > 0) {
        window.history.go(-1);
      } else {
        this.router.navigate(['index']);
      }
    } else if (!!u.match(/UCBrowser/i) || u.indexOf(' UBrowser') > -1) {
      if (window.history.length > 1) {
        window.history.go(-1);
      } else {
        this.router.navigate(['index']);
      }
    } else { //非IE浏览器
      if (u.indexOf('Firefox') >= 0 ||
        u.indexOf('Opera') >= 0 ||
        u.indexOf('Safari') >= 0 ||
        u.indexOf('Chrome') >= 0 ||
        u.indexOf('WebKit') >= 0) {
        if (window.history.length > 1) {
          window.history.go(-1);
        } else {
          this.router.navigate(['index']);
        }
      } else { //未知的浏览器
        window.history.go(-1);
      }
    }

  }

}