import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-payment-password',
  templateUrl: './payment-password.component.html',
  styleUrls: ['./payment-password.component.css']
})
export class PaymentPasswordComponent implements OnInit {

  public type = 1;
  public code = '';
  public password = '';
  public newPassword = '';
  public userInfo;
  public mobile;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) {
    let _this = this;
    _this.routeInfo.queryParams.subscribe(queryParams => {
      // _this.userInfo = queryParams == undefined ? {} : queryParams;

    });
    let mob = _this.utils.getLocalStorageItem('mobile');
    if (mob != undefined) {
      _this.mobile = _this.replacepos(mob, 3, 7, '****');
    }
  }

  public replacepos(text, start, stop, replacetext) {
    let mystr = text.substring(0, start) + replacetext + text.substring(stop, text.length);
    return mystr;
  }

  ngOnInit() {
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  public isShow = false;
  public getChildData(data) {
    this.isShow = false;
    if (data != 0) {
      this.isSend = true;
      this.requestCode(data);
    };
    $('html, body').animate({         
      scrollTop: 0
    }, 1000);
  }

  public sendCode() {
    if (!this.isSend) {
      this.isShow = true;
    }
  }

  public requestCode(data) {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.balance.sendVerifyCode',
      'plat': 'plat_wap',
      'openno': '1',
      'verifytoken':data
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        // console.log(JSON.stringify(result.data));
        if (result.data.ret) {
          this.nativeService.showSuccessAlert("发送成功");
          this.resetTime();
        } else {
          this.isSend = false;
          if (result.data.msg) {
            this.nativeService.showWarningAlert(result.data.msg);
          } else {
            this.nativeService.showWarningAlert('发送失败');
          }
        }

      } else {
        this.isSend = false;
        this.nativeService.showWarningAlert(result.msg);
      }
    })
  }
  public isSend = false;
  public time = 0;
  public mSendCodeText = '获取验证码';
  public resetTime() {
    let _this = this;
    _this.isSend = true;
    _this.time = 60;

    function countdown() {
      if (_this.time > 0) {
        _this.mSendCodeText = _this.time + 's' + "后重试";
        --_this.time;
        setTimeout(countdown, 1000);
      } else {
        _this.mSendCodeText = '重新获取';
        _this.isSend = false;
      }
    }

    countdown();
  }
  // 下一步
  public token = '';
  public next() {
    if (this.code == null || this.code == undefined || this.code.length < 1) {
      this.nativeService.showWarningAlert('请输入验证码!');
      return;
    } else {
      this.nativeService.showLoading();
      let params = {
        'code': this.code,
        'userid': this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.member.balance.verifyCode',
        'plat': 'plat_wap',
        'openno': '1'
      }
      this.http.request({
        'method': 'POST',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          // console.log(JSON.stringify(result.data));
          // ret:1 成功  0失败
          // token 
          if (result.data.ret == 1) {
            this.type = 2;
            this.token = result.data.token;
          } else {
            this.nativeService.showWarningAlert("效验失败！");
          }
        } else {
          this.nativeService.showWarningAlert(result.msg);
        }
      })
    }
  }
  // 确定
  public confirm() {
    var re = /^[0-9]+.?[0-9]*$/;
    if (this.password.length < 6 || this.newPassword.length < 6) {
      this.nativeService.showWarningAlert('密码长度为6位数字!');
    } else if (this.password != this.newPassword) {
      this.nativeService.showWarningAlert('密码不一致!');
    } else {
      if (!re.test(this.password)) {
        this.nativeService.showWarningAlert('请输入6位纯数字!');
        return;
      }
      this.nativeService.showLoading();
      let params = {
        'token': this.token,
        'passwd': this.password,
        'repasswd': this.newPassword,
        'userid': this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.member.balance.editPasswd',
        'plat': 'plat_wap',
        'openno': '1'
      }
      this.http.request({
        'method': 'POST',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          // console.log(JSON.stringify(result.data));
          // ret:1 成功  0失败
          // token 
          this.nativeService.showSuccessAlert('保存密码成功');
          this.backToPage();
        } else {
          this.nativeService.showWarningAlert(result.msg);
        }
      })
    }
  }
}
