import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { Utils } from '../providers/Utils';

@Component({
  selector: 'app-wechat-login-getkey',
  templateUrl: './wechat-login-getkey.component.html',
  styleUrls: ['./wechat-login-getkey.component.css']
})
export class WechatLoginGetkeyComponent implements OnInit {
  public key;
  constructor(public router: Router, public routeInfo: ActivatedRoute, public nativeService: NativeService, public utils: Utils) {

  }

  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.key = queryParams.key;
    })
    this.utils.setLocalStorageItem('WeChAtKeY-P', this.key);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', true);
    console.log('成功拿到key:' + this.key);
    this.nativeService.backToPage();
  }
  
  public backToPage(){
    this.nativeService.showSuccessAlert(this.key);
    this.nativeService.backToPage();
  }
}
