//门店确认订单
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ServicePackageOrderConfirmComponent } from "../service-package-order-confirm/service-package-order-confirm.component"
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'ServicePackageOrderConfirm', component: ServicePackageOrderConfirmComponent }]),
        LazyLoadImageModule,
        FormsModule,
        DataShowModule
    ],
    exports: [],
    declarations: [
        ServicePackageOrderConfirmComponent
    ],
    providers: [],
})
export class ServicePackageOrderConfirmModule { }