import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';//不加上这个module可以ng的一些指令不能识别
import { RouterModule } from '@angular/router';
import { FixedNavModule } from '../../component/fixed-nav/fixed-nav.module';
import { DataShowModule } from "../../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { LoginPasswordComponent } from './login-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GraphicCheckModule } from '../../component/graphic-check/graphic-check.module';
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'login-password', component: LoginPasswordComponent}]),
        FixedNavModule,
        DataShowModule, 
        LazyLoadImageModule,
        FormsModule,
        ReactiveFormsModule,
        GraphicCheckModule
    ],
    exports: [],
    declarations: [
        LoginPasswordComponent
    ],
    providers: [],
})
export class LoginPasswordModule {}