import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RegisterComponent } from '../register/register.component'
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GraphicCheckModule } from '../component/graphic-check/graphic-check.module';
@NgModule({
    imports: [
        RouterModule.forRoot([{ path: "register", component: RegisterComponent }]),
        CommonModule,
        FormsModule,
        GraphicCheckModule
    ],
    declarations: [RegisterComponent],
    providers: [],
    exports: []
})
export class RegisterModule{ }