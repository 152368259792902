import {  NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InvoiceInfoComponent } from "../invoice-info/invoice-info.component";//loading
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([{ path: 'invoiceInfo', component: InvoiceInfoComponent }]),
    FormsModule,//input [(ngModel)]需要导入这个module
  ],
  exports: [],
  declarations: [
    InvoiceInfoComponent
  ],
  providers: [],
})
export class InvoiceInfoModule{}