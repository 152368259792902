import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SinopecRefuelingComponent } from '../sinopecRefueling/sinopecRefueling.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'SinopecRefueling', component: SinopecRefuelingComponent }]),
        InfiniteScrollModule,
        FooterRefreshModule,
        LazyLoadImageModule,
    ],
    exports: [],
    declarations: [
        SinopecRefuelingComponent
    ],
    providers: [],
})
export class SinopecRefuelingModule { }