import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { HttpService } from '../providers/HttpService';
import { Utils } from '../providers/Utils';
declare let previewImage: any;
@Component({
  selector: 'app-shop-photos',
  templateUrl: './shop-photos.component.html',
  styleUrls: ['./shop-photos.component.css', '../../assets/css/swiper.min.css']
})
export class ShopPhotosComponent implements OnInit {
  public shopid: 0;
  public shopInfo: any = {};
  public myStyle2;
  public photoNum: 0;
  constructor(
    public nativeService: NativeService, 
    public utils: Utils, 
    public http: HttpService,
    public routeInfo: ActivatedRoute, 
    public router: Router
    ) {
    var clientW = (document.documentElement.clientWidth - 3 * 20) / 3 + "px";
    this.myStyle2 = {
      "height": clientW,
      "width": clientW
    }
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid;
    });
  }
  public backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    this.getShopInfo();
  }
  public imgClick(idnex){
    var obj = {
      urls : this.shopInfo.shoppics,
      current : this.shopInfo.shoppics[idnex]
  };
   previewImage.start(obj);
  }

  //获取店铺信息
  getShopInfo() {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.shop.shop.shopInfo',
      'shopid': this.shopid
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.shopInfo = result.data;
        if (this.shopInfo.shoppics != "" && this.shopInfo.shoppics != undefined) {
          this.photoNum = this.shopInfo.shoppics.length;
        } else {
          this.photoNum = 0;
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
        this.nativeService.hideLoading();
      }
    });
  }
}


