import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AddAddressComponent } from '../add-address/add-address.component';
import { DataShowModule } from "../component/data-show/data-show.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DiscountCouponComponent } from './discount-coupon.component';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'discountCoupon', component: DiscountCouponComponent }]),
        FormsModule,
        ReactiveFormsModule,
        FooterRefreshModule,
        InfiniteScrollModule,
        DataShowModule,
        ClipboardModule
    ],
    exports: [],
    declarations: [
        DiscountCouponComponent
    ],
    providers: [],
})
export class DiscountCouponModule{

}