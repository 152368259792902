import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from '../providers/NativeService';
import { HttpService } from '../providers/HttpService';
import { Utils } from '../providers/Utils';

@Component({
  selector: 'app-mall-order-details',
  templateUrl: './mall-order-details.component.html',
  styleUrls: ['./mall-order-details.component.css']
})
export class MallOrderDetailsComponent implements OnInit {

  public orderid;
  public isShowPage = false;
  public status = '';
  public status_ = 100;
  public dispatching = '';
  public isDialog = false;
  public dialogMessage = '';
  public confirmType = -1;
  constructor(public nativeService: NativeService, public routerInfo: ActivatedRoute, public http: HttpService, public utils: Utils, public router: Router) { }

  ngOnInit() {
    this.routerInfo.queryParams.subscribe(queryParams => {
      this.orderid = queryParams.orderid;
    })
    console.log(this.orderid);

    this.getDetailsMessage();
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  public mDetails;
  public goodsPrice;
  public getDetailsMessage() {
    let params = {
      'orderid': this.orderid,
      'openalias': 'wap.order.ordermall.orderSendDetail',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.isShowPage = true;
        console.log(JSON.stringify(result.data));
        this.mDetails = result.data;
        this.detectionStatus(result.data.status, result.data.express.sendtype);
        this.goodsPrice = (result.data.amounttotal - result.data.amountexpress).toFixed(2);
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  public detectionStatus(status, sendtype) {
    this.status_ = status;
    switch (status) {
      case 0:
        this.status = "待付款"
        break;
      case 1:
        this.status = "已完成"
        break;
      case 2:
        this.status = "已取消订单"
        break;
      case 3:
        this.status = "锁单中"
        break;
      case 4:
        this.status = "申请退款中"
        break;
      case 5:
        this.status = "用户已退款"
        break;
      case 6:
        this.status = "系统已退款"
        break;
      case 7:
        this.status = "已付款"
        break;
      case 8:
        this.status = "待发货"
        break;
      case 9:
        this.status = "待收货"
        break;
      default:
        break;
    }
    switch (sendtype) {
      case 1:
        this.dispatching = "只工作日送货";
        break;
      case 2:
        this.dispatching = "工作日、双休日与假日均可送货";
        break;
      case 3:
        this.dispatching = "只双休日、假日送货";
        break;
      default:
        break;
    }
  }

  // 取消订单
  public cancelOrder() {
    // console.log("取消订单");
    this.dialogMessage = "点击确定，订单被取消！";
    this.isDialog = true;
    this.confirmType = 1;
  }
  // 立即付款
  public payment() {
    console.log("立即付款");
    this.router.navigate(['orderPay'], {
      queryParams: {
        'orderid': this.orderid,
        'orderType': 0,
        'page': -1
      }
    });
  }
  // 查看物流
  public checkLogistics() {
    // console.log("查看物流");
    this.router.navigate(['logistics-details'], {
      queryParams: {
        'orderid': this.orderid
      }
    })
  }
  // 确认收货
  public confirmReceipt() {
    // console.log("确认收货");
    this.dialogMessage = "点击确认收货？";
    this.isDialog = true;
    this.confirmType = 2;
  }

  public cancel() {
    this.isDialog = false;
  }
  public confirm() {
    this.isDialog = false;
    if (this.confirmType == 1) {
      this.cancelOrder_();
    } else if (this.confirmType == 2) {
      this.confirmReceipt_();
    }

  }

  public confirmReceipt_() {
    this.nativeService.showLoading();
    let params = {
      'orderid': this.orderid,
      'openalias': 'wap.order.ordermall.confirm',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(JSON.stringify(result.data));
        this.nativeService.showSuccessAlert("确认收货成功");
        this.nativeService.backToPage();
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  public cancelOrder_() {
    this.nativeService.showLoading();
    let params = {
      'orderid': this.orderid,
      'openalias': 'wap.order.ordermall.cancelOrder',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(JSON.stringify(result.data));
        this.nativeService.showSuccessAlert("取消订单成功");
        this.nativeService.backToPage();
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
}
