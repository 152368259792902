import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { _require } from '../providers/require_lib';
import { TabsModule } from '../component/tabs/tabs.modules';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { ShopDetailComponent } from "../shop-detail/shop-detail.component"
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'shopDetail', component: ShopDetailComponent }]),
        TabsModule,
        InfiniteScrollModule,
        FooterRefreshModule,
        DataShowModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        ShopDetailComponent
    ],
    providers: [],
})
export class ShopDetailModule { }