import { Component, OnInit } from '@angular/core';
import { Utils } from '../providers/Utils';
import { NativeService } from '../providers/NativeService';
import { HttpService } from '../providers/HttpService';
import { ActivatedRoute } from '@angular/router'
@Component({
  selector: 'app-logistics-details',
  templateUrl: './logistics-details.component.html',
  styleUrls: ['./logistics-details.component.css']
})
export class LogisticsDetailsComponent implements OnInit {
  public orderid;
  public isShowPage = false;
  constructor(public nativeService: NativeService, public http: HttpService, public utils: Utils, public routerInfo: ActivatedRoute) { }

  ngOnInit() {
    this.routerInfo.queryParams.subscribe(queryParams => {
      this.orderid = queryParams.orderid;
    })
    // console.log("orderid:"+this.orderid);
    this.getLogisticsDetails();
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  public logisticsInfo = {
    'expressinfo': {
      'traces': []
    }
  };
  public getLogisticsDetails() {
    this.nativeService.showLoading();
    let params = {
      'orderid': this.orderid,
      'openalias': 'wap.order.ordermall.orderExpressInfo',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.isShowPage = true;
        // console.log(JSON.stringify(result.data));
        this.logisticsInfo = result.data[0];
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
}
