import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WelfareListComponent } from './welfare-list.component';
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'welfareList', component: WelfareListComponent }]),
        FormsModule,//input [(ngModel)]需要导入这个module
    ],
    exports: [],
    declarations: [
        WelfareListComponent
    ]
})
export class WelfareListModule{}