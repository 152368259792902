//商品订单
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { Utils } from "../providers/Utils"
import { HttpService } from "../providers/HttpService"
@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.css', '../../assets/css/animate.min.css']
})
export class OrderConfirmComponent implements OnInit {
  public pidStr = "";
  public sendtypeList = [];
  public sendtype = {};
  public invoicedetailList = [];
  public selectAddress = "";
  public productlist = [];
  public amountpay = 0;
  public amounttotal = 0;
  public amountexpress = 0;
  public goodsAllPrice: any = 0;
  public check = {};
  public canCommit = true;
  public calculateFreight = false;
  public remark = "";
  public invoiceInfo = '不开具发票';
  public sendtypeDict = {};
  public invoicename = '';
  public invoiceidentify = '';
  public invoicetype = 0;
  public invoicedetail = 0;
  public orderid = 0;
  public showView = false;
  public showDistributionType = false;
  public showDistributionAimation = false;
  public loadingStr = "数据加载中...";
  constructor(public httpService: HttpService, public utils: Utils, public router: Router, public nativeService: NativeService, public routeInfo: ActivatedRoute) { }

  clearStorageData() {
    this.utils.sessionStorageRemoveItem("chooceAddress");
    this.utils.sessionStorageRemoveItem("invoiceInfo");
    this.utils.sessionStorageRemoveItem("remarkInfo");
  }
  backToPage() {
    this.clearStorageData();
    this.nativeService.backToPage();
  }
  ngOnInit() {
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.pidStr = queryParams.pidStr == undefined ? "" : queryParams.pidStr;
    });
    this.loadingStr = "数据加载中...";
    this.nativeService.showLoading();
    var remarkInfo = this.utils.sessionStorageGetItem("remarkInfo");
    if (remarkInfo != undefined && remarkInfo != null) {
      this.remark = remarkInfo;
    } else {
      this.remark = "";
    }
    var invoiceInfo = this.utils.sessionStorageGetItem("invoiceInfo");
    if (invoiceInfo != undefined && invoiceInfo != null) {
      this.invoicename = invoiceInfo.invoicename;
      this.invoiceidentify = invoiceInfo.invoiceidentify;
      this.invoicetype = invoiceInfo.invoicetype;
      this.invoicedetail = invoiceInfo.invoicedetail;
      this.invoiceInfo = invoiceInfo.invoiceInfo;
    } else {
      this.invoicename = "";
      this.invoiceidentify = "";
      this.invoicetype = 0;
      this.invoicedetail = 0;
      this.invoiceInfo = '不开具发票';
    }
    var item = this.utils.sessionStorageGetItem("chooceAddress");
    if (item != undefined && item != null) {
      this.selectAddress = item;
      this.calculateFreight = true;
      this.getListData();
    } else {
      this.selectAddress = "";
      this.calculateFreight = false;
      this.requestAddressData();
    }
    this.requestInvoiceData();
  }
  remarkChange(e) {
    this.utils.sessionStorageSetItem("remarkInfo", e);
  }
  requestInvoiceData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.order.ordermall.mallSendInfoConfig",
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        var sendtypeList = [];
        for (var key in success.data.sendtype) {
          var item = {};
          item['name'] = success.data.sendtype[key];
          item['sendtype'] = key;
          sendtypeList.push(item);
        }
        var invoicedetailList = [];
        for (var key in success.data.invoicedetail) {
          var item = {};
          item['name'] = success.data.invoicedetail[key];
          item['sendtype'] = key;
          invoicedetailList.push(item);
        }
        that.sendtypeList = sendtypeList;
        that.sendtype = sendtypeList[0];
        that.invoicedetailList = invoicedetailList;

      } else {
        that.nativeService.showErrorAlert(success.msg);
        that.nativeService.hideLoading();
      }
    });
  }
  requestAddressData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.member.address.getList",
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        if (success.data.list.length > 0) {
          that.selectAddress = success.data.list[0];
        } else {
          that.selectAddress = '';
        }
        that.getListData();
      } else {
        that.nativeService.showErrorAlert(success.msg);
        that.nativeService.hideLoading();
      }
    });
  }
  getListData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.cart.mallcart.getCart",
      'addressid': that.selectAddress != '' ? that.selectAddress['id'] : 0,
      'productids': that.pidStr
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        var goodsAllPrice = (success.data.amounttotal - success.data.amountexpress).toFixed(2);
        that.productlist = success.data.productlist;
        that.amountpay = success.data.amountpay;
        that.amounttotal = success.data.amounttotal;
        that.amountexpress = success.data.amountexpress;
        that.goodsAllPrice = goodsAllPrice;
        if (success.data.check != undefined) {
          that.check = success.data.check;
          that.canCommit = true;
        } else {
          that.nativeService.showWarningAlert("请先去添加收货地址");
          that.canCommit = false;
        }
        if (success.data.check != undefined && success.data.check.ret == false) {
          that.nativeService.showWarningAlert(success.data.check.msg);
          that.canCommit = false;
        }
      } else {
        that.nativeService.showErrorAlert(success.msg);
      }
      that.nativeService.hideLoading();
      that.showView = true;
    });
  }
  //选择发货地址
  chooseAddress() {
    this.router.navigate(['mineAddress'], { queryParams: { selctAddress: true } });
  }
  //配送方式
  chooseSendType() {
    this.showDistributionType = true;
    this.showDistributionAimation = true;
  }
  //选择配送方式
  selctType(item) {
    this.sendtype = item;
  }
  close() {
    const that = this;
    this.showDistributionAimation = false;
    setTimeout(function () {
      that.showDistributionType = false;
    }, 800);
  }
  //确认选择配方式并隐藏选择配送box
  commitType() {
    const that = this;
    this.showDistributionAimation = false;
    setTimeout(function () {
      that.showDistributionType = false;
    }, 800);
  }
  //选择发票方式
  chooseInvoiceType() {
    this.router.navigate(['invoiceInfo']);
  }
  commitClick() {
    if (this.canCommit) {
      var that = this;
      if (that.orderid == 0) {
        if (that.selectAddress == '') {
          that.nativeService.showWarningAlert('请先添加收货地址!');
        } else {
          var productlist = this.productlist;
          var pidList = [];
          for (var i = 0; i < productlist.length; i++) {
            pidList.push(productlist[i].stockid);
          }
          var pidStr = pidList.join(",");
          var that = this;
          var param = {
            'plat': 'plat_wap',
            'openno': '1',
            'userid': this.utils.getLocalStorageItem("user_info").userid,
            'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
            'openalias': "wap.order.ordermall.mallcreate",
            'addressid': that.selectAddress['id'],
            'remark': that.remark,
            'sendtype': that.sendtype['sendtype'],
            'stockids': pidStr,
            'invoicename': that.invoicename,
            'invoiceidentify': that.invoiceidentify,
            'invoicetype': that.invoicetype,
            'invoicedetail': that.invoicedetail,
          }
          console.log('订单信息:' + JSON.stringify(param));
          this.httpService.request({
            method: 'POST',
            url: 'api/index',
            data: param,
          }).then(success => {
            if (success.code == 100 && success.data) {
              var orderid = success.data.orderid;
              this.orderid = orderid;
              this.router.navigate(['orderPay'], {
                queryParams: {
                  'orderid': orderid,
                  'orderType': 0
                }
              });
              this.nativeService.hideLoading();
            } else {

              that.nativeService.showErrorAlert(success.msg);
            }
          });
        }
      } else {
        this.nativeService.hideLoading();
        this.router.navigate(['orderPay'], {
          queryParams: {
            'orderid': this.orderid,
            'orderType': 0
          }
        });
      }
    }
  }
}

