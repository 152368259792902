import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MineAddressComponent } from '../mine-address/mine-address.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'mineAddress', component: MineAddressComponent }]),
    ],
    exports: [],
    declarations: [
        MineAddressComponent
    ],
    providers: [],
})
export class MineAddressModule { }