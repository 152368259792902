import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NativeService } from "../providers/NativeService";
import { SafePipe } from "../providers/SafePipe";
@Component({
  selector: 'app-iframe-show',
  templateUrl: './iframe-show.component.html',
  styleUrls: ['./iframe-show.component.css']
})
export class IframeShowComponent implements OnInit {
  constructor(public safePipe: SafePipe, public sanitizer: DomSanitizer, public routeInfo: ActivatedRoute, public nativeService: NativeService) { }
  iframe: SafeResourceUrl;
  public url = "";
  public innerHeight = 0;
  ngOnInit() {
    this.innerHeight = window.innerHeight - 45;
    console.log(this.innerHeight)
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.url = queryParams.url == undefined ? "" : queryParams.url;
    });
    this.iframe = this.safePipe.transform(this.url);

  }

  backToPage() {
    this.nativeService.backToPage();
  }

}
