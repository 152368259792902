import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';//不加上这个module可以ng的一些指令不能识别
import { RouterModule } from '@angular/router';
import { FixedNavModule } from '../../component/fixed-nav/fixed-nav.module';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DataShowModule } from "../../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { AddCarComponent } from './add-car.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'add-car', component: AddCarComponent}]),
        FixedNavModule,
        InfiniteScrollModule,
        FooterRefreshModule,
        DataShowModule,
        LazyLoadImageModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [],
    declarations: [
        AddCarComponent
    ],
    providers: [],
})
export class AddCarModule {}