import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { MallOrderDetailsComponent } from "../mall-order-details/mall-order-details.component";
@NgModule({
    imports: [
        RouterModule.forRoot([{ path: 'mall-order-details', component: MallOrderDetailsComponent }]),
        CommonModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        MallOrderDetailsComponent
    ],
    providers: []
})
export class MallOrderDetailsModule { }