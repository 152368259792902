import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ShopOrderDetailsComponent } from '../shop-order-details/shop-order-details.component';
import { DataShowModule } from '../component/data-show/data-show.module';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载

@NgModule({
    imports: [
        RouterModule.forRoot([{ path: 'shop-order-details', component: ShopOrderDetailsComponent }]),
        DataShowModule,
        CommonModule,
        LazyLoadImageModule
    ],
    declarations: [ShopOrderDetailsComponent],
    exports: [],
    providers: []
})
export class ShopOrderDetailsModule { }