/**
 * Created by yanxiaojun617@163.com on 12-27.
 */
import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';

declare let BMap: any;
/**
 * Utils类存放和业务无关的公共方法
 * @description
 */
@Injectable()
export class Utils {
  public headers = new Headers({ 'Content-Type': 'application/json' });
  constructor(public http: Http) {
  }

  static isEmpty(value): boolean {
    return value == null || typeof value === 'string' && value.length === 0;
  }

  static isNotEmpty(value): boolean {
    return !Utils.isEmpty(value);
  }

  /**
   * 格式“是”or“否”
   * @param value
   * @returns {string|string}
   */
  static formatYesOrNo(value: number | string): string {
    return value == 1 ? '是' : (value == '0' ? '否' : null);
  }


  /**
   * 日期对象转为日期字符串
   * @param date 需要格式化的日期对象
   * @param sFormat 输出格式,默认为yyyy-MM-dd                        年：y，月：M，日：d，时：h，分：m，秒：s
   * @example  dateFormat(new Date())                               "2017-02-28"
   * @example  dateFormat(new Date(),'yyyy-MM-dd')                  "2017-02-28"
   * @example  dateFormat(new Date(),'yyyy-MM-dd HH:mm:ss')         "2017-02-28 13:24:00"   ps:HH:24小时制
   * @example  dateFormat(new Date(),'yyyy-MM-dd hh:mm:ss')         "2017-02-28 01:24:00"   ps:hh:12小时制
   * @example  dateFormat(new Date(),'hh:mm')                       "09:24"
   * @example  dateFormat(new Date(),'yyyy-MM-ddTHH:mm:ss+08:00')   "2017-02-28T13:24:00+08:00"
   * @example  dateFormat(new Date('2017-02-28 13:24:00'),'yyyy-MM-ddTHH:mm:ss+08:00')   "2017-02-28T13:24:00+08:00"
   * @returns {string}
   */
  static dateFormat(date: Date, sFormat: String = 'yyyy-MM-dd'): string {
    let time = {
      Year: 0,
      TYear: '0',
      Month: 0,
      TMonth: '0',
      Day: 0,
      TDay: '0',
      Hour: 0,
      THour: '0',
      hour: 0,
      Thour: '0',
      Minute: 0,
      TMinute: '0',
      Second: 0,
      TSecond: '0',
      Millisecond: 0
    };
    time.Year = date.getFullYear();
    time.TYear = String(time.Year).substr(2);
    time.Month = date.getMonth() + 1;
    time.TMonth = time.Month < 10 ? "0" + time.Month : String(time.Month);
    time.Day = date.getDate();
    time.TDay = time.Day < 10 ? "0" + time.Day : String(time.Day);
    time.Hour = date.getHours();
    time.THour = time.Hour < 10 ? "0" + time.Hour : String(time.Hour);
    time.hour = time.Hour < 13 ? time.Hour : time.Hour - 12;
    time.Thour = time.hour < 10 ? "0" + time.hour : String(time.hour);
    time.Minute = date.getMinutes();
    time.TMinute = time.Minute < 10 ? "0" + time.Minute : String(time.Minute);
    time.Second = date.getSeconds();
    time.TSecond = time.Second < 10 ? "0" + time.Second : String(time.Second);
    time.Millisecond = date.getMilliseconds();

    return sFormat.replace(/yyyy/ig, String(time.Year))
      .replace(/yyy/ig, String(time.Year))
      .replace(/yy/ig, time.TYear)
      .replace(/y/ig, time.TYear)
      .replace(/MM/g, time.TMonth)
      .replace(/M/g, String(time.Month))
      .replace(/dd/ig, time.TDay)
      .replace(/d/ig, String(time.Day))
      .replace(/HH/g, time.THour)
      .replace(/H/g, String(time.Hour))
      .replace(/hh/g, time.Thour)
      .replace(/h/g, String(time.hour))
      .replace(/mm/g, time.TMinute)
      .replace(/m/g, String(time.Minute))
      .replace(/ss/ig, time.TSecond)
      .replace(/s/ig, String(time.Second))
      .replace(/fff/ig, String(time.Millisecond))
  }

  /**
   * 每次调用sequence加1
   * @type {()=>number}
   */
  static getSequence = (function () {
    let sequence = 1;
    return function () {
      return ++sequence;
    };
  })();

  /**
   * 返回字符串长度，汉子计数为2
   * @param str
   * @returns {number}
   */
  static strLength(str: string): number {
    let len = 0;
    for (let i = 0, length = str.length; i < length; i++) {
      str.charCodeAt(i) > 255 ? len += 2 : len++;
    }
    return len;
  }

  /**
   * 把url中的双斜杠替换为单斜杠
   * 如:http://localhost:8080//api//demo.替换后http://localhost:8080/api/demo
   * @param url
   * @returns {string}
   */
  static formatUrl(url: string = ''): string {
    let index = 0;
    if (url.startsWith('http')) {
      index = 7
    }
    return url.substring(0, index) + url.substring(index).replace(/\/\/*/g, '/');
  }


  public sessionStorageGetItem(key: string) {
    try {
      let jsonString = sessionStorage.getItem(key);
      if (jsonString) {
        return JSON.parse(jsonString);
      }
      return null;
    } catch (error) {

    }
  }

  public sessionStorageSetItem(key: string, value: any) {

    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {

    }
  }

  public sessionStorageRemoveItem(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {

    }
  }

  public sessionStorageClear() {
    try {
      sessionStorage.clear();
    } catch (error) {

    }
  }
  // 存
  public setLocalStorageItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  // 取
  public getLocalStorageItem(key) {
    try {
      let storage = JSON.parse(localStorage.getItem(key));
      return (storage == undefined) ? '' : storage;
    } catch (error) {

    }
  }
  // 删除一个item
  public removeLocalStorageItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {

    }
  }
  // 清除
  public clearLocalStorageItem() {

    try {
      localStorage.clear();
    } catch (error) {

    }
  }
  public doPOST(url, params) {

  }
  getCurrentLocation() {
    var that = this;
    return new Promise(function (resolve, reject) {
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() === 0) {
          that.getLocation(r.point.lng, r.point.lat).then(result => {
            resolve(result);
          })
        } else {
          reject(this.getStatus());
        }
      }, { enableHighAccuracy: true })
    });
  }
  getLocation(lng, lat) {
    return new Promise(function (resolve) {
      var pt = new BMap.Point(lng, lat);
      var geoc = new BMap.Geocoder();
      geoc.getLocation(pt, function (rs) {
        resolve(rs);
      });
    });
  }
  //广告活动跳转
  public activityJump(param, router) {
    var config = param.wapurl;
    var result = this.parsingURL(config);
    console.log("解析URL:" + JSON.stringify(result));
    if (result.protocol == "http" || result.protocol == "https") {
      if (config.indexOf("m.btobvip.com") != -1 || config.indexOf("m.jilisi.com") != -1) {
        //自己内部页面路径时
        var path = this.getPath(config);
        var params = this.getUrlParam(config);
        console.log("path:" + path + "----" + "params:" + JSON.stringify(params));
        router.navigate([path], { queryParams: params });
      } else {
        //直接跳转链接暂时不做
        router.navigate(['index']);
      }
    } else if (result.protocol == "jilisi") {
      let shopid = result.params["shopid"];//门店ID
      var u = navigator.userAgent;
      if (u.indexOf('Android') > -1) {
        result.host = result.segments[1];
        result.path = '/' + result.segments[2] + '/' + result.segments[3];
      }
      if (result.path.indexOf("/product/list") != -1) {//跳转门店商品列表
        router.navigate(['product'], { queryParams: { shopid: result.params["shopid"], categoryid: result.params["categoryid"] } });
      } else if (result.path.indexOf("/product/detail") != -1) {//门店跳转到商品详情页
        router.navigate(['goodsDetail'], { queryParams: { shopid: result.params["shopid"], stockid: result.params["stockid"] } });
      } else if (result.path.indexOf("/package/list") != -1) {//门店套餐列表
        router.navigate(['packageList'], { queryParams: { shopid: result.params["shopid"], categoryid: result.params["categoryid"] } });
      } else if (result.path.indexOf("/package/detail") != -1) {//门店跳转套餐详情
        router.navigate(['packageDetail'], { queryParams: { setmealid: result.params["setmealid"], shopid: result.params["shopid"] } });
      } else if (result.path.indexOf("/service/list") != -1) {//门店服务列表
        router.navigate(['serviceList'], { queryParams: { shopid: result.params["shopid"], categoryid: result.params["categoryid"] } });
      } else if (result.path.indexOf("/service/detail") != -1) {//门店服务详情
        router.navigate(['serviceDetail'], { queryParams: { serviceid: result.params["serviceid"], shopid: result.params["shopid"] } });
      } else if (result.path.indexOf("/activity/list") != -1) {//跳转门店促销活动列表
        router.navigate(['shopActivity'], { queryParams: { shopid: result.params["shopid"] } });
      } else if (result.path.indexOf("/ticket/list") != -1) {//通用券列表
        router.navigate(['ticketList']);
      } else if (result.path.indexOf("/ticket/detail") != -1) {//通用券详情
        router.navigate(['ticketDetail'], { queryParams: { id: result.params["id"], shopid: result.params["shopid"] } });
      } else if (result.path.indexOf("/goods") != -1){//平台商品列表
        router.navigate(['goods']);
      }else if (result.path.indexOf("/goodsDetail") != -1){//平台商品详情
        router.navigate(['goodsDetail'], { queryParams: { shopid: result.params["shopid"], stockid: result.params["stockid"] } });
      }else if (result.path.indexOf("/setmealList") != -1){//平台套餐列表
        router.navigate(['setmealList'], { queryParams: { categoryid: result.params["categoryid"] } });
      }else if (result.path.indexOf("/serveList") != -1){//平台服务列表
        router.navigate(['serveList'], { queryParams: { categoryid: result.params["categoryid"] } });
      }else if (result.path.indexOf("/serveSetMealDetail") != -1){//平台套餐服务详情
        router.navigate(['serveSetMealDetail'], { queryParams: { type: result.params["type"], id: result.params["id"] } });
      }else {
        router.navigate(['index']);
      }
    } else {
      router.navigate(['index']);
    }
  }
  parsingURL(url) {
    var a = document.createElement('a');
    a.href = url;
    return {
      source: url,
      protocol: a.protocol.replace(':', ''),
      host: a.hostname,
      port: a.port,
      query: a.search,
      params: (function () {
        var ret = {},
          seg = a.search.replace(/^\?/, '').split('&'),
          len = seg.length,
          i = 0,
          s;
        for (; i < len; i++) {
          if (!seg[i]) { continue; }
          s = seg[i].split('=');
          ret[s[0]] = s[1];
        }
        return ret;
      })(),
      file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
      hash: a.hash.replace('#', ''),
      path: a.pathname.replace(/^([^\/])/, '/$1'),
      relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
      segments: a.pathname.replace(/^\//, '').split('/')
    };
  }
  getUrlParam(url) {
    //获取连接地址
    //分隔开连接,用?把传的值取出来
    var arrObj = url.split("?");
    //创建一个空对象
    var result = {};
    //判断连接里面是不是有值,如果有则执行下面操作
    if (arrObj.length > 1) {
      //取出所有传的值
      var arrPara = arrObj[1].split("&");
      var arr;
      for (var i = 0; i < arrPara.length; i++) {

        arr = arrPara[i].split("=");
        //把第二个值赋值给第一个值
        result[arr[0]] = arr[1];

      }
    }
    return result;
  }
  getPath(url) {
    let routerName = ''
    let urlArr = (url + '').split('#');
    let routerNameArr = [];
    if (urlArr.length >= 1) {
      let routerStr = urlArr[1] + '';
      routerNameArr = routerStr.split('?');
      if (routerNameArr.length >= 1) {
        let routerstring = routerNameArr[0] + '';
        routerName = (routerstring).substring(1, routerstring.length);
      } else {
        routerName = ''
      }
    } else {
      routerName = '';
    }
    return routerName;
  }
  priceLimit(num) {
    if (isNaN(num)) {
      return 0;
    } else {
      var index = (num + "").indexOf('.');
      if (index >= 0) {
        var arr = (num + "").split('.');
        if (arr.length > 1) {
          if (arr[1].length >= 2) {
            if (arr[1].charAt(0) == '0' && arr[1].charAt(1) == '0')
              return arr[0];
            else if (arr[1].charAt(0) == '0' && arr[1].charAt(1) != '0')
              return arr[0] + "." + arr[1].charAt(0) + arr[1].charAt(1);
            else if (arr[1].charAt(0) != '0' && arr[1].charAt(1) == '0')
              return arr[0] + "." + arr[1].charAt(0);
            else
              return arr[0] + "." + arr[1].charAt(0) + arr[1].charAt(1);
          } else {
            if (arr[1].charAt(0) == '0')
              return arr[0];
            else
              return arr[0] + "." + arr[1].charAt(0);
          }
        } else {
          return arr[0];
        }
      } else {
        return num;
      }

    }
  }
  priceLimitToList(price) {
    var list = [];
    console.log("one:" + price);
    if (price.indexOf(".") != -1) {
      list = price.split('.');
      list[1] = "." + list[1];
      console.log("two:" + list);
      return list;
    } else {
      list.push(price);
      console.log("two:" + list);
      return list;
    }
  }
  // 距离
  public distance(distance) {
    if (distance > 0) {
      if (distance >= 1000 && distance <= 10000) {
        return (distance / 1000).toFixed(2) + "km";
      } else if (distance > 10000) {
        return "大于10km";
      } else {
        return distance.toFixed(2) + "m";
      }
    } else {
      return "";
    }
  }
}
// public getRoute(url) {
//   console.log('url:' + url);
//   let routerName = ''
//   let urlArr = (url + '').split('#');
//   let routerNameArr = [];
//   if (urlArr.length >= 1) {
//     let routerStr = urlArr[1] + '';
//     routerNameArr = routerStr.split('?');
//     if (routerNameArr.length >= 1) {
//       let routerstring = routerNameArr[0]+'' ;
//       routerName = (routerstring).substring(1,routerstring.length);
//     } else {
//       routerName = ''
//     }
//   } else {
//     routerName = '';
//   }
//   console.log('routerName:' + routerName);
//   return routerName;
// }

// public getParamsArr(url) {
//   let paramsArr = [] ;
//   let urlArr = (url + '').split('#');
//   let routerNameArr = [];
//   if (urlArr.length >= 1) {
//     let routerStr = urlArr[1] + '';
//     routerNameArr = routerStr.split('?');
//     if (routerNameArr.length > 1) {
//       let paramsstring = routerNameArr[1]+'' ;
//       paramsArr = paramsstring.split('&');
//     } else {
//       paramsArr = [] ;
//     }
//   } else {
//     paramsArr = [];
//   }
//   console.log('paramsArr:' + paramsArr);
//   return paramsArr;
// }

// public getParams(paramsArr){
//   let params = '{'
//   if(paramsArr!=undefined && paramsArr.length>0){
//     for(let i=0;i<paramsArr.length ; i++){
//       let arr = (paramsArr[i]+'').split('=') ;
//       params+= arr[0]+":"+arr[1];
//       if(!(paramsArr.length-1==i)){
//         params+=','
//       }
//     }
//     params+='}' 
//   }else{
//     params = '{}'
//   }
//   console.log(params);
//   // console.log(JSON.parse(params)); 
//   // console.log(JSON.stringify(params)); 
//   return params ;
// }
