import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MineIntegralComponent } from './mine-integral.component';
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
@NgModule({
  imports: [
    RouterModule.forRoot([{ path: 'mineIntegral', component: MineIntegralComponent }]),
    CommonModule,
    DataShowModule,
  ],
  declarations: [MineIntegralComponent]
})
export class MineIntegralModule { }
