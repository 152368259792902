import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {DataShowComponent} from '../data-show/data-show.component';
@NgModule({
    imports: [CommonModule,RouterModule],
    exports: [DataShowComponent],
    declarations: [DataShowComponent],
    providers: [],
})
export class DataShowModule {
}