import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { ServeListComponent } from '../serve-list/serve-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([{ path: 'serveList', component: ServeListComponent }]),
    InfiniteScrollModule,
    FooterRefreshModule,
    LazyLoadImageModule,
    FormsModule, 
    ReactiveFormsModule
  ],
  declarations: [
    ServeListComponent
  ]
})
export class ServeListModule { }
