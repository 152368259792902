import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {
  public sanitizeHtml;
  public alias;
  public id;
  public title = '关于基利斯' ;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router, public sanitizer: DomSanitizer) {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.alias = queryParams.alias;
      this.id = queryParams.id;
      console.log(queryParams);
    });
    console.log(this.alias);
    console.log(this.id);
    // console.log(this.alias);
    this.getData();
  }

  ngOnInit() {
    // this.sanitizeHtml = this.sanitizer.bypassSecurityTrustHtml(success.data.expand.detail); 
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  public getData() {
    this.nativeService.showLoading();
    let params = {
      'openalias': 'wap.news.News.info',
      'plat': 'plat_wap',
      'openno': '1',
      'alias':this.alias,
      'id':this.id
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        // console.log(JSON.stringify(result.data));
        this.sanitizeHtml = this.sanitizer.bypassSecurityTrustHtml(result.data.content); 
        this.title = result.data.title ;
      }
    });
  }
}
