import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { GraphicCheckModule } from '../component/graphic-check/graphic-check.module';
@NgModule({
    imports: [
        RouterModule.forRoot([{ path: 'forget-password', component: ForgetPasswordComponent }]),
        CommonModule,
        FormsModule,
        GraphicCheckModule
    ],
    declarations: [ForgetPasswordComponent],
    providers: [],
    exports: []
})
export class ForgetPasswordModule {
}