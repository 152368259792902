import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-my-team',
  templateUrl: './my-team.component.html',
  styleUrls: ['./my-team.component.css']
})
export class MyTeamComponent implements OnInit {

  public mTab = [
    { 'name': "邀请成功", 'type': 2, 'isSelect': true, 'page': 1, 'isNoData': false, 'showFooter': false, 'isNoMore': false, 'isFirst': false },
    { 'name': "邀请中", 'type': 1, 'isSelect': false, 'page': 1, 'isNoData': false, 'showFooter': false, 'isNoMore': false, 'isFirst': false },
    { 'name': "邀请失败", 'type': 3, 'isSelect': false, 'page': 1, 'isNoData': false, 'showFooter': false, 'isNoMore': false, 'isFirst': false }
  ];
  public type = this.mTab[0].type;
  public isNoData = false;//没有数据
  public isNoMore = false;//没有更多
  public showFooter = false;//加载更多
  public list = [];//邀请中
  public successList = [];//邀请成功
  public failList = [];//邀请失败
  public isAlert = true ; 
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.getTeamList(this.mTab[0].page, this.mTab[0].type);
  }

  public getTeamList(page, type) {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.shareholder.index.team',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.utils.getLocalStorageItem("shopid"),
      'status': type,
      'page': page,
      'limit': 10
    }
    this.http.request({
      'method': 'get',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      this.showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        if (type == 1) {
          this.mTab[1].isFirst = true;
          this.mTab[1].page++;
          if (page == 1) {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoData = true;
              this.mTab[1].isNoData = true;
            } else {
              this.list = result.data.list;
            }
          } else {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoMore = true;
              this.mTab[1].isNoMore = true;
            } else {
              this.list = this.list.concat(result.data.list);
            }
          }
          if (result.data.total != 0 && this.list.length >= result.data.total) {
            this.isNoMore = true;
            this.mTab[1].isNoMore = true;
          }
        }
        if (type == 2) {
          this.mTab[0].isFirst = true;
          this.mTab[0].page++;
          if (page == 1) {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoData = true;
              this.mTab[0].isNoData = true;
            } else {
              this.successList = result.data.list;
            }
          } else {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoMore = true;
              this.mTab[0].isNoMore = true;
            } else {
              this.successList = this.successList.concat(result.data.list);
            }
          }
          if (result.data.total != 0 && this.successList.length >= result.data.total) {
            this.isNoMore = true;
            this.mTab[0].isNoMore = true;
          }
        }
        if (type == 3) {
          this.mTab[2].isFirst = true;
          this.mTab[2].page++;
          if (page == 1) {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoData = true;
              this.mTab[2].isNoData = true;
            } else {
              this.failList = result.data.list;
            }
          } else {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoMore = true;
              this.mTab[2].isNoMore = true;
            } else {
              this.failList = this.failList.concat(result.data.list);
            }
          }
          if (result.data.total != 0 && this.failList.length >= result.data.total) {
            this.isNoMore = true;
            this.mTab[2].isNoMore = true;
          }
        }
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    })
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  // 加载更多。。。
  public onLoadMore() {
    if (this.showFooter || this.isNoData || this.isNoMore) return;
    if (this.type == 1) {
      this.getTeamList(this.mTab[1].page, this.mTab[1].type);
    } else if (this.type == 2) {
      this.getTeamList(this.mTab[0].page, this.mTab[0].type);
    } else if (this.type == 3) {
      this.getTeamList(this.mTab[2].page, this.mTab[2].type);
    }
  }
  // 选中的tab
  public select(i) {
    for (let j = 0; j < this.mTab.length; j++) {
      this.mTab[j].isSelect = false;
    }

    this.mTab[i].isSelect = true;
    this.type = this.mTab[i].type;
    this.type = this.mTab[i].type;
    this.isNoData = this.mTab[i].isNoData;
    this.isNoMore = this.mTab[i].isNoMore;
    if (!this.mTab[i].isFirst) {
      this.getTeamList(this.mTab[i].page, this.type);
    }
  }
  
}
