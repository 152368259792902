import { Component } from '@angular/core';
import { HttpService } from "./providers/HttpService"
import { NativeService } from "./providers/NativeService";
import { Utils } from "./providers/Utils";
import { NavigationEnd, Router} from '@angular/router';
import { WeChatShareService } from "./providers/weChatShareService";
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public getKey: boolean = false;
  public noNetwork: boolean = false;
  constructor(public shareService: WeChatShareService, public router: Router, public httpService: HttpService, public nativeService: NativeService, public utils: Utils,public titleServers:Title) {
    this.nativeService.showLoading();
    this.getAppkey();
  }
  //获取appkey
  getAppkey() {
    var param = {
      'plat': 'plat_wap',
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/getkey',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.utils.setLocalStorageItem('appKey', result.data.key);
        this.getKey = true;
        this.noNetwork = false;
        this.shareConfig();
      } else {
        this.noNetwork = true;
      }
      this.nativeService.hideLoading();
    });
  }
  //重新加载
  request(info: any) {
    this.nativeService.showLoading();
    this.getAppkey();
  }
  public shareConfig() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          // console.log(val);     
           //绑定路由变化事件，每次新转到一个页面即
          if (val.url.indexOf('/goodsDetail') > -1 || val.url.indexOf('/shopDetail') > -1 || val.url.indexOf('/couponDetail') > -1 || val.url.indexOf('/canGiveTicket') > -1 || val.url.indexOf('/serviceDetail') > -1 || val.url.indexOf('/packageDetail') > -1 || val.url.indexOf('/shareholder') > -1 || val.url.indexOf('/generalize') > -1 || val.url.indexOf('/couponShare') > -1) {} else {
            let that = this;
            let params = {
              'openalias': 'wap.share.wxshare.getWxSign',
              'url': window.location.href,
              'plat': 'plat_wap',
              'openno': '1',
            }
            that.httpService.request({
              'method': 'GET',
              'url': 'api/index',
              'data': params
            }).then(result => {
              that.nativeService.hideLoading();
              if (result.code == 100 && result.data) {
                var data = {
                  appId: result.data.appId,
                  timestamp: result.data.timestamp,
                  nonceStr: result.data.nonceStr,
                  signature: result.data.signature,
                };
                that.shareService.configWXJs(data);
                var title =   this.utils.getLocalStorageItem('shopInfo').shopname!=undefined? this.utils.getLocalStorageItem('shopInfo').shopname: "爱车爱生活 爱上基利斯";
                setTimeout(() => {
                  that.shareService.getShareDataAndReadyLink("基利斯",
                    "http://m.jilisi.com/static/images/commen/jlslogo.png",
                    title,"",location.href);
                }, 1000)
              }
            });
          }
        }
      });
    }
  }
}
