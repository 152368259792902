import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import {FixedNavModule} from '../component/fixed-nav/fixed-nav.module';//底部栏
import { indexRoutes } from './tab-index.routes';
import { TabIndexComponent } from '../tab-index/tab-index.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(indexRoutes),
        InfiniteScrollModule,
        FooterRefreshModule,
        LazyLoadImageModule,
        FixedNavModule
    ],
    exports: [],
    declarations: [
        TabIndexComponent
    ],
    providers: [],
})
export class TabIndexModule { }