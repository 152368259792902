import { Component, OnInit, NgZone } from '@angular/core';
import { NativeService } from '../providers/NativeService';
import { HttpService } from '../providers/HttpService';
import { Utils } from '../providers/Utils';
import { Router } from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public account = '';
  public code = '';
  public password = '';
  public isWeixin;
  public agreementStr = "<<基利斯用户协议>>"
  constructor(public zone:NgZone,public router: Router,public nativeService: NativeService, public http: HttpService, public utils: Utils) { }

  ngOnInit() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      this.isWeixin = true;
      return true;
    } else {
      this.isWeixin = false;
      return false;
    }
  }
  public isWechatWeb() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      // console.log('isWechatWeb:true');
      this.isWeixin = true;
      // this.nativeService.showSuccessAlert('isWechatWeb:true');
      return true;
    } else {
      // console.log('isWechatWeb:false');
      this.isWeixin = false;
      return false;
    }
  }
  // 返回
  public backToPage() {
    this.nativeService.backToPage();
  }
  public isShow = false;
  public getChildData(data){
      this.isShow = false;
      if(data!=0)this.sendCode(data);
      $('html, body').animate({         
        scrollTop: 0
      }, 1000);
  }
  public sendCode(data) {
    this.nativeService.showLoading();
    let params = {
      'mobile': this.account,
      'openalias': 'wap.member.register.sendCode',
      'plat': 'plat_wap',
      'openno': '1',
      'verifytoken':data
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        var status = result.data.status;
        if (status == 1) {
          this.nativeService.showSuccessAlert(result.data.msg);
          this.resetTime();
        } else if (status == 2) {
          this.nativeService.showErrorAlert(result.data.msg);
        }
        console.log(result.data);
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
    // this.resetTime();
  }
  public getCode() {
    if (this.isCode) {
      if (this.account.length != 11) {
        this.nativeService.showWarningAlert("手机号码长度不对");
        return;
      }
      this.isShow = true;
    } else {
      console.log(this.time + "s" + "后获取验证码");
    }

  }
  public bodyScroll(event) {
    event.preventDefault();
  }
  public time = 60;
  public msg = "获取验证码"
  public isCode = true;
  public resetTime() {
    this.isCode = false;
    let _this = this;
    _this.time = 60;
    function countdown() {

      if (_this.time > 0) {
        --_this.time;
        _this.msg = _this.time + "s" + "后重试"
        setTimeout(countdown, 1000);
      } else {
        _this.isCode = true;
        _this.msg = "获取验证码"
      }
    }
    countdown();
  }
  // 注册
  public toRegister() {

    // console.log("账号：" + this.account + "-------密码：" + this.password + "-----验证码：" + this.code);
    if (this.account == '') {
      this.nativeService.showWarningAlert("账号不能为空");
      return;
    }
    if (this.code == '') {
      this.nativeService.showWarningAlert("验证码不能为空");
      return;
    }
    if (this.password.length < 6 || this.password.length > 12) {
      this.nativeService.showWarningAlert("密码必须为6-12位数字胡字母");
      return;
    }
    this.nativeService.showLoading();
    let params;
    params = {
      'mobile': this.account,
      'passwd': this.password,
      'apasswd': this.password,
      'verifycode': this.code,
      'openalias': 'wap.member.register.register',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.utils.getLocalStorageItem('shopid')
    }
    let oauthkey = this.utils.getLocalStorageItem('WeChAtKeY-P') ;
    if (oauthkey!=undefined && oauthkey.length>0) {
      params = {
        'mobile': this.account,
        'passwd': this.password,
        'apasswd': this.password,
        'verifycode': this.code,
        'openalias': 'wap.member.register.register',
        'plat': 'plat_wap',
        'openno': '1',
        'oauthkey': this.utils.getLocalStorageItem('WeChAtKeY-P'),
        'shopid': this.utils.getLocalStorageItem('shopid')
      }
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        if (result.data.status == 1) {
          this.utils.setLocalStorageItem("isLogin", true);
          this.utils.setLocalStorageItem("user_token", result.data.usertoken);
          this.utils.setLocalStorageItem("user_id", result.data.userid);
          var user_info = {
            'userid': result.data.userid,
            'usertoken': result.data.usertoken
          }
          this.utils.setLocalStorageItem("user_info", user_info);
          this.utils.setLocalStorageItem('WeChAtKeY-P','') ;
          this.nativeService.showSuccessAlert("注册成功,已为您自动登录。");
          window.history.go(-2);
        } else {
          this.nativeService.showWarningAlert(result.data.msg);
        }
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    })
  }
  // 已有账号登录
  public goToLogin() {
    this.nativeService.backToPage();
  }
  public clickAgreement(){
    this.router.navigate(['news-details'],{queryParams:{'alias':"",'id':1}});
  }

}
