import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { ShopListComponent } from "./shopList.component";
import { shopListRoutes } from './shopList.routes';
import {FixedNavModule} from '../component/fixed-nav/fixed-nav.module';//底部栏
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forRoot(shopListRoutes),
        InfiniteScrollModule,
        FooterRefreshModule,
        DataShowModule,
        LazyLoadImageModule,
        FixedNavModule
    ],
    exports: [],
    declarations: [
        ShopListComponent
    ],
    providers: [],
}) export class ShopListModule { }