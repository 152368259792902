import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { HttpService } from "../providers/HttpService"
import { Utils } from "../providers/Utils";
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  public keyWord = "";
  public showHeader = false;
  public showFooter = false;
  public noMore = false;
  public total: number = 1;
  public page: number = 1;
  public itemList = [];
  public record: any = [];
  constructor(public router: Router, public httpService: HttpService, public nativeService: NativeService, public utils: Utils) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
  }
  searchAction() {
    if (this.keyWord.length <= 0) {
      this.nativeService.showWarningAlert("请输入搜索关键字");
    } else {
      this.itemList = [];
      this.page = 1;
      this.nativeService.showLoading();
      this.getGoodsList(false);
    }
  }
  getGoodsList(state) {
    // wap.product.productmall.getJlsShopStockList
    // wap.product.productmall.getJilisiStockList
    var param = {
      'page': this.page,
      'limit': 20,
      'openalias': "wap.product.productmall.getJlsShopStockList",
      'keyword': this.keyWord,
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.utils.getLocalStorageItem('shopid'),
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.total = result.data.total;
        if (this.total > 0) {
          for (let i = 0; i < result.data.list.length; i++) {
            result.data.list[i].integralpriceLimit = this.utils.priceLimit(result.data.list[i].integralprice);
            result.data.list[i].hPrice = 0;
            result.data.list[i].price = 0;
            if (result.data.list[i].useDisPrice == 1) {
              result.data.list[i].hPrice = result.data.list[i].sellprice;
              result.data.list[i].price = result.data.list[i].dis_price;
            } else {
              result.data.list[i].price = result.data.list[i].sellprice;
            }
            for (let j = 0; j < result.data.list[i].activitylable.length; j++) {
              // activitytype 促销类型 ： 1 订单促销 2 商品促销
              if (result.data.list[i].activitylable[j].activitytype == 1) {
                switch (result.data.list[i].activitylable[j].tagtype) {
                  case 1://满减
                    result.data.list[i].activitylable[j].type = 5;
                    result.data.list[i].activitylable[j].name = '满减';
                    break;
                  case 2://满折
                    result.data.list[i].activitylable[j].type = 6;
                    result.data.list[i].activitylable[j].name = '满折';
                    break;
                  case 3://满赠
                    result.data.list[i].activitylable[j].type = 7;
                    result.data.list[i].activitylable[j].name = '满赠';
                    break;
                  default:
                    break;
                }
              }
              if (result.data.list[i].activitylable[j].activitytype == 2) {
                switch (result.data.list[i].activitylable[j].tagtype) {
                  case 1://立减
                    result.data.list[i].activitylable[j].type = 1;
                    result.data.list[i].activitylable[j].name = '立减';
                    break;
                  case 2://折扣
                    result.data.list[i].activitylable[j].type = 2;
                    result.data.list[i].activitylable[j].name = '折扣';
                    break;
                  case 3://赠送
                    result.data.list[i].activitylable[j].type = 3;
                    result.data.list[i].activitylable[j].name = '赠送';
                    break;
                  case 4://特价
                    result.data.list[i].activitylable[j].type = 4;
                    result.data.list[i].activitylable[j].name = '特价';
                    break;
                  default:
                    break;
                }
              }
            }
          }
          if (state) {
            //加载更多
            this.itemList = this.itemList.concat(result.data.list);
          } else {
            //下拉刷新
            this.itemList = result.data.list;
          }
          console.log(JSON.stringify(this.itemList));
          this.noMore = this.itemList.length >= this.total ? true : false;
        } else {
          //没有数据
          this.total = 0;
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
      this.nativeService.hideLoading();
      this.showFooter = false;
      this.showHeader = false;
    });
  }
  onScrollDown() {
    console.log("onScrollDown");
    if (this.itemList.length < this.total) {
      this.page = 1 + this.page;
      this.showFooter = true;
      this.getGoodsList(true);
    }
  }

  onScrollUp() {

  }
  goodsClick(item) {
    this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.stockid } });
  }

}
