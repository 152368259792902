import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
@Component({
    selector: 'app-sinopecRefueling',
    templateUrl: './sinopecRefueling.component.html',
    styleUrls: ['./sinopecRefueling.component.css']
})
export class SinopecRefuelingComponent implements OnInit {
    public GoodsList;
    public pageNum: number = 1;
    public refuelList = [];
    public refuelOrder = [];

    constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
        public routerInfo: ActivatedRoute, public router: Router) { }

    ngOnInit() {
        this.routerInfo.queryParams.subscribe(queryParams => {
            this.pageNum = queryParams.type
          })
        this.getRefuelList();
        this.getRefuelOrder();
    }

    public backToPage() {
        this.nativeService.backToPage();
    }
    public onScrollDown() { }

    public onScrollUp() { }

    public GoGoodsDetails() { }

    public selectedPage(index) {
        this.pageNum = index;
    }

    public selectList(id) {
        this.router.navigate(['srListDetail'],{queryParams: {'id': id,}})
    }

    public selectOrder(id) {
        this.router.navigate(['srOrderDetail'],{queryParams: {'orderid': id,}})
    }
    // 获取加油列表
    public getRefuelList() {
        let params = {
            'openalias': 'wap.product.Thirds.lists',
            'plat': 'plat_wap',
            'openno': 1,
            'userid': this.utils.getLocalStorageItem('user_id'),
            'user_token': this.utils.getLocalStorageItem('user_token'),
        }
        this.http.request({
            'method': 'POST',
            'url': 'api/index',
            'data': params
        }).then(result => {
            // this.isShowLoading = false;
            if (result.code == 100 && result.data) {
                this.refuelList = result.data.list
            } else {
                this.nativeService.hideLoading();
                this.nativeService.showErrorAlert(result.msg);
            }
        })
    }
    // 获取加油订单
    public getRefuelOrder() {
        let params = {
            'openalias': 'wap.order.ordermalllist.thirdslist',
            'plat': 'plat_wap',
            'openno': 1,
            'userid': this.utils.getLocalStorageItem('user_id'),
            'user_token': this.utils.getLocalStorageItem('user_token'),
        }
        this.http.request({
            'method': 'POST',
            'url': 'api/index',
            'data': params
        }).then(result => {
            // this.isShowLoading = false;
            if (result.code == 100 && result.data) {
                console.log(result);
                this.refuelOrder = result.data.list
            } else {
                this.nativeService.hideLoading();
                this.nativeService.showErrorAlert(result.msg);
            }
        })
    }
    // 删除订单
    public godelOrder(item) {
        let params = {
            'openalias': 'wap.order.Order.delete',
            'plat': 'plat_wap',
            'openno': 1,
            'userid': this.utils.getLocalStorageItem('user_id'),
            'user_token': this.utils.getLocalStorageItem('user_token'),
            'orderid': item
        }
        this.http.request({
            'method': 'POST',
            'url': 'api/index',
            'data': params
        }).then(result => {
            // this.isShowLoading = false;
            if (result.code == 100 && result.data) {
                console.log(result);
                this.getRefuelOrder();
            } else {
                this.nativeService.hideLoading();
                this.nativeService.showErrorAlert(result.msg);
            }
        })
    }
    // 付款
    public goPlayOrder(id) {
        this.router.navigate(['orderPay'],{queryParams: {'orderid': id,}});
    }

}
