import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { Utils } from "../providers/Utils"
import { WeChatShareService } from "../providers/weChatShareService";
import * as $ from 'jquery';
@Component({
  selector: 'app-shop-detail',
  templateUrl: './shop-detail.component.html',
  styleUrls: ['./shop-detail.component.css', '../../assets/css/swiper.min.css']
})
export class ShopDetailComponent implements OnInit {
  public shopid = 0;
  public name = "";
  public swiper: any;
  public titleList = ["服务", "套餐", "商品"];
  public activeId = 0;
  public shopInfo: any = {};

  public pgList = [];
  public pgPage = 1;
  public pgTotal = 1;
  public pgNoMore = false;
  public pgShowFooter = false;
  public pgShowHeader = false;

  public serList = [];
  public serPage = 1;
  public serTotal = 1;
  public serNoMore = false;
  public serShowFooter = false;
  public serShowHeader = false;

  public goodsListData = [];
  public gsPage = 1;
  public gsTotal = 1;
  public gsNoMore = false;
  public gsShowFooter = false;
  public gsShowHeader = false;
  public currentItem = {};//商品。。用于加载更多

  public itemListData = [];
  public sendcondItmeid = 0;
  public selectGettype = 0;
  public selectPName = "";
  public selected = 0;
  public hangingTitle = "";
  public rulesStr = "";
  public aid = 0;
  public aGettype = 0;

  public isLogin = false;
  public productlist = []; //购物车列表
  public amounttotal = 0;
  public amountpay = 0;
  public cartTotalNum = 0;

  public showView = false;
  constructor(public shareService: WeChatShareService, public utils: Utils, public router: Router, public routeInfo: ActivatedRoute, public nativeService: NativeService, public httpService: HttpService) { }

  backToPage() {
    this.activeId = 0;
    this.navigateInitData();
    this.nativeService.backToPage();
  }
  ngOnInit() {
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid == undefined ? 0 : queryParams.shopid;
      this.aid = queryParams.aid == undefined ? 0 : queryParams.aid;
      this.aGettype = queryParams.aGettype == undefined ? 0 : queryParams.aGettype;
      this.utils.setLocalStorageItem('shopid', this.shopid);
    });
    this.isLogin = this.utils.getLocalStorageItem("isLogin");
    this.nativeService.showLoading();
    this.getmenuList();
    this.getShopInfo();
    this.getServiceList(false);
    this.getPackageList(false);
    if (this.isLogin) {
      this.getCarListData();
    }
    // var json = sessionStorage.getItem('enterShopdetailInitData');
    var json = this.utils.sessionStorageGetItem('enterShopdetailInitData');
    if (json != null && json != undefined) {
      this.activeId = JSON.parse(json).activeId;
    }
  }
  public shareConfig() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.httpService.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          var data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.shareService.configWXJs(data);
          var ditail = this.shopInfo.province + this.shopInfo.cityname + this.shopInfo.areaname + this.shopInfo.addressdetail;
          this.shareService.getShareDataAndReadyLink(this.shopInfo.shopname,
            this.shopInfo.pic,
            ditail, "", location.href).subscribe(
              (success) => {
                //分享bug:分享成功和取消都返回1
                console.log(success);
              });
        }
      });
    }
  }
  //获取购物车列表
  getCarListData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': 'wap.cart.shopcart.getCart',
      'productids': 0,
      'shopid': that.shopid,
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        var goodsListData = that.goodsListData;
        var listServerData = that.serList;
        var listPackageData = that.pgList;
        if (success.data.productlist.length > 0) {
          for (var i = 0; i < goodsListData.length; i++) {
            for (var j = 0; j < success.data.productlist.length; j++) {
              var item = success.data.productlist[j];
              if (item.pkey == (goodsListData[i].stockid + "_1")) {
                goodsListData[i].buyNum = item.num;
              }
            }
          }
          for (var i = 0; i < listServerData.length; i++) {
            for (var j = 0; j < success.data.productlist.length; j++) {
              var item = success.data.productlist[j];
              if (item.pkey == (listServerData[i].id + "_2")) {
                listServerData[i].buyNum = item.num;
              }
            }
          }
          for (var i = 0; i < listPackageData.length; i++) {
            for (var j = 0; j < success.data.productlist.length; j++) {
              var item = success.data.productlist[j];
              if (item.pkey == (listPackageData[i].id + "_3")) {
                listPackageData[i].buyNum = item.num;
              }
            }
          }
        } else {
          for (var i = 0; i < goodsListData.length; i++) {
            goodsListData[i].buyNum = 0;
          }
          for (var i = 0; i < listServerData.length; i++) {
            listServerData[i].buyNum = 0;
          }
          for (var i = 0; i < listPackageData.length; i++) {
            listPackageData[i].buyNum = 0;
          }
        }
        this.productlist = success.data.productlist;
        this.amounttotal = success.data.amounttotal;
        this.amountpay = success.data.amountpay;
        this.goodsListData = goodsListData;
        this.serList = listServerData;
        this.pgList = listPackageData;
        this.cartTotalNum = success.data.numtotal;
      } else {
        if (success.msg == "登录已过期,请重新登录" || success.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.cartTotalNum = 0;
        }
      }
    });
  }
  //点击tabs
  tabsClick(selectIndex) {
    this.activeId = selectIndex;
  }
  //获取店铺信息
  getShopInfo() {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.shop.shop.shopInfo',
      'shopid': this.shopid
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.shopInfo = result.data;
        this.shareConfig();
      } else {
        this.nativeService.showErrorAlert(result.msg);
        this.nativeService.hideLoading();
      }
      this.showView = true;
    });
  }
  // 获取服务列表
  getServiceList(state) {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.server.serverprogram.serverList',
      'shopid': this.shopid,
      'page': this.serPage,
      'limit': 20,
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.serTotal = result.data.total;
        if (this.serTotal > 0) {
          for (var i = 0; i < result.data.list.length; i++) {
            for (var j = 0; j < this.productlist.length; j++) {
              var item = this.productlist[j];
              if (item.pkey == (result.data.list[i].id + "_2")) {
                result.data.list[i].buyNum = item.num;
              }
            }
          }
        }
        if (this.serTotal > 0) {
          if (state) {
            //加载更多
            this.serList = this.serList.concat(result.data.list);
          } else {
            //下拉刷新
            this.serList = result.data.list;
          }
          this.serNoMore = this.serList.length >= this.serTotal ? true : false;
        } else {
          //没有数据
          this.serTotal = 0;
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
      // this.showLoading = false;
      this.showView = true;
      this.serShowFooter = false;
      this.serShowHeader = false;
    });
  }
  //服务下拉加载更多
  serOnScrollDown() {
    if (this.serList.length < this.serTotal) {
      this.serPage = 1 + this.serPage;
      this.serShowFooter = true;
      this.getServiceList(true);
    }
  }
  // 获取套餐列表
  getPackageList(state) {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.setmeal.setmealprogram.setmealList',
      'shopid': this.shopid,
      'page': this.pgPage,
      'limit': 20,
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.pgTotal = result.data.total;
        if (this.pgTotal > 0) {
          for (var i = 0; i < result.data.list.length; i++) {
            for (var j = 0; j < this.productlist.length; j++) {
              var item = this.productlist[j];
              if (item.pkey == (result.data.list[i].id + "_3")) {
                result.data.list[i].buyNum = item.num;
              }
            }
          }
        }
        if (this.pgTotal > 0) {
          if (state) {
            //加载更多
            this.pgList = this.pgList.concat(result.data.list);
          } else {
            //下拉刷新
            this.pgList = result.data.list;
          }
          this.pgNoMore = this.pgList.length >= this.pgTotal ? true : false;
        } else {
          //没有数据
          this.pgTotal = 0;
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
      // this.showLoading = false;
      this.pgShowFooter = false;
      this.pgShowHeader = false;
    });
  }
  //套餐下拉加载更多
  pgOnScrollDown() {
    // console.log("onScrollDown");
    if (this.pgList.length < this.pgTotal) {
      this.pgPage = 1 + this.pgPage;
      this.pgShowFooter = true;
      this.getPackageList(true);
    }
  }
  // 下拉刷新有点bug不要
  onScrollUp() {
  }
  //计算优惠规则
  ruleStr(pItem) {
    //rulestype = 1 满10减5、rulestype = 2 满10享3折
    if (pItem.gettype == 1 && pItem.rulesdetail.length > 0) {
      var rulesList = [];
      for (var i = 0; i < pItem.rulesdetail.length; i++) {
        var rulesItem = pItem.rulesdetail[i];
        if (pItem.rulestype == 1) {
          var rules = "满" + rulesItem.price + "减" + rulesItem.val;
          rulesList.push(rules);
        } else if (pItem.rulestype == 2) {
          var rules = "满" + rulesItem.price + "享" + rulesItem.val + "折";
          rulesList.push(rules);
        }
      }
      return rulesList.join(",");
    } else {
      return "";
    }
  }
  //获取商品栏目列表
  getmenuList() {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.product.categorymall.mallShopList',
      'shopid': this.shopid
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        var itemListData = [];
        //满额促销
        if (success.data.order != null && success.data.order != undefined && success.data.order.list != undefined) {
          var item = {};
          var order = success.data.order;
          item['catename'] = order.catename;
          item['gettype'] = order.gettype;
          item['pName'] = order.catename;
          item['pid'] = 0;
          item['rulesStr'] = "";
          itemListData.push(item);
          for (var i = 0; i < order.list.length; i++) {
            var pItem = order.list[i];
            pItem.pid = pItem.id;
            pItem.catename = pItem.name;
            pItem.pName = item['catename'];
            pItem.gettype = order.gettype;
            pItem.rulesStr = this.ruleStr(pItem);
            itemListData.push(pItem);
          }
        }
        //单品优惠
        if (success.data.goods != null && success.data.goods != undefined && success.data.goods.list != undefined) {
          var item = {};
          var goods = success.data.goods;
          item['catename'] = goods.catename;
          item['gettype'] = goods.gettype;
          item['pName'] = goods.catename;
          item['pid'] = 0;
          item['rulesStr'] = "";
          itemListData.push(item);
          for (var i = 0; i < goods.list.length; i++) {
            var pItem = goods.list[i];
            pItem.pid = pItem.id;
            pItem.catename = pItem.name;
            pItem.pName = item['catename'];
            pItem.gettype = goods.gettype;
            pItem.rulesStr = this.ruleStr(pItem);
            itemListData.push(pItem);
          }
        }
        //门店和基利斯类别
        if (success.data.category != null && success.data.category != undefined && success.data.category.length > 0) {
          var category = success.data.category;
          for (var i = 0; i < category.length; i++) {
            var mItem = category[i];
            mItem['pName'] = mItem.catename;
            mItem['rulesStr'] = "";
            itemListData.push(mItem);
            for (var j = 0; j < mItem['subcategory'].length; j++) {
              var pItem = mItem['subcategory'][j];
              pItem.gettype = mItem['gettype'];
              pItem.pName = mItem['catename'];
              pItem.rulesStr = this.ruleStr(pItem);
              itemListData.push(pItem);
            }
          }
        }
        //首次进来默认选择第一个
        var sendcondItmeid = 0;
        var selectGettype = 0;
        var selectPName = "";
        var index = 0;
        //凑单点击过来的时候
        if (this.aid > 0) {
          for (var i = 0; i < itemListData.length; i++) {
            var sItem = itemListData[i];
            if (sItem.pid == 0 && sItem.gettype == this.aGettype) {
              index = i;
            }
            if (sItem.pid == this.aid && sItem.gettype == this.aGettype) {
              selectGettype = sItem.gettype;
              selectPName = sItem.pName;
              sendcondItmeid = itemListData[i].id;
              this.getGoodsListData(itemListData[i], false);
              break;
            }
          }
        } else {
          for (var i = 0; i < itemListData.length; i++) {
            var dItem = itemListData[i];
            if (dItem.pid == 0) {
              selectGettype = dItem.gettype;
              selectPName = dItem.pName;
              if (itemListData[i + 1].id > 0) {
                sendcondItmeid = itemListData[i + 1].id;
                this.getGoodsListData(itemListData[i + 1], false);
              }
              break;
            }
          }
        }
        this.itemListData = itemListData;
        this.sendcondItmeid = sendcondItmeid;
        this.selectGettype = selectGettype;
        this.selectPName = selectPName;
        this.selected = index;

      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
      this.nativeService.hideLoading();
    });
  }
  // 获取商品列表
  getGoodsListData(item, state) {
    if(!state){
      this.nativeService.showLoading();
    }else{
      this.nativeService.hideLoading();
    }
    var param = {};
    var pItem = item;
    var that = this;
    //门店和基利斯类别
    if (item.gettype == 3 || item.gettype == 4) {
      param = {
        'plat': 'plat_wap',
        'openno': '1',
        'openalias': 'wap.product.productmall.getProductStockList',
        'shopcategory1': item.pid > 0 ? item.pid : item.id,
        'shopcategory2': item.pid > 0 ? item.id : 0,
        'shopid': this.shopid,
        'page': this.gsPage,
        'limit': 20,
        'gettype': item.gettype
      }
    } else {
      if (item.id == undefined || item.id == null) {
        return;
      }
      param = {
        'plat': 'plat_wap',
        'openno': '1',
        'openalias': 'wap.product.productmall.getProductStockList',
        'shopid': this.shopid,
        'page': this.gsPage,
        'limit': 20,
        'gettype': item.gettype,
        'activityid': item.id
      }
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.gsTotal = result.data.total;
        if (this.gsTotal > 0) {
          for (var i = 0; i < result.data.list.length; i++) {
            result.data.list[i].gettype = pItem.gettype;
            for (var j = 0; j < that.goodsListData.length; j++) {
              var item = that.goodsListData[j];
              if (item.pkey == (result.data.list[i].stockid + "_1")) {
                result.data.list[i].buyNum = item.num;
              }
            }
          }
          if (state) {
            //加载更多
            this.goodsListData = this.goodsListData.concat(result.data.list);
          } else {
            //下拉刷新
            this.goodsListData = result.data.list;
          }
          this.hangingTitle = pItem.catename;
          this.rulesStr = pItem.rulesStr;
          this.currentItem = pItem;
          this.gsNoMore = this.goodsListData.length >= this.gsTotal ? true : false;
          if (this.isLogin) {
            this.getCarListData();
          }
        } else {
          //没有数据
          this.gsTotal = 0;
          this.goodsListData = [];
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
      this.nativeService.hideLoading();
      this.gsShowFooter = false;
      this.gsShowHeader = false;
    });
  }
  //商品下拉加载更多
  gsOnScrollDown() {
    console.log("onScrollDown");
    if (this.goodsListData.length < this.gsTotal) {
      this.gsPage = 1 + this.gsPage;
      this.gsShowFooter = true;
      this.getGoodsListData(this.currentItem, true);
    }
  }
  // 点击栏目菜单切换
  turnMenu(item, idx) {
    this.hangingTitle = item.catename;
    this.rulesStr = item.rulesStr;
    this.gsPage = 1;
    if (item.pid > 0) {
      //二级分类
      this.getGoodsListData(item, false);
      var itemListData = this.itemListData;
      var index = 0;
      for (var i = 0; i < itemListData.length; i++) {
        var pItem = itemListData[i];
        if (item.gettype == 4 || item.gettype == 3) {
          if (item.gettype == pItem.gettype && pItem.id == item.pid) {
            index = i;
            break;
          }
        } else {
          if (item.gettype == pItem.gettype && pItem.pid == 0) {
            index = i;
            break;
          }
        }
      }
      this.sendcondItmeid = item.id;
      this.selectGettype = item.gettype;
      this.selectPName = item.pName;
      this.selected == index;

    } else {
      //一级分类
      var itemListData = this.itemListData;
      if (item.gettype == 1 || item.gettype == 2 || item.gettype == 4) {
        var pItem = itemListData[idx + 1];
        this.getGoodsListData(pItem, false);
        if (pItem.gettype == item.gettype) {
          this.sendcondItmeid = pItem.id;
          this.selectGettype = pItem.gettype;
          this.selectPName = pItem.pName;
          this.selected = idx;
        }
      } else {
        if (item.subcategory.length > 0) {
          var pItem = itemListData[idx + 1];
          this.getGoodsListData(pItem, false);
          if (pItem.gettype == item.gettype || pItem.pid == item.id) {
            this.sendcondItmeid = pItem.id;
            this.selectGettype = pItem.gettype;
            this.selected = idx;
            this.selectPName = pItem.pName;
          }
        } else {
          this.getGoodsListData(item, false);
          this.selected = idx;
          this.sendcondItmeid = 0;
          this.selectGettype = 3;
          this.selectPName = item.pName;
        }
      }
    }
  }
  // 减少商品
  removeGoodsFromCar(item) {
    var that = this;
    var pid = that.activeId == 2 ? item.stockid + "_" + 1 : (that.activeId == 0 ? item.id + "_" + 2 : item.id + "_" + 3);
    var pKey = that.activeId == 2 ? 1 : (that.activeId == 0 ? 2 : 3);
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': "wap.cart.shopcart.reduce",
      'userid': that.utils.getLocalStorageItem("user_info").userid,
      'user_token': that.utils.getLocalStorageItem("user_info").usertoken,
      'pid': pid,
      'shopid': that.shopid,
      'num': 1,
    }
    // that.showLoading = true;
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        if (pKey == 1) {
          var goodsListData = that.goodsListData;
          for (var i = 0; i < goodsListData.length; i++) {
            if (item.productid == goodsListData[i].productid) {
              goodsListData[i].buyNum = goodsListData[i].buyNum - 1;
            }
          }
          that.goodsListData = goodsListData;
        } else if (pKey == 2) {
          var listServerData = that.serList;
          for (var i = 0; i < listServerData.length; i++) {
            if (item.id == listServerData[i].id) {
              listServerData[i].buyNum = listServerData[i].buyNum - 1;
            }
          }
          that.serList = listServerData;

        } else if (pKey == 3) {
          var listPackageData = that.pgList;
          for (var i = 0; i < listPackageData.length; i++) {
            if (item.id == listPackageData[i].id) {
              listPackageData[i].buyNum = listPackageData[i].buyNum - 1;
            }
          }
          that.pgList = listPackageData;
        }
        that.getCarListData();
      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
      this.nativeService.hideLoading();
    });
  }
  // 加入购物车
  addGoodsToCar(item) {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': "wap.cart.shopcart.add",
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'pid': this.activeId == 2 ? item.stockid : item.id,
      'shopid': this.shopid,
      'num': 1,
      'productType': this.activeId == 2 ? 1 : (this.activeId == 0 ? 2 : 3)
    }
    // this.showLoading = true;
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        that.getCarListData();
      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
      this.nativeService.hideLoading();
    });
  }
  navigateInitData() {
    var json = JSON.stringify({
      "activeId": this.activeId
    });
    this.utils.sessionStorageSetItem("enterShopdetailInitData", json);
  }
  //服务详情
  serviceClick(item) {
    this.navigateInitData();
    this.router.navigate(['serviceDetail'], { queryParams: { serviceid: item.id, shopid: this.shopid } });
  }
  //套餐详情
  packgeClick(item) {
    this.navigateInitData();
    this.router.navigate(['packageDetail'], { queryParams: { setmealid: item.id, shopid: this.shopid } });
  }
  //商品详情
  goodsClick(item) {
    this.navigateInitData();
    this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.stockid, shopid: this.shopid } });
  }
  whetherLogin() {
    if (!this.isLogin) {
      this.utils.setLocalStorageItem('isWechatAuth', false);
      this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
  // 减少商品
  removeToCar(item, index) {
    if (this.whetherLogin()) {
      this.removeGoodsFromCar(item);
    }
  }
  // 加入购物车
  addToCar(item, index) {
    if (this.whetherLogin()) {
      this.addGoodsToCar(item);
    }
  }
  //去购物车 去结算
  gotoShopCart(index) {
    if (this.whetherLogin()) {
      this.navigateInitData();
      this.router.navigate(['shoppingCart'], { queryParams: { shopid: this.shopid } });
    }
  }

}
