import { Component, OnInit, ElementRef, ViewChild, Renderer2, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {
  @Input() titleList = [];
  @Input() activeId = 0;
  @Output() selectIndex = new EventEmitter<any>();  
  constructor() {
  
   }
  ngOnInit() {
  }
  tabsClick(idx){
    this.activeId = idx;
    this.selectIndex.emit(idx); 
  }
}
