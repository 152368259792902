import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { HttpService } from '../providers/HttpService';
import { Utils } from '../providers/Utils';
@Component({
  selector: 'app-shop-orders',
  templateUrl: './shop-orders.component.html',
  styleUrls: ['./shop-orders.component.css']
})
export class ShopOrdersComponent implements OnInit {
  public tabArr = [
    { 'name': "全部", 'tabId': 0, 'isNoMore': false, 'isFooter': false, 'isError': false, 'isNoData': false, 'page': 1, 'total': 0, 'first': true },
    { 'name': "待付款", 'tabId': 1, 'isNoMore': false, 'isFooter': false, 'isError': false, 'isNoData': false, 'page': 1, 'total': 0, 'first': true },
    { 'name': "未使用", 'tabId': 2, 'isNoMore': false, 'isFooter': false, 'isError': false, 'isNoData': false, 'page': 1, 'total': 0, 'first': true },
    { 'name': "已完成", 'tabId': 3, 'isNoMore': false, 'isFooter': false, 'isError': false, 'isNoData': false, 'page': 1, 'total': 0, 'first': true },
    { 'name': "退款/售后", 'tabId': 4, 'isNoMore': false, 'isFooter': false, 'isError': false, 'isNoData': false, 'page': 1, 'total': 0, 'first': true }
  ]
  public isShowLoading = false;
  public selectTab = 0;
  public isDialog = false;
  public dialogMessage = '';
  public orderid;
  public isLogin;
  public iswechat ;
  public logid;
  public shopid ;
  public shopName;
  constructor(public router: Router, public routeInfo: ActivatedRoute, public nativeService: NativeService, public http: HttpService, public utils: Utils) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.iswechat = queryParams.iswechat == undefined ? 0 : parseInt(queryParams.iswechat);
      this.logid = queryParams.logid ;
      var shopid = queryParams.shopid == undefined ? 0 : queryParams.shopid;
      if (shopid != 0)this.utils.setLocalStorageItem('shopid',shopid);
      this.shopid = this.utils.getLocalStorageItem('shopid');
    });
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = isLogin;
    }
    if (!this.isLogin) {
      this.goToLogin();
    } else {
      if (this.iswechat == 1) {
        this.wechatLog();
        this.utils.setLocalStorageItem('shopid',this.shopid)
      } else {
        this.shopid = this.utils.getLocalStorageItem('shopid');
      }
      this.getOrderList(1, this.selectTab);
    }
    this.shopName = this.utils.getLocalStorageItem('shopInfo').shopname
  }
  // 微信消息
  public wechatLog() {
    let params = {
      'openalias': 'wap.ticket.Ticketmessage.upWxMsgStatus',
      'id':this.logid ,
      'plat': 'plat_wap',
      'openno': '1',
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        // this.nativeService.showSuccessAlert("微信消息");
      } else {
        // this.wechatLog()
        // this.nativeService.showSuccessAlert("微信消息err");
      }
    })
  }
  public goToLogin() {
    this.utils.setLocalStorageItem('isWechatAuth', false);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
    this.router.navigate(['login']);
  }
  public potFor(id) {
    if (this.isShowLoading) {
      return;
    }
    this.selectTab = id;
    this.checkedRequest(this.selectTab);
  }
  public checkedRequest(selectTab) {
    this.isNoOrder = false;
    this.isNoMore = false;
    switch (selectTab) {
      case 0:
        if (this.tabArr[0].first) {
          this.getOrderList(this.tabArr[0].page, selectTab);
        } else {
          this.isNoOrder = this.tabArr[0].isNoData;
          this.isNoMore = this.tabArr[0].isNoMore;
          this.showFooter = this.tabArr[0].isFooter;
        }
        break;
      case 1:
        if (this.tabArr[1].first) {
          this.getOrderList(this.tabArr[1].page, selectTab);
        } else {
          this.isNoOrder = this.tabArr[1].isNoData;
          this.isNoMore = this.tabArr[1].isNoMore;
          this.showFooter = this.tabArr[1].isFooter;
        }
        break;
      case 2:
        if (this.tabArr[2].first) {
          this.getOrderList(this.tabArr[2].page, selectTab);
        } else {
          this.isNoOrder = this.tabArr[2].isNoData;
          this.isNoMore = this.tabArr[2].isNoMore;
          this.showFooter = this.tabArr[2].isFooter;
        }
        break;
      case 3:
        if (this.tabArr[3].first) {
          this.getOrderList(this.tabArr[3].page, selectTab);
        } else {
          this.isNoOrder = this.tabArr[3].isNoData;
          this.isNoMore = this.tabArr[3].isNoMore;
          this.showFooter = this.tabArr[3].isFooter;
        }
        break;
      case 4:
        if (this.tabArr[4].first) {
          this.getOrderList(this.tabArr[4].page, selectTab);
        } else {
          this.isNoOrder = this.tabArr[4].isNoData;
          this.isNoMore = this.tabArr[4].isNoMore;
          this.showFooter = this.tabArr[4].isFooter;
        }
        break;
      default:
        break;
    }
  }
  public onRefresh() {
    // console.log("加载更多...");
  }
  public onLoadMore() {
    // console.log("加载更多...");
    if (this.isShowLoading || this.showFooter || this.isNoMore || this.isNoOrder) {
      return;
    }
    var page_;
    switch (this.selectTab) {
      case 0:
        page_ = this.tabArr[0].page;
        break;
      case 1:
        page_ = this.tabArr[1].page;
        break;
      case 2:
        page_ = this.tabArr[2].page;
        break;
      case 3:
        page_ = this.tabArr[3].page;
        break;
      case 4:
        page_ = this.tabArr[4].page;
        break;
      default:
        break;
    }
    this.showFooter = true;
    this.getOrderList(page_, this.selectTab);
    // setTimeout(, 2000);
    // setTimeout(this.getOrderList(1, 1), 1000);
  }
  /**
   * @param page 页码
   * @param type 类型
   */
  public getOrderList(page, type) {
    let this_ = this;
    if (!this.showFooter) {
      this.isShowLoading = true;
      this.nativeService.showLoading();
    }
    let params = {
      'status': type,
      'page': page,
      'limit': 20,
      'openalias': 'wap.order.ordermalllist.shopOrderList',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
      'shopid': this.utils.getLocalStorageItem('shopid'),
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        this_.examineData(page, type, result.data);
      } else {
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.goToLogin();
        }
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }


  public allArr = [];
  public obligationArr = [];
  public unusedArr = [];
  public doneArr = [];
  public afterSaleArr = [];
  public showFooter = false;
  public isNoMore = false;
  public total = 0;
  public isNoOrder = false;
  public examineData(page, type, data) {
    this.isNoMore = false;
    this.showFooter = false;
    switch (type) {
      case 0:
        this.tabArr[0].total = data.total;
        this.total = data.total;
        if (this.tabArr[0].total == 0) {
          this.tabArr[0].isNoData = true;
        }
        this.isNoOrder = this.tabArr[0].isNoData;
        this.tabArr[0].first = false;

        if (page == 1) {
          this.allArr = data.list;
          // console.log("page:" + page);
        } else {
          this.allArr = this.allArr.concat(data.list);
          // console.log("-----page:" + page);
        }
        if (this.tabArr[0].total != 0 && this.allArr.length >= this.tabArr[0].total) {
          this.tabArr[0].isNoMore = true;
          this.isNoMore = true;
        }
        this.tabArr[0].page++;
        // console.log("length:" + this.allArr.length + "-----page:" + this.tabArr[0].page);
        break;
      case 1:
        this.tabArr[1].total = data.total;
        this.total = data.total;
        if (this.tabArr[1].total == 0) {
          this.tabArr[1].isNoData = true;
        }
        this.isNoOrder = this.tabArr[1].isNoData;
        this.tabArr[1].first = false;
        if (page == 1) {
          this.obligationArr = data.list;
        } else {
          this.obligationArr = this.obligationArr.concat(data.list);
        }
        if (this.tabArr[1].total != 0 && this.obligationArr.length >= this.tabArr[1].total) {
          this.tabArr[1].isNoMore = true;
          this.isNoMore = true;
        }
        this.tabArr[1].page++;
        break;
      case 2:
        this.tabArr[2].total = data.total;
        this.total = data.total;
        if (this.tabArr[2].total == 0) {
          this.tabArr[2].isNoData = true;
        }
        this.isNoOrder = this.tabArr[2].isNoData;
        this.tabArr[2].first = false;
        if (page == 1) {
          this.unusedArr = data.list;
        } else {
          this.unusedArr = this.unusedArr.concat(data.list);
        }
        if (this.tabArr[2].total != 0 && this.unusedArr.length >= this.tabArr[2].total) {
          this.tabArr[2].isNoMore = true;
          this.isNoMore = true;
        }
        this.tabArr[2].page++;
        break;
      case 3:
        this.tabArr[3].total = data.total;
        this.total = data.total;
        if (this.tabArr[3].total == 0) {
          this.tabArr[3].isNoData = true;
        }
        this.isNoOrder = this.tabArr[3].isNoData;
        this.tabArr[3].first = false;
        if (page == 1) {
          this.doneArr = data.list;
        } else {
          this.doneArr = this.doneArr.concat(data.list);
        }
        if (this.tabArr[3].total != 0 && this.doneArr.length >= this.tabArr[3].total) {
          this.tabArr[3].isNoMore = true;
          this.isNoMore = true;
        }
        this.tabArr[3].page++;
        break;
      case 4:
        this.tabArr[4].total = data.total;
        this.total = data.total;
        if (this.tabArr[4].total == 0) {
          this.tabArr[4].isNoData = true;
        }
        this.isNoOrder = this.tabArr[4].isNoData;
        this.tabArr[4].first = false;
        if (page == 1) {
          this.afterSaleArr = data.list;
        } else {
          this.afterSaleArr = this.afterSaleArr.concat(data.list);
        }
        if (this.tabArr[4].total != 0 && this.afterSaleArr.length >= this.tabArr[4].total) {
          this.tabArr[4].isNoMore = true;
          this.isNoMore = true;
        }
        this.tabArr[4].page++;
        break;
      default:
        break;
    }
  }
  public cancelOrder(event, orderid) {
    event.stopPropagation();
    // console.log("取消订单");
    this.dialogMessage = "点击确定，订单被取消！";
    this.isDialog = true;
    this.orderid = orderid;
  }
  public payment(event, orderid) {
    event.stopPropagation();
    this.orderid = orderid;
    console.log("立即支付" + orderid);
    this.router.navigate(['orderPay'], {
      queryParams: {
        'orderid': this.orderid,
        'orderType': 1,
        'page': -1
      }
    });

  }
  public goDetailsPage(orderid) {
    // console.log("orderid:" + orderid);
    this.router.navigate(['shop-order-details'], {
      queryParams: {
        'orderid': orderid
      }
    })
  }
  public cancel() {
    this.isDialog = false;
  }
  public confirm() {
    this.isDialog = false;
    this.cancelOrder_();
  }

  public cancelOrder_() {
    this.isShowLoading = true;
    this.nativeService.showLoading();
    let params = {
      'orderid': this.orderid,
      'openalias': 'wap.order.ordermall.cancelOrder',
      'plat': 'plat_wap',
      'openno': 1,
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      // this.isShowLoading = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        this.nativeService.showSuccessAlert("取消订单成功");
        for (var i = 0; i < this.tabArr.length; i++) {
          if (this.tabArr[i].tabId == this.selectTab) {
            this.tabArr[i].page = 1;
          } else {
            this.tabArr[i].page = 1;
            this.tabArr[i].first = true;
          }
        }
        this.getOrderList(1, this.selectTab);
      } else {
        this.isShowLoading = false;
        this.nativeService.hideLoading();
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
}
