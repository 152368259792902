import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { HttpService } from "../providers/HttpService";
import { Utils } from "../providers/Utils";
@Component({
  selector: 'app-shop-area-select',
  templateUrl: './shop-area-select.component.html',
  styleUrls: ['./shop-area-select.component.css']
})
export class ShopAreaSelectComponent implements OnInit {
  public pcode = 0;
  public cityCode = 0;
  public areaList = [];
  constructor(public utils:Utils,public router:Router, public routeInfo: ActivatedRoute, public nativeService: NativeService,public httpService:HttpService) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
         //接收参数
         this.routeInfo.queryParams.subscribe(queryParams => {
          // this.pcode = queryParams.pcode == undefined ? 0 : queryParams.pcode;
          this.nativeService.showLoading();
          this.cityCode = queryParams.cityCode == undefined ? 0 : queryParams.cityCode;
          this.getAreaList(queryParams.pcode);
        });
  }
  getAreaList(pcode){
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.area.area.getList',
      'pcode':pcode
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      this.areaList = [{ "id": 0, "code": 0, "pcode": 0, "name": "全国", "depth": 0 }];
      if (result.code == 100 && result.data) {
        if(result.data.total>0){
          var list = this.areaList.concat(result.data.list);
          if (this.cityCode != 0) {
            for (var i = 0; i < list.length; i++) {
              var item = list[i];
              if (item.code == this.cityCode) {
                this.pcode = item.code;
              }
            }
          }
          this.areaList = list;
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
      this.nativeService.hideLoading();
    });
  }
  selctAction(item){
    this.pcode = item.code;
    var json = JSON.stringify(item);
    this.utils.sessionStorageSetItem('shopAreaSelect',json);
    this.nativeService.backToPage();
  }

}
