//门店购物车
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TicketDetailComponent } from "../ticket-detail/ticket-detail.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'ticketDetail', component: TicketDetailComponent }]),
        FormsModule, 
        ReactiveFormsModule

    ],
    exports: [],
    declarations: [
        TicketDetailComponent
    ],
    providers: [],
})
export class TicketDetailModule { }