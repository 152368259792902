import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'data-refresh',
  templateUrl: './footer-refresh.component.html',
  styleUrls: ['./footer-refresh.component.css']
})
export class FooterRefreshComponent implements OnInit {
  @Input() show:boolean = false;
  @Input() content: string = "数据加载中...";
  constructor() { }

  ngOnInit() {
  }

}
