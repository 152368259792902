import { NativeService } from './../../providers/NativeService';
import { Utils } from './../../providers/Utils';
import { HttpService } from './../../providers/HttpService';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
declare let DialogByZ: any;
@Component({
  selector: 'app-welfare-centre-detial',
  templateUrl: './welfare-centre-detial.component.html',
  styleUrls: ['./welfare-centre-detial.component.css']
})
export class WelfareCentreDetialComponent implements OnInit {
  public myStyle1 = {};
  public myStyle2 = {};
  public showSy1 = true;
  public showSy2 = true;
  public id = 0;
  public dataDict: any = {};
  constructor(public sanitizer: DomSanitizer, public routeInfo: ActivatedRoute, public router: Router, public nativeService: NativeService, private http: HttpService, public utils: Utils) { }
  public backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.id = queryParams.id;
    });
    this.nativeService.showLoading();
    this.getData();

    this.myStyle2 = {
      "width": "50" + "%"
    }
  }
  getData() {
    let param = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.shareholder.Welfare.getInfo',
      'plat': 'plat_wap',
      'openno': '1',
      'id': this.id
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        for (let i = 0; i < result.data.detailList.length; i++) {
          var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data.detailList[i].price));
          result.data.detailList[i].price = priceArr[0];
          result.data.detailList[i].price1 = priceArr[1];
        }
        result.data.detail = this.sanitizer.bypassSecurityTrustHtml(result.data.detail);
        this.dataDict = result.data;
        // this.dataDict.usertask.membernum = 0.9999999;
        // this.dataDict.getnum = 1;
        // this.dataDict.usertask.amountpay = 100.08;
        // this.dataDict.buyamount = 100.90;
        this.dataDict.buyamount =  this.formatNumber(this.dataDict.buyamount);
        var memberPercent = Math.floor((this.dataDict.usertask.membernum / this.dataDict.getnum) * 10000) / 100;
        var payPercent = Math.floor((this.dataDict.usertask.amountpay / this.dataDict.buyamount) * 10000) / 100;
        console.log(memberPercent);
        console.log(payPercent);
        if (memberPercent >= 100) {
          //  已完成
          this.myStyle1 = {
            "width": 100 + "%"
          }
          this.dataDict.memberPercent = 100;
        } else {
          this.dataDict.memberPercent = memberPercent;
          this.myStyle1 = {
            "width": this.dataDict.memberPercent + "%"
          }
        }
        if (payPercent>= 100) {
          //  已完成
          this.myStyle2 = {
            "width": 100 + "%"
          }
          this.dataDict.payPercent = 100;
        } else {
          this.dataDict.payPercent = payPercent;
          this.myStyle2 = {
            "width": this.dataDict.payPercent + "%"
          }
        }
        this.showSy1 = this.dataDict.memberPercent < 30 ? false : true;
        this.showSy2 = this.dataDict.payPercent < 30 ? false : true;
      } else {
        if (result.code == 66 || result.code == 67) {
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.nativeService.showErrorAlert(result.msg);

      }
    })
  };
  /**
* @param price 价格限制处理
*/
  public formatNumber(num) {
    if (isNaN(num)) {
      return 0;
    } else {
      var index = (num + "").indexOf('.');
      if (index >= 0) {
        var arr = (num + "").split('.');
        if (arr.length > 1) {
          if (arr[1].length >= 2) {
            if (arr[1].charAt(0) == '0' && arr[1].charAt(1) == '0')
              return arr[0];
            else if (arr[1].charAt(0) == '0' && arr[1].charAt(1) != '0')
              return arr[0] + "." + arr[1].charAt(0) + arr[1].charAt(1);
            else if (arr[1].charAt(0) != '0' && arr[1].charAt(1) == '0')
              return arr[0] + "." + arr[1].charAt(0);
            else
              return arr[0] + "." + arr[1].charAt(0) + arr[1].charAt(1);
          } else {
            if (arr[1].charAt(0) == '0')
              return arr[0];
            else
              return arr[0] + "." + arr[1].charAt(0);
          }
        } else {
          return arr[0];
        }
      } else {
        return num;
      }

    }
  }
  //  分享积客券
  public gotoShare() {
    this.router.navigate(['generalize'], { queryParams: { 'type': 'generalize' } });
  }
  //领取福利
  public gotoPick() {
    this.nativeService.showLoading();
    let param = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wechat.shareholder.Welfare.getTicket',
      'plat': 'plat_wap',
      'openno': '1',
      'id': this.id
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      this.nativeService.hideLoading();
      var that = this;
      if (result.code == 100 && result.data) {
        DialogByZ.Confirm({
          Title: "", Content: "领取成功，请到个人中心---优惠券---已领取列表查看", BtnL: "确定", BtnR: "查看优惠券", FunL: function () {
            //点击左按钮
            DialogByZ.Close();
            that.getData();
          }, FunR: function () {
            //点击右按钮
            DialogByZ.Close();
            that.router.navigate(['discountCoupon'], {
              queryParams: {
                'shopid': that.utils.getLocalStorageItem("shopid"),
                'isUnUser': true
              }
            });
          }
        })
      } else {
        if (result.code == 66 || result.code == 67) {
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        } else {
          DialogByZ.Alert({
            Title: "提示", Content: result.msg, BtnL: "确定", FunL: function () {
              DialogByZ.Close();
            }
          })
        }
      }
    });
  }
}
