import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { APP_SERVE_URL} from "./Constants";
import { Utils } from "./Utils";
import { ApiParamMD5 } from "../providers/ApiParamMD5"
@Injectable()
export class HttpService {
  //https://www.jb51.net/article/141526.htm
  constructor(public http: Http, public apiParamMD5: ApiParamMD5) {
  }
  /**
     * 统一发送请求
     * @param params
     * @returns {Promise<{success: boolean, msg: string}>|Promise<R>}
     */
  public request(params: any): any {
    // POST请求（参数、返回值类型都是任意类型）
    if (params['method'] == 'post' || params['method'] == 'POST') {
      return this.post(params['url'], params['data']);
    } else { // 其他请求
      return this.get(params['url'], params['data']);
    }
  }

  /**
   * get请求
   * @param url 接口地址
   * @param params 参数
   * @returns {Promise<R>|Promise<U>}
   */
  public get(url: string, params: any): any {
    if (url != "api/getkey") {
      let apitoken = this.apiParamMD5.getApi_tokent(params);
      Object(params).apitoken = apitoken;
    }
    url = Utils.formatUrl(url.startsWith('http') ? url : APP_SERVE_URL + url);
    return this.http.get(url, { search: params })
      .toPromise()
      .then(this.handleSuccess)
      .catch(res => this.handleError(res));
  }

  /**
   * post请求
   * @param url 接口地址
   * @param params 参数
   * @returns {Promise<R>|Promise<U>}
   */
  public post(url: string, params: any) {
    url = Utils.formatUrl(url.startsWith('http') ? url : APP_SERVE_URL + url);
    let apitoken = this.apiParamMD5.getApi_tokent(params);
    Object(params).apitoken = apitoken;
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    myHeaders.append('Accept', 'text/plain');
    myHeaders.append('Accept', ' application/xhtml+xml ');
    let options = new RequestOptions({ headers: myHeaders, params: params });
    return this.http.post(url, null, options)
      .toPromise()
      .then(this.handleSuccess)
      .catch(res => this.handleError(res));
  }
  /**
   * 处理请求成功
   * @param res
 */
  public handleSuccess(res: Response) {
    let body = res["_body"];
    // console.log("接口返回的成功信息：" + body)
    if (body) { // 有数据返回
      return {
        data: res.json().data || {}, // 返回内容
        code: res.json().code || {}, // 返回code
        msg: res.json().msg || {}, // 返回信息
        statusText: res.statusText,
        status: res.status,
        success: true
      }
    } else { // 无数据返回
      return {
        data: res.json().data || {}, // 返回内容
        code: res.json().code || {}, // 返回code
        msg: res.json().msg || {}, // 返回信息
        statusText: res.statusText,
        status: res.status,
        success: true
      }
    }
  }

  /**
   * 处理请求错误
   * @param error
   * @returns {void|Promise<string>|Promise<T>|any}
   */
  public handleError(error) {
    console.log(error);
    let msg = '请求失败,请重试!';
    if (error.status == 400) {
      console.log('请求参数正确');
    }
    if (error.status == 404) {
      console.error('请检查路径是否正确');
    }
    if (error.status == 500) {
      console.error('请求的服务器错误');
    }
    console.log(error);
    return { success: false, msg: msg };
  }
}
