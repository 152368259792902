import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddAddressComponent } from '../add-address/add-address.component';
import { AddressSelectionModule } from '../component/address-selection/address-selection.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'addAddress', component: AddAddressComponent }]),
        AddressSelectionModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [],
    declarations: [
        AddAddressComponent
    ],
    providers: [],
})
export class  AddAddressModule {
}