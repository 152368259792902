import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
import { _require } from '../providers/require_lib';
declare let Swiper: any;
declare let BMap: any;

@Component({
  selector: 'app-tab-index',
  templateUrl: './tab-index.component.html',
  styleUrls: ['./tab-index.component.css','../../assets/css/swiper.min.css']
})
export class TabIndexComponent implements OnInit {
  public jlsBanner: Array<any> = []; //轮播图
  public jlsCenterAd: Array<any> = []; //
  public jlsNewsList: Array<any> = []; //基利斯头条
  public ticketList: Array<any> = []; //通用券
  public setmealList: Array<any> = []; //套餐
  public serverList: Array<any> = []; //服务
  public storeList: Array<any> = []; //商城列表
  public storePage: number = 1;
  public storeStatus: boolean = true;
  // 
  public isLogin;
  // 
  public menuList: Array<any> = [];//自定菜单
  public nearShopList: Array<any> = [];//附近店铺
  public payShopList: Array<any> = [];//消费店铺
  public shopItem: number = 0;
  // 
  public infoStatus = true;
  constructor(
    public nativeService: NativeService,
    public utils: Utils,
    public http: HttpService,
    public routeInfo: ActivatedRoute,
    public router: Router) { }

  ngOnInit() {
    let that = this;
    // var geolocation = new BMap.Geolocation();
    // geolocation.getCurrentPosition(function (r) {
    //   that.utils.setLocalStorageItem('lng', r.point.lng);
    //   that.utils.setLocalStorageItem('lat', r.point.lat);
    // }, { enableHighAccuracy: true })
    var isLogin = that.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      that.isLogin = false;
    } else {
      that.isLogin = true;
      // 获取消费门店列表
      that.getPayShopList();
    }
    // 数据初始化
    that.getInitObj();
    // 商城列表
    that.getStoreList();
    // 获取自定义菜单列表
    that.getMenuList();
    // 获取附近门店列表
    that.getNearShopList();
  }
  // 数据初始化
  public getInitObj() {
    let params = {
      'openalias': 'wap.saas.index.getIndex',
      'plat': 'plat_wap',
      'openno': '1',
      'lat': this.utils.getLocalStorageItem('lat'),
      'lng': this.utils.getLocalStorageItem('lng')
    }
    var that = this;
    that.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        // console.log(result.data);
        // let arr = [];
        // arr.push(result.data.jilisimessages);
        that.jlsBanner = result.data.jilisiAdvert; //轮播图
        that.jlsNewsList = result.data.jilisimessages;//基利斯头条
        that.ticketList = result.data.ticket; //通用券
        that.setmealList = result.data.setmeal; //套餐
        that.serverList = result.data.server; //服务
        that.jlsCenterAd = result.data.centerAdvert;
        // 轮播图
        that.swiperInit1();
      } else {
        that.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 轮播图
  public swiperInit1() {
    var that = this;
    _require('wap/assets/js/swiper.min.js', function () {
      /* swiper初始化 */
      var home_Swiper = new Swiper('#topBanner', {
        autoplay: 4000,
        loop: that.jlsBanner.length > 1 ? true : false,
        pagination: '.swiper-pagination',
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        longSwipesRatio: 0.3,
        touchRatio: 1,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
      });
    });
  }
  // 商城列表
  public getStoreList() {
    let params = {
      'openalias': 'wap.saas.productmall.getJlsShopStockList',
      'plat': 'plat_wap',
      'openno': '1',
      'categoryid': '',
      'page': this.storePage,
      'limit': '10',
      'order': '0',
      'keyword': ''
    }
    var that = this;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        let newArr = result.data.list;
        let oldArr = that.storeList;
        if (newArr && newArr.length > 0) {
          if (that.storePage == 1) {
            that.storeStatus = true;
            that.storeList = newArr;
          } else {
            that.storeStatus = true;
            that.storeList = oldArr.concat(newArr);
          }
        } else {
          that.storeStatus = false;
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 商城列表上拉
  public StoreListMore() {
    if (this.storeStatus) {
      console.log('下拉了')
      this.storePage = this.storePage += 1;
      // 获取商城列表
      this.getStoreList();
    }
  }
  // 商品详情
  public categoryItemClick(sid) {
    this.router.navigate(['goodsDetail'], { queryParams: { stockid: sid } });
  }
  // 点击banner
  public clickBanner(item) {
    this.utils.activityJump({
      'wapurl': item.wapurl
    }, this.router);
  }
  // 通用券详情
  public goTicketDetail(item) {
    this.router.navigate(['ticketDetail'], { queryParams: { id: item.id, shopid: 0 } });
  }
  // 通用券立即购买
  public goToOrder(tid, sid) {
    if (!this.isLogin) {
      this.router.navigate(['login']);
      return;
    }
    this.router.navigate(['ticketOrder'], { queryParams: {id: tid,sid: sid}})
  }
  // 套餐详情
  public goPackageDetail(sid) {
    this.router.navigate(['serveSetMealDetail'], { queryParams: { type: 'package', id: sid } });
  }
  // 服务详情
  public goServerDetail(sid) {
    this.router.navigate(['serveSetMealDetail'], { queryParams: { type: 'server', id: sid } });
  }
  // 商城
  public goGoods() {
    this.router.navigate(['goods']);
  }
  // 通用券
  public goGeneralTicket() {
    this.router.navigate(['ticketList'], { queryParams: { type: 'pt' } });
  }
  // 套餐
  public goPackageList() {
    this.router.navigate(['setmealList']);
  }
  // 服务
  public goServerList() {
    this.router.navigate(['serveList']);
  }
  // 清洁美容
  public goServerOne() {
    this.router.navigate(['serveList'], { queryParams: { categoryid: '1' } });
  }
  // 保养维修
  public goServerTwo() {
    this.router.navigate(['serveList'], { queryParams: { categoryid: '2' } });
  }
  // 爆款活动
  public goPackageOne() {
    this.router.navigate(['setmealList'], { queryParams: { categoryid: '3' } });
  }
  // 优惠洗车
  public goPackageTwo() {
    this.router.navigate(['setmealList'], { queryParams: { categoryid: '4' } });
  }
  // 获取自定义菜单列表
  public getMenuList() {
    let params = {
      'openalias': 'wap.saas.menus.lists',
      'plat': 'plat_wap',
      'openno': '1',
    }
    var that = this;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        this.menuList = result.data.list;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 点击自定义菜单
  public clickMenuItem(items) {
    this.utils.activityJump({
      'wapurl': items.wapurl
    }, this.router);
  }
  // 获取附近门店列表
  public getNearShopList() {
    let params = {
      'openalias': 'wap.shop.Shop.getShopList',
      'plat': 'plat_wap',
      'openno': '2',
      'type': '1',//1 附近门店，2.消费门店
      'lat': this.utils.getLocalStorageItem('lat'),
      'lng': this.utils.getLocalStorageItem('lng')
    }
    var that = this;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        this.nearShopList = result.data;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 获取消费门店列表
  public getPayShopList() {
    let params = {
      'openalias': 'wap.shop.Shop.getShopList',
      'plat': 'plat_wap',
      'openno': '2',
      'type': '2',//1 附近门店，2.消费门店
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
      'lat': this.utils.getLocalStorageItem('lat'),
      'lng': this.utils.getLocalStorageItem('lng')
    }
    var that = this;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        this.payShopList = result.data;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 
  public changeShopItem(index) {
    this.shopItem = index;
  }
  public clickShopItem(ids){
    this.router.navigate(['home'],{queryParams: { shopid: ids } });
  }
  // 
  public moreShop(){
    this.router.navigate(['shopList']);
  }
  // 
  

}
