export const Routes = [
    {
      path: '',
      redirectTo:'index',
      pathMatch:'full'
    },
    {
      path: 'index',
      loadChildren: './tab-index/tab-index.module#TabIndexModule'
    },
    {
        path: 'shopList',
        loadChildren: './shopList/shopList.module#ShopListModule'
    },
    {
      path: 'shopCar',
      loadChildren: './shop-car/shop-car.module#ShopCarModule'
    },
    {
      path: 'mine',
      loadChildren: './mine/mine.module#MineModule'
    },
    {
      path:'**',
      loadChildren:'./tab-index/tab-index.module#TabIndexModule'
    }
  ];