import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-show-details',
  templateUrl: './show-details.component.html',
  styleUrls: ['./show-details.component.css']
})
export class ShowDetailsComponent implements OnInit {
  public details ;
  public cardId: any;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.cardId = queryParams.cardId ;
    });
  }

  ngOnInit() {
    this.getDetails() ;
  }

  getDetails() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.balance.Cash.getInfo',
      'plat': 'plat_wap',
      'openno': '1',
      'id': this.cardId,
      'type':'3'  //3 会员提现 4 会员(股金)提现
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        // console.log(JSON.stringify(result.data));
        this.details = result.data ;
      } else {
        if(result.code == 66 || result.code == 67){
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

}
