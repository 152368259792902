import { Component, OnInit } from '@angular/core';
import { HttpService } from "../providers/HttpService"
import { NativeService } from "../providers/NativeService";
import { Utils } from "../providers/Utils"
import { Router } from '@angular/router';
@Component({
  selector: 'app-invoice-info',
  templateUrl: './invoice-info.component.html',
  styleUrls: ['./invoice-info.component.css']
})
export class InvoiceInfoComponent implements OnInit {

  public typeList = [{ "name": "不开具发票", "type": 0, selected: true }, { "name": "电子发票", "type": 2, selected: false }];
  public ttList = [{ "name": "个人", "type": 0, selected: true }, { "name": "单位", "type": 1, selected: false }];
  public invoicedetailList = [];
  public invoicedetailType = {};
  public showInvoice = false;
  public showInvoiceTT = false;
  public invoicename = '';
  public invoiceidentify = '';
  constructor(public nativeService: NativeService, public utils: Utils, public router: Router, public httpService: HttpService) { }
  ngOnInit() {
    this.requestInvoiceData();
  }
  backToPage() {
    this.nativeService.backToPage();
  }
  requestInvoiceData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.order.ordermall.mallSendInfoConfig",
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        var invoicedetailList = [];
        for (var key in success.data.invoicedetail) {
          var item = {};
          item['name'] = success.data.invoicedetail[key];
          item['sendtype'] = key;
          invoicedetailList.push(item);
        }
        that.invoicedetailList = invoicedetailList;
        that.invoicedetailType = invoicedetailList[0];
      } else {
        if (success.msg == "登录已过期,请重新登录" || success.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    });
  }
  typeSelect(index) {
    var that = this;
    var typeList = this.typeList;
    for (var i = 0; i < typeList.length; i++) {
      typeList[i].selected = false;
    }
    typeList[index].selected = true;
    that.typeList = typeList;
    that.showInvoice = index == 0 ? false : true;

  };
  selectTT(index) {
    var ttList = this.ttList;
    for (var i = 0; i < ttList.length; i++) {
      ttList[i].selected = false;
    }
    ttList[index].selected = true;
    this.ttList = ttList;
    this.showInvoiceTT = index == 0 ? false : true;
  }
  selectType(item) {
    this.invoicedetailType = item;
  }
  commit() {
    var that = this;
    var invoiceInfo = '';
    var canCommit = true;
    var invoicedetail = 0;
    if (!that.showInvoice) {
      //不开具发票
      var invoicename = '';
      var invoiceidentify = '';
      var invoicetype = 0;
      invoicedetail = 0;
      invoiceInfo = '不开具发票';
    } else {
      var invoicetype = 2;
      invoicedetail = that.invoicedetailType['sendtype'];
      if (that.showInvoiceTT) {
        //单位
        var invoicename = that.invoicename;
        var invoiceidentify = that.invoiceidentify;
        invoiceInfo = "单位," + that.invoicedetailType['name'];
      } else {
        //个人
        var invoicename = '个人';
        var invoiceidentify = '';
        invoiceInfo = "个人," + that.invoicedetailType['name'];
      }

      if (that.showInvoiceTT && invoicename == '') {
        that.nativeService.showWarningAlert('请填写单位名称');
        canCommit = false;
      } else if (that.showInvoiceTT && invoiceidentify == '') {
        that.nativeService.showWarningAlert('请填写纳税人识别号');
        canCommit = false;
      }
    }

    if (canCommit) {
      var dic = { invoicename: invoicename, invoiceidentify: invoiceidentify, invoicetype: invoicetype, invoicedetail: invoicedetail, invoiceInfo: invoiceInfo }
      this.utils.sessionStorageSetItem("invoiceInfo", dic);
      this.nativeService.backToPage();
    }
  }

}
