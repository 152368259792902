//支付结果页
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PayResultsComponent } from "../pay-results/pay-results.component"
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'payResults', component: PayResultsComponent }]),
        FormsModule
    ],
    exports: [],
    declarations: [
        PayResultsComponent
    ],
    providers: [],
})
export class PayResultsModule { }