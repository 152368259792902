import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-add-car',
  templateUrl: './add-car.component.html',
  styleUrls: ['./add-car.component.css']
})
export class AddCarComponent implements OnInit {
  public type = 1;//type (1：添加 ，2：编辑) ；
  public show = false;
  public plateNumber = ""; // 键盘操作结果值
  public placeholder = '请输入车牌号';
  public provinceList = {
    line1: ['京', '津', '渝', '沪', '冀', '晋', '辽', '吉', '黑', '苏'],
    line2: ['浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '粤', '琼'],
    line3: ['川', '贵', '云', '陕', '甘', '青', '蒙', '桂', '宁', '新'],
    line4: ['藏', '使', '领', '警', '学', '港', '澳']
  };
  public letterNumberList = {
    line1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
    line2: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    line3: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    line4: ['Z', 'X', 'C', 'V', 'B', 'N', 'M']
  };
  public showProvince = true; // 是否显示省份面板，控制省份面板和字符面板显示
  public initValue = ''; // 初始化的值
  public showPlaceholder = true;
  public brandmodel = '';//品牌型号
  public enginemodel = '';//发动机型号
  public framenum = '';//车架号
  public vid = 0;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.type = queryParams.type == undefined ? 1 : queryParams.type;
      this.vid = queryParams.vid == undefined ? 0 : queryParams.vid;
    });
    if(this.type==2)this.getCarInfo();//请求车辆信息
  }

  ngOnInit() {

  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  public showInput() {
    //只有添加车辆的时候能编辑车牌号
    if (this.type == 1){
      this.show = !this.show;
    }else{
      this.nativeService.showWarningAlert("车牌号不能编辑");
    } 
  }
  // 删除
  public delete(index) {
    switch (index) {
      case 1:
        this.placeholder = '请输入车牌号';
        this.carNumColor();
        break;
      case 2:
        this.brandmodel = '';
        break;
      case 3:
        this.enginemodel = '';
        break;
      case 4:
        this.framenum = '';
        break;

      default:
        break;
    }
  }
  //===========选择车牌键盘start=======
  public replacepos(text, stop, replacetext) {
    var mystr = text.substring(0, stop - 1) + replacetext + text.substring(stop + 1);
    return mystr;
  }
  // 切换省市区 
  public clickChangePlane() {
    this.showProvince = !this.showProvince;
  }
  //省点击
  public provinceClick(text) {
    this.plateNumber = this.replacepos(this.plateNumber, 0, text);
    console.log(this.plateNumber);
    this.showProvince = !this.showProvince;
  }
  // 选择的文字
  public handleClick(text) {
    if (this.plateNumber.length > 0) {
      if (this.showProvince) {
        return;
      }
    } else {
      if (!this.showProvince) {
        return;
      }
    }
    if (this.plateNumber.length == 1) {
      if (this.Number(text)) {
        return;
      }
    }
    this.plateNumber += text;
    console.log(this.plateNumber);
  }
  public Number(val) {
    if (parseInt(val).toString() == "NaN") {
      return false;
    } else {
      return true;
    }
  }
  // 删除已选择号码
  public _handleDelete() {
    this.plateNumber = this.plateNumber.substring(0, this.plateNumber.length - 1);
    if (this.plateNumber.length <= 0) this.showProvince = true;
  }
  // 确定
  public commitKeyboard() {
    this.placeholder = this.plateNumber;
    this.show = false;
    this.carNumColor();
  }
  //取消
  public cancalKeyboard() {
    this.show = false;
  }
  public carNumColor() {
    if (this.placeholder == "请输入车牌号" || this.placeholder.length <= 0) {
      this.showPlaceholder = true;
    } else {
      this.showPlaceholder = false;
    }
  }
  //===========选择车牌键盘end=======
  public checkParam() {
    if (this.placeholder == "请输入车牌号" || this.placeholder.length <= 0) {
      this.nativeService.showWarningAlert("车牌号不能为空!");
      return false;
    } else {
      return true;
    }
  }
  // 保存
  public saveCar() {
    if (this.checkParam()) {
      if (this.type == 1) {
        this.addCar();
      } else {
        this.editCar();
      }
    }
  }
  //请求车辆信息
  public getCarInfo() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.Vehicle.getinfo',
      'plat': 'plat_wap',
      'openno': '1',
      "vid": this.vid
    }
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: params,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
       this.showPlaceholder = false;
       this.vid = result.data.vid;
       this.placeholder = result.data.cartnum;
       this.brandmodel = result.data.brandmodel;
       this.framenum = result.data.framenum;
       this.enginemodel = result.data.enginemodel;
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }
  //删除
  public deleteCar() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.Vehicle.del',
      'plat': 'plat_wap',
      'openno': '1',
      "vid": this.vid
    }
    this.http.request({
      method: 'POST',
      url: 'api/index',
      data: params,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert("删除成功");
        this.backToPage();
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }
  public addCar() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.Vehicle.add',
      'plat': 'plat_wap',
      'openno': '1',
      "cartnum": this.placeholder,//车牌号码
      "brandmodel": this.brandmodel,//品牌型号
      "enginemodel": this.enginemodel,//发动机型号
      "framenum": this.framenum,//车架号
    }
    this.http.request({
      method: 'POST',
      url: 'api/index',
      data: params,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert("添加成功");
        this.backToPage();
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }
  public editCar() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.Vehicle.edit',
      'plat': 'plat_wap',
      'openno': '1',
      "vid": this.vid,
      "brandmodel": this.brandmodel,//品牌型号
      "enginemodel": this.enginemodel,//发动机型号
      "framenum": this.framenum,//车架号
    }
    this.http.request({
      method: 'POST',
      url: 'api/index',
      data: params,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert("修改成功");
        this.backToPage();
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }

}
