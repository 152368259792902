import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jls-vip',
  templateUrl: './jls-vip.component.html',
  styleUrls: ['./jls-vip.component.css']
})
export class JlsVipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
