//支付结果页
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { Utils } from "../providers/Utils"
@Component({
  selector: 'app-pay-results',
  templateUrl: './pay-results.component.html',
  styleUrls: ['./pay-results.component.css']
})
export class PayResultsComponent implements OnInit {
  public orderid = 0;
  public orderType = 0;
  public status = 0;//status : 【1：交易完成】【2：交易中】【3：交易失败】【4：交易异常】
  constructor(public utils: Utils, public router: Router, public nativeService: NativeService, public routeInfo: ActivatedRoute) { }
  backToPage() {
    this.utils.removeLocalStorageItem("checkOrder");
    this.router.navigate(['mine']);
  }
  ngOnInit() {
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.orderid = queryParams.orderid == undefined ? 0 : queryParams.orderid;
      this.orderType = queryParams.orderType == undefined ? 0 : queryParams.orderType;
      this.status = queryParams.status == undefined ? 0 : queryParams.status;
    });
  }
  checkOrder() {
    // orderType： 0商品订单 , 1门店订单 5.合并订单
    this.utils.removeLocalStorageItem("checkOrder");
    if (this.orderType == 0) {
      this.router.navigate(['mall-order-details'], {
        queryParams: {
          'orderid': this.orderid
        }
      })
    } else {
      this.router.navigate(['shop-order-details'], {
        queryParams: {
          'orderid': this.orderid
        }
      })
    }
  }
  gotoHomePage() {
    this.utils.removeLocalStorageItem("checkOrder");
    this.router.navigate(['']);
  }

  goVip(){
    this.router.navigate(['welfareList']);
  }

}
