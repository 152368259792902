import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';//不加上这个module可以ng的一些指令不能识别
import { RouterModule } from '@angular/router';
import { FixedNavModule } from '../../component/fixed-nav/fixed-nav.module';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DataShowModule } from "../../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { ShareholderComponent } from './shareholder.component';
import { FinancialDetailsModule } from './../../shareholder/financial-details/financial-details.module' ;//股金明细
import { MyTeamModule } from './../../shareholder/my-team/my-team.module' ;//股金明细
import { MyBonusModule } from './../../shareholder/my-bonus/my-bonus.module' ;//我的分红
import { RankingsModule } from './../../shareholder/rankings/rankings.module' ;//排行榜
import { IncreaseCapitalModule } from './../../shareholder/increase-capital/increase-capital.module' ;//增持股金
import { WithdrawDepositModule } from './../../shareholder/withdraw-deposit/withdraw-deposit.module' ;//提现
import { WithdrawalRecordModule } from './../../shareholder/withdrawal-record/withdrawal-record.module' ;//提现记录
import { GeneralizeModule } from './../../shareholder/generalize/generalize.module' ;//开始推广

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'shareholder', component: ShareholderComponent}]),
        FixedNavModule,
        InfiniteScrollModule,
        FooterRefreshModule,
        DataShowModule,
        LazyLoadImageModule,
        RankingsModule,
        IncreaseCapitalModule,
        WithdrawDepositModule,
        WithdrawalRecordModule,
        GeneralizeModule,
        FinancialDetailsModule,
        MyTeamModule,
        MyBonusModule,
    ],
    exports: [],
    declarations: [
        ShareholderComponent
    ],
    providers: [],
})
export class ShareholderModule {}