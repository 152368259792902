import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-my-bonus',
  templateUrl: './my-bonus.component.html',
  styleUrls: ['./my-bonus.component.css']
})
export class MyBonusComponent implements OnInit {
  public mTab = [
    { 'name': "已结算分红", 'type': 1, 'isSelect': true, 'page': 1, 'isNoData': false, 'showFooter': false, 'isNoMore': false, 'isFirst': false },
    { 'name': "未结算分红", 'type': 2, 'isSelect': false, 'page': 1, 'isNoData': false, 'showFooter': false, 'isNoMore': false, 'isFirst': false },
  ];
  public type = this.mTab[0].type;
  public isNoData = false;//没有数据
  public isNoMore = false;//没有更多
  public showFooter = false;//加载更多
  public index = 0;
  public list = [];//已结算
  public mList = []; //未结算
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.getMyBonusList(this.mTab[0].page, this.type);
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  public getMyBonusList(page, type) {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wechat.shareholder.index.mydividend',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.utils.getLocalStorageItem("shopid"),
      'status': type,
      'page': page,
      'limit': 10
    }
    this.http.request({
      'method': 'get',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      this.showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        if (type == 1) {
          this.mTab[0].isFirst = true;
          this.mTab[0].page++ ;
          if (page == 1) {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoData = true;
              this.mTab[0].isNoData = true;
            } else {
              this.list = result.data.list;
            }
          } else {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoMore = true;
              this.mTab[0].isNoMore = true;
            } else {
              this.list = this.list.concat(result.data.list);
            }
          }
          if (result.data.total != 0 && this.list.length >= result.data.total) {
            this.isNoMore = true;
            this.mTab[0].isNoMore = true;
          }
        } else {
          this.mTab[1].isFirst = true;
          this.mTab[1].page++ ;
          if (page == 1) {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoData = true;
              this.mTab[1].isNoData = true;
            } else {
              this.mList = result.data.list;
            }
          } else {
            if (result.data.list == undefined || result.data.list.length == 0) {
              this.isNoMore = true;
              this.mTab[1].isNoMore = true;
            } else {
              this.mList = this.mList.concat(result.data.list);
            }
          }
          if (result.data.total != 0 && this.mList.length >= result.data.total) {
            this.isNoMore = true;
            this.mTab[1].isNoMore = true;
          }
        }
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    })
  }
  // 加载更多。。。
  public onLoadMore() {
    if(this.showFooter||this.isNoData||this.isNoMore)return ;
    if(this.type==1){
      this.getMyBonusList(this.mTab[0].page,this.mTab[0].type);
    }else{
      this.getMyBonusList(this.mTab[1].page,this.mTab[1].type);
    }
  }

  // 选中的tab
  public select(i) {
    for (let j = 0; j < this.mTab.length; j++) {
      this.mTab[j].isSelect = false;
    }
    this.index = i;
    this.mTab[i].isSelect = true;
    this.type = this.mTab[i].type;
    this.isNoData = this.mTab[i].isNoData;
    this.isNoMore = this.mTab[i].isNoMore;
    if (!this.mTab[i].isFirst) {
      this.getMyBonusList(this.mTab[i].page, this.type);
    }
  }

}
