import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FooterRefreshComponent} from '../footer-refresh/footer-refresh.component';
@NgModule({
    imports: [CommonModule,RouterModule],
    exports: [FooterRefreshComponent],
    declarations: [FooterRefreshComponent],
    providers: [],
})
export class FooterRefreshModule {
}