import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-change-mobile',
  templateUrl: './change-mobile.component.html',
  styleUrls: ['./change-mobile.component.css']
})
export class ChangeMobileComponent implements OnInit {

  public type = 1;//1旧手机号码验证  2新手机号码验证 。
  public mCode = '';
  public mNewCode = '';
  public mNewMobile = '';
  public mobile = '';
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) {
    let _this = this;
    _this.routeInfo.queryParams.subscribe(queryParams => {
      // _this.mobile = queryParams.mobile;
      let mob = queryParams.mobile;
      if (mob != undefined) {
        _this.mobile = _this.replacepos(mob, 3, 7, '****');
      }
    });
  }

  public replacepos(text, start, stop, replacetext) {
    let mystr = text.substring(0, start) + replacetext + text.substring(stop, text.length);
    return mystr;
  }
  ngOnInit() {
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  public isShow = false;
  public getChildData(data) {
    this.isShow = false;
    $('html, body').animate({         
      scrollTop: 0
    }, 1000);
    if (data != 0) {
      if (!this.isSend) {
        this.isSend = true;
        this.nativeService.showLoading();
        let params = {
          'userid': this.utils.getLocalStorageItem("user_id"),
          'user_token': this.utils.getLocalStorageItem("user_token"),
          'openalias': 'wap.member.UpdateMobile.sendCode',
          'plat': 'plat_wap',
          'openno': '1',
          'verifytoken': data
        }
        this.http.request({
          'method': 'POST',
          'url': 'api/index',
          'data': params
        }).then(result => {
          this.nativeService.hideLoading();
          if (result.code == 100 && result.data) {
            // console.log(result.data);
            // console.log(JSON.stringify(result.data));
            if (result.data.ret) {
              this.nativeService.showSuccessAlert("发送成功");
              this.resetTime();
            } else {
              this.isSend = false;
              if (result.data.msg) {
                this.nativeService.showWarningAlert(result.data.msg);
              } else {
                this.nativeService.showWarningAlert("发送失败");
              }
            }

          } else {
            this.isSend = false;
            this.nativeService.showWarningAlert(result.msg);
          }
        })
      } else if (!this.isSendNew) {
        this.isSendNew = true;
        this.nativeService.showLoading();
        let params = {
          'token': this.token,
          'new_mobile': this.mNewMobile,
          'userid': this.utils.getLocalStorageItem("user_id"),
          'user_token': this.utils.getLocalStorageItem("user_token"),
          'openalias': 'wap.member.UpdateMobile.sendNewCode',
          'plat': 'plat_wap',
          'openno': '1',
          'verifytoken': data
        }
        this.http.request({
          'method': 'POST',
          'url': 'api/index',
          'data': params
        }).then(result => {
          this.nativeService.hideLoading();
          if (result.code == 100 && result.data) {
            if (result.data.ret) {
              this.nativeService.showSuccessAlert("发送成功");
              this.new_mobile = this.mNewMobile;
              this.resetTimeNew();
            } else {
              this.nativeService.showWarningAlert(result.data.msg);
            }

          } else {
            this.nativeService.showWarningAlert(result.msg);
          }
        })
      }
    }
  }
  public sendCode() {
    this.isShow = true;
  }
  public new_mobile = '';
  public sendCodeNew() {
    if (this.mNewMobile == undefined || this.mNewMobile.length < 11) {
      this.nativeService.showWarningAlert("手机号码长度不对！");
      return;
    }
    this.isShow = true;
  }
  public isSendNew = false;
  public mSendCodeTextNew = '获取验证码';
  public timeNew = 0;
  public resetTimeNew() {
    let _this = this;
    _this.isSendNew = true;
    _this.timeNew = 60;

    function countdown() {
      if (_this.timeNew > 0) {
        _this.mSendCodeTextNew = _this.timeNew + 's' + "后重试";
        --_this.timeNew;
        setTimeout(countdown, 1000);
      } else {
        _this.mSendCodeTextNew = '重新获取';
        _this.isSendNew = false;
      }
    }

    countdown();
  }
  public isSend = false;
  public time = 0;
  public mSendCodeText = '获取验证码';
  public resetTime() {
    let _this = this;
    _this.isSend = true;
    _this.time = 60;

    function countdown() {
      if (_this.time > 0) {
        _this.mSendCodeText = _this.time + 's' + "后重试";
        --_this.time;
        setTimeout(countdown, 1000);
      } else {
        _this.mSendCodeText = '重新获取';
        _this.isSend = false;
      }
    }

    countdown();
  }

  public token = "";//保存手机号需要
  public next() {
    if (this.mCode != undefined && this.mCode.length > 0) {
      this.nativeService.showLoading();
      let params = {
        'code': this.mCode,
        'userid': this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.member.UpdateMobile.verifyCode',
        'plat': 'plat_wap',
        'openno': '1'
      }
      this.http.request({
        'method': 'POST',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          // console.log(result.data);
          // console.log(JSON.stringify(result.data));
          if (result.data.ret) {
            this.token = result.data.token;
            this.type = 2;
          } else {
            this.nativeService.showWarningAlert("验证码效验失败！");
          }

        } else {
          this.nativeService.showWarningAlert(result.msg);
        }
      })
    } else {
      this.nativeService.showWarningAlert('请填写验证码！');
    }
  }

  public confirm() {
    // console.log('手机号码：' + this.mNewMobile + '---验证码：' + this.mNewCode);
    if (this.mNewCode != undefined && this.mNewCode.length > 0) {
      this.nativeService.showLoading();
      let params = {
        'code': this.mNewCode,
        'new_mobile': this.new_mobile,
        'userid': this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.member.UpdateMobile.saveMobile',
        'plat': 'plat_wap',
        'openno': '1'
      }
      this.http.request({
        'method': 'POST',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          // console.log(result.data);
          // console.log(JSON.stringify(result.data));
          if (result.data.ret) {
            this.nativeService.showSuccessAlert('修改成功');
            this.utils.setLocalStorageItem("mobile", this.new_mobile);
            this.backToPage();
          } else {
            this.nativeService.showWarningAlert(result.data.msg);
          }

        } else {
          this.nativeService.showWarningAlert(result.msg);
        }
      })
    } else {
      this.nativeService.showWarningAlert('请填写验证码！');
    }
  }
}
