import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NativeService } from '../providers/NativeService';
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { Router, ActivatedRoute } from "@angular/router";
import { Utils } from '../providers/Utils';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载]
import { APP_SERVE_URL } from "../providers/Constants";
import { SHARE_URL } from "../providers/Constants";
import { DomSanitizer } from '@angular/platform-browser';
import { WeChatShareService } from "../providers/weChatShareService";
declare var wx: any;
@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.css', '../../assets/css/animate.min.css']
})
export class CouponDetailComponent implements OnInit {
  public isShowPage = false;
  public ticketid;
  public shareid;
  public shopid;
  public type;
  public addtype = 1;
  public mDetails: any = { "tickettype": 0, "id": 0, "shopid": 0 };
  public isLogin;
  public showWXShareTip = false;
  public showAnimation = false;
  constructor(public cdr: ChangeDetectorRef, public shareService: WeChatShareService, public nativeService: NativeService, public routeInfo: ActivatedRoute, public http: HttpService, public utils: Utils, public router: Router, public sanitize: DomSanitizer) { }

  ngOnInit() {
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = isLogin;
    }
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.ticketid = queryParams.id;
      this.shareid = queryParams.shareid == undefined ? '' : queryParams.shareid;
      this.shopid = queryParams.shopid;
      this.type = queryParams.type;
    })
    this.utils.setLocalStorageItem('shopid', this.shopid);
    // console.log("ticketid:" + this.ticketid + "--shopid:" + this.shopid + "--shareid:" + this.shareid + "--type:" + this.type);
    this.nativeService.showLoading();
    this.getCouponDetail();
  }
  // fanhui
  public backToPage() {
    this.nativeService.backToPage();
  }
  // 进店看看
  public goToShop() {
    var shopid = this.shopid;
    // console.log("进店看看...");
    this.router.navigate(['home'], { queryParams: {shopid:shopid} })
  }
  // 免费领取
  public freeToReceive() {
    // console.log("免费领取...");

    var params;
    // if (this.type == 'wap') {
    // params = {
    //   'userid': this.utils.getLocalStorageItem("user_id"),
    //   'user_token': this.utils.getLocalStorageItem("user_token"),
    //   'openalias': 'wap.ticket.ticketusergive.addGiveTicket',
    //   'shareuserid': this.shareid,
    //   'ticketid': this.ticketid,
    //   'plat': 'plat_wap',
    //   'openno': '1'
    // };
    // } else {//扫码分享
    params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.ticket.Ticketuser.addTicket',
      'shareId': this.ticketid,
      'shareuserid': this.shareid,
      'plat': 'plat_wap',
      'openno': '1'
    };
    // }

    if (this.isLogin) {
      this.nativeService.showLoading();
      this.canGiveTicket(params);
    } else {
      this.goToLogin();
    }

  }

  public goToLogin() {
    this.utils.setLocalStorageItem('isWechatAuth', false);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
    this.router.navigate(['login']);
  }
  // 领取优惠券...
  public canGiveTicket(params) {
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(JSON.stringify(result));
        this.nativeService.showSuccessAlert("领取成功！");
      } else {
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.goToLogin();
        }
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 获取详情数据...
  public shareUserId ='';
  public getCouponDetail() {
    var openalias = '';
    // if (this.type == 'wap') {
    //   openalias = 'wap.product.Ticket.getGiveInfo';
    // } else {
    openalias = 'wechat.product.Ticket.getInfo';
    // }
    let params = {
      'openalias': openalias,
      'plat': 'plat_wap',
      'openno': '1',
      'ticketid': this.ticketid,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {

        // result.data.price = parseInt(result.data.price);
        result.data.detail = this.sanitize.bypassSecurityTrustHtml(result.data.detail);
        console.log(JSON.stringify(result.data));
        this.mDetails = result.data;
        this.isShowPage = true;
        this.shareUserId = this.utils.getLocalStorageItem("user_id") == undefined ? '' : this.utils.getLocalStorageItem("user_id") ;
        if (this.type == 'wap') {
          this.shareLink = SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.mDetails.shopid + '&type=wap' + '&shareid=' + this.shareUserId;
        } else {
          this.shareLink = SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.mDetails.shopid + '&shareid=' + this.shareUserId;

        }
        this.shareConfig();
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  public mShareLinkParams = '';
  public showShareLinkParams = '';
  public sharepic;
  public isShare = false;
  public shareLink = "";
  // 立即分享
  public share(id, shopid) {
    // console.log("立即分享...");
    // this.router.navigate(['couponDetail'], { queryParams: {} });
    // document.execCommand("优惠券赠送规则...");
    // if (this.isLogin) {
    if (this.isWeixin()) {
      this.showWXShareTip = true;
      setTimeout(() => {
        this.showWXShareTip = false;
        this.isShare = false;
        this.isMenu = false;
        this.showAnimation = false;
      }, 6000);
    } else {
      this.sharepic = this.mDetails.sharepic;
      var tip = "点击链接或者复制链接到浏览器打开!!!";
      this.shareUserId = this.utils.getLocalStorageItem("user_id") == undefined ? '' : this.utils.getLocalStorageItem("user_id") ;
      if (this.type == 'wap') {
        this.mShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail + SHARE_URL + '#/couponDetail?id=' + id + '&shopid=' + shopid + '&type=wap' + '&shareid=' + this.shareUserId + tip;
      } else {
        this.mShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail + SHARE_URL + '#/couponDetail?id=' + id + '&shopid=' + shopid + '&shareid=' + this.shareUserId + tip;

      }
      this.showShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail;
      // console.log(this.mShareLinkParams);
      this.isShare = true;
      this.showAnimation = true;
    }
    // } else {
    //   this.goToLogin();
    // }
  }

  public shareCopylink() {
    // var this_ = this ;
    // var save = function (e) {
    //   e.clipboardData.setData('text/plain', APP_SERVE_URL+this_.mShareLinkParams);//下面会说到clipboardData对象"#/couponDetail?id=123&shopid=222&shareid=111&type=web"
    //   e.preventDefault();//阻止默认行为
    // }
    // document.addEventListener('copy', save);
    // document.execCommand("copy");//使文档处于可编辑状态，否则无效
    // document.execCommand("优惠券赠送规则...");
    this.showAnimation = false;
    this.nativeService.showSuccessAlert("复制成功,快去粘贴分享吧~");
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  }
  public successFun() {
    // this.nativeService.showSuccessAlert("复制成功,快去粘贴吧~");
  }
  public cancelShare() {
    this.showAnimation = false;
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  };
  // 打开dialog
  public isMenu = false;
  public menu() {
    this.isMenu = !this.isMenu;
  }
  public closeMenu() {
    this.isMenu = false;
  }
  public goToHome() {
    this.isMenu = false;
    this.router.navigate(['index'])
  }
  public goToShare(id, shopid) {
    if (this.isWeixin()) {
      this.isShare = false;
      this.showWXShareTip = true;
    } else {
      this.showWXShareTip = false;
      this.isMenu = false;
      this.share(id, shopid);
    }
    // this.isShare = true ;
  }
  public showWXShareTipClick() {
    this.showWXShareTip = false;
    this.isShare = false;
  }
  public isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }
  public shareConfig() {
    if (this.isWeixin()) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.http.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        if (result.code == 100 && result.data) {
          var data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.getShareDataAndReady(data);
        }
      });
    }
  }
  public shareSuccess(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  public shareCancel(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  // 对分享信息进行处理，并注册分享函数
  public getShareDataAndReady(data) {
    wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp + '',
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareQZone',
        'onMenuShareWeibo']
    });
    let shareDesc = this.mDetails.sharedetail;
    let shareImg = this.mDetails.sharepic;
    let link = this.shareLink;
    let timelineTitle = this.mDetails.sharecircledetail;
    let frendTitle = this.mDetails.sharetitle;
    var that = this;
    wx.ready(function () {
      wx.onMenuShareTimeline({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareAppMessage({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQQ({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQZone({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareWeibo({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
    });
  }
}
