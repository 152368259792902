import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { SearchComponent } from "../search/search.component"
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forRoot([{ path: 'search', component: SearchComponent }]),
        InfiniteScrollModule,
        FooterRefreshModule,
        DataShowModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        SearchComponent
    ],
    providers: [],
}) export class SearchModule { }