import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InviteFriendsComponent } from './invite-friends.component';
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'inviteFriends', component: InviteFriendsComponent }]),
        FormsModule,//input [(ngModel)]需要导入这个module
    ],
    exports: [],
    declarations: [
        InviteFriendsComponent
    ]
})
export class InviteFriendsModule{}