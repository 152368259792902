import { NativeService } from './../../providers/NativeService';
import { Utils } from './../../providers/Utils';
import { HttpService } from './../../providers/HttpService';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare let MeScroll: any;
@Component({
  selector: 'app-welfare-centre',
  templateUrl: './welfare-centre.component.html',
  styleUrls: ['./welfare-centre.component.css']
})
export class WelfareCentreComponent implements OnInit {
  public shopid = 0;
  public noMore = false;
  public total: number = 1;
  public page: number = 1;
  public itemList:any = [];
  constructor(public routeInfo: ActivatedRoute, public router: Router, public nativeService: NativeService, private http: HttpService, public utils: Utils) { }
  public backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid;
    });
    this.nativeService.showLoading();
    this.initMesCroll();
  }
  public mescroll;
  initMesCroll() {
    var that = this;
    this.mescroll = new MeScroll("mescroll", {
      //下拉刷新
      down: {
        auto: true,//是否加载时回调
        callback: function () {
          that.getListData(false);
        }
      },
      //上拉加载更多
      up: {
        auto: false,
        callback: function () {
          if (that.itemList.length < that.total) {
            that.getListData(true);
          } else {
            that.noMore = true;
            that.mescroll.endSuccess(that.total);
          }
        }
      }
    });
  }
  getListData(status) {
    status ? this.page = this.page + 1 : this.page = 1;
    this.noMore = false;
    let param = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.shareholder.Welfare.getList',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.shopid,
      'limit': 20,
      'page': this.page,
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.total = result.data.total;
        if (status) {
          //上拉加载更多
          console.log("上拉加载更多");
          this.mescroll.endSuccess(result.data.total);
          this.itemList = this.itemList.concat(result.data.list);
        } else {
          //下拉刷新
          console.log("下拉刷新");
          this.itemList = [];
          this.mescroll.endSuccess();
          this.itemList = result.data.list;
          // console.log(this.total);
        }
      } else {
        if(result.code == 66 || result.code == 67){
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.mescroll.endErr();
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  };
  //福利中心详情 
  public goToDetail(item) {
    this.router.navigate(['welfareCentreDetial'], { queryParams: {id:item.id} });
  }
}
