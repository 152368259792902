import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WechatLoginGetkeyComponent } from './wechat-login-getkey.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'wechatGetkey', component: WechatLoginGetkeyComponent}]),
    ],
    exports: [],
    declarations: [
        WechatLoginGetkeyComponent
    ],
    providers: [],
})
export class WechatLoginGetkeyModule { }