import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { Utils } from "../providers/Utils"
import { HttpService } from "../providers/HttpService";
import { Md5 } from "ts-md5/dist/md5";
declare var wx: any;
declare var WeixinJSBridge: any;
@Component({
  selector: 'app-order-pay',
  templateUrl: './order-pay.component.html',
  styleUrls: ['./order-pay.component.css']
})
export class OrderPayComponent implements OnInit {
  public orderid = 0;
  public orderType = 0;
  public orderInfo;
  public paylist = [];
  public selectIndex = 0;
  public page = -1;
  public showView = false;
  //公众号支付参数
  public appId = "";
  public timeStamp = "";
  public nonceStr = "";
  public package = "";
  public signType = "";
  public paySign = "";
  public paykey = "";
  public paySuccess = false;
  public mWalletInfo: any;
  constructor(public httpService: HttpService, public utils: Utils, public router: Router, public nativeService: NativeService, public routeInfo: ActivatedRoute) {
  }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.page = queryParams.page == undefined ? -2 : -1;
      this.orderid = queryParams.orderid == undefined ? "" : queryParams.orderid;
      this.orderType = queryParams.orderType == undefined ? 0 : queryParams.orderType;
      this.paykey = queryParams.paykey == undefined ? "" : queryParams.paykey;
      this.getWalletInfo();
      this.requestOrderData();
      //检查订单状态
      this.checkOrder();
    });
  }
  backToPage() {
    this.paySuccess = true;
    if (this.orderType == 2) {
      this.nativeService.backToPage();
    } else {
      window.history.go(this.page);
      this.utils.removeLocalStorageItem("checkOrder");
    }
  }
  ngOnDestroy() {
    this.paySuccess = true;
  }
  public getWalletInfo() {
    let params = {
      'shopid': this.utils.getLocalStorageItem('shopid'),
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.balance.getBalance',
      'plat': 'plat_wap',
      'openno': '1'
    }
    this.httpService.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.mWalletInfo = result.data;
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.backToPage();
        }
      }
    })
  }
  requestOrderData() {
    // wap.order.paymall.payinfo
    var that = this;
    this.nativeService.showLoadingWithText("数据加载中...");
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.order.order.payinfo",
      'orderid': this.orderid
    }
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        that.orderInfo = success.data.orderInfo;
        if (success.data.paylist != undefined && success.data.paylist != null) {
          for (var i = 0; i < success.data.paylist.length; i++) {
            if (i == 0) {
              success.data.paylist[i].selected = true;
            } else {
              success.data.paylist[i].selected = false;
            }
          }
          that.paylist = success.data.paylist;
          that.showView = true;
          //是否授权支付
          if (that.paykey.length > 0) {
            that.commitClick();
          }
        } else {
          that.nativeService.showWarningAlert("缺少支付渠道");
        }
      } else {
        that.nativeService.showErrorAlert(success.msg);
      }
      that.nativeService.hideLoading();
    });
  }
  //选择支付渠道
  channelChooce(index) {
    for (var i = 0; i < this.paylist.length; i++) {
      this.paylist[i].selected = false;
    }
    this.paylist[index].selected = true;
    this.selectIndex = index;
  }
  // 确认支付
  commitClick() {
    var item = this.paylist[this.selectIndex];
    if (item != undefined && item != null) {
      // 余额支付或者股金支付
      if (item.channel == 'wallet' || item.channel == 'shares') {
        this.channel = item.channel;
        if (this.mWalletInfo.has_passwd != undefined && this.mWalletInfo.has_passwd == false) {
          this.isGoSetPayPwd = true;
        } else {
          this.isPwdPay = true;
        }

        return;
      }
      var that = this;
      this.nativeService.showLoadingWithText("订单支付中...");
      var param = {};
      // wap.order.paymall.pay
      if (that.paykey.length > 0) {
        param = {
          'plat': 'plat_wap',
          'openno': '1',
          'userid': this.utils.getLocalStorageItem("user_info").userid,
          'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
          'openalias': "wap.order.order.pay",
          'orderid': this.orderid,
          'paytype': item.channel,
          'paykey': this.paykey
        }
      } else {
        param = {
          'plat': 'plat_wap',
          'openno': '1',
          'userid': this.utils.getLocalStorageItem("user_info").userid,
          'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
          'openalias': "wap.order.order.pay",
          'orderid': this.orderid,
          'paytype': item.channel
        }
      }
      this.httpService.request({
        method: 'POST',
        url: 'api/index',
        data: param,
      }).then(success => {
        that.nativeService.hideLoading();
        if (success.code == 100 && success.data) {
          that.utils.setLocalStorageItem("checkOrder", { "orderid": that.orderid, "orderType": that.orderType });
          if (success.data.indexOf("HTTP") == -1 && success.data.indexOf("HTTPS") == -1 && success.data.indexOf("http") == -1 && success.data.indexOf("https") == -1) {
            if (success.data.paytype != undefined && success.data.paytype == "zeropay") {
              that.paySuccess = true;
              that.utils.removeLocalStorageItem("checkOrder");
              // 跳支付结果页
              if (that.orderType == 2) {
                this.nativeService.showSuccessAlert("支付成功");
                window.history.go(this.page);
              } else if (that.orderType == 12) {
                that.nativeService.backToPage();
                that.utils.setLocalStorageItem("viporderid", that.orderid);
              } else {
                that.router.navigate(['payResults'], { queryParams: { orderid: success.data.orderid, orderType: that.orderType, status: 1 } });
              }
            } else {
              var pay = JSON.parse(success.data);
              that.appId = pay.appId;
              that.timeStamp = pay.timeStamp;
              that.nonceStr = pay.nonceStr;
              that.package = pay.package;
              that.signType = pay.signType;
              that.paySign = pay.paySign;
              if (that.package.length > 0 && that.appId.length > 0) {
                that.wxPublicPay();
              }
            }
          } else {
            window.location.href = success.data;
          }
        } else {
          that.nativeService.showErrorAlert(success.msg);
        }
      });
    }
  }

  // 余额支付或者股金支付
  public channel;
  public password;
  public isPwdPay = false;
  public walletPay() {
    var that = this;
    this.nativeService.showLoadingWithText("订单支付中...");
    var param = {};
    // wap.order.paymall.pay
    param = {
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.order.order.pay",
      'orderid': this.orderid,
      'paytype': this.channel,
      'walletpasswd': Md5.hashStr(this.password).toString(),
    }
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      that.nativeService.hideLoading();
      if (success.code == 100 && success.data) {
        console.log(JSON.stringify(success));
        // that.utils.setLocalStorageItem("checkOrder", { "orderid": that.orderid, "orderType": that.orderType });
        // that.utils.removeLocalStorageItem("checkOrder");
        that.utils.setLocalStorageItem("checkOrder", { "orderid": that.orderid, "orderType": that.orderType });
        that.paySuccess = true;
        if (that.orderType == 2) {
          that.nativeService.showSuccessAlert("支付成功");
          window.history.go(that.page);
          that.utils.removeLocalStorageItem("checkOrder");
        } else if (that.orderType == 12) {
          that.nativeService.backToPage();
          that.utils.setLocalStorageItem("viporderid", that.orderid);
        } else {
          that.utils.removeLocalStorageItem("checkOrder");
          // 跳支付结果页
          that.router.navigate(['payResults'], { queryParams: { orderid: success.data.orderid, orderType: that.orderType, status: 1 } });
        }
      } else {
        that.nativeService.showErrorAlert(success.msg);
      }
    });
  }
  // checkPwd 检测密码
  public checkPwd(event) {
    // console.log(event + "");
    if (event.length >= 6) {
      console.log("去支付咯");
      this.walletPay();
    }
  }
  public closePwdDialog() {
    this.isPwdPay = false;
    this.password = '';
  }
  public isGoSetPayPwd = false;
  public dismiss() {
    this.isGoSetPayPwd = false;
  }
  public goToSetPayPwd() {
    this.isGoSetPayPwd = false;
    this.router.navigate(['payment-password']);
  }
  public checkOrder() {
    let that = this;
    // wap.order.paymall.orderInfo
    function check() {
      if (that.paySuccess) return;
      setTimeout(check, 1000);
      var param = {
        'plat': 'plat_wap',
        'openno': '1',
        'userid': that.utils.getLocalStorageItem("user_info").userid,
        'user_token': that.utils.getLocalStorageItem("user_info").usertoken,
        'openalias': "wap.order.order.orderInfo",
        'orderid': that.orderid
      }
      that.httpService.request({
        method: 'GET',
        url: 'api/index',
        data: param,
      }).then(success => {
        if (success.code == 100 && success.data) {
          if (success.data.status != 2) {
            console.log('0');
            that.paySuccess = true;
            that.utils.removeLocalStorageItem("checkOrder");
            if (that.orderType == 2) {
              that.nativeService.showSuccessAlert("支付成功");
              window.history.go(that.page);
            } else if (that.orderType == 12) {
              that.nativeService.backToPage();
              that.utils.setLocalStorageItem("viporderid", that.orderid);
            } else {
              that.router.navigate(['payResults'], { queryParams: { orderid: that.orderid, orderType: that.orderType, status: success.data.status } });
            }
          } else {
            //会循环请求
          }
        } else {
          that.utils.removeLocalStorageItem("checkOrder");
        }
      });
    }
    check();
  }
  //微信公众号支付
  wxPublicPay() {
    if (typeof WeixinJSBridge == "undefined") {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
      }
    } else {
      this.onBridgeReady();
    }
  }
  onBridgeReady() {
    var that = this;
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
      "appId": that.appId,//公众号名称，由商户传入     
      "timeStamp": that.timeStamp + "",//时间戳，自1970年以来的秒数     
      "nonceStr": that.nonceStr, //随机串     
      "package": that.package,
      "signType": that.signType,         //微信签名方式：     
      "paySign": that.paySign,//微信签名 
    },
      function (res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {

        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {

        } else {
        }
      });
  }
}
