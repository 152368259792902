import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { NativeService } from '../providers/NativeService';
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { Router, ActivatedRoute } from "@angular/router";
import { Utils } from '../providers/Utils';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { DomSanitizer } from '@angular/platform-browser';
import { SHARE_URL } from "../providers/Constants";
import { WeChatShareService } from "../providers/weChatShareService";

declare var wx: any;
declare let DialogByZ: any;
@Component({
  selector: 'app-discount-coupon',
  templateUrl: './discount-coupon.component.html',
  styleUrls: ['./discount-coupon.component.css', '../../assets/css/animate.min.css']
})
export class DiscountCouponComponent implements OnInit {

  public tabArr = [
    { name: '领券中心', id: 3, page: 1, isNoData: false, showFooter: false, isNoMore: false, status: '0', total: "" },
    { name: '未使用', id: 0, page: 1, isNoData: false, showFooter: false, isNoMore: false, status: '1', total: "" },
    { name: '使用记录', id: 1, page: 1, isNoData: false, showFooter: false, isNoMore: false, status: '2,4,5,6', total: "" },
    { name: '已过期', id: 2, page: 1, isNoData: false, showFooter: false, isNoMore: false, status: '3', total: "" },
  ];//tab 数组
  public selectTab = 3;//选中的tab
  public mUnusedArray = [];//未使用
  public mUsageLogArray = [];//使用记录
  public mOverdueArray = [];//已过期
  public isShowLoading: boolean = false;//是否显示loading
  public isNoData = false;
  public showFooter = false;
  public isNoMore = false;
  public isIntroduce = false;
  public total: any;
  public sanitizeHtml: any;
  public shopid;
  public clientH: any;
  public bodyH: any;
  @ViewChild('footerHeight') elementView: ElementRef;
  viewHeight: number;
  public footerHeight: number;
  public isTransparent = false;
  public isUnUser = false;
  constructor(public nativeService: NativeService, public router: Router
    , public routerInfo: ActivatedRoute, public utils: Utils
    , public http: HttpService, public sanitize: DomSanitizer
    , public shareService: WeChatShareService) { }


  ngOnInit() {
    this.getBodyH();
    this.routerInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid;
      this.isUnUser = queryParams.isUnUser;
    })
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = isLogin;
    }
    this.isShowLoading = true;
    this.nativeService.showLoading();
    // this.getDiscountCouponList(this.tabArr[0].page, this.tabArr[0].status)
    this.getCouponList();
    //进来跳到未使用
    if (this.isUnUser) {
      setTimeout(() => {
        this.potFor(0);
      }, 500);
    };
  }
  public goToLogin() {
    this.utils.setLocalStorageItem('isWechatAuth', false);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
    this.router.navigate(['login']);
  }
  public backToPage() {
    this.nativeService.backToPage();
  }
  //选中的tab
  public potFor(id) {
    this.getBodyH();
    if (this.isShowLoading) {
      return;
    }
    if (id == this.selectTab) {
      return;
    }
    this.selectTab = id;
    switch (id) {
      case 0:
        if (this.tabArr[1].page == 1) {
          this.isShowLoading = true;
          this.nativeService.showLoading();
          this.getDiscountCouponList(this.tabArr[1].page, this.tabArr[1].status);
        }
        this.isNoData = this.tabArr[1].isNoData;
        this.showFooter = this.tabArr[1].showFooter;
        this.isNoMore = this.tabArr[1].isNoMore;
        this.total = this.tabArr[1].total;
        break;
      case 1:
        if (this.tabArr[2].page == 1) {
          this.isShowLoading = true;
          this.nativeService.showLoading();
          this.getDiscountCouponListUsed(this.tabArr[2].page, this.tabArr[2].status);
        }
        this.isNoData = this.tabArr[2].isNoData;
        this.showFooter = this.tabArr[2].showFooter;
        this.isNoMore = this.tabArr[2].isNoMore;
        this.total = this.tabArr[2].total;
        break;
      case 2:
        if (this.tabArr[3].page == 1) {
          this.isShowLoading = true;
          this.nativeService.showLoading();
          this.getDiscountCouponListPastDue(this.tabArr[3].page, this.tabArr[3].status);
        }
        this.isNoData = this.tabArr[3].isNoData;
        this.showFooter = this.tabArr[3].showFooter;
        this.isNoMore = this.tabArr[3].isNoMore;
        this.total = this.tabArr[3].total;
        break;
      case 3://领券中心
        if (this.tabArr[0].page == 1) {
          this.isShowLoading = true;
          this.nativeService.showLoading();
          // this.getDiscountCouponListPastDue(this.tabArr[2].page, this.tabArr[2].status);
          this.getCouponList();
        }
        this.isNoData = this.tabArr[0].isNoData;
        this.showFooter = this.tabArr[0].showFooter;
        this.isNoMore = this.tabArr[0].isNoMore;
        this.total = this.tabArr[0].total;
        break;
      default:
        break;
    }
  }

  public getBodyH() {
    try {
      setTimeout(() => {
        this.clientH = document.documentElement.clientHeight;
        this.bodyH = document.body.clientHeight;
        this.footerHeight = this.elementView.nativeElement.offsetHeight;
        console.log('-----' + this.clientH + '-----' + this.bodyH + '-----' + this.footerHeight);
        if ((this.bodyH - this.clientH - this.footerHeight) > 0) {
          this.isTransparent = true;
        } else {
          this.isTransparent = false;
        }
      }, 300);
    } catch (error) {
    }
  }
  // 立即使用
  public immediateUse(tid) {
    // console.log("立即使用");
    // uselimit = 4 ;
    // if (uselimit == 1) {
    //   this.router.navigate(['home'], { queryParams: { 'shopid': shopid } });
    // } else if (uselimit == 2) {//商品
    //   // product
    //   // shopDetail
    //   this.router.navigate(['product'], { queryParams: { 'shopid': shopid } });
    // } else if (uselimit == 3) {//服务
    //   this.router.navigate(['serviceList'], { queryParams: { 'shopid': shopid } });
    // } else if (uselimit == 4) {//套餐
    //   this.router.navigate(['packageList'], { queryParams: { 'shopid': shopid } });
    // }
    // if (shopid == 0) {
    //   this.router.navigate(['home'], { queryParams: { 'shopid': shopid } });
    // } else {
    //   // shopDetail
    //   this.router.navigate(['home'], { queryParams: { 'shopid': shopid } });
    // }

    // 20220707
    // this.utils.activityJump({
    //   'wapurl': url
    // }, this.router);

    // 20220820
    this.router.navigate(['discouoondetail'], { queryParams: { tid: tid, type: 'unused' } });
  }
  // 状态（1未使用，2预扣，3已过期，4已使用，5已锁定，6已销毁）多个状态以逗号隔开拼接查询
  //获取数据 未使用
  public getDiscountCouponList(page, status) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.ticket.ticketuser.getlist',
      'limit': 20,
      'page': page,
      'status': status,
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.shopid
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      this.showFooter = false;
      this.tabArr[1].showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        for (var i = 0; i < result.data.list.length; i++) {
          var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data.list[i].price));  
          result.data.list[i].price = priceArr[0];
          result.data.list[i].price1 = priceArr[1];
          // result.data.list[i].price = parseInt(result.data.list[i].price);
          result.data.list[i].isIntroduce = false;
          result.data.list[i].limitprice = parseInt(result.data.list[i].limitprice);
          result.data.list[i].detail = this.sanitize.bypassSecurityTrustHtml(result.data.list[i].detail);
        }
        if (page == 1) {
          this.tabArr[1].total = "(" + result.data.total + ")";
          this.total = result.data.total;
          this.mUnusedArray = result.data.list;
          // this.mUnusedArray = ["", ""];
        } else {
          this.mUnusedArray = this.mUnusedArray.concat(result.data.list);
        }
        if (result.data.total == 0) {
          this.isNoData = true;
          this.tabArr[1].isNoData = this.isNoData;
        }

        if (result.data.total > 0 && this.mUnusedArray.length >= result.data.total) {
          this.isNoMore = true;
          this.tabArr[1].isNoMore = this.isNoMore;
        }

        this.tabArr[1].page++;
        this.getBodyH();
      } else {
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.goToLogin();
          return;
        }
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  //获取数据 使用记录
  public getDiscountCouponListUsed(page, status) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.ticket.ticketuser.getlist',
      'limit': 20,
      'page': page,
      'status': status,
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.shopid
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      this.showFooter = false;
      this.tabArr[2].showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        for (var i = 0; i < result.data.list.length; i++) {
          var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data.list[i].price));
          result.data.list[i].price = priceArr[0];
          result.data.list[i].price1 = priceArr[1];
          result.data.list[i].isIntroduce = false;
          result.data.list[i].limitprice = parseInt(result.data.list[i].limitprice);
          result.data.list[i].detail = this.sanitize.bypassSecurityTrustHtml(result.data.list[i].detail);
          result.data.list[i].name = '抵用券';
          if (result.data.list[i].type == 1) {
            result.data.list[i].name = '抵用券';
          }
          if (result.data.list[i].type == 2) {
            result.data.list[i].name = '现金券';
          }
          if (result.data.list[i].type == 3) {
            result.data.list[i].name = '兑换券';
            result.data.list[i].price = '兑';
          }
          if (result.data.list[i].type == 4) {
            result.data.list[i].name = '折扣券';
          }
        }
        if (page == 1) {
          this.tabArr[2].total = "(" + result.data.total + ")";
          this.total = result.data.total;
          this.mUsageLogArray = result.data.list;
        } else {
          this.mUsageLogArray = this.mUsageLogArray.concat(result.data.list);
        }
        if (result.data.total == 0) {
          this.isNoData = true;
          this.tabArr[2].isNoData = this.isNoData;
        }

        if (result.data.total > 0 && this.mUsageLogArray.length >= result.data.total) {
          this.isNoMore = true;
          this.tabArr[2].isNoMore = this.isNoMore;
        }

        this.tabArr[2].page++;
        this.getBodyH();
      } else {
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.goToLogin();
          return;
        }
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  //获取数据 过期
  public getDiscountCouponListPastDue(page, status) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.ticket.ticketuser.getlist',
      'limit': 20,
      'page': page,
      'status': status,
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.shopid
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      this.showFooter = false;
      this.tabArr[3].showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        for (var i = 0; i < result.data.list.length; i++) {
          var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data.list[i].price));
          result.data.list[i].price = priceArr[0];
          result.data.list[i].price1 = priceArr[1];
          result.data.list[i].isIntroduce = false;
          result.data.list[i].limitprice = parseInt(result.data.list[i].limitprice);
          result.data.list[i].detail = this.sanitize.bypassSecurityTrustHtml(result.data.list[i].detail);
          result.data.list[i].name = '抵用券';
          if (result.data.list[i].type == 1) {
            result.data.list[i].name = '抵用券';
          }
          if (result.data.list[i].type == 2) {
            result.data.list[i].name = '现金券';
          }
          if (result.data.list[i].type == 3) {
            result.data.list[i].name = '兑换券';
            result.data.list[i].price = '兑';
          }
          if (result.data.list[i].type == 4) {
            result.data.list[i].name = '折扣券';
          }
        }
        if (page == 1) {
          this.tabArr[3].total = "(" + result.data.total + ")";
          this.total = result.data.total;
          this.mOverdueArray = result.data.list;
          // this.mOverdueArray = result.data.list;
        } else {
          this.mOverdueArray = this.mOverdueArray.concat(result.data.list);
        }
        if (result.data.total == 0) {
          this.isNoData = true;
          this.tabArr[3].isNoData = this.isNoData;
        }

        if (result.data.total > 0 && this.mOverdueArray.length >= result.data.total) {
          this.isNoMore = true;
          this.tabArr[3].isNoMore = this.isNoMore;
        }

        this.tabArr[3].page++;
        this.getBodyH();
      } else {
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.goToLogin();
          return;
        }
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 加载更多。。。
  public onLoadMore() {
    // console.log("加载更多。。。");
    switch (this.selectTab) {
      case 3:
        // console.log(this.tabArr[0]);
        if (this.tabArr[0].isNoData || this.tabArr[0].isNoMore || this.tabArr[0].showFooter) {
          return;
        }
        console.log(this.tabArr[0]);
        this.showFooter = true;
        this.tabArr[0].showFooter = true;
        this.getCouponList();
        break;
      case 0:
        console.log(this.selectTab);
        if (this.tabArr[1].isNoData || this.tabArr[1].isNoMore || this.tabArr[1].showFooter) {
          return;
        }
        this.showFooter = true;
        this.tabArr[1].showFooter = true;
        this.getDiscountCouponList(this.tabArr[1].page, this.tabArr[1].status);

        break;

      case 1:
        console.log(this.selectTab);
        if (this.tabArr[2].isNoData || this.tabArr[2].isNoMore || this.tabArr[2].showFooter) {
          return;
        }
        this.showFooter = true;
        this.tabArr[2].showFooter = true;
        this.getDiscountCouponListUsed(this.tabArr[2].page, this.tabArr[2].status);

        break;
      case 2:
        console.log(this.selectTab);
        if (this.tabArr[3].isNoData || this.tabArr[3].isNoMore || this.tabArr[3].showFooter) {
          return;
        }
        this.showFooter = true;
        this.tabArr[3].showFooter = true;
        this.getDiscountCouponListPastDue(this.tabArr[3].page, this.tabArr[3].status);

        break;
    }
  }
  // 区分数据
  // public partitionData(page, data) {
  // }
  // 进入可赠送券页面
  public goToCanGiveTicket() {
    if (this.isShowLoading) {
      return;
    }
    this.router.navigate(['canGiveTicket'], {
      queryParams: {
        shopid: this.shopid
      }
    });
  }
  // 进入领券中心界面
  public goTocouponRedemptionCentre() {
    if (this.isShowLoading) {
      return;
    }
    this.router.navigate(['couponRedemptionCentre'], {
      queryParams: {
        shopid: this.shopid
      }
    })
  }

  public showIntroduce(index) {
    switch (this.selectTab) {
      case 0:
        this.mUnusedArray[index].isIntroduce = !this.mUnusedArray[index].isIntroduce;
        break;
      case 1:
        this.mUsageLogArray[index].isIntroduce = !this.mUsageLogArray[index].isIntroduce;
        break;
      case 2:
        this.mOverdueArray[index].isIntroduce = !this.mOverdueArray[index].isIntroduce;
        break;
      default:
        break;
    }

  }

  // goToCouponDetail
  public mCouponArray = [];
  public isLogin;
  public goToCouponDetail() { }
  public goShopID = '';
  public uselimit = 1;
  public url = '';
  // 领取优惠券
  public getCoupon(event, id, item) {
    this.goShopID = item.shopid;
    this.mDetails = item;
    this.uselimit = item.uselimit;
    this.url = item.url;
    if (this.url == undefined || this.url.length == 0) {
      this.url = item.wapurl;
    }
    if (this.uselimit == undefined) {
      this.uselimit = 1;
    }
    // if (this.uselimit == 0) {
    //   console.log(JSON.stringify(item));
    //   return ;
    // }
    this.isShowLoading = true;
    this.nativeService.showLoading();
    event.stopPropagation();
    if (!this.isLogin) {
      this.goToLogin();
      return;
    }
    // console.log("id:"+id +"---" +"tagtype:"+tagtype);
    // 'userid': this.utils.getLocalStorageItem("user_id"),
    // 'user_token': this.utils.getLocalStorageItem("user_token"),
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.ticket.Ticketuser.addTicket',
      'plat': 'plat_wap',
      'openno': '1',
      'shareId': id,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.showFooter = false;
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      var that = this;
      if (result.code == 100 && result.data) {
        DialogByZ.Confirm({
          Title: "领取成功", Content: "", BtnL: "去使用", BtnR: "邀请好友领券", FunL: function () {
            //点击左按钮
            DialogByZ.Close();
            that.immediateUse(item.ticketid);
          }, FunR: function () {
            //点击右按钮
            DialogByZ.Close();
            that.share();
          }
        });
      } else {
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.goToLogin();
          return;
        } else {
          DialogByZ.Confirm({
            Title: "提示", Content: result.msg, BtnL: "确定", BtnR: "邀请好友领券", FunL: function () {
              //点击左按钮
              DialogByZ.Close();
            }, FunR: function () {
              //点击右按钮
              DialogByZ.Close();
              that.share();
            }
          });
        }
      }
    })
  }

  public showWXShareTip = false;
  public showAnimation = false;
  public mShareLinkParams = '';
  public showShareLinkParams = '';
  public sharepic;
  public isShare = false;
  public shareLink = "";
  public isMenu = false;
  public mDetails: any;
  public type = 'wap';
  public shareid = this.utils.getLocalStorageItem("user_id");
  // 分享
  public share() {
    console.log("分享");
    // this.router.navigate(['couponFriendsShare'], { queryParams: { 'shopid': 'shopid' } });
    if (this.isWeixin()) {
      this.showWXShareTip = true;
      this.getShareDataAndReady(this.data);
      setTimeout(() => {
        this.showWXShareTip = false;
        this.isShare = false;
        this.isMenu = false;
        this.showAnimation = false;
      }, 6000);
    } else {
      console.log(JSON.stringify(this.mDetails));
      this.sharepic = this.mDetails.sharepic;
      var tip = "点击链接或者复制链接到浏览器打开!!!";
      if (this.type == 'wap') {
        this.mShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail + SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.goShopID + '&type=wap' + '&shareid=' + this.shareid + tip;
      } else {
        this.mShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail + SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.goShopID + '&shareid=' + this.shareid + tip;

      }
      this.showShareLinkParams = '【' + this.mDetails.sharetitle + '】' + this.mDetails.sharedetail;
      // console.log(this.mShareLinkParams);
      this.isShare = true;
      this.showAnimation = true;
    }
  }

  public shareCopylink() {
    // var this_ = this ;
    // var save = function (e) {
    //   e.clipboardData.setData('text/plain', APP_SERVE_URL+this_.mShareLinkParams);//下面会说到clipboardData对象"#/couponDetail?id=123&shopid=222&shareid=111&type=web"
    //   e.preventDefault();//阻止默认行为
    // }
    // document.addEventListener('copy', save);
    // document.execCommand("copy");//使文档处于可编辑状态，否则无效
    // document.execCommand("优惠券赠送规则...");
    this.showAnimation = false;
    this.nativeService.showSuccessAlert("复制成功,快去粘贴分享吧~");
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  }
  public cancelShare() {
    this.showAnimation = false;
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  };
  public isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }
  public goToCouponFriendsShare() {
    this.router.navigate(['couponFriendsShare'], { queryParams: { 'shopid': this.shopid } });
  }
  //获取券列表(领券中心)
  public getCouponList() {
    // 'userid': this.utils.getLocalStorageItem("user_id"),
    // 'user_token': this.utils.getLocalStorageItem("user_token"),
    // wap.product.ticket.shareList  wap.product.ticket.getlist 
    let params = {
      'openalias': 'wap.product.ticket.shareList',
      'plat': 'plat_wap',
      'openno': '1',
      'limit': 20,
      'page': this.tabArr[0].page,
      'shopid': this.shopid,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.showFooter = false;
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      this.tabArr[0].showFooter = false;
      if (result.code == 100 && result.data) {
        this.total = result.data.total;
        // console.log(JSON.stringify(result.data));
        for (let i = 0; i < result.data.list.length; i++) {
          var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data.list[i].price));
          result.data.list[i].price = priceArr[0];
          result.data.list[i].price1 = priceArr[1];
          // result.data.list[i].price = parseInt(result.data.list[i].price);
          // }
        }
        if (this.tabArr[0].page == 1) {
          if (result.data.total <= 0) {
            this.isNoData = true;
            this.tabArr[0].isNoData = true;
          }
          this.mCouponArray = result.data.list;
          // console.log(JSON.stringify(this.mCouponArray));
        } else {
          this.mCouponArray = this.mCouponArray.concat(result.data.list);
          // console.log(JSON.stringify(this.mCouponArray));
        }
        // console.log('this.tabArr[0].isNoMore');
        if (this.mCouponArray.length >= result.data.total && result.data.total != 0) {
          this.isNoMore = true;
          this.tabArr[0].isNoMore = this.isNoMore;
          // console.log(this.tabArr[0].isNoMore);
        }

        this.tabArr[0].page++;
        this.shareConfig();
        // console.log(JSON.stringify(result.data));
        this.getBodyH();
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  public successFun() {
    // this.nativeService.showSuccessAlert("复制成功,快去粘贴吧~");
  }
  public showWXShareTipClick() {
    this.showWXShareTip = false;
    this.isShare = false;
  }

  public data;
  public shareConfig() {
    if (this.isWeixin()) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.http.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        if (result.code == 100 && result.data) {
          this.data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.getShareDataAndReady(this.data);
        }
      });
    }
  }
  public shareSuccess(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  public shareCancel(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  // 对分享信息进行处理，并注册分享函数
  public getShareDataAndReady(data) {
    wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp + '',
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareQZone',
        'onMenuShareWeibo']
    });
    let shareDesc = this.mDetails.sharedetail;
    let shareImg = this.mDetails.sharepic;
    if (this.type == 'wap') {
      this.shareLink = SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.mDetails.shopid + '&type=wap' + '&shareid=' + this.shareid;
    } else {
      this.shareLink = SHARE_URL + '#/couponDetail?id=' + this.mDetails.id + '&shopid=' + this.mDetails.shopid + '&shareid=' + this.shareid;
    }
    let link = this.shareLink;
    let timelineTitle = this.mDetails.sharecircledetail;
    let frendTitle = this.mDetails.sharetitle;
    var that = this;
    wx.ready(function () {
      wx.onMenuShareTimeline({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareAppMessage({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQQ({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQZone({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareWeibo({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
    });
  }

  /****************/
  @HostListener('window:scroll', ['$event']) public onScroll = ($event) => {
    // console.log('滚动了...'); 
    //客户端高度
    var clientH = document.documentElement.clientHeight;
    //body高度
    var bodyH = document.body.clientHeight;
    //滚动的高度
    var scrollTop = document.documentElement.scrollTop;
    // console.log("滚动的高度:" + scrollTop);
    // console.log("滚动的高度:" + scrollTop+'----'+'body高度:'+bodyH+'-----------'+'客户端高度:'+clientH);
    try {
      // this.shopAddressHeight = this.elementView.nativeElement.offsetHeight;
    } catch (error) {
    }
    // console.log('店铺div高度：' + this.shopAddressHeight);
    if (scrollTop > 0) {

    } else {

    }
    // 滚动到底部60以内
    if (bodyH - clientH - scrollTop < 3) {
      // if (!this.flag) {
      //     console.log('翻页');
      //     //翻页
      //     this.changePage('+');
      // }
      // this.flag = true;
      this.isTransparent = false;
    } else {
      // this.flag = false;
      this.isTransparent = true;
    }
  }
}
