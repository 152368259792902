import { Injectable } from '@angular/core';
declare var wx: any;
import { NativeService } from '../providers/NativeService';
import { Observable } from 'rxjs';
@Injectable()
export class WeChatShareService {
  constructor(public nativeService: NativeService) { };
  // 根据后台获取的数据注册config
  public configWXJs(obj: any) {
    wx.config({
      debug: false,
      appId: obj.appId,
      timestamp: obj.timestamp + '',
      nonceStr: obj.nonceStr,
      signature: obj.signature,
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareQZone',
        'onMenuShareWeibo']
    });
  }
  // 对分享信息进行处理，并注册分享函数
  public getShareDataAndReadyLink(title, imgUrl, desc, circleTitle, sharelink): Observable<object> {
    let shareTitle = title.length > 0 ? title : '基利斯';//主标题
    let shareImg = imgUrl.length > 0 ? imgUrl : "http://m.jilisi.com/static/images/commen/jlslogo.png";
    let shareDesc = desc.length > 0 ? desc : '爱车爱生活 爱上基利斯';
    let circleDesc = circleTitle.length > 0 ? circleTitle : shareDesc;
    let link = sharelink;
    console.log("标题：" + shareTitle + "---详情：" + shareDesc + "---链接：" + link);
    var that = this;
    return Observable.create(observer => {
      wx.ready(function () {
        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
        wx.onMenuShareTimeline({
          title: shareTitle, // 分享标题
          desc: circleDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function () {
            //分享成功
           observer.next({data:1});
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
            observer.next({data:0});
          }
        });
        wx.onMenuShareAppMessage({
          title: shareTitle, // 分享标题
          desc: shareDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function (data) {
            //分享成功
           observer.next({data:1});
          },
          cancel: function (data) {
            observer.next({data:0});
          }
        });
        wx.onMenuShareQQ({
          title: shareTitle, // 分享标题
          desc: shareDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function () {
            //分享成功
            observer.next({data:1});
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
            observer.next({data:0});
          }
        });
        wx.onMenuShareQZone({
          title: shareTitle, // 分享标题
          desc: circleDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function () {
            //分享成功
           observer.next({data:1});
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
            observer.next({data:0});
          }
        });
        wx.onMenuShareWeibo({
          title: shareTitle, // 分享标题
          desc: shareDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function () {
            //分享成功
           observer.next({data:1});
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
            observer.next({data:0});
          }
        });
      });
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    });
  }
}
