import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CouponActivityShareComponent } from './coupon-activity-share.component';
import { _require } from '../providers/require_lib';
import { ClipboardModule } from 'ngx-clipboard';
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'couponActivityShare', component:  CouponActivityShareComponent}]),
        FormsModule,
        ReactiveFormsModule,
        ClipboardModule
    ],
    exports: [],
    declarations: [
        CouponActivityShareComponent
    ],
    providers: [],
})
//可赠送券
export class  CouponActivityShareModule{

}