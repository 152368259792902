import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NativeService } from '../providers/NativeService';
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService";
import { APP_SERVE_URL } from "../providers/Constants";
import { SHARE_URL } from "../providers/Constants";
import { Router, ActivatedRoute } from "@angular/router";
import { Utils } from '../providers/Utils';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { DomSanitizer } from '@angular/platform-browser';
import { WeChatShareService } from "../providers/weChatShareService";
declare var wx: any;
@Component({
  selector: 'app-can-give-ticket',
  templateUrl: './can-give-ticket.component.html',
  styleUrls: ['./can-give-ticket.component.css', '../../assets/css/animate.min.css']
})
// //可赠送券
export class CanGiveTicketComponent implements OnInit {
  public isShowLoading = false;
  public isNoData = false;
  public isNoMore = false;
  public showFooter = false;
  public isShare = false;
  public mTicketList = [];//可赠送券列表
  public mTicketLogList = [];//赠送券记录列表
  public mTicketPastDueList = [];//赠送券过期列表
  public mShareLinkParams = '';
  public showShareLinkParams = '';
  public mRegulation;
  public isRegulation = false;
  public tabArr = [
    { name: '可赠送', id: 0, page: 1, isNoData: false, showFooter: false, isNoMore: false, status: '1', total: "" },
    { name: '赠送记录', id: 1, page: 1, isNoData: false, showFooter: false, isNoMore: false, status: '1', total: "" },
    { name: '已过期', id: 2, page: 1, isNoData: false, showFooter: false, isNoMore: false, status: '1', total: "" }
  ];
  public selectTab = 0;//选中的tab
  public showAnimation = false;
  public showShareView = 0;
  public shareItem: any = {};
  public shopid;
  constructor(public cdr: ChangeDetectorRef, public shareService: WeChatShareService, public nativeService: NativeService, public router: Router, public routeInfo: ActivatedRoute, public http: HttpService, public utils: Utils, public sanitize: DomSanitizer) { }

  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid;
      this.utils.setLocalStorageItem('shopid',this.shopid );
    });

    this.isShowLoading = true;
    this.nativeService.showLoading();
    this.getTicketList(1);
    this.getRegulation();
    this.showShareView = 0;
    this.shareConfig();
  }

  //选中的tab
  public potFor(id) {
    if (this.isShowLoading) {
      return;
    }
    if (id == this.selectTab) {
      return;
    }
    this.selectTab = id;
    switch (id) {
      case 0:
        if (this.tabArr[0].page == 1) {
          this.getTicketList(this.tabArr[0].page);
        }
        this.isNoData = this.tabArr[0].isNoData;
        this.showFooter = this.tabArr[0].showFooter;
        this.isNoMore = this.tabArr[0].isNoMore;
        break;
      case 1:
        if (this.tabArr[1].page == 1) {
          // this.isShowLoading = true;
          this.getTicketLogList(this.tabArr[1].page);
        }
        this.isNoData = this.tabArr[1].isNoData;
        this.showFooter = this.tabArr[1].showFooter;
        this.isNoMore = this.tabArr[1].isNoMore;
        break;
      case 2:
        if (this.tabArr[2].page == 1) {
          // this.isShowLoading = true;
          this.getTicketPastDueList(this.tabArr[2].page);
        }
        this.isNoData = this.tabArr[2].isNoData;
        this.showFooter = this.tabArr[2].showFooter;
        this.isNoMore = this.tabArr[2].isNoMore;
        break;
      default:
        break;
    }
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  // 优惠券赠送规则
  public presentRules() {
    // console.log("优惠券赠送规则...");
    // this.router.navigate(['discountCouponSelect'], { queryParams: {} });
    this.isRegulation = true;
  }
  // 关闭规则弹窗
  public closeDialog() {
    this.isRegulation = false;
  }
  // 立即分享
  public sharepic = '';
  public share(list) {
    console.log("d" + list);
    if (this.isWeixin()) {
      this.showShareView = 1;
      this.shareItem = "";
      this.shareItem = list;
      this.mShareLinkParams = SHARE_URL + '#/couponDetail?id=' + list.id + '&shopid=' + list.shopid + '&type=wap' + '&shareid=' + this.utils.getLocalStorageItem("user_id");
      this.shareConfig();
    } else {
      this.sharepic = list.sharepic;
      this.mShareLinkParams = "【" + list.sharetitle + "】 " + list.sharedetail + SHARE_URL + '#/couponDetail?id=' + list.id + '&shopid=' + list.shopid + '&type=wap' + '&shareid=' + this.utils.getLocalStorageItem("user_id") + "点击链接或者复制链接到浏览器打开!!!";
      this.showShareLinkParams = "【" + list.sharetitle + "】 " + list.sharedetail;
      this.isShare = true;
      this.showAnimation = true;
    }
  }

  public shareCopylink() {
    // var this_ = this ;
    // var save = function (e) {
    //   e.clipboardData.setData('text/plain', APP_SERVE_URL+this_.mShareLinkParams);//下面会说到clipboardData对象"#/couponDetail?id=123&shopid=222&shareid=111&type=web"
    //   e.preventDefault();//阻止默认行为
    // }
    // document.addEventListener('copy', save);
    // document.execCommand("copy");//使文档处于可编辑状态，否则无效
    // document.execCommand("优惠券赠送规则...");

    this.showAnimation = false;
    this.nativeService.showSuccessAlert("复制成功,快去粘贴分享吧~");
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  }
  public successFun() {
    // console.log()
    // this.nativeService.showSuccessAlert("复制成功,快去粘贴吧～");
  }
  public cancelShare() {
    // console.log('vbnm,./');
    this.showAnimation = false;
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  };
  // public shareListener(event){
  //   event.
  // }
  // 获取规则
  public getRegulation() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.ticket.Ticketusergive.getGiveTicketRulesText',
      'plat': 'plat_wap',
      'openno': '1',
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        this.mRegulation = this.sanitize.bypassSecurityTrustHtml(result.data);
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 获取可赠送券列表
  public getTicketList(page) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'gettype': '1',
      'limit': 20,
      'page': page,
      'openalias': 'wap.ticket.ticketusergive.getgiveList',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid':this.shopid ,
    }
    // console.log(params)
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      this.showFooter = false;
      this.tabArr[0].showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        for (var i = 0; i < result.data.list.length; i++) {
          result.data.list[i].price = parseInt(result.data.list[i].price);
          result.data.list[i].isIntroduce = false;
          result.data.list[i].limitprice = parseInt(result.data.list[i].limitprice);
          result.data.list[i].detail = this.sanitize.bypassSecurityTrustHtml(result.data.list[i].detail);
        }
        if (page == 1) {
          this.mTicketList = result.data.list;
          this.tabArr[0].total = "(" + result.data.total + ")";
        } else {
          this.mTicketList = this.mTicketList.concat(result.data.list);
        }
        if (result.data.total == 0) {
          this.isNoData = true;
          this.tabArr[0].isNoData = this.isNoData;
        }
        if (result.data.total > 0 && this.mTicketList.length >= result.data.total) {
          this.isNoMore = true;
          this.tabArr[0].isNoMore = this.isNoMore;
        }
        this.tabArr[0].page++;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 记录
  public getTicketLogList(page) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'limit': 20,
      'page': page,
      'openalias': 'wap.ticket.ticketusergive.getGiveListLog',//ticket.ticketusergive.getGiveLogList
      'plat': 'plat_wap',
      'openno': '1',
      'shopid':this.shopid ,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      this.showFooter = false;
      this.tabArr[1].showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        for (var i = 0; i < result.data.list.length; i++) {
          result.data.list[i].price = parseInt(result.data.list[i].price);
          result.data.list[i].isIntroduce = false;
          result.data.list[i].limitprice = parseInt(result.data.list[i].limitprice);
          result.data.list[i].detail = this.sanitize.bypassSecurityTrustHtml(result.data.list[i].detail);
          result.data.list[i].name = '抵用券'
          if (result.data.list[i].type == 1) {
            result.data.list[i].name = '抵用券'
          } else if (result.data.list[i].type == 2) {
            result.data.list[i].name = '现金券'
          } else if (result.data.list[i].type == 3) {
            result.data.list[i].name = '兑换券'
            result.data.list[i].price = '兑';
          }
        }
        if (page == 1) {
          this.mTicketLogList = result.data.list;
          this.tabArr[1].total = "(" + result.data.total + ")";
        } else {
          this.mTicketLogList = this.mTicketLogList.concat(result.data.list);
        }
        if (result.data.total == 0) {
          this.isNoData = true;
          this.tabArr[1].isNoData = this.isNoData;
        }
        if (result.data.total > 0 && this.mTicketLogList.length >= result.data.total) {
          this.isNoMore = true;
          this.tabArr[1].isNoMore = this.isNoMore;
        }
        this.tabArr[1].page++;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 过期
  public getTicketPastDueList(page) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'gettype': '2',
      'limit': 20,
      'page': page,
      'openalias': 'wap.ticket.ticketusergive.getgiveList',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid':this.shopid ,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.isShowLoading = false;
      this.nativeService.hideLoading();
      this.showFooter = false;
      this.tabArr[2].showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        for (var i = 0; i < result.data.list.length; i++) {
          result.data.list[i].price = parseInt(result.data.list[i].price);
          result.data.list[i].isIntroduce = false;
          result.data.list[i].limitprice = parseInt(result.data.list[i].limitprice);
          result.data.list[i].detail = this.sanitize.bypassSecurityTrustHtml(result.data.list[i].detail);
          result.data.list[i].name = '抵用券'
          if (result.data.list[i].type == 1) {
            result.data.list[i].name = '抵用券'
          } else if (result.data.list[i].type == 2) {
            result.data.list[i].name = '现金券'
          } else if (result.data.list[i].type == 3) {
            result.data.list[i].name = '兑换券';
            result.data.list[i].price = '兑';
          }
        }
        if (page == 1) {
          this.mTicketPastDueList = result.data.list;
          this.tabArr[2].total = "(" + result.data.total + ")";
        } else {
          this.mTicketPastDueList = this.mTicketPastDueList.concat(result.data.list);
        }
        if (result.data.total == 0) {
          this.isNoData = true;
          this.tabArr[2].isNoData = this.isNoData;
        }
        if (result.data.total > 0 && this.mTicketPastDueList.length >= result.data.total) {
          this.isNoMore = true;
          this.tabArr[2].isNoMore = this.isNoMore;
        }
        this.tabArr[2].page++;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  // 加载更多。。。
  public onLoadMore() {
    // console.log("加载更多。。。");
    switch (this.selectTab) {
      case 0:
        // console.log(this.selectTab);
        if (this.tabArr[0].isNoData || this.tabArr[0].isNoMore || this.tabArr[0].showFooter) {
          return;
        }
        this.showFooter = true;
        this.tabArr[0].showFooter = true;
        this.getTicketList(this.tabArr[0].page);
        break;

      case 1:
        // console.log(this.selectTab);
        if (this.tabArr[1].isNoData || this.tabArr[1].isNoMore || this.tabArr[1].showFooter) {
          return;
        }
        this.showFooter = true;
        this.tabArr[1].showFooter = true;
        this.getTicketLogList(this.tabArr[1].page);
        break;

      case 2:
        // console.log(this.selectTab);
        if (this.tabArr[2].isNoData || this.tabArr[2].isNoMore || this.tabArr[2].showFooter) {
          return;
        }
        this.showFooter = true;
        this.tabArr[2].showFooter = true;
        this.getTicketPastDueList(this.tabArr[2].page);
        break;
    }
  }

  public showIntroduce(index) {
    if (this.selectTab == 0) {
      this.mTicketList[index].isIntroduce = !this.mTicketList[index].isIntroduce;
    }
    if (this.selectTab == 1) {
      this.mTicketLogList[index].isIntroduce = !this.mTicketLogList[index].isIntroduce;
    }
    if (this.selectTab == 2) {
      this.mTicketPastDueList[index].isIntroduce = !this.mTicketPastDueList[index].isIntroduce;
    }
  }
  public isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }
  public closeWXShareTipClick() {
    this.showShareView = 0;
    this.shareConfig();
  }
  public shareConfig() {
    var that = this;
    if (this.isWeixin()) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.http.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        if (result.code == 100 && result.data) {
          var data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          that.getShareDataAndReady(data, that);
        }
      });
    }
  }
  public shareSuccess(that, res) {
    that.showShareView = 0;
    that.shareConfig();
    //刷新页面
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  public shareCancel(that, res) {
    that.showShareView = 0;
    that.shareConfig();
    //刷新页面
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }

  // 对分享信息进行处理，并注册分享函数
  public getShareDataAndReady(data, that) {
    if (that.showShareView == 0) {
      that.shareService.configWXJs(data);
      that.shareService.getShareDataAndReady("基利斯",
        "http://m.jilisi.com/static/images/commen/jlslogo.png",
        "可赠送券列表");
      that.showShareView = 0;
    } else {
      wx.config({
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp + '',
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: [
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareQZone',
          'onMenuShareWeibo']
      });
      let shareDesc = this.shareItem.sharedetail;
      let shareImg = this.shareItem.sharepic;
      let link = this.mShareLinkParams;
      let timelineTitle = this.shareItem.sharecircledetail;
      let frendTitle = this.shareItem.sharetitle;
      wx.ready(function () {
        wx.onMenuShareTimeline({
          title: timelineTitle, // 分享标题
          desc: shareDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function (res) {
            that.shareSuccess(that, res);
          },
          cancel: function (res) {
            // 用户取消分享后执行的回调函数
            that.shareCancel(that, res);
          }
        });
        wx.onMenuShareAppMessage({
          title: frendTitle, // 分享标题
          desc: shareDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function (res) {
            that.shareSuccess(that, res);
          },
          cancel: function (res) {
            // 用户取消分享后执行的回调函数
            that.shareCancel(that, res);
          }
        });
        wx.onMenuShareQQ({
          title: frendTitle, // 分享标题
          desc: shareDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function (res) {
            // 设置成功
            that.shareSuccess(that, res);
          },
          cancel: function (res) {
            // 用户取消分享后执行的回调函数
            that.shareCancel(that, res);
          }
        });
        wx.onMenuShareQZone({
          title: timelineTitle, // 分享标题
          desc: shareDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function (res) {
            // 设置成功
            that.shareSuccess(that, res);
          },
          cancel: function (res) {
            // 用户取消分享后执行的回调函数
            that.shareCancel(that, res);
          }
        });
        wx.onMenuShareWeibo({
          title: timelineTitle, // 分享标题
          desc: shareDesc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareImg, // 分享图标
          success: function (res) {
            // 设置成功
            that.shareSuccess(that, res);
          },
          cancel: function (res) {
            // 用户取消分享后执行的回调函数
            that.shareCancel(that, res);
          }
        });
      });
    }
  }
}
