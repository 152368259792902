import { Component, OnInit, ViewEncapsulation, Renderer2 } from '@angular/core';
import { HttpService } from "../providers/HttpService"
import { NativeService } from "../providers/NativeService";
import { Utils } from "../providers/Utils";
import { WeChatShareService } from "../providers/weChatShareService";
import { Router, ActivatedRoute } from '@angular/router';
import { _require } from '../providers/require_lib';
import { trigger, state, style, animate, transition } from "@angular/animations";
declare let Swiper: any;
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../assets/css/swiper.min.css'],
  encapsulation: ViewEncapsulation.None,//ViewEncapsulation.None这个是指该组件的css设置成为全局css
  animations: [
    trigger("signal", [
      state("hide", style({
        "right": "-68px",
      })),
      state("show", style({
        "right": "0",
      })),
      transition("*=>*", animate(400))
    ])
  ],
})
export class HomeComponent implements OnInit {
  public showView = false;
  public paySuccess = false;
  public iswechat = 0;
  public logid;
  public showLocalStorageSupported = false;//uc浏览器无痕阅读时，localStorage.getItem报错不能判断，只能根据“apitoken丢失”来处理
  public shopInfo: any = {};//店铺信息
  public shopAdvert = [];//店铺广告
  public shopAdvertLength = 0;//广告数量
  public centerAdvert = [];//店铺中间广告
  public centerAdvertLength = 0;//中间广告数量
  public ticket = [];//店铺抵用券
  public setmeal = [];//套餐
  public server = [];//服务
  public activityOrder = [];//活动促销
  public signal: string;
  public showChangeShop = false;
  public shopid = 0;
  public menuList = [];
  public myStyle;
  public menuType = 0;
  public clientW;
  public myStyle2;
  public goodsList: any[];
  public goodsListTotal: 0;
  public onlinePic: "";
  public navTitle: "";
  public pic: "";
  public ticketList = [];
  public isLogin;
  constructor(public titleServers: Title, public shareService: WeChatShareService, public renderer: Renderer2, public routerInfo: ActivatedRoute, public router: Router, public httpService: HttpService, public nativeService: NativeService, public utils: Utils) {
    this.clientW = document.documentElement.clientWidth;
    this.myStyle2 = {
      "max-height": this.clientW / 2 - 30 + "px"
    }
    this.utils.setLocalStorageItem('hadShare', false);//去掉已分享状态
  }
  ngOnInit() {
    this.routerInfo.queryParams.subscribe(queryParams => {
      this.iswechat = queryParams.iswechat == undefined ? 0 : parseInt(queryParams.iswechat);
      this.logid = queryParams.logid;
      var shopid = queryParams.shopid == undefined ? 0 : queryParams.shopid;
      this.whetherTheFristEnter(shopid);
      this.navTitle = this.utils.getLocalStorageItem('shopInfo').shopname != undefined ? this.utils.getLocalStorageItem('shopInfo').shopname : "爱车爱生活 爱上基利斯";
    });
    // 获取通用券列表
    this.getTicketList();
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
  }
  // 返回
  public backToPage() {
    this.nativeService.backToPage();
  }
  // 初始化菜单
  public initMenuData() {
    if (this.menuList.length <= 0) {
      this.menuType = 0;
    } else if (this.menuList.length > 4 && this.menuList.length < 8) {
      this.menuType = 1;
      this.swiperInit3();
      this.myStyle = {
        "height": this.clientW * 0.22 + "px"
      }
    } else if (this.menuList.length <= 4 || this.menuList.length >= 8) {
      this.menuType = 2;
      this.myStyle = {
        "height": this.clientW / 4 + "px"
      }
    }
  }
  swiperInit3() {
    /* 引入：swiper.js */
    var that = this;
    _require('wap/assets/js/swiper.min.js', function () {
      /* swiper初始化 */
      var menuSwiper = new Swiper('#menuTabs', {
        freeMode: true,
        freeModeSticky: true,
        slidesPerView: 'auto',
      });
    });
  }
  // 判断是否第一次进入店铺
  whetherTheFristEnter(shopid) {
    //扫码进来的
    if (shopid != 0) this.utils.setLocalStorageItem('shopid', shopid);//shopname
    this.shopid = this.utils.getLocalStorageItem("shopid");
    if (this.shopid != undefined && this.shopid != 0) this.initData();
  }
  //选择店铺
  slectShop(info: any) {
    // console.log(info);
    this.utils.setLocalStorageItem('shopInfo', info);
    this.utils.setLocalStorageItem('shopid', info.shopid);
    this.shopid = this.utils.getLocalStorageItem("shopid");
    this.initData();
  }
  initData() {
    if (this.iswechat == 1) {
      this.wechatLog();
    }
    this.showLocalStorageSupported = false;
    this.nativeService.showLoading();
    this.checkOrder();
    this.getHomeData();
    this.getProductData();
  }
  swiperInit() {
    /* 引入：swiper.js */
    var that = this;
    // console.log(that.centerAdvertLength)
    _require('wap/assets/js/swiper.min.js', function () {
      /* swiper初始化 */
      var home_Swiper = new Swiper('#shopAdvert', {
        autoplay: 4000,
        loop: that.shopAdvertLength > 1 ? true : false,
        pagination: '.swiper-pagination',
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        longSwipesRatio: 0.3,
        touchRatio: 1,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
      });
    });
  }
  swiperInit2() {
    var that = this;
    _require('wap/assets/js/swiper.min.js', function () {
      /* swiper初始化 */
      var home_Swiper2 = new Swiper('#centerAdvert', {
        autoplay: 4000,
        loop: that.centerAdvertLength > 1 ? true : false,
        pagination: '.swiper-pagination',
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        longSwipesRatio: 0.3,
        touchRatio: 1,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
      });
    });
  }
  public shareConfig() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.httpService.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        if (result.code == 100 && result.data) {
          var data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.shareService.configWXJs(data);
          this.shareService.getShareDataAndReadyLink("基利斯",
            this.pic,
            this.navTitle, "", location.href).subscribe(
              (success) => {
                //分享bug:分享成功和取消都返回1
                console.log(success);
              });
        }
      });
    }
  }
  // 微信消息
  public wechatLog() {
    let params = {
      'openalias': 'wap.ticket.Ticketmessage.upWxMsgStatus',
      'id': this.logid,
      'plat': 'plat_wap',
      'openno': '1',
    }
    this.httpService.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) { } else { }
    })
  }
  public isWechatWeb() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      console.log('isWechatWeb:true');
      return true;
    } else {
      console.log('isWechatWeb:false');
      return false;
    }
  }

  clearInterval() {
    this.utils.removeLocalStorageItem("checkOrder");
    this.paySuccess = true;
  }
  checkOrder() {
    var that = this;
    var checkOrderDic = this.utils.getLocalStorageItem("checkOrder");
    if (checkOrderDic.orderid > 0) {
      var orderid = checkOrderDic.orderid;
      var orderType = checkOrderDic.orderType;
    } else {
      that.paySuccess = true;
    }
    function countdown() {
      if (that.paySuccess) return;
      setTimeout(countdown, 1000);
      var param = {
        'plat': 'plat_wap',
        'openno': '1',
        'userid': that.utils.getLocalStorageItem("user_info").userid,
        'user_token': that.utils.getLocalStorageItem("user_info").usertoken,
        'openalias': "wap.order.paymall.orderInfo",
        'orderid': orderid
      }
      that.httpService.request({
        method: 'GET',
        url: 'api/index',
        data: param,
      }).then(success => {
        if (success.code == 100 && success.data) {
          if (success.data.status != 2) {
            // 跳支付结果页
            that.paySuccess = true;
            that.utils.removeLocalStorageItem("checkOrder");
            that.router.navigate(['payResults'], { queryParams: { orderid: orderid, orderType: orderType, status: success.data.status } });
          } else {
            //会循环请求
          }
        } else {
          that.paySuccess = true;
          that.utils.removeLocalStorageItem("checkOrder");
        }
      });
    }
    countdown();
  }
  //请求首页数据
  getHomeData() {
    let params = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': 'wap.shop.index.getIndex',
      'shopid': this.utils.getLocalStorageItem('shopid'),
    }
    if (params.shopid.length <= 0) {
      //无痕模式提示
      this.showLocalStorageSupported = true;
      this.nativeService.hideLoading();
      return;
    }
    this.httpService.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.showView = true;
        this.shopInfo = result.data.shopInfo;//店铺信息
        this.utils.setLocalStorageItem('shopInfo', result.data.shopInfo);
        this.shopAdvert = (result.data.shopAdvert != null && result.data.shopAdvert != undefined) ? result.data.shopAdvert : [];
        this.shopAdvertLength = (result.data.shopAdvert != null && result.data.shopAdvert != undefined) ? result.data.shopAdvert.length : 0;
        this.centerAdvert = (result.data.centerAdvert != null && result.data.centerAdvert != undefined) ? result.data.centerAdvert : [];
        this.centerAdvertLength = (result.data.centerAdvert != null && result.data.centerAdvert != undefined) ? result.data.centerAdvert.length : 0;
        this.ticket = result.data.ticket;//店铺抵用券
        this.setmeal = result.data.setmeal;//套餐
        this.server = result.data.server;//服务
        this.activityOrder = result.data.activityOrder;//活动促销
        this.onlinePic = result.data.pic;
        this.swiperInit();
        this.swiperInit2();
        if (result.data.icon != null && result.data.icon.length > 0) {
          this.menuList = result.data.icon;
          this.initMenuData();
        } else {
          this.menuList = [];
        }
        this.pic = (this.shopInfo.pic != undefined && this.shopInfo.pic.length > 0) ? this.shopInfo.pic : "http://m.jilisi.com/static/images/commen/jlslogo.png";
        this.shareConfig();
        var title = this.shopInfo.shopname;
        this.titleServers.setTitle(title);
      } else {
        this.showView = false;
        if (result.msg == "apitoken丢失") {
          this.showLocalStorageSupported = true;
          this.showView = false;
        } else {
          this.showLocalStorageSupported = false;
          this.showView = true;
          this.nativeService.showErrorAlert(result.msg);
        }
      }
      this.nativeService.hideLoading();
    })
  }
  //获取商品
  getProductData() {
    let params = {
      'categoryid': 0,
      'page': 1,
      'limit': 10,
      'order': 0,
      'openalias': "wap.product.productmall.getJlsShopStockList",
      'keyword': "",
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.utils.getLocalStorageItem('shopid'),
      'pchannel': 3,
    }
    var thet = this;
    this.httpService.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        thet.goodsListTotal = result.data.total;
        if (thet.goodsListTotal > 0) {
          for (let i = 0; i < result.data.list.length; i++) {
            result.data.list[i].integralpriceLimit = this.utils.priceLimit(result.data.list[i].integralprice);
            result.data.list[i].hPrice = 0;
            result.data.list[i].price = 0;
            if (result.data.list[i].useDisPrice == 1) {
              result.data.list[i].hPrice = result.data.list[i].sellprice;
              result.data.list[i].price = result.data.list[i].dis_price;
            } else {
              result.data.list[i].price = result.data.list[i].sellprice;
            }
            for (let j = 0; j < result.data.list[i].activitylable.length; j++) {
              // activitytype 促销类型 ： 1 订单促销 2 商品促销
              if (result.data.list[i].activitylable[j].activitytype == 1) {
                switch (result.data.list[i].activitylable[j].tagtype) {
                  case 1://满减
                    result.data.list[i].activitylable[j].type = 5;
                    result.data.list[i].activitylable[j].name = '满减';
                    break;
                  case 2://满折
                    result.data.list[i].activitylable[j].type = 6;
                    result.data.list[i].activitylable[j].name = '满折';
                    break;
                  case 3://满赠
                    result.data.list[i].activitylable[j].type = 7;
                    result.data.list[i].activitylable[j].name = '满赠';
                    break;
                  default:
                    break;
                }
              }
              if (result.data.list[i].activitylable[j].activitytype == 2) {
                switch (result.data.list[i].activitylable[j].tagtype) {
                  case 1://立减
                    result.data.list[i].activitylable[j].type = 1;
                    result.data.list[i].activitylable[j].name = '立减';
                    break;
                  case 2://折扣
                    result.data.list[i].activitylable[j].type = 2;
                    result.data.list[i].activitylable[j].name = '折扣';
                    break;
                  case 3://赠送
                    result.data.list[i].activitylable[j].type = 3;
                    result.data.list[i].activitylable[j].name = '赠送';
                    break;
                  case 4://特价
                    result.data.list[i].activitylable[j].type = 4;
                    result.data.list[i].activitylable[j].name = '特价';
                    break;
                  default:
                    break;
                }
              }
            }
          }
          thet.goodsList = result.data.list;
        } else {
          thet.goodsList = [];
          thet.goodsListTotal = 0;
        }
      } else {
        this.showView = false;
        if (result.msg == "apitoken丢失") {
          this.showLocalStorageSupported = true;
          this.showView = false;
        } else {
          this.showLocalStorageSupported = false;
          this.showView = true;
          this.nativeService.showErrorAlert(result.msg);
        }
      }
      this.nativeService.hideLoading();
    })
  }
  // 顶部广告点击
  topAdClick(item) {
    console.log(item);
    this.clearInterval();
    this.utils.activityJump(item, this.router);
  }
  pushClick() {
    this.showChangeShop = !this.showChangeShop;
    if (this.showChangeShop) {
      this.signal = "show";
      setTimeout(() => {
        this.showChangeShop = false;
        this.signal = "hide";
      }, 5000);
    } else {
      this.signal = "hide";
    }
  }
  //切换店铺
  changeShopClick() {
    this.clearInterval();
    this.router.navigate(['selectShop'], { queryParams: { homePush: true } });
  }
  //拨打电话
  callPhoneClick() {
    window.location.href = "tel:" + this.shopInfo.tel;
  }
  //立即领取
  vouchersClick(item) {
    this.clearInterval();
    // this.router.navigate(['couponRedemptionCentre'], {
    //   queryParams: {}
    // })
    this.router.navigate(['discountCoupon'], {
      queryParams: {
        'shopid': this.shopid
      }
    });
  }
  //更多点击
  moreClcik(index, item) {
    //index 0:套餐 1:服务 2:活动促销
    this.clearInterval();
    switch (index) {
      case 0:
        this.router.navigate(['packageList'], { queryParams: { categoryid: item.categoryid, shopid: this.utils.getLocalStorageItem('shopid') } });
        break;
      case 1:
        this.router.navigate(['serviceList'], { queryParams: { categoryid: item.categoryid, shopid: this.utils.getLocalStorageItem('shopid') } });
        break;
      case 2:
        this.router.navigate(['shopActivity']);
        break;
      case 3:
        this.router.navigate(['product'], { queryParams: { shopid: this.utils.getLocalStorageItem('shopid'), categoryid: 0 } });
        break;
      default:
        break;
    }
  }
  //活动促销商品点击
  itemClick(index, item) {
    //index 0:套餐 1:服务 2:活动促销
    this.clearInterval();
    switch (index) {
      case 0:
        this.router.navigate(['packageDetail'], { queryParams: { setmealid: item.id, shopid: this.utils.getLocalStorageItem('shopid') } });
        break;
      case 1:
        this.router.navigate(['serviceDetail'], { queryParams: { serviceid: item.id, shopid: this.utils.getLocalStorageItem('shopid') } });
        break;
      case 2:
        this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.stockid, shopid: this.utils.getLocalStorageItem('shopid') } });
        break;
      default:
        break;
    }
  }
  //菜单点击
  menuClick(item) {
    console.log(item);
    this.clearInterval();
    this.utils.activityJump(item, this.router);
  }
  // 进入详情
  public gotoGoodsDetails(item) {
    this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.stockid, shopid: this.utils.getLocalStorageItem('shopid') } });
  }
  //门店相册
  shopPicClick() {
    this.router.navigate(['shopPhotos'], { queryParams: { shopid: this.utils.getLocalStorageItem('shopid') } });
  }
  // 门店导航
  addressClick() {
    //直接调起腾讯地图
    var txMap = this.bMapTransQQMap(this.shopInfo.blng, this.shopInfo.blat);
    var latitude = txMap.lat;
    var longitude = txMap.lng;
    var address = this.shopInfo.addressdetail;
    var shopName = this.shopInfo.shopname;
    window.location.href = "https://3gimg.qq.com/lightmap/v1/marker/index.html?type=0&marker=coord%3A" + latitude + "%2C" + longitude + "%3Btitle%3A" + shopName + "%3Baddr%3A" + address + "%2F12&referer=jilisi&key=YJ4BZ-DHGWR-JEOWH-WQV63-6Z356-QLBTX";
  }
  //百度经纬度转腾讯地图经纬度
  public bMapTransQQMap(lng, lat) {
    let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
    let x = lng - 0.0065;
    let y = lat - 0.006;
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
    let lngs = z * Math.cos(theta);
    let lats = z * Math.sin(theta);
    return {
      lng: lngs,
      lat: lats
    }
  }

  /* == lq === */
  // 获取通用券列表
  public getTicketList() {
    let params = {
      'shopid': this.utils.getLocalStorageItem('shopid'),//门店id
      'page': '1',//页数
      'limit': '20',//	条数
      'openalias': 'wap.saas.Ticket.getList',
      'plat': 'plat_wap',
      'openno': '1',
      'pchannel': 3,
    }
    var that = this;
    this.httpService.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        console.log(result.data);
        that.ticketList = result.data.list;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
      this.nativeService.hideLoading();
    })
  }
  // 通用券详情
  public goTicketDetail(item) {
    this.router.navigate(['ticketDetail'], { queryParams: { id: item.id, shopid: item.shopid } });
  }
  // 通用券立即购买
  public goToOrder(tid) {
    if (!this.isLogin) {
      this.router.navigate(['login']);
      return;
    }
    this.router.navigate(['ticketOrder'], { queryParams: {id: tid,sid: this.shopid}})
  }
  // 通用券
  public goGeneralTicket() {
    this.router.navigate(['ticketList'], { queryParams: { type: 'md',sid:  this.shopid} });
  }

}



