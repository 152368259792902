//商品购物车
import { NgModule } from '@angular/core';
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GoodsCartComponent } from '../goods-cart/goods-cart.component';
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'goodsCart', component: GoodsCartComponent }]),
        DataShowModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        GoodsCartComponent
    ],
    providers: [],
})
export class GoodsCartModule {
}