import { Component, OnInit, NgModule, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from "../../providers/HttpService";
import { NativeService } from '../../providers/NativeService';
@Component({
  selector: 'app-address-selection',
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.css','../../../assets/css/animate.min.css']
})
export class AddressSelectionComponent implements OnInit {
  @Input() isShow: boolean;
  @Input() showAnimatin: boolean;
  @Input() hidn;
  @Output() child = new EventEmitter<any>();
  constructor(public http: HttpService , public nativeService : NativeService) {
    this.isLoad = true;
    this.showAnimatin = true;
    this.getAddressList(0);
  }

  ngOnInit() {
   
  }
  public mSelectTabItem = 1;
  public mProvinceList = [];
  public mProvinceName: string = "请选择";
  public mCityName: string = "请选择";
  public mDistrictName: string = "请选择";
  public isProvince = false;//显示省
  public isCity = false;//显示市
  public isDistrict = false;//显示区
  public msg = "我收到了";
  public isReload = false;
  public isLoad = false;
  public codeC: any;
  public codeD: any;
  public provinceCode = 0 ;
  public cityCode = 0 ;
  public districtCode = 0 ;
  public mAddressData = {
    'isShow': false,
    'type': 0,
    address: {
      'address': "",
      'provinceCode':0 ,
      'cityCode':0 ,
      'districtCode': 0
    }
  }
  public Confirm() {
    // console.log(this.isShow);
    if (this.mProvinceName == '请选择') {
      this.nativeService.showWarningAlert("请选择省份");
      return;
    } else {
      // || this.mProvinceName == '香港特别行政区' || this.mProvinceName == '澳门特别行政区'
      if (this.mProvinceName == '台湾省') {
        this.mAddressData.address.address = this.mProvinceName + "";
      } else {
        if (this.mCityName == '请选择') {
          this.nativeService.showWarningAlert("请选择城市");
          return;
        } else {
          if (this.mProvinceName == '香港特别行政区' || this.mProvinceName == '澳门特别行政区') {
            this.mAddressData.address.address = this.mProvinceName + this.mCityName + "";
          } else {
            if (this.mDistrictName == '请选择') {
              this.nativeService.showWarningAlert("请选择区");
              return;
            }
            this.mAddressData.address.address = this.mProvinceName + this.mCityName + this.mDistrictName + "";
          }
        }
      }
    }
    const that = this;
    that.showAnimatin = false;
    setTimeout(function () {
      that.isShow = false;
      that.mAddressData.type = 1;
      that.mAddressData.isShow = that.isShow;
      that.mAddressData.address.cityCode = that.cityCode ;
      that.mAddressData.address.provinceCode = that.provinceCode ;
      that.mAddressData.address.districtCode = that.districtCode ;
      that.child.emit(that.mAddressData);
    }, 800);
    // this.hidn(this.isShow , this.msg);
  }
  public Cancel() {
    const that = this;
    that.showAnimatin = false;
    setTimeout(function () {
      that.isShow = false;
      that.mAddressData.isShow = that.isShow  ;
      that.mAddressData.type = 0 ;
      that.child.emit(that.mAddressData);
    }, 800);
  }
  // 省选项卡
  public ProvinceTab() {
    if (this.isLoad) {
      return;
    }
    this.mSelectTabItem = 1;
    this.isCity = false;
    this.isDistrict = false;
    this.isProvince = true;
  }
  // 市选项卡
  public CityTab() {
    if (this.isLoad) {
      return;
    }
    this.mSelectTabItem = 2;
    this.isCity = true;
    this.isDistrict = false;
    this.isProvince = false;
  }
  // 区选项卡
  public DistrictTab() {
    if (this.isLoad) {
      return;
    }
    this.mSelectTabItem = 3;
    this.isCity = false;
    this.isDistrict = true;
    this.isProvince = false;
  }

  public getAddressList(pcode: any) {
    // {
    //   "id": 37,
    //   "code": 130000,
    //   "pcode": 0,
    //   "name": "河北省",
    //   "depth": 1
    // }
    let params = {
      'plat': 'plat_wap',
      'openno': '1',
      'pcode': pcode,
      "openalias": "wap.area.area.getList"
    }
    let that = this;
    that.http.request({
      method: 'GET',
      url: 'api/index',
      data: params,
    }).then(result => {
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        that.isLoad = false;
        this.isProvince = true;
        that.mProvinceList = result.data.list;
        // this.isShowLoading = false;
      } else {
        that.isLoad = false;
        that.isReload = true;
        // this.nativeService.showErrorAlert(result.msg);
        // this.isShowLoading = false;
      }
    });
  }

  public ProvinceSelect(name, code) {
    if (this.isLoad) {
      return;
    }
    this.isProvince = false;
    this.isDistrict = false;
    this.mSelectTabItem = 2;
    this.codeC = code;
    this.provinceCode = code ;
    this.cityCode = 0 ;
    this.districtCode = 0 ;
    if (this.mProvinceName != name) {
      this.mProvinceName = name;
      this.CityList = [];
      this.DistrictList = [];
      this.mCityName = "请选择";
      this.mDistrictName = "请选择";
      this.isLoad = true;
      this.getCityAddressList(code);
    } else {
      this.isCity = true;
    }
  }

  public CityList = [];
  public getCityAddressList(pcode) {
    // {
    //   "id": 38,
    //   "code": 130100,
    //   "pcode": 130000,
    //   "name": "石家庄市",
    //   "depth": 2
    // },
    let params = {
      'plat': 'plat_wap',
      'openno': '1',
      'pcode': pcode,
      "openalias": "wap.area.area.getList"
    }
    let that = this;
    that.http.request({
      method: 'GET',
      url: 'api/index',
      data: params,
    }).then(result => {
      that.isLoad = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        that.isCity = true;
        that.CityList = result.data.list;
        if (that.CityList.length <= 0) {
          that.mCityName = "完成";
        }
      } else {
        that.isReload = true;
      }
    });
  }
  public CitySelect(name, code) {
    if (this.isLoad) {
      return;
    }
    this.codeD = code;
    this.isCity = false;
    this.isProvince = false;
    this.mSelectTabItem = 3;
    this.cityCode = code ;
    this.districtCode = 0 ;
    if (this.mCityName != name) {
      this.mCityName = name;
      this.mDistrictName = "请选择";
      this.DistrictList = [];
      this.isLoad = true;
      this.getDistrictAddressList(code);
    } else {
      this.isDistrict = true;
    }
  }
  public DistrictList = [];
  public getDistrictAddressList(pcode) {
    // {
    //   "id": 38,
    //   "code": 130100,
    //   "pcode": 130000,
    //   "name": "石家庄市",
    //   "depth": 2
    // },
    let params = {
      'plat': 'plat_wap',
      'openno': '1',
      'pcode': pcode,
      "openalias": "wap.area.area.getList"
    }
    let that = this;
    that.http.request({
      method: 'GET',
      url: 'api/index',
      data: params,
    }).then(result => {
      this.isLoad = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        that.isDistrict = true;
        that.DistrictList = result.data.list;
        if (that.DistrictList.length <= 0) {
          this.mDistrictName = "完成";
        }
        // this.isShowLoading = false;
      } else {
        // this.nativeService.showErrorAlert(result.msg);
        // this.isShowLoading = false;
        this.isReload = true;
      }
    });
  }

  public DistrictSelect(name,code) {
    if (this.isLoad) {
      return;
    }
    this.mDistrictName = name;
    this.districtCode = code ;
  }

  public Reload() {
    this.isReload = false;
    this.isLoad = true;
    this.isProvince = false;
    this.isCity = false;
    this.isDistrict = false;
    switch (this.mSelectTabItem) {
      case 1:
        this.getAddressList(0);
        break;
      case 2:
        this.getCityAddressList(this.codeC);
        break;
      case 3:
        this.getDistrictAddressList(this.codeD);
        break;
      default:
        break;
    }
  }
}
