import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';//不加上这个module可以ng的一些指令不能识别
import { RouterModule } from '@angular/router';
import { FixedNavModule } from '../../component/fixed-nav/fixed-nav.module';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../../component/footer-refresh/footer-refresh.module";//加载和刷新
import { DataShowModule } from "../../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { CashAccountComponent } from './cash-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GraphicCheckModule } from '../../component/graphic-check/graphic-check.module';
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'cash-account', component: CashAccountComponent}]),
        FixedNavModule,
        InfiniteScrollModule,
        FooterRefreshModule,
        DataShowModule,
        LazyLoadImageModule,
        FormsModule,
        ReactiveFormsModule,
        GraphicCheckModule
    ],
    exports: [],
    declarations: [
        CashAccountComponent
    ],
    providers: [],
})
export class CashAccountModule {}