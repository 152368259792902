//门店确认订单
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShopOrderConfirmComponent } from "../shop-order-confirm/shop-order-confirm.component"
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'ShopOrderConfirm', component: ShopOrderConfirmComponent }]),
        LazyLoadImageModule,
        FormsModule,
        DataShowModule
    ],
    exports: [],
    declarations: [
        ShopOrderConfirmComponent
    ],
    providers: [],
})
export class ShopOrderConfirmModule { }