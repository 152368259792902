import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-withdraw-apply',
  templateUrl: './withdraw-apply.component.html',
  styleUrls: ['./withdraw-apply.component.css']
})
export class WithdrawApplyComponent implements OnInit {

  public isPayPwd = false;
  public payPassword = '';
  public mBindCardInfo: any;
  public accountno = '';//提现账号
  public accountname = '';//账号名称
  public way = '银行卡';//提现账号
  public amount: any;
  public applyPrice: any;
  public configInfo;
  public serverfee;
  public minamount;
  public maxamount;
  public dzAmount = '0';
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.getBindCard();
    this.cashConfigInfo();
  }

  public cashConfigInfo() {
    let params = {
      'type': '2',
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.balance.Cash.getConfigInfo',
      'plat': 'plat_wap',
      'openno': '1'
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        // console.log(JSON.stringify(result));
        this.configInfo = result.data;
        if (this.configInfo) {
          this.minamount = parseFloat(this.configInfo.minamount).toFixed(2);
          this.maxamount = parseFloat(this.configInfo.maxamount).toFixed(2);
          this.serverfee = parseFloat(this.configInfo.serverfee).toFixed(2);
        } else {
          this.nativeService.showWarningAlert('获取配置失败')
        }
        // if (result.data.code) {
        //   this.nativeService.showSuccessAlert('发送成功');
      } else {
        if(result.code == 66 || result.code == 67){
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }
  public getBindCard() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.balance.Bank.getInfo',
      'plat': 'plat_wap',
      'openno': '1'
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        console.log(JSON.stringify(result));
        // if (result.data.code) {
        //   this.nativeService.showSuccessAlert('发送成功');
        if (!result.data.accountno) {
          // console.log("未绑定卡");
        } else {
          this.mBindCardInfo = result.data;
          // result.data.bankname ;
          this.accountno = this.replacepos(result.data.accountno, 4, result.data.accountno.length - 4, "******");;
          this.accountname = this.replacepos(result.data.accountname, 1, 2, "*");
          // console.log("已绑定卡");
        }
      } else {
        if(result.code == 66 || result.code == 67){
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }

  public replacepos(text, start, stop, replacetext) {
    let mystr = text.substring(0, start) + replacetext + text.substring(stop, text.length);
    return mystr;
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

  public commitClick() {
    if (this.amount == undefined || this.amount.length <= 0) {
      this.nativeService.showWarningAlert('请输入金额');
      return;
    }
    let amountStr = (this.amount + '');
    var regA = amountStr.split('.').join('');
    if (!this.checknumber(regA)) {
      console.log('.................');
      this.nativeService.showWarningAlert('请输入正确金额1');
      return;
    }
    if (this.amount.length == 1) {
      if (amountStr.indexOf('.') != -1) {
        this.nativeService.showWarningAlert('请输入正确金额2');
        return;
      }
    } else {
      let amountSubStart = amountStr.substring(0, 1);
      if (amountSubStart.indexOf('.') != -1) {
        this.nativeService.showWarningAlert('请输入正确金额3');
        return;
      }
      let amountSub = amountStr.substring(amountStr.length - 1, amountStr.length);
      console.log(amountSub);
      if (amountSub.indexOf('.') != -1) {
        this.nativeService.showWarningAlert('请输入正确金额4');
        return;
      }
    }
    if (!(this.normalize(this.amount))) {
      this.nativeService.showWarningAlert('请输入正确金额5');
      return;
    }
    let maxamount = parseFloat(this.maxamount).toFixed(2)
    let minamount = parseFloat(this.minamount).toFixed(2);
    let amount = parseFloat(this.amount).toFixed(2);
    this.applyPrice = amount;
    if (amount < minamount) {
      this.nativeService.showWarningAlert('请输入金额不能小于' + minamount);
      return;
    }
    if (amount > maxamount) {
      this.nativeService.showWarningAlert('请输入金额不能大于' + maxamount);
      return;
    }
    this.isPayPwd = true;
  }
  public checknumber(String) {
    // var reg = /^[0-9]+.?[0-9]*$/
    let reg = /^[0-9]+$/;
    if (reg.test(String)) {
      return true
    }
    return false
  }
  public cancel() {
    this.isPayPwd = false;
  }
  public confirm() {
    if(this.payPassword==undefined||this.payPassword.length<6||!this.checknumber(this.payPassword)){
      this.nativeService.showWarningAlert('请输入六位数字密码');
      return ;
    }
    this.isPayPwd = false;
    this.nativeService.showLoading();
    // console.log('password:' + this.payPassword);
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wechat.balance.Cash.apply',
      'plat': 'plat_wap',
      'openno': '1',
      'cachamount': this.applyPrice,
      'passwd': this.payPassword
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(JSON.stringify(result));
        if(result.data.ret){
          this.nativeService.showSuccessAlert('提现成功');
          this.backToPage();
        }else{
          this.nativeService.showWarningAlert(result.data.msg);
        }
      } else {
        if(result.code == 66 || result.code == 67){
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }

  public goToWithdrawalRecrod() {
    this.router.navigate(['w-record'], { queryParams: {} });
  }

  public checkAmount(event) {
    // let amountStr = event.toString();
    if (event == undefined || event.length == 0) {
      console.log('输入为空' + 'event:' + event);
      this.dzAmount = '0';
      return;
    }
    if (event.length == 1) {
      if (event.indexOf('.') != -1) {
        console.log('开头不能为.' + event);
        this.dzAmount = '0';
        return;
      }
    } else {
      let amountSubStart = event.substring(0, 1);
      if (amountSubStart.indexOf('.') != -1) {
        console.log('开头不能为.' + event);
        this.dzAmount = '0';
        return;
      }
      let amountSub = event.substring(event.length - 1, event.length);
      console.log(amountSub);
      if (amountSub.indexOf('.') != -1) {
        console.log('结束不能为.' + event);
        this.dzAmount = '0';
        return;
      }
    }

    let amountStr = (event + '');
    var regA = amountStr.split('.').join('');
    if (!this.checknumber(regA)) {
      console.log('请输入纯数字..' + event);
      this.dzAmount = '0';
      return;
    }
    if (this.normalize(event)) {
      let amountFl = parseFloat(event).toFixed(2);
      // console.log(amountFl);
      if (this.serverfee != undefined) {
        if (parseFloat(amountFl) >= parseFloat(this.serverfee)) {
          this.dzAmount = (parseFloat(amountFl) - this.serverfee).toFixed(2);
        } else {
          this.dzAmount = '0';
        }
      }
    } else {
      // console.log('格式不对');
      this.dzAmount = '0';
    }
  }

  public normalize(event) {
    let amountF = parseFloat(event + '');
    if (isNaN(amountF)) {
      return false;
    }
    return true;
  }
}
