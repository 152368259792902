import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OrderPayComponent } from "../order-pay/order-pay.component"
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'orderPay', component: OrderPayComponent }]),
        LazyLoadImageModule,
        FormsModule,//input [(ngModel)]需要导入这个module
    ],
    exports: [],
    declarations: [
        OrderPayComponent
    ],
    providers: [],
})
export class OrderPayModule { }