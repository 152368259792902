import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { _require } from '../providers/require_lib';
import { ServiceDetailComponent } from "../service-detail/service-detail.component";
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'serviceDetail', component: ServiceDetailComponent }]),
        DataShowModule
    ],
    exports: [],
    declarations: [
        ServiceDetailComponent,
    ],
    providers: [],
})
export class ServiceDetailModule { }