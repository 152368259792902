import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShopAreaSelectComponent } from "../shop-area-select/shop-area-select.component";
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'shopAreaSelect', component: ShopAreaSelectComponent }]),
    ],
    exports: [],
    declarations: [
        ShopAreaSelectComponent
    ],
    providers: [],
})
export class ShopAreaSelectModule { }