import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { HttpService } from './../providers/HttpService';
import { Utils } from './../providers/Utils';
@Component({
  selector: 'app-mine-address',
  templateUrl: './mine-address.component.html',
  styleUrls: ['./mine-address.component.css']
})
export class MineAddressComponent implements OnInit {
  public addressList = [];
  public selctAddress = false;
  public isNoData = false ;
  constructor(public routeInfo: ActivatedRoute, public router: Router, public nativeService: NativeService, public http: HttpService, public utils: Utils) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {

    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.selctAddress = queryParams.selctAddress == undefined ? false : queryParams.selctAddress;
    });
    // this.addressList = ["", "", "", "", "", "", "", ""]
    this.getAddressList();
  }
  // 新建收货地址
  public newAddress() {
    // console.log("新建收货地址");
    this.router.navigate(['addAddress'], {
      queryParams: {
        'address': "{}",
        'type': 1,
        'title': '新建地址'
      }
    });
  }

  // 编辑收货地址
  public editAddress(list) {
    // console.log("编辑收货地址");
    this.router.navigate(['addAddress'], {
      queryParams: {
        'address': JSON.stringify(list),
        'type': 2,
        'title': '编辑地址'
      }
    });
  }

  public getAddressList() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem('user_token'),
      'openalias': "wap.member.address.getList",
      'plat': 'plat_wap',
      'openno': '1',
    }

    this.http.request({
      method: "GET",
      url: 'api/index',
      data: params,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        this.addressList = result.data.list;
        if(this.addressList==undefined||this.addressList.length==0){
          this.isNoData = true ;
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  selctedAddress(item) {
    if (this.selctAddress) {
      this.utils.sessionStorageSetItem("chooceAddress", item);
      this.nativeService.backToPage();
    }
  }
}
