import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.css', '../../assets/css/animate.min.css']
})
//商品列表
export class GoodsComponent implements OnInit {
  public allSever: string = 'downoff'; // downoff downon upoff upon
  public priceStatus: string = '0'; // 1 从低到高 2 从高到低
  public screenType: any = ''; // 0全部 1价格 2销量
  public infoList: Array<any> = [];
  public infoPage: number = 1;
  public infoStatus: boolean = true;
  public showScreenBtn: boolean = false;
  public typeList: Array<any> = [];
  public typeIndex: number | String = 0;
  public typeNum: number = 2;//1 块状 2条状
  constructor(
    public nativeService: NativeService,
    public utils: Utils,
    public http: HttpService,
    public routeInfo: ActivatedRoute,
    public router: Router
  ) { }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(query => {
      this.typeIndex = query.categoryid ? query.categoryid : 0;
    })
    // 获取列表
    this.getList();
    // 获取类别
    this.getTypeList();
  }

  // 返回
  public backToPage() {
    this.nativeService.backToPage();
  }
  // 搜索
  public gotoSearch() {
    console.log(123)
  }
  // 点击全部商品
  public changeType() {
    if (this.screenType != 0) {
      this.allSever = 'upon';
    } else {
      this.allSever = this.allSever == 'downon' ? 'upon' : 'downon';
    }
    this.screenType = 0;
    this.priceStatus = '0'; // 1 从低到高 2 从高到低
    this.showScreenBtn = !this.showScreenBtn;
  }
  // 点击价格
  public changePrice() {
    if (this.screenType != 2) {
      this.priceStatus = '1';
    } else {
      this.priceStatus = this.priceStatus == '1' ? '2' : '1';
    }
    this.screenType = 2;
    this.allSever = 'downoff';
    this.showScreenBtn = false;
    this.typeIndex = 0;
    // 更新列表
    this.toSearch();
  }
  // 点击综合
  public changeZH() {
    this.screenType = 1;
    this.allSever = 'downoff'; // downoff downon upoff upon
    this.priceStatus = '0'; // 1 从低到高 2 从高到低
    this.showScreenBtn = false;
    this.typeIndex = 0;
    // 更新列表
    this.toSearch();
  }
  // 点击距离
  public changeSales() {
    this.screenType = 3;
    this.allSever = 'downoff'; // downoff downon upoff upon
    this.priceStatus = '0'; // 1 从低到高 2 从高到低
    this.showScreenBtn = false;
    this.typeIndex = 0;
    // 更新列表
    this.toSearch();
  }
  // 隐藏
  public closeScreenBtn() {
    this.allSever = 'downon';
    this.showScreenBtn = false;
  }
  // 修改类别
  public changeTypeIndex(index) {
    this.typeIndex = index;
    // 更新列表
    this.toSearch();
    // 隐藏
    this.closeScreenBtn();
  }
  // 商品详情
  public goDetail(sid) {
    this.router.navigate(['goodsDetail'], { queryParams: { stockid: sid } });
  }
  // 获取列表
  public getList() {
    let params = {
      'openalias': 'wap.saas.productmall.getJlsShopStockList',
      'plat': 'plat_wap',
      'openno': '1',
      'categoryid': this.typeIndex,
      'lng': '',
      'lat': '',
      'page': this.infoPage,
      'limit': '10',
      'order': (this.screenType == 1 || this.screenType == 2) ? this.priceStatus : this.screenType,
      'keyword': ''
    }
    var that = this;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        let newArr = result.data.list;
        let oldArr = that.infoList;
        if (newArr && newArr.length > 0) {
          if (that.infoPage == 1) {
            that.infoStatus = true;
            that.infoList = newArr;
          } else {
            that.infoStatus = true;
            that.infoList = oldArr.concat(newArr);
          }
        } else {
          that.infoStatus = false;
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 获取类别
  public getTypeList() {
    let params = {
      'plat': 'plat_wap',
      'openno': '1',
      'pid': 0,
      "openalias": "wap.product.categorymall.mallList"
    }
    var that = this;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        this.typeList = result.data;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 更新列表
  public toSearch() {
    this.infoList = [];
    this.infoPage = 1;
    this.infoStatus = true;
    // 获取列表
    this.getList();
  }
  // 列表上啦加载更多
  public listMore() {
    if (this.infoStatus) {
      this.infoPage += 1;
      this.getList();
    }
  }

  // 切换格式
  public changeTypeNum() {
    this.typeNum = (this.typeNum == 1) ? 2 : 1;
    // 更新列表
    this.toSearch();
  }
}
