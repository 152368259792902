//门店确认订单
import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { Utils } from "../providers/Utils"
import { HttpService } from "../providers/HttpService"
import { _require } from '../providers/require_lib';
declare let DialogByZ: any;
@Component({
  selector: 'app-shop-order-confirm',
  templateUrl: './shop-order-confirm.component.html',
  styleUrls: ['./shop-order-confirm.component.css', '../../assets/css/animate.min.css']
})
export class ShopOrderConfirmComponent implements OnInit {
  @ViewChild('shopAddressHeight') elementView: ElementRef;
  viewHeight: number;
  public shopAddressHeight: number;
  public shopid = 0;
  public shopLongAddress = "";
  public productlist = [];
  public productlistWL = [];
  public amountpay = 0;//订单支付总额
  public amountpayZT = 0;//订单支付总额--自提
  public amountpayWL = 0;//订单支付总额--物流
  public amountdiscount = 0;
  public numtotal = 0;//商品总数
  public numtotalZT = 0;//自提商品总数
  public numtotalWL = 0;//物流商品总数
  public remark = '';//自提留言
  public remarkWL = '';//物流留言
  public pidStr = '';
  public pidStrKey = '';
  public orderid = 0;
  public showView = false;
  public productIdList = '';
  public productIdListWL = '';
  public ticketlist;//优惠券列表
  public ticketlistZT;//优惠券列表--自提
  public ticketlistWL;//优惠券列表--物流
  public amountticket;//券优惠金额
  public amountticketZT;//券优惠金额--自提
  public amountticketWL;//券优惠金额--物流
  public totalDiscount;//总优惠金额
  public ticketList = [];
  public isNoData = false;
  public isSelectAddress = false;//是否已经选择了地址
  public mineAddress;//我的地址数据
  public mineAddressText;//我的地址文本
  public consignee; //收件人
  public mobile; //收件人
  public orderdiscountZT: 0;
  // 是否切换物流配送方式isSHowSwitchDialog
  public isSHowSwitchDialog = false;
  public productlistId = "";
  public modeOfDistributionArr = [
    { 'name': '物流配送发货', 'type': 2, 'isSelect': false },
    { 'name': '到店自提/服务', 'type': 1, 'isSelect': false }
  ];
  public discountsOrderArr = [];//订单优惠
  public isShowOpDialog = false;
  public addressid = '';
  public stockids = '';
  public amountexpressWl = '';//运费
  public carListData: any = {};
  public integralamount = 0;
  // 积分抵扣开关
  public scoreShow = false;
  public status = true;
  public isShow = false;
  public showAnimatin = false;
  public integralPrice: any = 0;
  constructor(public httpService: HttpService, public utils: Utils, public router: Router, public nativeService: NativeService, public routeInfo: ActivatedRoute, public el: ElementRef) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    //接收参数
    let sids = '';
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid == undefined ? 0 : queryParams.shopid;
      // this.shopLongAddress = queryParams.shopLongAddress == undefined ? "" : queryParams.shopLongAddress;
      sids = queryParams.pidStr == undefined ? "" : queryParams.pidStr;
    });
    // console.log(this.shopid + "--" + this.shopLongAddress + "--" + this.pidStr);
    this.getShopInfo();
    let pids = this.utils.getLocalStorageItem('pidStr');
    this.pidStr = pids == undefined ? "" : pids;
    this.stockids = pids == undefined ? "" : pids;
    this.isSelectAddress = this.utils.sessionStorageGetItem('chooceAddress') == null ? false : true;
    if (this.isSelectAddress) {
      this.mineAddress = this.utils.sessionStorageGetItem('chooceAddress');
      this.mineAddressText = this.mineAddress.province + this.mineAddress.cityname + this.mineAddress.areaname + this.mineAddress.address;
      this.mobile = this.mineAddress.mobile;
      this.consignee = this.mineAddress.consignee;
      this.addressid = this.mineAddress.id;
      this.nativeService.showLoading();
      this.getListData();
    } else {
      this.requestAddressData();
    }
    // this.setIntegralStatus();
  }
  getShopInfo() {
    let param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': 'wap.shop.index.getIndex',
      'shopid': this.shopid,
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        this.shopLongAddress = success.data.shopInfo.addressdetail;
      }
    });
  }
  requestAddressData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.member.address.getList",
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        if (success.data.list.length > 0) {
          this.isSelectAddress = true;
          this.utils.sessionStorageSetItem('chooceAddress', success.data.list[0])
          this.mineAddress = this.utils.sessionStorageGetItem('chooceAddress');
          this.mineAddressText = this.mineAddress.province + this.mineAddress.cityname + this.mineAddress.areaname + this.mineAddress.address;
          this.mobile = this.mineAddress.mobile;
          this.consignee = this.mineAddress.consignee;
          this.addressid = this.mineAddress.id;
        } else {
          that.mineAddressText = '';
        }
        that.getListData();
      } else {
        that.nativeService.showErrorAlert(success.msg);
        that.nativeService.hideLoading();
      }
    });
  }
  getListData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.cart.shopcart.getCart",
      'shopid': that.shopid,
      'productids': that.pidStr,
      'userAddressId': this.addressid
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      that.nativeService.hideLoading();
      if (success.code == 100 && success.data) {
        that.carListData = success.data;
        if(success.data.integralmax>0){
          that.scoreShow = true;
          that.status = success.data.integralStatus == 1 ? true : false;
        }else{
          that.scoreShow = false;
          that.status = false;
        }
        that.integralamount = success.data.integralamount;
        that.isNoData = false;
        // console.log(JSON.stringify(success.data));
        // that.productlist = success.data.productlist;
        var suborder = success.data.suborder;
        that.productlist = [];
        that.productlistWL = [];
        if (suborder != undefined || suborder != null) {
          let length = suborder.length;
          if (suborder[0].tip == "以下商品需到店自提") {
            that.productlist = suborder[0].productlist;
            that.numtotalZT = suborder[0].numtotal;
            that.amountpayZT = suborder[0].amountpay;
            that.amountticketZT = suborder[0].amountticket;
            that.ticketlistZT = suborder[0].ticketlist;
            that.orderdiscountZT = suborder[0].orderdiscount;
            // numtotal, orderdislist, productlist
            that.discountsOrderArr = that.dataFiltration(suborder[0].numtotal, suborder[0].orderdislist, suborder[0].productlist);
            // that.productlistId = 
            // console.log(JSON.stringify(suborder[0].productlist))
            for (let i = 0; i < suborder[0].productlist.length; i++) {
              that.productlistId += suborder[0].productlist[i].pid + ',';
              that.productlistId += suborder[0].productlist[i].ptype;
              that.pidStrKey += suborder[0].productlist[i].key;
              if (i != (suborder[0].productlist.length - 1)) {
                that.productlistId += '/';
                that.pidStrKey += ',';
              }
            }
          } else {
            that.productlistWL = suborder[0].productlist;
            that.numtotalWL = suborder[0].numtotal;
            that.amountpayWL = suborder[0].amountpay;
            that.amountticketWL = suborder[0].amountticket;
            that.ticketlistWL = suborder[0].ticketlist;
            that.amountexpressWl = suborder[0].amountexpress;
          }
          if (length > 1) {
            if (suborder[1].tip == "以下商品需到店自提") {
              that.productlist = suborder[1].productlist;
              that.numtotalZT = suborder[1].numtotal;
              that.amountpayZT = suborder[1].amountpay;
              that.amountticketZT = suborder[1].amountticket;
              that.ticketlistZT = suborder[1].ticketlist;
              that.orderdiscountZT = suborder[1].orderdiscount;
              that.discountsOrderArr = that.dataFiltration(suborder[1].numtotal, suborder[1].orderdislist, suborder[1].productlist);
              for (let i = 0; i < suborder[1].productlist.length; i++) {
                that.productlistId += suborder[1].productlist[i].pid + ',';
                that.productlistId += suborder[1].productlist[i].ptype;
                that.pidStrKey += suborder[0].productlist[i].key;
                if (i != (suborder[0].productlist.length - 1)) {
                  that.productlistId += '/';
                  that.pidStrKey += ',';
                }
              }
            } else {
              that.productlistWL = suborder[1].productlist;
              that.numtotalWL = suborder[1].numtotal;
              that.amountpayWL = suborder[1].amountpay;
              that.amountticketWL = suborder[1].amountticket;
              that.ticketlistWL = suborder[1].ticketlist;
              that.amountexpressWl = suborder[1].amountexpress;
            }
          }
        }

        that.amountpay = success.data.amountpay;
        that.numtotal = success.data.numtotal;
        that.amountticket = success.data.amountticket;
        var orderdiscount = parseFloat(success.data.orderdiscount);
        var amountticket = parseFloat(success.data.amountticket);
        that.totalDiscount = (orderdiscount + amountticket).toFixed(2);
        that.amountdiscount = parseInt(that.totalDiscount);
        that.showView = true;
        for (let index = 0; index < that.productlist.length; index++) {
          that.productIdList += that.productlist[index].pid + "," + that.productlist[index].ptype;
          if (index < (that.productlist.length - 1)) {
            that.productIdList += "/"
          }
        }
        for (let index = 0; index < that.productlistWL.length; index++) {
          that.productIdListWL += that.productlistWL[index].pid + "," + that.productlistWL[index].ptype;
          if (index < (that.productlistWL.length - 1)) {
            that.productIdListWL += "/"
          }
        }
        // console.log('ticketlistZT1:'+JSON.stringify(that.ticketlistZT));
        // that.ticketlist = success.data.ticketlist;
        // if (that.ticketlist != undefined) {
        //   for (let i = 0; i < that.ticketlist.length; i++) {
        //     var par = {
        //       "userticketid": 0,
        //       "type": 0
        //     }
        //     par.userticketid = that.ticketlist[i].userticketid;
        //     par.type = that.ticketlist[i].tickettype;
        //     that.ticketList.push(par);
        //   }
        //   for (let i = 0; i < that.ticketlist.length; i++) {
        //     if (that.ticketlist[i].ret == false) {
        //       that.nativeService.showWarningAlert('亲，您的优惠券信息有变更(' + that.ticketlist[i].ticketname + ',' + that.ticketlist[i].msg + ')');
        //       return;
        //     }
        //   }
        // }
        if (that.productlistWL.length == 0 && that.productlist.length == 0) {
          that.isNoData = true;
        }
      } else {
        if (success.msg == "产品不存在") {
          that.isNoData = true;
        }
      }

    });
  }
  // 筛选数据
  public dataFiltration(numtotal, orderdislist, productlist) {
    var carGoodsList = [];
    var pkeyList = [];
    //满减的商品
    if (numtotal > 0 && orderdislist.length > 0) {
      for (var i = 0; i < orderdislist.length; i++) {
        var orderdisItem = orderdislist[i];
        orderdisItem.showDisstatus = true;
        orderdisItem.list = [];
        for (var j = 0; j < orderdisItem.stock.length; j++) {
          for (var k = 0; k < productlist.length; k++) {
            productlist[k].pkey = productlist[k].key;
            var productlistItem = productlist[k];
            // productlistItem.pkey = productlistItem.key;
            if (productlistItem.pkey == orderdisItem.stock[j]) {
              orderdisItem.list.push(productlistItem);
              pkeyList.push(productlistItem.pkey);//把有满减的商品的kpey放在数组里
            }
          }
        }
        carGoodsList.push(orderdisItem);
      }
    } else {
      carGoodsList = [];
    }
    return carGoodsList;
  }
  commitClick() {
    var that = this;
    if (this.integralamount > 0) {
      var title = "本单将兑换使用" + this.integralamount * 100 + "个积分，请确定是否进行积分兑换";
      DialogByZ.Confirm({
        Title: title, Content: "", BtnL: "取消", BtnR: "确定", FunL: function () {
          //点击左按钮
          DialogByZ.Close();
        }, FunR: function () {
          //点击右按钮
          DialogByZ.Close();
          that.pickOrder();
        }
      })
    } else {
      that.pickOrder();
    }
  }
  pickOrder() {
    var that = this;
    if (that.orderid == 0) {
      var productlist = this.productlist;
      var pidList = [];
      for (var i = 0; i < productlist.length; i++) {
        pidList.push(productlist[i].pkey);
      }
      var pidStr = pidList.join(",");
      var param;
      let orderType = 1;
      if (this.productlistWL == undefined || this.productlistWL.length == 0) {
        param = {
          'plat': 'plat_wap',
          'openno': '1',
          'userid': this.utils.getLocalStorageItem("user_info").userid,
          'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
          'openalias': "wap.order.orderbuilder.shopcreate",
          'shopid': that.shopid,
          'remark': that.remark,
          'stockids': this.stockids,
          'sendtype': '',
          'invoicename': '',
          'invoiceidentify': '',
          'invoicetype': '',
          'invoicedetail': ''
        }
      } else {
        if (!this.isSelectAddress) {
          this.nativeService.showWarningAlert('请先选择配送地址！');
          return;
        }
        param = {
          'plat': 'plat_wap',
          'openno': '1',
          'userid': this.utils.getLocalStorageItem("user_info").userid,
          'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
          'openalias': "wap.order.orderbuilder.shopcreate",
          'shopid': that.shopid,
          'remarkshop': that.remark,
          'remarkplat': that.remarkWL,
          'stockids': this.stockids,
          'addressid': this.addressid,
          'sendtype': '',
          'invoicename': '',
          'invoiceidentify': '',
          'invoicetype': '',
          'invoicedetail': ''
        }
      }
      var that = this;
      if (this.productlistWL == undefined || this.productlistWL.length == 0) {
        orderType = 1;
      } else if (this.productlist == undefined || this.productlist.length == 0) {
        orderType = 0;
      } else {
        orderType = 5;
      }
      // console.log('订单信息:' + JSON.stringify(param));
      this.httpService.request({
        method: 'post',
        url: 'api/index',
        data: param,
      }).then(success => {
        if (success.code == 100 && success.data) {
          var orderid = success.data.orderid;
          this.orderid = orderid;
          if (this.amountpay > 0) {
            this.router.navigate(['orderPay'], {
              queryParams: {
                'orderid': orderid,
                'orderType': orderType
              }
            });
            this.nativeService.hideLoading();
          } else {
            this.zeropay(this.orderid, orderType);
          }
        } else {
          this.nativeService.hideLoading();
          if (success.msg == '收货信息不存在') {
            that.nativeService.showWarningAlert(success.msg + ',请重新选择！');
          } else {
            that.nativeService.showErrorAlert(success.msg);
          }
        }
      });
    } else {
      let orderType = 1;
      if (this.productlistWL == undefined || this.productlistWL.length == 0) {
        orderType = 1;
      } else if (this.productlist == undefined || this.productlist.length == 0) {
        orderType = 0;
      } else {
        orderType = 5;
      }
      if (this.amountpay > 0) {
        //支付金额大于0
        this.nativeService.hideLoading();
        this.router.navigate(['orderPay'], {
          queryParams: {
            'orderid': this.orderid,
            'orderType': orderType
          }
        });
      } else {
        this.zeropay(this.orderid, orderType);
      }
    }
  }
  //0元支付
  public zeropay(orderid, orderType) {

    var that = this;
    that.nativeService.showLoadingWithText("支付中...");
    //支付金额小于等于0
    var params = {
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.order.order.pay",
      'orderid': orderid,
      'paytype': "zeropay"
    }
    this.httpService.request({
      method: 'post',
      url: 'api/index',
      data: params,
    }).then(success => {
      if (success.code == 100 && success.data) {
        that.router.navigate(['payResults'], { queryParams: { orderid: orderid, orderType: orderType, status: 1 } });
      } else {
        that.nativeService.showErrorAlert(success.msg);
      }
      this.nativeService.hideLoading();
    });

  }
  // 选择优惠券
  public selectCoupon() {
    // this.router.navigate([""])
    // var tl = this.ticketList == undefined ? '[]' : JSON.stringify(this.ticketList);
    var tl = this.ticketList == undefined ? '[]' : JSON.stringify(this.ticketlistZT);
    this.router.navigate(['discountCouponSelect'], { queryParams: { 'productlist': this.productlistId, 'shopid': this.shopid, 'ticketlist': tl, 'pidStr': this.pidStrKey } });
  }
  // 选择收货地址
  public selectAddress() {
    this.router.navigate(['mineAddress'], { queryParams: { 'selctAddress': true } });
  }

  public isShowAddress = false;
  @HostListener('window:scroll', ['$event']) public onScroll = ($event) => {
    // console.log('滚动了...'); 
    //客户端高度
    var clientH = document.documentElement.clientHeight;
    //body高度
    var bodyH = document.body.clientHeight;

    //滚动的高度
    var scrollTop = document.documentElement.scrollTop;
    // console.log("滚动的高度:" + scrollTop);
    try {
      this.shopAddressHeight = this.elementView.nativeElement.offsetHeight;
    } catch (error) {
    }
    // console.log('店铺div高度：' + this.shopAddressHeight);
    if (scrollTop > this.shopAddressHeight) {
      this.isShowAddress = true;
    } else {
      this.isShowAddress = false;
    }
    // 滚动到底部60以内
    // if (bodyH - clientH - scrollTop < 80) {
    //     if (!this.flag) {
    //         console.log('翻页');
    //         //翻页
    //         this.changePage('+');
    //     }
    //     this.flag = true;
    // } else {
    //     this.flag = false;
    // }
  }

  // 切换配送方式
  public dismissSwitchDialog() {
    this.isSHowSwitchDialog = false;
  }
  public selectType = 0;
  public productData: any;
  public trigger(itemData, type) {
    this.selectType = type;
    this.productData = itemData;
    // console.log(JSON.stringify(itemData));
    let index = 1;
    for (let index = 0; index < this.modeOfDistributionArr.length; index++) {
      if (this.modeOfDistributionArr[index].type == type) {
        this.modeOfDistributionArr[index].isSelect = true;
      } else {
        this.modeOfDistributionArr[index].isSelect = false;
      }
    }
    this.isSHowSwitchDialog = true;
    if (index == 1) {
      return;
    }

  }

  // 选择配送方式
  public selectLogisticsMode(idx) {
    for (let index = 0; index < this.modeOfDistributionArr.length; index++) {
      this.modeOfDistributionArr[index].isSelect = false;
    }
    this.modeOfDistributionArr[idx].isSelect = true;
  }
  // 选择完成
  public done() {
    let isEqual = false;
    for (let index = 0; index < this.modeOfDistributionArr.length; index++) {
      if (this.modeOfDistributionArr[index].isSelect) {
        if (this.modeOfDistributionArr[index].type == this.selectType) {
          isEqual = true;
        }
      }
    }
    this.isSHowSwitchDialog = false;
    if (!isEqual) {
      this.goTrigger();
    }
  }

  public goTrigger() {
    let that = this;
    this.nativeService.showLoading();
    let param = {
      'plat': 'plat_wap',
      'openno': '2',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.cart.shopcart.change",
      'pkey': this.productData.key,
      'shopid': this.utils.getLocalStorageItem("shopid"),
    }
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        let key = success.data.pkey;
        // console.log(that.productData.key);
        // console.log(key);
        // console.log(that.pidStr);
        // console.log(that.stockids);
        if (success.data.ret) {
          that.nativeService.showSuccessAlert('切换成功');
          that.pidStr = that.getNewKeyList(that.pidStr, that.productData.key, key);
          that.stockids = that.getNewKeyList(that.stockids, that.productData.key, key);
          that.utils.setLocalStorageItem('pidStr', that.pidStr);
          that.pidStrKey = '';
        }

        // console.log(that.pidStr);
        // console.log(that.stockids);
        that.getListData();
      } else {
        that.nativeService.showErrorAlert(success.msg);
      }
      that.nativeService.hideLoading();
    });
  }

  public getNewKeyList(KeyList, key, newKey) {
    let kList = (KeyList + '').split(',');
    for (let i = 0; i < kList.length; i++) {
      if (key == kList[i]) {
        kList.splice(i, 1);
      }
    }
    kList.push(newKey);
    return kList.join(',');
  }
  // 关闭订单优惠dia
  public dismissOpDialog() {
    this.isShowOpDialog = false;
  }
  // 开启订单优惠dia
  public showOpDialog() {
    if (this.discountsOrderArr.length > 0) {
      this.isShowOpDialog = true;
    } else {
      this.nativeService.showWarningAlert("暂无订单优惠!");
    }
  }
  public triggerFn() {
    this.status = !this.status;
    this.setIntegralStatus();
  }
  public setIntegralStatus() {
    let that = this;
    let param = {
      'plat': 'plat_wap',
      'openno': '2',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.cart.shopcart.setIntegralStatus",
      'status': this.status ? 1 : 2,
      'shopid': this.utils.getLocalStorageItem("shopid"),
    }
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        that.getListData();
      } else {
        that.nativeService.showErrorAlert(success.msg);
        that.status = !that.status;
      }
    });
  }
  //修改抵扣金额
  public maxValue = 0;
  public canCommit = true;
  public changeIntegralamount() {
    this.canCommit = true;
    this.integralPrice = this.integralamount;
    this.maxValue = this.carListData.integralmember / 100 < this.carListData.integralmax ? Math.floor(this.carListData.integralmember / 100) : Math.floor(this.carListData.integralmax);
    this.isShow = true;
    this.showAnimatin = true;
  }
  //关闭列表
  closeBox() {
    const that = this;
    that.showAnimatin = false;
    setTimeout(function () {
      that.isShow = false;
      that.nativeService.hideLoading();
    }, 800);
  }
  priceChange(value) {
    if (value > this.maxValue) {
      this.canCommit = false;
      // this.nativeService.showWarningAlert("已超出抵扣金额!");
    } else {
      this.canCommit = true;
    }
  }
  integralPriceCommit() {
    var obj = this.integralPrice;
    if (obj === null) {
      this.nativeService.showWarningAlert("请填写抵扣金额!");
    } else {
      if (!this.canCommit) {
        // this.nativeService.showWarningAlert("已超出抵扣金额!");
        return;
      }
      this.nativeService.showLoading();
      let that = this;
      let param = {
        'plat': 'plat_wap',
        'openno': '2',
        'userid': this.utils.getLocalStorageItem("user_info").userid,
        'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
        'openalias': "wap.cart.shopcart.setIntegralAmount",
        'amount': this.integralPrice,
        'shopid': this.utils.getLocalStorageItem("shopid"),
      }
      this.httpService.request({
        method: 'POST',
        url: 'api/index',
        data: param,
      }).then(success => {
        if (success.code == 100 && success.data) {
          // this.nativeService.showSuccessAlert("设置抵扣金额成功");
          that.getListData();
          that.closeBox();
        } else {
          that.nativeService.showErrorAlert(success.msg);
          that.nativeService.hideLoading();
        }
      });
    }
  }
}
