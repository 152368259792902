import { Component, OnInit, asNativeElements, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "../providers/HttpService"
import { NativeService } from "../providers/NativeService";
import { _require } from '../providers/require_lib';
import { Utils } from "../providers/Utils"
// declare let Swiper: any;
@Component({
  selector: 'app-Product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css', '../../assets/css/animate.min.css'],
})
export class ProductComponent implements OnInit {
  public showLoading = false;
  public topAdList = [];
  public topAdListLength = 0;
  public centerAdList = [];
  public centerAdListLength = 0
  public mallList = [];
  public itemGoodsList = [];
  public active: number = 0;
  public showView = false;
  public showLocalStorageSupported = false;
  /**********商品**************/
  public categoryid = 0;
  public checkedItem = 0;//现在的头部item
  public order = 0;//价格排序
  public isShowLoading = false;
  public GoodsList = [];
  public GoodsGategory = [];
  public isGategory = false;
  public isNoData = false;
  public categroyName = "全部商品";
  public total = 0;
  public page = 1;
  public noMore: boolean = false;
  public showFooter: boolean = false;
  public modeOfDistribution = '配送方式';//配送方式
  public allGoodsBean = { 'id': 0, 'pid': 0, 'pic': "", 'catename': "全部商品", 'depth': 0, 'sort': 0, 'status': 1 };
  public modeOfDistributionArr = [
    { 'name': '全部', 'id': 3, 'isSelect': true },
    { 'name': '到店自提', 'id': 2, 'isSelect': false },
    { 'name': '物流配送', 'id': 1, 'isSelect': false }
  ]
  /**********商品**************/
  constructor(public utils: Utils, public router: Router, public httpService: HttpService, public nativeService: NativeService, public routerInfo: ActivatedRoute) {
  }
  ngOnInit() {
    this.showLoading = true;
    this.nativeService.showLoading();
    this.showLocalStorageSupported = false;
    // this.getAdList();
    // this.isWechatWeb();
    /**********商品**************/
    this.checkedItem = 0;
    //接收参
    this.isShowLoading = true;
    this.routerInfo.queryParams.subscribe(queryParams => {
      this.categoryid = queryParams.categoryid == undefined ? 0 : queryParams.categoryid;
    });
    // console.log("categoryid:"+this.categoryid);
    // this.categoryid = 6;
    this.page = 1;
    this.getGoodsList(this.page, false);
    this.getGoodsGategory();
    /**********商品**************/
  }
  // 返回
  public backToPage() {
    this.nativeService.backToPage();
  }
  swiperInit() {
    /* 引入：swiper.js */
    // var that = this;
    // _require('wap/assets/js/swiper.min.js', function () {
    //   /* swiper初始化 */
    //   var mySwiper = new Swiper('.swiper-container', {
    //     autoplay: 4000,
    //     loop: that.topAdListLength > 1 ? true : false,
    //     pagination: '.swiper-pagination',
    //     autoplayDisableOnInteraction: false,
    //     paginationClickable: true,
    //     longSwipesRatio: 0.3,
    //     touchRatio: 1,
    //     observer: true,//修改swiper自己或子元素时，自动初始化swiper
    //     observeParents: true,//修改swiper的父元素时，自动初始化swiper
    //   });
    // });
  }
  //广告列表
  getAdList() {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.advert.advert.getChannelList',
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        if (result.data != null && result.data != undefined) {
          this.topAdList = (result.data.index_top_carousel_wap != null && result.data.index_top_carousel_wap != undefined) ? result.data.index_top_carousel_wap : [];
          this.centerAdList = (result.data.index_center_wap != null && result.data.index_center_wap != undefined) ? result.data.index_center_wap : [];
          this.topAdListLength = (result.data.index_top_carousel_wap != null && result.data.index_top_carousel_wap != undefined) ? result.data.index_top_carousel_wap.length : 0;
          this.centerAdListLength = (result.data.index_center_wap != null && result.data.index_center_wap != undefined) ? result.data.index_center_wap.length : 0;
        }
        this.getMallList();
      } else {
        this.showView = true;
        this.showLoading = false;
        this.nativeService.hideLoading();
        if (result.msg == "apitoken丢失") {
          this.showLocalStorageSupported = true;
          this.showView = false;
        } else {
          this.showLocalStorageSupported = false;
          this.nativeService.showErrorAlert(result.msg);
        }
      }
    });
  }
  // 类别列表
  getMallList() {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.product.categorymall.mallList',
      'pid': '0',
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.mallList = result.data;
        this.getItemGoodsList();
      } else {
        this.nativeService.showErrorAlert(result.msg);
        this.showLoading = false;
        this.nativeService.hideLoading();
        this.showView = true;
      }
    });
  }
  // 分类商品列表
  getItemGoodsList() {
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.product.productmall.indexCategoryList',
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.itemGoodsList = result.data;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
      this.showLoading = false;
      this.nativeService.hideLoading();
      this.showView = true;
      if (this.topAdListLength > 0) {
        this.swiperInit();
      }
    });
  }

  // 顶部广告点击
  topAdClick(item) {
    this.utils.activityJump(item, this.router);
  }
  //搜索
  gotoSearch() {
    this.router.navigate(['search']);
  }
  // 类别栏点击
  mallListClcik(item) {
    this.router.navigate(['goods'], { queryParams: { categoryid: item.id } });
  }
  //单页广告点击
  singleAdClick() {
    this.utils.activityJump(this.centerAdList[0], this.router);
  }
  //更多点击
  moreClcik(item) {
    // console.log(item);
    this.router.navigate(['goods'], { queryParams: { categoryid: item.categoryid } });
  }
  goodsClick(item) {
    this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.stockid } });
  }

  /**********商品**************/
  // 全部
  public All() {
    this.order = 0;
    this.checkedItem = 0;
    this.isGategory = !this.isGategory;
    this.isMode = false;
  }
  // 配送方式
  public isMode = false;//选择配送方式弹出
  public Synthesize() {
    if (this.isShowLoading) { return; }
    this.checkedItem = 1;
    this.isGategory = false;
    this.isMode = true;
    this.order = 0;
    this.upDataGoodsList();
  }
  // 选择配送方式
  public pchannel = 3;
  public selectMode(id, name, index) {
    this.modeOfDistribution = name;
    for (let i = 0; i < this.modeOfDistributionArr.length; i++) {
      this.modeOfDistributionArr[i].isSelect = false;
    }
    this.pchannel = id;
    this.modeOfDistributionArr[index].isSelect = true;
    this.isGategory = false;
    this.isMode = false;
    this.upDataGoodsList();
  }
  // 价格
  public Price() {
    if (this.isShowLoading) { return; }
    this.isGategory = false;
    this.isMode = false;
    this.checkedItem = 2;
    if (this.order == 0 || this.order == 3) {
      this.order = 1;
    } else if (this.order == 1) {
      this.order = 2;
    } else if (this.order == 2) {
      this.order = 1;
    }
    this.upDataGoodsList();
  }
  //销量 
  public SalesVolume() {
    if (this.isShowLoading) { return; }
    this.isGategory = false;
    this.isMode = false;
    this.checkedItem = 3;
    this.order = 3;
    this.upDataGoodsList();
  }
  // 关闭分类
  public GategoryClose() {
    this.isGategory = false;
    this.isMode = false;
  }
  // 更新数据
  public upDataGoodsList() {
    this.isGategory = false;
    this.isNoData = false;
    this.noMore = false;
    this.showFooter = false;
    this.GoodsList = [];
    this.isShowLoading = true;
    this.nativeService.showLoading();
    this.page = 1;
    this.getGoodsList(this.page, false);
  }

  // 获取商品列表
  public getGoodsList(page, state) {
    let thet = this;
    // wap.product.productmall.getJlsShopStockList
    // wap.product.productmall.getJilisiStockList
    let params = {
      'categoryid': thet.categoryid,
      'page': page,
      'limit': 20,
      'order': thet.order,
      'openalias': "wap.product.productmall.getJlsShopStockList",
      'keyword': "",
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.utils.getLocalStorageItem('shopid'),
      'pchannel': this.pchannel,
    }
    // console.log("参数：" + JSON.stringify(params));
    thet.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: params,
    }).then(result => {
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        thet.total = result.data.total;
        if (thet.total > 0) {
          for (let i = 0; i < result.data.list.length; i++) {
            result.data.list[i].integralpriceLimit = this.utils.priceLimit(result.data.list[i].integralprice);
            result.data.list[i].hPrice = 0;
            result.data.list[i].price = 0;
            if (result.data.list[i].useDisPrice == 1) {
              result.data.list[i].hPrice = result.data.list[i].sellprice;
              result.data.list[i].price = result.data.list[i].dis_price;
            } else {
              result.data.list[i].price = result.data.list[i].sellprice;
            }
            for (let j = 0; j < result.data.list[i].activitylable.length; j++) {
              // activitytype 促销类型 ： 1 订单促销 2 商品促销
              if (result.data.list[i].activitylable[j].activitytype == 1) {
                switch (result.data.list[i].activitylable[j].tagtype) {
                  case 1://满减
                    result.data.list[i].activitylable[j].type = 5;
                    result.data.list[i].activitylable[j].name = '满减';
                    break;
                  case 2://满折
                    result.data.list[i].activitylable[j].type = 6;
                    result.data.list[i].activitylable[j].name = '满折';
                    break;
                  case 3://满赠
                    result.data.list[i].activitylable[j].type = 7;
                    result.data.list[i].activitylable[j].name = '满赠';
                    break;
                  default:
                    break;
                }
              }
              if (result.data.list[i].activitylable[j].activitytype == 2) {
                switch (result.data.list[i].activitylable[j].tagtype) {
                  case 1://立减
                    result.data.list[i].activitylable[j].type = 1;
                    result.data.list[i].activitylable[j].name = '立减';
                    break;
                  case 2://折扣
                    result.data.list[i].activitylable[j].type = 2;
                    result.data.list[i].activitylable[j].name = '折扣';
                    break;
                  case 3://赠送
                    result.data.list[i].activitylable[j].type = 3;
                    result.data.list[i].activitylable[j].name = '赠送';
                    break;
                  case 4://特价
                    result.data.list[i].activitylable[j].type = 4;
                    result.data.list[i].activitylable[j].name = '特价';
                    break;
                  default:
                    break;
                }
              }
            }
          }
          // console.log(JSON.stringify(result.data));
          if (state == false) {
            thet.GoodsList = result.data.list;
          } else {
            thet.GoodsList = thet.GoodsList.concat(result.data.list);
          }
          thet.noMore = thet.GoodsList.length >= thet.total ? true : false;
        } else {
          thet.isNoData = true;
        }
        thet.isShowLoading = false;
        this.nativeService.hideLoading();
        thet.showFooter = false;
        this.page++;
      } else {
        if (state) {
          thet.page--;
        }
        if (result.msg == "apitoken丢失") {
          this.showLocalStorageSupported = true;

        } else {
          this.showLocalStorageSupported = false;
          this.nativeService.showErrorAlert(result.msg);
        }
        this.isShowLoading = false;
        this.nativeService.hideLoading();
      }
    });
  }
  // 获取商品分类
  public getGoodsGategory() {
    let params = {
      'plat': 'plat_wap',
      'openno': '1',
      'pid': 0,
      "openalias": "wap.product.categorymall.mallList"
    }
    let thet = this;
    thet.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: params,
    }).then(result => {
      if (result.code == 100 && result.data) {
        thet.GoodsGategory = result.data;
        thet.GoodsGategory.splice(0, 0, thet.allGoodsBean);
        for (let i = 0; i < thet.GoodsGategory.length; i++) {
          // console.log(JSON.stringify(thet.GoodsGategory[i]));
          if (thet.categoryid == thet.GoodsGategory[i].id) {
            thet.categroyName = thet.GoodsGategory[i].catename;
          }
        }
        // console.log(thet.categroyName);
        // this.isShowLoading = false;
      } else {
        this.nativeService.showErrorAlert(result.msg);
        // this.isShowLoading = false;
      }
    });
  }
  public SelectGategory(id, catename) {
    if (this.isShowLoading) {
      return;
    }
    this.categoryid = id;
    this.categroyName = catename;
    this.isGategory = false;
    this.order = 0;
    this.checkedItem = 0;
    this.upDataGoodsList();
  }
  // 上拉加载更多
  public onScrollDown() {
    // console.log("Down");
    if (this.isShowLoading) {
      return;
    }
    // console.log("pagexxx:"+this.page);
    if (this.GoodsList.length < this.total) {
      // this.page++;
      this.isShowLoading = true;
      this.nativeService.showLoading();
      this.showFooter = true;
      this.isNoData = false;
      this.noMore = false;
      this.showFooter = false;
      console.log("page:" + this.page);
      this.getGoodsList(this.page, true);
    }
  }
  // 下拉刷新
  public onScrollUp() {
    // console.log("Up");
  }
  // 进入详情
  public GoGoodsDetails(item) {
    // console.log(item);
    this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.stockid, shopid: this.utils.getLocalStorageItem('shopid') } });
  }

  public msgDDD;
  public hidden(isshow, msg) {
    this.isShow = isshow;
    this.msgDDD = msg;
    // console.log(':' + this.isShow + this.msgDDD);
  }

  public addressData: any;
  public address: any;
  public isShow = false;
  public getChildData(ev) {
    this.addressData = ev;
    this.isShow = this.addressData.isShow;
    if (this.addressData.type == 1) {
      this.address = this.addressData.address.address;
      // console.log(this.address)
    }
    // console.log(this.addressData);
  }
  /**********商品**************/

  // 点击搜索
  public searchClick() {
    // console.log('搜索... ...');
    this.router.navigate(['search']);
  }
}


