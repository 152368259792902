import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-withdrawal-record',
  templateUrl: './withdrawal-record.component.html',
  styleUrls: ['./withdrawal-record.component.css']
})
export class WithdrawalRecordComponent implements OnInit {

  public isNoData = false;
  public cashList = [];
  public showFooter = false;
  public isNoMore = false;
  public page = 1;
  public total = 0;
  public isND = true ;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.nativeService.showLoading();
    this.getCashList();
  }

  public getCashList() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.balance.Cash.getList',
      'plat': 'plat_wap',
      'openno': '1',
      'page': this.page,
      'limit': 10
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      this.showFooter = false ;
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        // console.log(JSON.stringify(result.data));
        this.total = result.data.total ;
        if (this.page == 1) {
          if (result.data.list == undefined || result.data.list.length == 0) {
            this.isNoData = true;
          } else {
            this.cashList = result.data.list;
            // for(let i=0 ; i<result.data.list.length ; i++){
            //   result.data.list[i].accountno = this.replacepos(result.data.list[i].accountno,4,result.data.list[i].accountno.length-4,'**********');
            // }
          }
        } else {
          if (result.data.list == undefined || result.data.list.length == 0) {
            this.isNoMore = true ;
          } else {
            this.cashList = this.cashList.concat(result.data.list);
            // for(let i=0 ; i<result.data.list.length ; i++){
            //   result.data.list[i].accountno = this.replacepos(result.data.list[i].accountno,4,result.data.list[i].accountno.length-4,'**********');
            // }
          }
        }

        this.page ++ ;
        if(this.cashList.length>0&&this.cashList.length==this.total){
          this.isNoMore = true ;
        }
      } else {
        if(result.code == 66 || result.code == 67){
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

  public goToDetalis(id) {
    this.router.navigate(['show-details'], { queryParams: { 'cardId': id } });
  }

  public replacepos(text, start, stop, replacetext) {
    let mystr = text.substring(0, start) + replacetext + text.substring(stop, text.length);
    return mystr;
  }

  public onScrollDown() {
    // console.log('加载。。。1');
    if(this.isNoMore || this.showFooter )return ;
    this.showFooter = true ;
    this.getCashList();
  }
  public onScrollUp() {
    console.log('加载。。。2');
  }
}
