import { Component, OnInit } from '@angular/core';
import { NativeService } from '../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "../providers/HttpService"
import { Utils } from "../providers/Utils";


@Component({
  selector: 'app-vip-member',
  templateUrl: './vip-member.component.html',
  styleUrls: ['./vip-member.component.css']
})
export class VipMemberComponent implements OnInit {

  public isOpen = false;
  // 
  public isShow = false;
  public containerType = '1';
  // 
  public giftData;
  public giftList;
  // 
  public telVal = '';
  public codeText = '获取验证码';
  public codeVal = '';
  // 
  public isAgree = false;
  // 
  public shopId = '';
  public phoneNumber = '';
  public info;
  public resultObj;
  public isLogin = false;
  // 
  public orderId;
  public isShowCheck = false;
  public stepNum = 0;
  constructor(
    public nativeService: NativeService,
    public utils: Utils,
    public http: HttpService,
    public routeInfo: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    this.getList();
    this.shopId = this.utils.getLocalStorageItem('shopid');
    this.isLogin = this.utils.getLocalStorageItem("user_id") ? true : false;
    let _isopen = this.utils.getLocalStorageItem('viporderid');
    if (_isopen) {
      this.openSuccess(_isopen);
    }
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  // 获取列表
  public getList() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.memberGift',
      'plat': 'plat_wap',
      'openno': '3',
      'shopid': this.utils.getLocalStorageItem('shopid')
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        let obj = result.data;
        let arr = obj.list ? obj.list : [];
        arr.map((item, index) => {
          let _price = item.price.split('.');
          if (_price[1] == '00') {
            item.price = _price[0];
          }
        })
        // 
        this.giftData = obj;
        this.giftList = arr;
        this.isOpen = obj.btn_status == 1 ? false : true;
        console.log(obj.btn_status);
        if (this.stepNum == 1 && obj.btn_status == 1) {
          this.getVipConfigInfo();
        }
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }
  // 
  public changeAgree() {
    this.isAgree = !this.isAgree;
  }
  // 
  public openVip() {
    if (!this.isAgree) {
      this.nativeService.showErrorAlert('请先同意协议');
      return;
    }
    if (this.telVal == '') {
      this.nativeService.showErrorAlert('手机号不能为空！');
      return;
    }
    if (this.codeVal == '') {
      this.nativeService.showErrorAlert('验证码不能为空！');
      return;
    }
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.Register.login',
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.shopId,//否,下单的门店Id
      'mobile': this.telVal,//手机号
      'code': this.codeVal,//验证码
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        console.log(result.data);
        //
        this.stepNum = 1;
        this.utils.setLocalStorageItem("isLogin", true);
        this.utils.setLocalStorageItem("user_token", result.data.usertoken);
        this.utils.setLocalStorageItem("user_id", result.data.userid);
        this.utils.setLocalStorageItem("user_info", result.data);
        this.getList();
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }
  // 
  public toCancel() {
    this.isShow = false;
    this.telVal = '';
    this.codeText = '获取验证码';
    this.codeVal = '';
  }

  public toClose() {
    this.isShow = false;
  }
  // 
  public toPayNow() {
    let vid = this.info.id;
    // 创建订单
    this.creatOrder(vid);
  }

  // creatOrder
  public creatOrder(vid) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.order.orderbuilder.openvip',
      'plat': 'plat_wap',
      'openno': '1',
      'vipip': vid,//是,vip配置ID
      'puserid': '',//否,推广用户id【分享购买vip会员必传】
      'remark': '',//否,订单备注
      'shopid': this.shopId,//否,下单的门店Id
      'cityid': '',//否,下单城市ID
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.orderId = result.data.orderid
        this.router.navigate(['orderPay'], {
          queryParams: {
            'orderid': result.data.orderid,
            'page': -1,
            'orderType': 12
          }
        });
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }

  //
  public getVipConfigInfo() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.Config.getInfo',
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.shopId,//否,下单的门店Id
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        this.info = result.data;
        this.isShow = true;
        this.containerType = '2';
        this.phoneNumber = this.telVal;
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }

  // 
  public toOpenVip() {
    if (!this.isLogin) {
      this.isShow = true;
      this.containerType = '1';
    } else {
      this.getVipConfigInfo();
    }
  }

  public openSuccess(ids) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.setResult',
      'plat': 'plat_wap',
      'openno': '2',
      'orderid': ids,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.resultObj = result.data;
        this.isShow = true;
        this.containerType = '3';
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }

  public toFinish() {
    this.utils.removeLocalStorageItem('viporderid');
    this.isShow = false;
    this.containerType = '1';
    this.getList();
  }

  public getGiftAllTicket() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.getGiftAllTicket',
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.shopId
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        if (result.data.ret == 2) {
          this.nativeService.showErrorAlert(result.data.msg);
        } else {
          this.nativeService.showSuccessAlert('领取成功~');
        }
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }

  public toUseTicket(_item) {
    let _pid = _item.productid;
    let _ptotal = _item.ptotal;
    let _shopId = this.giftData.shopid;
    let _tid = _item.ticketid;
    //1无限制2商品3服务4套餐6平台商品7平台通用券
    switch (_item.uselimit) {
      case 1:
        if (_shopId) {
          this.router.navigate(['home'], { queryParams: { shopid: _shopId } })
        } else {
          this.router.navigate(['index']);
        }
        break;
      case 2:
        if (_ptotal == 1) {
          this.router.navigate(['goodsDetail'], { queryParams: { stockid: _pid, shopid: _shopId, tid: _tid } });
        } else {
          this.router.navigate(['product'], { queryParams: { 'shopid': _shopId } });
        }
        break;
      case 3:
        if (_shopId) {
          if (_ptotal == 1) {
            this.router.navigate(['serviceDetail'], { queryParams: { serviceid: _pid, shopid: _shopId, tid: _tid } });
          } else {
            this.router.navigate(['serviceList'], { queryParams: { shopid: _shopId } });
          }
        } else {
          this.router.navigate(['serveList']);
        }
        break;
      case 4:
        if (_shopId) {
          if (_ptotal == 1) {
            this.router.navigate(['packageDetail'], { queryParams: { setmealid: _pid, shopid: _shopId, tid: _tid } });
          } else {
            this.router.navigate(['packageList'], { queryParams: { shopid: _shopId } });
          }
        } else {
          this.router.navigate(['setmealList']);
        }
        break;
      case 6:
        if (_ptotal == 1) {
          this.router.navigate(['goodsDetail'], { queryParams: { stockid: _pid } });
        } else {
          this.router.navigate(['goods']);
        }
        break;
      case 7:
        if (_ptotal == 1) {
          this.router.navigate(['ticketDetail'], { queryParams: { id: _pid, shopid: _shopId, tid: _tid } });
        } else {
          this.router.navigate(['ticketList']);
        }
        break;
    }
  }

  public getCode() {
    if (this.telVal == undefined || this.telVal.length == 0) {
      this.nativeService.showWarningAlert('请输入手机号!');
      return;
    }
    if (this.telVal.length < 11 || this.telVal.length > 11) {
      this.nativeService.showWarningAlert('手机号格式不对!');
      return;
    }
    this.isShowCheck = true;
  }

  public sendCode(data) {
    if (this.telVal == '') {
      this.nativeService.showErrorAlert('手机号不能为空！');
      return;
    }
    this.nativeService.showLoading();
    let params = {
      'openalias': 'wap.vip.register.sendCode',
      'plat': 'plat_wap',
      'openno': '2',
      'mobile': this.telVal,
      'shopid': this.shopId,
      'verifytoken': data
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        console.log(result.data);
        var status = result.data.status;
        if (status == 1) {
          this.nativeService.showSuccessAlert(result.data.msg);
          let time = 60;
          let times = setInterval(() => {
            if (time == 0) {
              this.codeText = "获取验证码";
              clearInterval(times);
            } else {
              time--;
              this.codeText = time + "s后重新获取";
            }
          }, 1000);
        } else if (status == 2) {
          this.nativeService.showErrorAlert(result.data.msg);
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
    // this.resetTime();
  }

  public getChildData(data) {
    this.isShowCheck = false;
    if (data != 0) this.sendCode(data);
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
  }

  public toReceive(tid) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.getGiftTicket',
      'plat': 'plat_wap',
      'openno': '2',
      'ticketid': tid,
      'shopid': this.utils.getLocalStorageItem('shopid')
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert('领取成功！');
        this.getList();
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }
}