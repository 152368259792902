import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
@Component({
  selector: 'app-account-center',
  templateUrl: './account-center.component.html',
  styleUrls: ['./account-center.component.css']
})
export class AccountCenterComponent implements OnInit {

  public userInfo: any;
  public mobile = "";
  public userInfoNew = {
    "userid": "",
    "mobile": "",
    "head_thumb": "",
    "name": ""
  }
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.userInfo = queryParams == undefined ? {} : queryParams;
    });
    // console.log("userInfo:" + JSON.stringify(this.userInfo));

    let lsMobile = this.utils.getLocalStorageItem("mobile");
    let mob = this.userInfo.mobile;
    if (lsMobile.length <= 0) {
      if (mob != undefined) {
        this.mobile = this.replacepos(this.userInfo.mobile, 3, 7, '****');
      }
      // this.utils.setLocalStorageItem("mobile", '');
    } else {
      this.mobile = this.replacepos(lsMobile, 3, 7, '****');
      this.userInfoNew.mobile = lsMobile ;
      this.userInfo = this.userInfoNew ;
      // this.utils.setLocalStorageItem("mobile", '');
    }
  }

  ngOnInit() {
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  // 切换登录
  public switchTheLogin() {
    // console.log('切换登录...');
    this.router.navigate(['change-mobile'], { queryParams: { "mobile": this.userInfo.mobile } })
  }
  // 修改密码
  public changePwd() {
    // console.log('修改密码...');
    this.router.navigate(['login-password'], { queryParams: { "mobile": this.userInfo.mobile } })
  }
  // 修改支付密码
  public changePaymentPwd() {
    // console.log('修改支付密码。。。');
    this.router.navigate(['payment-password'], { queryParams: this.userInfo })
  }

  public replacepos(text, start, stop, replacetext) {
    let mystr = text.substring(0, start) + replacetext + text.substring(stop, text.length);
    return mystr;
  }

  public loginOut(){
    this.utils.setLocalStorageItem("isLogin", false);
    this.utils.setLocalStorageItem("user_token", "");
    this.utils.setLocalStorageItem("user_id", "");
    this.utils.setLocalStorageItem("user_info", "");
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    this.nativeService.showSuccessAlert('退出成功');
    this.backToPage();
  }

  public toMyInfo(){
    console.log('我的资料')
    this.router.navigate(['myInfo'])
  }

  public bindWx(){
    console.log('微信绑定')
  }
}
