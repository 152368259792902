import { Component, OnInit } from '@angular/core';
import { HttpService } from '../providers/HttpService';
import { NativeService } from '../providers/NativeService';
import { Router, ActivatedRoute } from "@angular/router";
import { Utils } from '../providers/Utils';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载

@Component({
  selector: 'app-discount-coupon-select',
  templateUrl: './discount-coupon-select.component.html',
  styleUrls: ['./discount-coupon-select.component.css']
})
// 选择优惠券
export class DiscountCouponSelectComponent implements OnInit {
  public ticketNumber: any = '';
  public productlist;
  public shopid;
  public mCouponList = [];
  public isShowPage = false;
  public ticketlist = [];
  public pidStr;
  public isServicePakage = false;
  constructor(public http: HttpService, public utils: Utils, public nativeService: NativeService, public routeInfo: ActivatedRoute) {

  }

  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.productlist = queryParams.productlist;
      this.shopid = queryParams.shopid;
      this.isServicePakage = queryParams.isServicePakage;
      console.log(this.isServicePakage)
      try{
        this.ticketlist = JSON.parse(queryParams.ticketlist);
      }catch(err){
        this.ticketlist=[];
      }
      this.pidStr = queryParams.pidStr;
      console.log(this.pidStr);
    })

    this.nativeService.showLoading();
    this.getCouponList();
  }

  public getCouponList() {
    // 'userid': this.utils.getLocalStorageItem("user_id"),
    // 'user_token': this.utils.getLocalStorageItem("user_token"),
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      // 'openalias': 'wap.ticket.ticketuser.getUseList',wap.cart.shopcart.getUseList
      'openalias': 'wap.cart.shopcart.getUseList',
      'shopid': this.shopid,
      'productlist': this.productlist,
      'plat': 'plat_wap',
      'openno': '2',
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        for (let index = 0; index < result.data.length; index++) {
          var priceArr = this.utils.priceLimitToList(this.utils.priceLimit(result.data[index].price)); 
          result.data[index].price = priceArr[0];
          result.data[index].price1 = priceArr[1];
          result.data[index].isSelect = false;
        }
        for (let i = 0; i < this.ticketlist.length; i++) {
          var id = this.ticketlist[i].userticketid;
          for (let j = 0; j < result.data.length; j++) {
            if (id == result.data[j].id) {
              result.data[j].isSelect = true;
            }
          }
        }
        this.mCouponList = result.data;
        for (var i = 0; i < this.mCouponList.length; i++) {
          this.mCouponList[i].name = '抵用券';
          if (this.mCouponList[i].type == 1) {
            this.mCouponList[i].name = '抵用券';
          }
          if (this.mCouponList[i].type == 2) {
            this.mCouponList[i].name = '现金券';
          }
          if (this.mCouponList[i].type == 3) {
            this.mCouponList[i].name = '兑换券';
            this.mCouponList[i].price = '兑';
          }
          if (this.mCouponList[i].type == 4) {
            this.mCouponList[i].name = '折扣券';
          }
        }
        // console.log(JSON.stringify(this.mCouponList));
        if (this.mCouponList.length > 0) {
          this.isShowPage = true;
        }
        // console.log(this.mCouponList);
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  public backToPage() {
    //是套餐服务时
    if(this.isServicePakage){
      var isSelect = false;
      for (let index = 0; index < this.mCouponList.length; index++) {
        const element = this.mCouponList[index];
        if(element.isSelect==true){
          isSelect = true;
        }
      }
      if(!isSelect){this.utils.setLocalStorageItem("ServicePakageCoupon","")};
    }
    this.nativeService.backToPage();
  }
  // 添加券...
  public addTicket() {
    console.log("添加...:" + this.ticketNumber);
  }
  // 确定 
  public confirm() {
    this.nativeService.backToPage();
  }

  public couponSelect(id, type, ix, issupport,price) {
    if (issupport) {
      this.nativeService.showLoading();
      if (this.mCouponList[ix].isSelect) {
        this.deleteCoupon(id, type, ix);
      } else {
        if(this.isServicePakage){
          var par = {
            "userticketid": id,
            "type": type,
            "price": price
          }
          this.utils.setLocalStorageItem("ServicePakageCoupon",par)
          this.nativeService.hideLoading();
          this.nativeService.backToPage();
        }else{
          this.addCoupon(id, type, ix);
        }
      }
    }
    // this.mCouponList[ix].isSelect = !this.mCouponList[ix].isSelect;
  }
  // 删除券
  public deleteCoupon(ticketid, type, ix) {
    // console.log("删除券");
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.cart.shopcart.delTicket',
      'userTicketId': ticketid,
      'plat': 'plat_wap',
      'shopid': this.shopid,
      'openno': '2',
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {

      if (result.code == 100 && result.data) {
        this.couponDeleteCar(ticketid, type, ix);
        // console.log(JSON.stringify(result));
      } else {
        this.nativeService.hideLoading();
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  };
  // 添加券
  public addCoupon(ticketid, type, ix) {
    // console.log("添加券");
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.cart.shopcart.addTicket',
      'userTicketId': ticketid,
      'plat': 'plat_wap',
      'openno': '2',
      'pidstr': this.pidStr,
      'shopid': this.shopid,
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        this.couponAddCar(ticketid, type, ix);
      } else {
        this.nativeService.hideLoading();
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  public couponDeleteCar(id, type, ix) {
    // console.log(JSON.stringify(this.ticketlist));
    for (let i = 0; i < this.ticketlist.length; i++) {
      if (type == this.ticketlist[i].type) {
        this.ticketlist.splice(i, 1);
      }
    }
    this.mCouponList[ix].isSelect = false;
    this.nativeService.hideLoading();
    // console.log(JSON.stringify(this.ticketlist));
  }
  public couponAddCar(id, type, ix) {
    // console.log(JSON.stringify(this.ticketlist));
    for (let i = 0; i < this.ticketlist.length; i++) {
      if (type == this.ticketlist[i].type) {
        for (let j = 0; j < this.mCouponList.length; j++) {
          if (this.ticketlist[i].userticketid == this.mCouponList[j].id) {
            this.mCouponList[j].isSelect = false;
          }
        }
        this.ticketlist.splice(i, 1);
      }
    }
    var par = {
      "userticketid": id,
      "type": type
    }

    for (let j = 0; j < this.mCouponList.length; j++) {
      this.mCouponList[j].isSelect = false;
    }
    this.mCouponList[ix].isSelect = true;
    this.ticketlist.push(par);
    this.nativeService.hideLoading();
    this.nativeService.backToPage();
    // console.log(JSON.stringify(this.ticketlist));
  }
}
