import { NativeService } from '../providers/NativeService';
import { Utils } from '../providers/Utils';
import { HttpService } from '../providers/HttpService';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare let MeScroll: any;
@Component({
  selector: 'app-mine-integral',
  templateUrl: './mine-integral.component.html',
  styleUrls: ['./mine-integral.component.css','../../assets/css/animate.min.css']
})
export class MineIntegralComponent implements OnInit {
  public shopid = 0;
  public noMore = false;
  public total: number = 1;
  public page: number = 1;
  public itemList: any = [];
  public integraltotal = 0;
  public isShow = false;
  public showAnimatin = false;
  public ruleList = [];
  constructor(public routeInfo: ActivatedRoute, public router: Router, public nativeService: NativeService, private http: HttpService, public utils: Utils) { }
  public backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.shopid = queryParams.shopid;
    });
    this.nativeService.showLoading();
    this.initMesCroll();
  }
  public mescroll;
  initMesCroll() {
    var that = this;
    this.mescroll = new MeScroll("mescroll", {
      //下拉刷新
      down: {
        auto: true,//是否加载时回调
        callback: function () {
          that.getListData(false);
        }
      },
      //上拉加载更多
      up: {
        auto: false,
        callback: function () {
          if (that.itemList.length < that.total) {
            that.getListData(true);
          } else {
            that.noMore = true;
            that.mescroll.endSuccess(that.total);
          }
        }
      }
    });
  }
  getListData(status) {
    status ? this.page = this.page + 1 : this.page = 1;
    this.noMore = false;
    let param = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.Integral.getList',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.shopid,
      'limit': 20,
      'page': this.page,
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.total = result.data.total;
        if (result.data.total > 0) {
          this.integraltotal = result.data.integraltotal;
          var changeName = "获取";
          var typeName = "";
          result.data.list.forEach(element => {
            switch (element.type) {
              case 1:
                typeName = "积分消费";
                break;
              case 2:
                typeName = "积分退回";
                break;
              case 3:
                typeName = "在线预购";
                break;
              case 4:
                typeName = "终端预购";
                break;
              case 5:
                typeName = "终端交易";
                break;
              case 6:
                typeName = "商城";
                break;

              default:
                typeName = "";
                break;
            }
            if (element.change == 1) {
              changeName = "获取";
            } else {
              changeName = "消费";
            }
            element.title = element.shopname + typeName + changeName;
          });
        }
        if (status) {
          //上拉加载更多
          console.log("上拉加载更多");
          this.mescroll.endSuccess(result.data.total);
          this.itemList = this.itemList.concat(result.data.list);
        } else {
          //下拉刷新
          console.log("下拉刷新");
          this.itemList = [];
          this.mescroll.endSuccess();
          this.itemList = result.data.list;
          // console.log(this.total);
        }
      } else {
        if (result.code == 66 || result.code == 67) {
          this.utils.setLocalStorageItem("isLogin", false);
          this.router.navigate(['login'], { queryParams: {} });
        }
        this.mescroll.endErr();
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  };
  public watchRule() {
    this.nativeService.showLoading();
    let param = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.Integral.rule',
      'plat': 'plat_wap',
      'openno': '1',
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        this.ruleList = result.data;
        this.isShow = true;
        this.showAnimatin = true;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  //关闭列表
  closeBox() {
    const that = this;
    that.showAnimatin = false;
    setTimeout(function () {
      that.isShow = false;
    }, 800);
  }
}
