import { Component, OnInit } from '@angular/core';
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { Router } from "@angular/router";
import { Utils } from '../providers/Utils'
import { NativeService } from '../providers/NativeService';

@Component({
  selector: 'app-welfare-list',
  templateUrl: './welfare-list.component.html',
  styleUrls: ['./welfare-list.component.css']
})
export class WelfareListComponent implements OnInit {
  public giftData;
  public giftList;

  constructor(public http: HttpService, public router: Router, public utils: Utils, public nativeService: NativeService) { }

  ngOnInit() {
    this.getList();
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  // 获取列表
  public getList() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.memberGift',
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.utils.getLocalStorageItem('shopid')
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.giftData = result.data;
        this.giftList = result.data.list;
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }
  // 
  public setItem(tid) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.getGiftTicket',
      'plat': 'plat_wap',
      'openno': '2',
      'ticketid': tid,
      'shopid': this.utils.getLocalStorageItem('shopid')
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert('领取成功！');
        this.getList();
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }

  public toUseTicket(_item) {
    let _pid = _item.productid;
    let _ptotal = _item.ptotal;
    let _shopId = this.giftData.shopid;
    let _tid = _item.ticketid;
    //1无限制2商品3服务4套餐6平台商品7平台通用券
    switch (_item.uselimit) {
      case 1:
        if (_shopId) {
          this.router.navigate(['home'], { queryParams: { shopid: _shopId } })
        } else {
          this.router.navigate(['index']);
        }
        break;
      case 2:
        if (_ptotal == 1) {
          this.router.navigate(['goodsDetail'], { queryParams: { stockid: _pid, shopid: _shopId, tid: _tid } });
        } else {
          this.router.navigate(['product'], { queryParams: { 'shopid': _shopId } });
        }
        break;
      case 3:
        if (_shopId) {
          if (_ptotal == 1) {
            this.router.navigate(['serviceDetail'], { queryParams: { serviceid: _pid, shopid: _shopId, tid: _tid } });
          } else {
            this.router.navigate(['serviceList'], { queryParams: { shopid: _shopId } });
          }
        } else {
          this.router.navigate(['serveList']);
        }
        break;
      case 4:
        if (_shopId) {
          if (_ptotal == 1) {
            this.router.navigate(['packageDetail'], { queryParams: { setmealid: _pid, shopid: _shopId, tid: _tid } });
          } else {
            this.router.navigate(['packageList'], { queryParams: { shopid: _shopId } });
          }
        } else {
          this.router.navigate(['setmealList']);
        }
        break;
      case 6:
        if (_ptotal == 1) {
          this.router.navigate(['goodsDetail'], { queryParams: { stockid: _pid } });
        } else {
          this.router.navigate(['goods']);
        }
        break;
      case 7:
        if (_ptotal == 1) {
          this.router.navigate(['ticketDetail'], { queryParams: { id: _pid, shopid: _shopId, tid: _tid } });
        } else {
          this.router.navigate(['ticketList']);
        }
        break;
    }
  }

}
