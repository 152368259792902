/**
 * Created by yanxiaojun617@163.com on 07-25.
 */
import {Injectable} from '@angular/core';
import {Md5} from "ts-md5/dist/md5";
import { NativeService } from "../providers/NativeService";
import { Utils } from "../providers/Utils";
@Injectable()
export class ApiParamMD5 {
  constructor(public nativeService:NativeService,public utils:Utils)
  {

  }
  getApi_tokent(param){
    let str = this.objKeySort(param);
    var appkey = this.utils.getLocalStorageItem('appKey');
    if(appkey==null || appkey==""||appkey==undefined){
      //  this.nativeService.showErrorAlert("获取key失败,请稍后重试!");
      return;
    }else{
      str =  str + appkey
      str =  str.replace(/"/g, "")
      str =  Md5.hashStr(str).toString();
      return str;
    }
  }
  objKeySort(obj) {//排序的函数
    var newkey = Object.keys(obj).sort();
    //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    var newObj = {};//创建一个新的对象，用于存放排好序的键值对
    for (var i = 0; i < newkey.length; i++) {//遍历newkey数组
      newObj[newkey[i]] = obj[newkey[i]];//向新创建的对象中按照排好的顺序依次增加键值对
    }
   
    for (var key in newObj) {
      newObj["\"" + key.toUpperCase() + "\""] = newObj[key];
      delete (newObj[key]);
    }
    var str = "";
    for (var key in newObj) {
     
      str =  str + key + '='+ newObj[key] + '&' ;
    }
    // console.log(str);
    return str;//返回排好序的新对象
  }
}