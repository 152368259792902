/**
 * Created by yanxiaojun on 2017/4/13.
 */
import {Injectable} from '@angular/core';

@Injectable()
export class GlobalData {

  public _userId: string;//用户id
  public _username: string;//用户名
  public _fullName: string;//姓名
  public _token: string;//token
  get userId(): string {
    return this._userId;
  }

  set userId(value: string) {
    this._userId = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get fullName(): string {
    return this._fullName;
  }

  set fullName(value: string) {
    this._fullName = value;
  }

  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
  }
}
