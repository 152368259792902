import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";

@Component({
  selector: 'app-serve-set-meal-detail',
  templateUrl: './serve-set-meal-detail.component.html',
  styleUrls: ['./serve-set-meal-detail.component.css', '../../assets/css/coupon.css']
})
export class ServeSetMealDetailComponent implements OnInit {
  public pageTitle: String = ''; //页面标题
  public pageType: any; // package server
  public infoObj: Object = {};
  public shopInfo: Object = {}; //门店信息
  public productlist: Array<any> = [];//产品
  public serverList: Array<any> = [];//服务
  public pid: String | Number = ''; //服务套餐id
  // 
  public isLogin = false;
  public sellPrice:any = '0.00';
  // 
  public ticketList;
  public isShow: boolean = true;


  constructor(
    public nativeService: NativeService,
    public utils: Utils,
    public http: HttpService,
    public routeInfo: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    // 处理路由参数
    this.routeInfo.queryParams.subscribe(query => {
      this.pageType = query.type ? query.type : '';
      this.pid = query.id ? query.id : 0;
      if (query.type == "package") {
        this.pageTitle = '套餐详情';
      } else {
        this.pageTitle = '服务详情';
      }
      // 获取订单详情
      this.getInfo(query.id);
    })
    // 
    this.isLogin = this.utils.getLocalStorageItem("isLogin");
  }
  // 返回
  public backToPage() {
    this.nativeService.backToPage();
  }
  // 获取订单详情
  public getInfo(ids) {
    let params = {
      'plat': 'plat_wap',
      'openno': '2',
      'lng': this.utils.getLocalStorageItem('lng'),
      'lat': this.utils.getLocalStorageItem('lat'),
    }
    if (this.pageType == 'server') {
      params['openalias'] = 'wap.saas.Serverprogram.getInfo';
      params['serverid'] = ids;
    } else {
      params['openalias'] = 'wap.saas.setmealprogram.getInfo';
      params['setmealid'] = ids;
    }
    var that = this;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        let isLogin = this.utils.getLocalStorageItem("isLogin");
        let isVipUser = this.utils.getLocalStorageItem("user_info").isVipUser; 
        if (that.pageType == 'server') {
          this.infoObj = result.data;
          this.shopInfo = result.data.shopInfo;
          this.productlist = result.data.productlist;
          if(result.data.isvipprice == 2&&isLogin&&isVipUser==1){
            this.sellPrice = result.data.vipprice;
          }else {
            this.sellPrice = result.data.sellprice;
          }
        } else {
          this.infoObj = result.data.setmeal;
          this.shopInfo = result.data.shopInfo;
          this.serverList = result.data.server;
          if(result.data.setmeal.isvipprice == 2&&isLogin&&isVipUser==1){
            this.sellPrice = result.data.setmeal.vipprice;
          }else {
            this.sellPrice = result.data.setmeal.sellprice;
          }
        }
        this.getTicketList();
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }
  // 拨打电弧
  public callTel() {
    window.location.href = "tel:" + this.shopInfo['tel'];
  }
  // 门店首页，店铺
  public goShopIndex() {
    this.router.navigate(['home'], { queryParams: { shopid: this.shopInfo['shopid'] } });
  }
  // 立即购买
  public goPayOrder() {
    if (this.whetherLogin()) {
      this.router.navigate(['ServicePackageOrderConfirm'], { queryParams: { shopid: this.shopInfo['shopid'], pid: this.pid, productType: (this.pageType == 'server'?2:3) } });
    }
  }
  whetherLogin() {
    if (!this.isLogin) {
      this.utils.setLocalStorageItem('isWechatAuth', false);
      this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }

  public getTicketList() {
    let param = {
      'plat': 'plat_wap',
      'openno': '1',
      'pchannel': 3,
      'openalias': 'wap.ticket.GetUseTicket.getList',
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      'productid': this.pid,//产品id
      'shopid': this.shopInfo['shopid'],//领取的门店ID
      'producttype': this.pageType=='package'?4:3
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        let arr = result.data;
        for(let i =0; i < arr.length;i++){
          arr[i].hidden = true;
        }
        this.ticketList = arr;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }

  public openCoupon() {
    this.isShow = false;
  }
  public toClose() {
    this.isShow = true;
  }

  public getVoucher(pid){
    let param = {
      'plat': 'plat_wap',
      'openno': '1',
      'pchannel': 3,
      'openalias': 'wap.ticket.Ticketuser.addTicket',
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      'shareId': pid,//产品id
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert('领取成功！')
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }

  public showDetails(index){
    this.ticketList[index].hidden = !this.ticketList[index].hidden;
  }

}
