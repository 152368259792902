import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WelfareCentreDetialComponent } from './welfare-centre-detial.component';
import { DataShowModule } from "../../component/data-show/data-show.module";//数据状态展示
@NgModule({
  imports: [
    RouterModule.forRoot([{ path: 'welfareCentreDetial', component: WelfareCentreDetialComponent }]),
    CommonModule,
    DataShowModule,
  ],
  declarations: [WelfareCentreDetialComponent]
})
export class WelfareCentreDetialModule { }
