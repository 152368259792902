import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { MimeQrcodeComponent } from '../mime-qrcode/mime-qrcode.component'

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'mimeQrcode', component: MimeQrcodeComponent }]),
        QRCodeModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        MimeQrcodeComponent
    ],
    providers: [],
})
export class MimeQrcodeModule { }