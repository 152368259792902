import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
@Component({
  selector: 'app-my-info',
  templateUrl: './my-info.component.html',
  styleUrls: ['./my-info.component.css']
})
export class MyInfoComponent implements OnInit {
  imgs = 'wap/assets/images/init/default_image.png'
  arr = [];
  city = '';
  topImg = '';
  nickname = '';
  gender = '';
  birthday = '';
  detailedAddress = '';
  constructor(
    public nativeService: NativeService, 
    public utils: Utils, 
    public http: HttpService,
    public routeInfo: ActivatedRoute, 
    public router: Router) { }

  ngOnInit() {
    this.getMyInfoObj();
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

    // 获取我的资料
  public getMyInfoObj () {
    let params = {
      'openalias': 'wap.member.member.getInfo',
      'plat': 'plat_wap',
      'openno': '1',
      'userid': this.utils.getLocalStorageItem('user_id'),
      'user_token': this.utils.getLocalStorageItem('user_token'),
    }
    let that = this;
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(res => {
      this.nativeService.hideLoading();
      if (res.code == 100) {
        console.log(res);
        let obj = res.data;
        // let codeArr = [0,440000,440100,440103,440103001];
        // obj.provinceid,obj.cityid,obj.areaid,obj.streetid
        that.getCityList(0, obj.provinceid);
        that.getCityList(obj.provinceid, obj.cityid);
        that.getCityList(obj.cityid, obj.areaid);
        that.getCityList(obj.areaid, obj.streetid);
        // that.getCityList(0,440000);
        // that.getCityList(440000,440100);
        // that.getCityList(440100,440103);
        // that.getCityList(440103,440103001);
        // that.setData({
        //   topImg: obj.head_thumb,
        //   nickname: obj.name,
        //   gender: obj.gender == 1 ? '男' : '女',
        //   birthday: obj.birthday == ''?'2021-01-01':obj.birthday,
        //   detailedAddress: obj.address,
        // })
        that.topImg = obj.head_thumb;
        that.nickname = obj.name;
        that.gender = obj.gender == 1 ? '男' : '女';
        that.birthday = obj.birthday == ''?'2021-01-01':obj.birthday;
        that.detailedAddress = obj.address;
      } else {
        that.nativeService.showErrorAlert(res.msg);
      }
    })
  }
    // 获取城市列表
  public getCityList(pcode, code) {
    let newarr = this.arr;
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.area.area.getList',
      'pcode': pcode //0 440000 440100 440103
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': param
    }).then(res => {
      if (res.code == 100) {
        for (let i = 0; i < res.data.list.length; i++) {
          if (res.data.list[i].code == code) {
            newarr.push(res.data.list[i].name);
            that.arr = newarr;
            console.log(res.data.list[i].name);
            console.log(that.arr);
            that.city =  that.arr.join('')
          }
        }
      } else {
        that.nativeService.showErrorAlert(res.msg);
      }
    })
      // http.GET(param, function (res) {
      //   if (res.code == 100) {
      //     for (let i = 0; i < res.data.list.length; i++) {
      //       if (res.data.list[i].code == code) {
      //         newarr.push(res.data.list[i].name);
      //         that.setData({
      //           arr: newarr
      //         })
      //         console.log(res.data.list[i].name);
      //         console.log(that.data.arr);
      //         that.setData({
      //           city: that.data.arr.join('')
      //         })
      //       }
      //     }
      //   } else {
      //     util.showToast(res.msg);
      //   }
      // }, function (error) {
      //   util.showToast("请稍后重试!");
      // });
  }

}
