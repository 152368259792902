import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";
@Component({
  selector: 'app-financial-details',
  templateUrl: './financial-details.component.html',
  styleUrls: ['./financial-details.component.css']
})
export class FinancialDetailsComponent implements OnInit {

  public list = [];
  public isNoData = false;
  public showFooter = false;
  public isNoMore = false;
  public page = 1;
  public total
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.getFinancialDetails();
  }

  public getFinancialDetails() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.shareholder.index.shareholderLogs',
      'plat': 'plat_wap',
      'openno': '1',
      'shopid': this.utils.getLocalStorageItem("shopid"),
      'page': this.page,
      'limit': 20
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      this.showFooter = false;
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result.data));
        // this.list = result.data;
        this.total = result.data.total ;
        if (this.page == 1) {
          if (result.data.list == undefined || result.data.list.length == 0) {
            this.isNoData = true;
          } else {
            this.list = result.data.list;
          }
        } else {
          if (result.data.list == undefined || result.data.list.length == 0) {
            this.isNoMore = true;
          } else {
            this.list = this.list.concat(result.data.list);
          }
        }
        if(this.total>0&&!this.isNoData){
          if(this.list.length>=this.total){
            this.isNoMore=true ;
          }
        }
        this.page++;
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    })
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

  public onScrollDown(){
    if(this.showFooter || this.isNoData || this.isNoMore )return ;
    this.showFooter = true ;
    this.getFinancialDetails();
  }
}
