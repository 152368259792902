import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisCouoonDetailComponent } from './dis-couoon-detail.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'discouoondetail', component:  DisCouoonDetailComponent}]),
        FormsModule,
        ReactiveFormsModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        DisCouoonDetailComponent
    ],
    providers: [],
})
export class DisCouoonDetailModule{

}