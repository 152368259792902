import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { HttpService } from "../../providers/HttpService";
import { NativeService } from '../../providers/NativeService';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-graphic-check',
  templateUrl: './graphic-check.component.html',
  styleUrls: ['./graphic-check.component.css']
})
export class GraphicCheckComponent implements OnInit {
  @Input() isShow: boolean;
  @Output() child = new EventEmitter<any>();
  public verifycode = "";
  public imgKey = "";
  public imagePath;
  constructor(public sanitizer: DomSanitizer,public http: HttpService, public nativeService: NativeService, private el: ElementRef) {
    this.imgRequest();
  }
  ngOnInit() {
    
  }
  //获取验证图片
  public imgRequest() {
    this.verifycode = "";
    var that = this;
    let params = {
      'plat': 'plat_wap',
      'openno': '1',
      "openalias": "wap.captcha.tpcaptcha.getVerify",
    }
    that.http.request({
      method: 'GET',
      url: 'api/index',
      data: params,
    }).then(result => {
      if (result.code == 100 && result.data) {
        that.imgKey = result.data.key;
        that.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
                 + result.data.code);
      } else {
          that.nativeService.showWarningAlert(result.data.msg);
      }
    });
  }
  public closeBox() {
    this.child.emit(0);
    this.imgRequest();
  }
  public imgClick() {
    this.imgRequest();
  }
  public commitClick() {
    let that = this;
    if (that.verifycode.length > 0) {
      that.verifycode = that.verifycode.replace(/\s+/g,""); 
      that.nativeService.showLoading();
      let params = {
        'plat': 'plat_wap',
        'openno': '1',
        "openalias": "wap.captcha.tpcaptcha.verify",
        'code': that.verifycode,
        "key":that.imgKey
      }
      that.http.request({
        method: 'POST',
        url: 'api/index',
        data: params,
      }).then(result => {
        console.log(JSON.stringify(result));
        that.nativeService.hideLoading();
        if (result.code == 100 && result.data.status == 2) {
          // that.nativeService.showSuccessAlert(result.data.msg);
          that.child.emit(result.data.verifytoken);
        } else {
          that.imgRequest();
          that.nativeService.showWarningAlert(result.data.msg);
        }
      });
    } else {
      that.nativeService.showWarningAlert("验证字符不能为空!");
    }

  }

}
