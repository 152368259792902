import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CouponShareComponent } from './coupon-share.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'couponShare', component:  CouponShareComponent}]),
        FormsModule,
        ReactiveFormsModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        CouponShareComponent
    ],
    providers: [],
})
export class CouponShareModule{

}