import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from "ngx-infinite-scroll";//上拉下拉
import { FooterRefreshModule } from "../component/footer-refresh/footer-refresh.module";//加载和刷新
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { ServeSetMealDetailComponent } from './serve-set-meal-detail.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([{ path: 'serveSetMealDetail', component: ServeSetMealDetailComponent }]),
    InfiniteScrollModule,
    FooterRefreshModule,
    LazyLoadImageModule,
  ],
  declarations: [ServeSetMealDetailComponent]
})
export class ServeSetMealDetailModule { }
