//门店购物车
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DataShowModule } from "../component/data-show/data-show.module";//数据状态展示
import { LazyLoadImageModule } from 'ng-lazyload-image';//图片懒加载
import { ShoppingCartComponent } from "../shopping-cart/shopping-cart.component"
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'shoppingCart', component: ShoppingCartComponent }]),
        DataShowModule,
        LazyLoadImageModule
    ],
    exports: [],
    declarations: [
        ShoppingCartComponent
    ],
    providers: [],
})
export class ShoppingCartModule { }