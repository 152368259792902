import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogisticsDetailsComponent } from '../logistics-details/logistics-details.component';
import { DataShowModule } from '../component/data-show/data-show.module';
import { RouterModule } from '@angular/router'

@NgModule({
    imports: [
        CommonModule,
        DataShowModule,
        RouterModule.forRoot([{ path: 'logistics-details', component: LogisticsDetailsComponent }])
    ],
    declarations: [LogisticsDetailsComponent],
    exports: [],
    providers: []

})
export class LogisticsDetailsModule { }