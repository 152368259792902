import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NativeService } from '../providers/NativeService';
import { HttpService } from "../providers/HttpService"
import { SHARE_URL } from "../providers/Constants";
import { WeChatShareService } from "../providers/weChatShareService";
import { Utils } from '../providers/Utils';
import { Title } from '@angular/platform-browser';
import { NgZone } from '@angular/core';

declare var zymedia: any;
@Component({
  selector: 'app-activity-page',
  templateUrl: './activity-page.component.html',
  styleUrls: ['./activity-page.component.css']
})
export class ActivityPageComponent implements OnInit {

  public title = "基利斯门店系统";
  public iswx = true;
  public videoIndex = 0;
  public videoSrc = '';
  public srcList = [
    'wap/assets/images/init/1.png',
    'wap/assets/images/init/2.png',
    'wap/assets/images/init/3.png',
    'wap/assets/images/init/4.png',
    'wap/assets/images/init/5.png',
    'wap/assets/images/init/6.png',
    'wap/assets/images/init/7.png',
    'wap/assets/images/init/8.png',
    'wap/assets/images/init/9.png',
    'wap/assets/images/init/10.png'
  ]
  public videoList = [];
  public player: any = 'jls_000001.mp4';
  public activeVideoIndex = 0;
  public isAndroid = false;
  public isIOS = false;
  @ViewChild('videos') videos: ElementRef;
  @ViewChild('videoBox') videoBox: ElementRef;
  constructor(
    public nativeService: NativeService, 
    public routerInfo: ActivatedRoute, 
    public http: HttpService, 
    public shareService: WeChatShareService, 
    public router: Router, 
    public utils: Utils, 
    public titleServers: Title, 
    public zone: NgZone, 
    public routeInfo: ActivatedRoute
  ) { }

  ngOnInit() {
    var u = navigator.userAgent;
    console.log(u);
    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
      console.log(1)//安卓手机
      this.isAndroid = true;
    } else if (u.indexOf('iPhone') > -1) {
      console.log(2)//苹果手机
      this.isIOS = true;
    } else if (u.indexOf('Windows Phone') > -1) {
      console.log(3)//winphone手机
    }

    // 判断是否是微信打开的页面
    // if (this.isWeixin()) {
    //   this.iswx = true;
    // } else {
    //   this.iswx = false;
    //   this.nativeService.showWarningAlert("请用微信打开此页面");
    // }
    this.getVideoList();
  }
  public backToPage() {
    this.router.navigate(['home']);
  }

  public isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }

  public getVideoList(){
    let params = {
      'openalias': 'wap.video.video.getList',
      'plat': 'plat_wap',
      'openno': '1',
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      // console.log(result);
      if (result.code == 100 && result.data) {
        this.videoList = result.data;
      }
    });
  }
 public toplay(i){
  // console.log(i);
  this.videoIndex = i;
  this.videoSrc = this.videoList[i-1];
  // zymedia('video');
  // 播放完成就缩回去
  let _this = this;
  this.videos.nativeElement.play() //！！！
  this.videos.nativeElement.onended = function() {
    console.log(_this.videoIndex)/*  */
    _this.videoSrc = '';
    _this.videoIndex = 0;
    
  };
 }

 
 public close(){
  this.videos.nativeElement.pause();
  this.videoSrc = '';
  this.videoIndex = 0;
  //  console.log(22)
 }
}
