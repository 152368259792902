import { Component, OnInit } from '@angular/core';
import { NativeService } from './../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../providers/HttpService"
import { Utils } from "./../providers/Utils";
@Component({
    selector: 'app-SR-order-confirm',
    templateUrl: './SR-order-confirm.component.html',
    styleUrls: ['./SR-order-confirm.component.css']
})
export class SROrderConfirmComponent implements OnInit {
    public id = '';
    public detailInfo;
    public phone;

    constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
        public routeInfo: ActivatedRoute, public router: Router) { }

    ngOnInit() {
        this.routeInfo.queryParams.subscribe(queryParams => {
            this.id = queryParams.id == undefined ? '' : queryParams.id;
            this.getDetailInfo(this.id);
        });
    }

    public backToPage() {
        this.nativeService.backToPage();
    }
    // 创建订单
    public createdOrder(phone,id){
        let params = {
            'openalias': 'wap.order.orderbuilder.thirds',
            'plat': 'plat_wap',
            'openno': 1,
            'userid': this.utils.getLocalStorageItem('user_id'),
            'user_token': this.utils.getLocalStorageItem('user_token'),
            'phone': phone,//下单充值的手机号码
            'id': id,//购买的券产品ID
            'remark': '',//订单备注
            'shopid': this.utils.getLocalStorageItem('shopid'),//下单的门店Id
            'cityid': '',//下单城市ID
        }
        this.http.request({
            'method': 'POST',
            'url': 'api/index',
            'data': params
        }).then(result => {
            // this.isShowLoading = false;
            if (result.code == 100 && result.data) {
                console.log(result.data.orderid);
                this.router.navigate(['orderPay'],{queryParams: {'orderid': result.data.orderid,}});
            } else {
                this.nativeService.hideLoading();
                this.nativeService.showErrorAlert(result.msg);
            }
        })
    }

    // 获取详情
    public getDetailInfo(id){
        let params = {
            'openalias': 'wap.product.Thirds.getInfo',
            'plat': 'plat_wap',
            'openno': 1,
            'userid': this.utils.getLocalStorageItem('user_id'),
            'user_token': this.utils.getLocalStorageItem('user_token'),
            id: id,
        }
        this.http.request({
            'method': 'POST',
            'url': 'api/index',
            'data': params
        }).then(result => {
            // this.isShowLoading = false;
            if (result.code == 100 && result.data) {
                console.log(result);
                this.detailInfo = result.data;
            } else {
                this.nativeService.hideLoading();
                this.nativeService.showErrorAlert(result.msg);
            }
        })
    }

    // 支付
    public goPaly(){
        if(this.phone == undefined || this.phone == ''){
            this.nativeService.showErrorAlert('手机号不能为空');
        }else {
            this.createdOrder(this.phone,this.id);
        }
    }
}
