import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { JlsVipComponent } from "./jls-vip.component";
@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot([{ path: 'jlsVip', component: JlsVipComponent }]),
        FormsModule,//input [(ngModel)]需要导入这个module
    ],
    exports: [],
    declarations: [
        JlsVipComponent
    ]
})
export class JlsVipModule{}