import { Component, OnInit } from '@angular/core';
import { NativeService } from './../../providers/NativeService';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from "./../../providers/HttpService"
import { Utils } from "./../../providers/Utils";

@Component({
  selector: 'app-cash-account',
  templateUrl: './cash-account.component.html',
  styleUrls: ['./cash-account.component.css']
})
export class CashAccountComponent implements OnInit {

  public isSend = false;
  public mSendCodeText = "发送验证码";
  public code = '';
  /**
   *bankName：银行名称 
   *bankAccount：银行账号
   *bankAccountName：开户人
   */
  public bankName = '';
  public bankAccount = '';
  public bankAccountName = '';
  /**
   * alipayAccount :支付宝账号
   * alipayAccountName：支付宝账号名
   */
  public alipayAccount = '';
  public alipayAccountName = '';
  public mBindCardInfo: any;
  public mTab = [{ 'name': '银行卡', 'type': 0, 'isSelect': true }, { 'name': '支付宝', 'type': 1, 'isSelect': false }];
  /**
   * @type 0:银行卡 1：支付宝
   */
  public type = 0;
  constructor(public nativeService: NativeService, public utils: Utils, public http: HttpService,
    public routeInfo: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.getBindCard();
  }

  public getBindCard() {
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.balance.Bank.getInfo',
      'plat': 'plat_wap',
      'openno': '1'
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        // console.log(JSON.stringify(result));
        // if (result.data.code) {
        //   this.nativeService.showSuccessAlert('发送成功');
        if (!result.data.accountno) {
          // console.log("未绑定卡");
        } else {
          this.mBindCardInfo = result.data;
          this.bankName = result.data.bankname;
          this.bankAccount = result.data.accountno;
          this.bankAccountName = result.data.accountname;
          // console.log("已绑定卡");
        }
      } else {
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }
  public backToPage() {
    this.nativeService.backToPage();
  }

  public select(index) {
    for (let i = 0; i < this.mTab.length; i++) {
      this.mTab[i].isSelect = false;
    }
    this.mTab[index].isSelect = true;
    this.type = this.mTab[index].type;
  }
  public isShow = false;
  public getChildData(data) {
    this.isShow = false;
    if (data != 0) this.sendCodeNow(data);
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
  }
  public sendCode() {
    if (!this.isSend) {
      this.isShow = true;
    }
  }
  public sendCodeNow(data) {
    this.isSend = true;
    this.nativeService.showLoading();
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.balance.Bank.sendCode',
      'plat': 'plat_wap',
      'openno': '1',
      'verifytoken': data
    }
    this.http.request({
      'method': 'POST',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(result.data);
        // console.log(JSON.stringify(result.data));
        if (result.data.code) {
          this.nativeService.showSuccessAlert('发送成功');
          this.resetTime();
        } else {
          this.isSend = false;
          if (result.data.msg) {
            this.nativeService.showWarningAlert(result.data.msg);
          } else {
            this.nativeService.showWarningAlert('发送失败');
          }
        }
      } else {
        this.isSend = false;
        this.nativeService.showWarningAlert(result.msg);
      }
    });
  }

  public time = 60;
  public resetTime() {
    this.isSend = true;
    let _this = this;
    _this.time = 60;
    function countdown() {
      if (_this.time > 0) {
        _this.mSendCodeText = _this.time + 's' + '后重试';
        --_this.time;
        setTimeout(countdown, 1000);
      } else {
        _this.mSendCodeText = '获取验证码';
        _this.isSend = false;
      }
    }

    countdown();
  }

  public confirm() {
    var re = /^[0-9]+.?[0-9]*$/;
    console.log('银行名称：' + this.bankName + "---银行账号：" + this.bankAccount + "---银行账号：" + this.bankAccountName + "---验证码：" + this.code);
    if (this.bankName == undefined || this.bankName.length == 0) {
      this.nativeService.showWarningAlert("请输入开户银行");
    } else if (this.bankAccount == undefined || this.bankAccount.length == 0) {
      this.nativeService.showWarningAlert("请输入银行账号");
    } else if (this.bankAccount.length < 16) {
      this.nativeService.showWarningAlert("银行账号不能小于16位");
    } else if (!this.checknumber(this.bankAccount)) {
      this.nativeService.showWarningAlert("请输入纯数字银行账号");
    } else if (this.bankAccountName == undefined || this.bankAccountName.length == 0) {
      this.nativeService.showWarningAlert("请输入账户名称");
    } else if (this.code == undefined || this.code.length == 0) {
      this.nativeService.showWarningAlert("请输入验证码");
    } else {
      this.nativeService.showLoading();
      let params = {
        'accountno': this.bankAccount,
        'accountname': this.bankAccountName,
        'bankname': this.bankName,
        'mobilecode': this.code,
        'userid': this.utils.getLocalStorageItem("user_id"),
        'user_token': this.utils.getLocalStorageItem("user_token"),
        'openalias': 'wap.balance.Bank.edit',
        'plat': 'plat_wap',
        'openno': '1'
      }
      this.http.request({
        'method': 'POST',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          // console.log(result.data);
          // console.log(JSON.stringify(result.data));
          if (result.data.code == 1) {
            this.nativeService.showSuccessAlert('绑定成功');
            this.backToPage();
          } else {
            this.nativeService.showWarningAlert(result.data.msg);
          }
        } else {
          this.nativeService.showWarningAlert(result.msg);
        }
      });
    }
  }

  public checknumber(String) {
    // var reg = /^[0-9]+.?[0-9]*$/
    let reg = /^[0-9]+$/;
    if (reg.test(String)) {
      return true
    }
    return false
  }
}
