import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NativeService } from '../providers/NativeService';
import { Utils } from '../providers/Utils'
import { WeChatShareService } from "../providers/weChatShareService";
import { HttpService } from "../providers/HttpService"
import { _require } from '../providers/require_lib';
import { SHARE_URL } from "../providers/Constants";
declare var wx: any;
@Component({
  selector: 'app-coupon-activity-share',
  templateUrl: './coupon-activity-share.component.html',
  styleUrls: ['./coupon-activity-share.component.css', '../../assets/css/animate.min.css']
})
export class CouponActivityShareComponent implements OnInit {
  public dataDic: any;
  public shopid;
  // 分享相关
  public showWXShareTip = false;
  public showAnimation = false;
  public mShareLinkParams = '';
  public showShareLinkParams = '';
  public sharePic;
  public isShare = false;
  public shareLink = "";
  public isMenu = false;
  public shareid = '';
  public showCount = false;
  constructor(public httpService: HttpService, public shareService: WeChatShareService, public routeInfo: ActivatedRoute, public nativeService: NativeService, public utils: Utils, public router: Router) { }
  ngOnInit() {
    //接收参数
    this.shopid = this.utils.getLocalStorageItem('shopid');
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.dataDic = JSON.parse(queryParams.data);
    });
    setTimeout(() => {
      this.showCount = true;
    }, 800);
    this.shareConfig();
  }
  public backToPage() {
    this.nativeService.backToPage();
  }
  public gotoCounpList() {
    this.router.navigate(['discountCoupon'], {
      queryParams: {
        'shopid': this.shopid
      }
    });
  }
  // 分享
  public share() {
    event.stopPropagation();
    if (this.isWeixin()) {
      this.showWXShareTip = true;
      this.getShareDataAndReady(this.data);
      setTimeout(() => {
        this.showWXShareTip = false;
        this.isShare = false;
        this.isMenu = false;
        this.showAnimation = false;
      }, 6000);
    } else {
      // console.log(JSON.stringify(this.dataDic));
      this.sharePic = this.dataDic.sharePic;
      var tip = "点击链接或者复制链接到浏览器打开!!!";
      this.mShareLinkParams = '【' + this.dataDic.shareTitle + '】' + this.dataDic.shareDetail + SHARE_URL + '#/couponDetail?id=' + this.dataDic.id + '&shopid=' + this.dataDic.shop + '&type=wap' + '&shareid=' + this.utils.getLocalStorageItem("user_id") + tip;
      this.showShareLinkParams = '【' + this.dataDic.shareTitle + '】' + this.dataDic.shareDetail;
      this.isShare = true;
      this.showAnimation = true;
    }
  }
  public isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }

  public shareCopylink() {
    this.showAnimation = false;
    this.nativeService.showSuccessAlert("复制成功,快去粘贴分享吧~");
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  }
  public cancelShare() {
    this.showAnimation = false;
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  };

  public successFun() {
    // this.nativeService.showSuccessAlert("复制成功,快去粘贴吧~");
  }
  public showWXShareTipClick() {
    this.showWXShareTip = false;
    this.isShare = false;
  }

  public data;
  public shareConfig() {
    if (this.isWeixin()) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.httpService.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        if (result.code == 100 && result.data) {
          this.data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.getShareDataAndReady(this.data);
        }
      });
    }
  }
  public shareSuccess(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  public shareCancel(that, res) {
    that.showWXShareTip = false;
    that.showAnimation = false;
    that.isShare = false;
    that.isMenu = false;
    that.cdr.markForCheck();
    that.cdr.detectChanges();
  }
  // 对分享信息进行处理，并注册分享函数
  public getShareDataAndReady(data) {
    wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp + '',
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareQZone',
        'onMenuShareWeibo']
    });
    let shareDesc = this.dataDic.shareDetail;
    let shareImg = this.dataDic.sharePic;
    this.shareLink = SHARE_URL + '#/couponDetail?id=' + this.dataDic.id + '&shopid=' + this.dataDic.shop + '&type=wap' + '&shareid=' + this.utils.getLocalStorageItem("user_id");
    let link = this.shareLink;
    let timelineTitle = this.dataDic.shareCircleDetail;
    let frendTitle = this.dataDic.shareTitle;
    var that = this;
    wx.ready(function () {
      wx.onMenuShareTimeline({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareAppMessage({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQQ({
        title: frendTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareQZone({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
      wx.onMenuShareWeibo({
        title: timelineTitle, // 分享标题
        desc: shareDesc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareImg, // 分享图标
        success: function (res) {
          // 设置成功
          that.shareSuccess(that, res);
        },
        cancel: function (res) {
          // 用户取消分享后执行的回调函数
          that.shareCancel(that, res);
        }
      });
    });
  }
}