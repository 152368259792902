import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { Utils } from '../providers/Utils';
import { HttpService } from '../providers/HttpService'

@Component({
  selector: 'app-mime-qrcode',
  templateUrl: './mime-qrcode.component.html',
  styleUrls: ['./mime-qrcode.component.css']
})
export class MimeQrcodeComponent implements OnInit ,OnDestroy {
  public name;
  public mobile;
  public portrait;
  public seconds = 0;
  public qrCodeName = 'Test the qr code';
  public isSend = true;
  constructor(public router: Router, public routeInfo: ActivatedRoute, public nativeService: NativeService, public utils: Utils, public http: HttpService) { }
  backToPage() {
    this.nativeService.backToPage();
  }
  ngOnInit() {
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.name = queryParams.name;
      this.mobile = queryParams.mobile;
      this.portrait = queryParams.portrait;
    });

    this.getQrCode();
  }
  
  ngOnDestroy(){
    this.isSend = false;
    // console.log("页面销毁了");
  }

  public getQrCode() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.member.qrcode.creat',
      'plat': 'plat_wap',
      'openno': '1'
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      // this.isShowLoading = false;
      if (result.code == 100 && result.data) {
        // this.isShowPage = true;
        // console.log(JSON.stringify(result.data));
        this.qrCodeName = result.data.content;
        this.seconds = parseInt(result.data.seconds / 10 + "");
        // console.log(JSON.stringify(this.qrCodeName + "---"));
        // this.resetTime(this.seconds);
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  public resetTime(time) {
    let _this = this;
    function countdown() {
      if (!_this.isSend) return;
      // console.log(time)
      if (time > 0) {
        --time;
        setTimeout(countdown, 1000);
      } else {
        _this.getQrCode();
      }
    }
    countdown();
  }
}
